import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import FilterProjectComponent from '../FilterProjectComponent';
import defaultProjectImage from '../../assests/Projects Icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { projectReducer } from 'src/redux/projects';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import {
  getConstructionBilling,
  getDARReport,
  getDLRReport,
  getLabourExpense,
  getMusterRollData,
  getProgressReport,
  getProjectProgressReport,
  reportsReducer,
} from 'src/redux/reports';
import moment from 'moment';
import toast from 'react-hot-toast';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { downloadFIeldNoteReport } from 'src/redux/fieldNote';
registerLocale('es', es);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    textTransform: 'capitalize',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  [theme.breakpoints.down('sm')]: {},
}));

const MusterRoll = ({
  constructionBilling = false,
  isMonthPicker = false,
  isProjectProgressReport,
  handleBack,
  labourExpenseReport,
  isFormT = false,
  isIssueLog = false,
  DAR = false,
  DLR = false,
  isProgressReport = false,
  historicalReport = false,
}) => {
  const [currentProject, setCurrentProject] = useState('');
  const [classifiedProject, setClassifiedProject] = useState('');
  const [reportSelectedDate, setReporteSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangeHistory, setDateRangeHistory] = useState([null, null]);
  const [startDateHistory, endDateHistory] = dateRangeHistory;
  //   const Access = {}
  //  Access["MUSTERROLL_MANAGEMENT"] =
  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();

  //! LIFECYCLE
  useEffect(() => {
    // dispatch(getProjectsClassificationList());
  }, []);

  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const { loading } = useSelector(reportsReducer);

  const classifyProject = (project) => {
    if (project) {
      setCurrentProject(project);
    } else {
      setCurrentProject('');
    }
  };

  const onChangeDateReport = (update, isDateEnd) => {
    // let [start, end] = update;

    if (isDateEnd) {
      return setEndDate(update);
    }
    setReporteSelectedDate(update);

    // Calculate the start and end of the week
    // const startOfWeek = moment(date).startOf("week"); // Sunday
    // const endOfWeek = moment(date).endOf("week"); // Saturday

    // if (date) {
    //  dispatch(
    //    getAttendanceTrendline(
    //      currentProject?.projectId,
    //      startOfWeek.format("YYYY-MM-DD HH:mm:ss"),
    //      endOfWeek.format("YYYY-MM-DD HH:mm:ss")
    //    )
    //  );
    //  setAttendanceCurrentWeek({
    //    startOfWeek,
    //    endOfWeek,
    //  });
    // } else {
    //  setAttendanceCurrentWeek(null);
    //  dispatch(
    //    getAttendanceTrendline(
    //      currentProject?.projectId,
    //      moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
    //      moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
    //    )
    //  );
    // }
  };

  const downloadExcelFile = async () => {
    if ((DAR || DLR) && (!dateRangeHistory[0] || !dateRangeHistory[1])) {
      return toast.error('Please Select Date to Download');
    }
    let resp = await dispatch(
      constructionBilling
        ? getConstructionBilling(
            currentProject?.projectId,
            moment(reportSelectedDate).format('YYYY-MM-DD')
          )
        : isProjectProgressReport
        ? getProjectProgressReport(
            currentProject?.projectId,
            moment(reportSelectedDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD')
          )
        : labourExpenseReport
        ? getLabourExpense(
            currentProject?.projectId,
            moment(reportSelectedDate).format('YYYY-MM-DD')
          )
        : isIssueLog
        ? downloadFIeldNoteReport(currentProject?.projectId)
        : DLR
        ? getDLRReport(
            currentProject.projectId,
            0,
            true,
            moment(dateRangeHistory[0])
              .utcOffset(0, true)
              .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            moment(dateRangeHistory[1])
              .utcOffset(0, true)
              .format('YYYY-MM-DDTHH:mm:ss[Z]')
          )
        : DAR
        ? getDARReport(
            currentProject.projectId,
            0,
            true,
            moment(dateRangeHistory[0])
              .utcOffset(0, true)
              .format('YYYY-MM-DDTHH:mm:ss[Z]'),
            moment(dateRangeHistory[1])
              .utcOffset(0, true)
              .format('YYYY-MM-DDTHH:mm:ss[Z]')
          )
        : isProgressReport
        ? getProgressReport(
            currentProject?.projectId,
            moment(reportSelectedDate).format('YYYY-MM-DD')
          )
        : getMusterRollData(
            currentProject?.projectId,
            isFormT
              ? moment(reportSelectedDate)
                  .startOf('month')
                  .format('YYYY-MM-DD HH:mm:ss')
              : moment(startDateHistory).format('YYYY-MM-DD'),
            isFormT
              ? moment(reportSelectedDate)
                  .endOf('month')
                  .format('YYYY-MM-DD HH:mm:ss')
              : moment(endDateHistory).format('YYYY-MM-DD')
          )
    );
    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute(
        'download',
        constructionBilling
          ? `Construction Billing Forecast ${moment(reportSelectedDate).format(
              'MMM - YYYY'
            )}.xlsx`
          : isProjectProgressReport
          ? `Project Progress ${moment(reportSelectedDate).format(
              'MMM - YYYY'
            )}.xlsx`
          : labourExpenseReport
          ? `Labour Expense ${moment(reportSelectedDate).format(
              'MMM - YYYY'
            )}.xlsx`
          : isIssueLog
          ? `Issue Log ${moment(reportSelectedDate).format('MMM - YYYY')}.xlsx`
          : DAR
          ? `Daily Attendance Report ${moment(dateRangeHistory[0]).format(
              'MMM - YYYY'
            )} - ${moment(dateRangeHistory[1]).format('MMM - YYYY')} .xlsx`
          : DLR
          ? `Daily Labour Report ${moment(dateRangeHistory[0]).format(
              'MMM - YYYY'
            )} - ${moment(dateRangeHistory[1]).format('MMM - YYYY')} .xlsx`
          : isProgressReport
          ? `Daily Progress Report ${moment(reportSelectedDate).format(
              'MMM - YYYY'
            )}.xlsx`
          : `Muster Roll ${
              historicalReport
                ? moment(startDateHistory).format('YYYY-MM-DD') +
                  '-' +
                  moment(endDateHistory).format('YYYY-MM-DD')
                : moment(reportSelectedDate).format('MMM - YYYY')
            }.xlsx`
      );

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleDateRangeHistory = (update) => {
    setDateRangeHistory(update);
  };

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justify="space-between"
        style={{ padding: '0 1rem' }}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          style={{ textTransform: 'capitalize' }}
          onClick={handleBack}
        >
          Back
        </Button>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justify="space-between"
        style={{ padding: '1rem' }}
      >
        <Grid container item xs={12}>
          <Grid item container alignItems="center">
            <Grid item>
              <Typography variant="subtitle">Select Project</Typography>
            </Grid>
            <Grid item>
              <FilterProjectComponent
                projectClassificationList={projectClassificationList}
                src={
                  !classifiedProject[0]?.url
                    ? defaultProjectImage
                    : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                }
                value={currentProject}
                onChange={classifyProject}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            item
            md={4}
            xs={12}
            className={classes.marginTop}
            style={{ marginTop: '1rem' }}
            justify="space-between"
          >
            {' '}
            {isIssueLog ? null : (
              <Grid item style={{ marginRight: '10px' }}>
                <Typography variant="subtitle1">
                  {isProjectProgressReport
                    ? 'Select Start Date'
                    : historicalReport
                    ? 'Select Date Range'
                    : isProgressReport
                    ? 'Select Date'
                    : 'Select Month'}
                </Typography>
              </Grid>
            )}
            {historicalReport ? (
              <Grid
                item
                style={{
                  position: 'relative',
                }}
                xs={7}
              >
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  selectsRange={true}
                  startDate={startDateHistory}
                  endDate={endDateHistory}
                  onChange={(update) => {
                    handleDateRangeHistory(update);
                  }}
                  isClearable={true}
                  className="font12 date-picker "
                />
                {!startDateHistory && (
                  <EventNoteIcon
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '7px',
                    }}
                  />
                )}
              </Grid>
            ) : isIssueLog ? null : DAR || DLR ? (
              <Grid
                item
                style={{
                  position: 'relative',
                }}
                xs={7}
              >
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  selectsRange={true}
                  startDate={startDateHistory}
                  endDate={endDateHistory}
                  onChange={(update) => {
                    handleDateRangeHistory(update);
                  }}
                  isClearable={true}
                  className="font12 date-picker "
                />
                {!startDateHistory && (
                  <EventNoteIcon
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '7px',
                    }}
                  />
                )}
              </Grid>
            ) : (
              <Grid item xs={7}>
                <DatePicker
                  showMonthYearPicker={
                    isProjectProgressReport || isProgressReport
                      ? false
                      : isMonthPicker
                  }
                  dateFormat={
                    isProjectProgressReport || isProgressReport
                      ? 'MMM dd yyyy'
                      : 'MMM yyyy'
                  }
                  selected={reportSelectedDate}
                  onChange={(update) => onChangeDateReport(update, false)}
                  // startDate={startDate}
                  // endDate={endDate}
                  // selectsRange
                  isClearable={true}
                  className="border-datepicker"
                  popperPlacement="top-start"
                  // className="font12 date-picker"
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [205, 50],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                  placeholderText={
                    isProjectProgressReport || isProgressReport
                      ? 'Date Filter'
                      : 'Month Filter'
                  }
                />
              </Grid>
            )}
          </Grid>
          {isProjectProgressReport && (
            <Grid
              container
              item
              md={4}
              xs={12}
              className={classes.marginTop}
              style={{ marginTop: '1rem', marginLeft: '10px' }}
              justify="space-between"
            >
              {' '}
              <Grid item>
                <Typography variant="subtitle1">Select End Date</Typography>
              </Grid>
              <Grid item xs={7}>
                <DatePicker
                  showMonthYearPicker={false}
                  dateFormat={'MMM dd yyyy'}
                  selected={endDate}
                  onChange={(update) => onChangeDateReport(update, true)}
                  // startDate={startDate}
                  // endDate={endDate}
                  // selectsRange
                  isClearable={true}
                  className="border-datepicker"
                  popperPlacement="top-start"
                  // className="font12 date-picker"
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [205, 50],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                  placeholderText={'Date Filter'}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1rem' }}>
          <Button
            disabled={currentProject === '' || loading}
            variant="outlined"
            startIcon={<SystemUpdateAltIcon />}
            // style={{ marginLeft: "20px" }}
            onClick={downloadExcelFile}
            style={{
              backgroundColor: '#81b734',
              color: '#fff',
              textTransform: 'capitalize',
            }}
            s
          >
            {loading ? 'Downloading...' : 'Download Report'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MusterRoll;
