import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  workProgress: null,
};

const slice = createSlice({
  name: 'graphs',
  initialState,
  reducers: {
    gettinWorkProgress(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettinWorkProgressSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.workProgress = action.payload;
    },
    gettinWorkProgressFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
  },
});
const {
  gettinWorkProgress,
  gettinWorkProgressSuccess,
  gettinWorkProgressFailure,
} = slice.actions;

export const graphsReducer = (state) => state.graphs;

export const getWorkProgress = (projectId) => async (dispatch) => {
  dispatch(gettinWorkProgress());
  if (projectId) {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/graph/sow-progress-by-contractor?projectId=${projectId}`
    );
    if (response?.status === 200) {
      dispatch(gettinWorkProgressSuccess(response.data.result));
    } else {
      dispatch(
        gettinWorkProgressFailure(
          'Something went wrong while getting work progress graph data!'
        )
      );
    }
    return response;
  }
};

export default slice.reducer;
