import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../services/config";

const initialState = {
  loading: false,
  error: null,
  leads: null,
  assignLoading: false,
};

const slice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    savingLead(state, action) {
      state.loading = true;
    },
    savingLeadSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    savingLeadFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingLeads(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingLeadsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.leads = action.payload;
    },
    gettingLeadsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      // state.leads = action.payload;
    },
    assigningLead(state, action) {
      state.assignLoading = true;
      state.error = null;
    },
    assigningLeadSuccess(state, action) {
      state.assignLoading = false;
      state.error = null;
    },
    assigningLeadFailure(state, action) {
      state.assignLoading = false;
      state.error = action.payload;
      // state.leads = action.payload;
    },
  },
});
const {
  savingLead,
  savingLeadSuccess,
  savingLeadFailure,
  gettingLeads,
  gettingLeadsSuccess,
  gettingLeadsFailure,
  assigningLead,
  assigningLeadSuccess,
  assigningLeadFailure,
} = slice.actions;
export const leadReducer = (state) => state.lead;

export const saveLead = (lead) => async (dispatch) => {
  dispatch(savingLead());
  try {
    const response = await axios.post(`${config.baseUrl}/dashboard/Lead`, lead);

    if (response.status === 200) {
      dispatch(savingLeadSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(savingLeadFailure("Something went wrong while saving lead!"));
  }
};

export const getLeads = () => async (dispatch) => {
  dispatch(gettingLeads());
  try {
    const response = await axios.get(`${config.baseUrl}/dashboard/Lead`);

    if (response.status === 200) {
      dispatch(gettingLeadsSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingLeadsFailure("Something went wrong while getting leads!"));
  }
};

export const assignLead = (id) => async (dispatch) => {
  dispatch(assigningLead());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Lead/convert/${id}`
    );

    if (response.status === 200) {
      dispatch(assigningLeadSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      assigningLeadFailure("Something went wrong while assigning leads!")
    );
  }
};

export default slice.reducer;
