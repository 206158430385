import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TableContainer,
  TextField,
  Dialog,
} from '@material-ui/core';
import { assignLead, getLeads, leadReducer } from 'src/redux/lead';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import SearchInput from 'src/components/SearchInput';

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // maxWidth: '400px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    padding: '.5rem 1rem ',
    textTransform: 'capitalize',
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonStyle: {
    textTransform: 'capitalize',
    borderRadius: '10px',
    // color: "white",
    fontSize: '12px',
    fontFamily: 'Roboto',
    // "&:hover": {
    //   color:"white",
    //   // backgroundColor: "#908DA6",
    // },
  },

  filterButton: {
    // backgroundColor: "#908DA6",
    // color: "white",
    '&:hover': {
      backgroundColor: '#81B734',
      color: 'white',
    },
  },
  selectedButton: {
    color: 'white',

    backgroundColor: '#81B734',
  },

  paymentDetailField: {
    fontSize: '12px',
    padding: '.8rem',
  },
  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
  },
}));

const CustomersListView = ({ leads }) => {
  const [filteredArray, setFilteredArray] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    range: '',
    project: 0,
  });
  //!INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  const { addToast } = useToasts();
  const [packageType] = useState([
    { name: 'Free' },
    { name: 'Standard' },
    { name: 'Premium' },
    { name: 'Enterprise' },
  ]);
  const [currentSelectedButton, setCurrentSelectedButton] = useState(1);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [isPaymentDetailOpen, setIsPaymentDetailOpen] = useState(false);
  const [rowPaymentDetail, setRowPaymentDetail] = useState('');
  //! SELECTORS
  const { assignLoading } = useSelector(leadReducer);

  //! METHODS
  const handleAssignLead = async (id) => {
    let resp = await dispatch(assignLead(id));
    if (resp.status === 200) {
      addToast('Lead Assigned Successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      dispatch(getLeads());
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      const data = filteredArray.length ? filteredArray : leads;
      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }
    if (value === '') {
      setFilteredArray([]);
    }
  };
  const handlePackageType = (value, number) => {
    setCurrentSelectedButton(number);
    if (!value) {
      setCurrentPackage(null);
      return setFilteredArray([]);
    }
    setCurrentPackage(value);

    if (value === 'Free') {
      let newData = leads?.filter(
        (item) => item.packageType === value || !item.packageType
      );
      if (newData.length) {
        return setFilteredArray(newData);
      } else {
        return setFilteredArray([]);
      }
    }

    let newData = leads?.filter((item) => item.packageType === value);
    if (newData.length) {
      setFilteredArray(newData);
    } else {
      setFilteredArray([]);
    }
  };

  const openCustomerPaymentDetail = (customer) => {
    setIsPaymentDetailOpen(true);

    if (!customer?.customData) return setRowPaymentDetail(null);

    setRowPaymentDetail(JSON.parse(customer?.customData));
  };

  return (
    <Grid container justify="flex-start" alignItems="center" xs={12}>
      <Dialog
        // fullScreen
        maxWidth={'md'}
        fullWidth={'md'}
        open={isPaymentDetailOpen}
        onClose={() => setIsPaymentDetailOpen(false)}
        // TransitionComponent={ Transition }
      >
        <Grid container style={{ padding: '0.8rem' }}>
          <Typography>Payment Detail</Typography>
        </Grid>

        <Grid container item xs={12} className={classes.overflow}>
          {!rowPaymentDetail ? (
            <Grid
              container
              justify="center"
              xs={12}
              style={{ padding: '1rem 0' }}
            >
              No Payment Record Found!
            </Grid>
          ) : (
            <Grid container style={{ margin: '1rem' }}>
              {Object.entries(rowPaymentDetail).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <TextField
                    label={key}
                    className={classes.paymentDetailField}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={value === '' ? 'N/A' : value}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Dialog>
      <Grid container justify="space-between" xs={12} item>
        {leads?.length && (
          <Grid
            container
            md={6}
            sm={12}
            style={{ paddingBottom: '10px' }}
            alignItems="center"
          >
            <Grid item style={{ paddingRight: '10px' }}>
              <Button
                className={`${classes.buttonStyle} ${
                  currentSelectedButton === 1
                    ? classes.selectedButton
                    : classes.filterButton
                } `}
                size="small"
                variant="outlined"
                onClick={() => handlePackageType(null, 1)}
              >
                {' '}
                All:
                <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                  {leads?.length}
                </span>
              </Button>
            </Grid>
            <Grid item style={{ paddingRight: '10px' }}>
              <Button
                className={`${classes.buttonStyle} ${
                  currentSelectedButton === 2
                    ? classes.selectedButton
                    : classes.filterButton
                } `}
                size="small"
                variant="outlined"
                onClick={() => handlePackageType('Free', 2)}
              >
                {' '}
                Free:
                <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                  {
                    leads?.filter(
                      (item) => item.packageType === 'Free' || !item.packageType
                    )?.length
                  }
                </span>
              </Button>
            </Grid>
            <Grid item style={{ paddingRight: '10px' }}>
              <Button
                className={`${classes.buttonStyle} ${
                  currentSelectedButton === 3
                    ? classes.selectedButton
                    : classes.filterButton
                }`}
                size="small"
                variant="outlined"
                onClick={() => handlePackageType('Standard', 3)}
              >
                {' '}
                Standard:{' '}
                <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                  {
                    leads?.filter((item) => item.packageType === 'Standard')
                      ?.length
                  }
                </span>
              </Button>
            </Grid>
            <Grid
              item
              className={classes.marginTop}
              style={{ paddingRight: '10px' }}
            >
              <Button
                className={`${classes.buttonStyle} ${
                  currentSelectedButton === 4
                    ? classes.selectedButton
                    : classes.filterButton
                }`}
                size="small"
                variant="outlined"
                onClick={() => handlePackageType('Premium', 4)}
              >
                Premium:{' '}
                <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                  {
                    leads?.filter((item) => item.packageType === 'Premium')
                      ?.length
                  }
                </span>
              </Button>
            </Grid>
            <Grid item className={classes.marginTop}>
              <Button
                className={`${classes.buttonStyle} ${
                  currentSelectedButton === 5
                    ? classes.selectedButton
                    : classes.filterButton
                }`}
                size="small"
                variant="outlined"
                onClick={() => handlePackageType('Enterprise', 5)}
              >
                Enterprise:{' '}
                <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                  {
                    leads?.filter((item) => item.packageType === 'Enterprise')
                      ?.length
                  }
                </span>
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={4}
          container
          justify="flex-end"
          alignItems="center"
        >
          <Grid item style={{ padding: '0 5px' }} xs={2}>
            <small>Search:</small>
          </Grid>
          <Grid item style={{ position: 'relative' }} xs={10}>
            <SearchInput
              search={filters.search}
              handleSearch={handleSearch}
              // className='font12'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: '1rem' }}
        className={classes.overflow}
      >
        <TableContainer>
          <Table
            // stickyHeader
            style={{
              position: 'relative',
              opacity: assignLoading ? '.3' : 1,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.headerCellText1}>
                  #
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  FIRST NAME
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  LAST NAME
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  COMPANY NAME
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  EMAIL
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  PROFESSION{' '}
                </TableCell>

                <TableCell align="left" className={classes.headerCellText1}>
                  PHONE NUMBER{' '}
                </TableCell>

                <TableCell align="left" className={classes.headerCellText1}>
                  ACTIVE PLAN
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  PAYMENT AMOUNT{' '}
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  PAYMENT DATE{' '}
                </TableCell>
                <TableCell align="left" className={classes.headerCellText1}>
                  PAYMENT DETAILS
                </TableCell>

                {/* <TableCell align="right" className={classes.headerCellText1}>
                  ACTION
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {assignLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                  }}
                >
                  <CircularProgress style={{ color: '#81B734' }} />
                </div>
              )}
              {leads?.length &&
              filteredArray?.length === 0 &&
              filters?.search === '' &&
              !currentPackage ? (
                leads?.map((val, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val.firstName ?? 'N/A'}
                    </TableCell>{' '}
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val.lastName ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val.companyName ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val.email ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val.Profession}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val.phoneNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.packageType ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.customData
                        ? (() => {
                            try {
                              const customDataObj = JSON.parse(val.customData);
                              return '₹' + customDataObj?.amount;
                            } catch (error) {
                              return 'Invalid JSON';
                            }
                          })()
                        : 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.customData
                        ? (() => {
                            try {
                              const customDataObj = JSON.parse(val?.customData);
                              return customDataObj?.trans_date;
                            } catch (error) {
                              return 'Invalid JSON';
                            }
                          })()
                        : 'N/A'}
                    </TableCell>
                    {/* <TableCell
                      align="right"
                      className={classes.headerCellText}
                      width="40%"
                    >
                      <Button
                        // disabled
                        className={`${classes.buttonPurpleOpacity}`}
                        style={{
                          padding: ".6rem 1rem",
                          borderRadius: "9px",
                          fontSize: "11px",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        size="small"
                        disabled={val.isConverted ?? false}
                        // startIcon={<ReceiptIcon />}
                        onClick={() => handleAssignLead(val.leadId)}
                      >
                        Assign
                      </Button>
                    </TableCell> */}
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      <Button
                        className={classes.buttonPurpleOpacity}
                        onClick={() => openCustomerPaymentDetail(val)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (filters?.search !== '' || currentPackage) &&
                filteredArray?.length === 0 ? (
                <TableRow>No Search Found!</TableRow>
              ) : (
                filteredArray?.map((val, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val?.firstName ?? 'N/A'}
                    </TableCell>{' '}
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val?.lastName ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.companyName ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val?.email ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="15%"
                    >
                      {val?.Profession}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.phoneNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.packageType ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.customData
                        ? (() => {
                            try {
                              const customDataObj = JSON.parse(val.customData);
                              return '₹' + customDataObj?.amount;
                            } catch (error) {
                              return 'Invalid JSON';
                            }
                          })()
                        : 'N/A'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      {val?.customData
                        ? (() => {
                            try {
                              const customDataObj = JSON.parse(val?.customData);
                              return customDataObj?.trans_date;
                            } catch (error) {
                              return 'Invalid JSON';
                            }
                          })()
                        : 'N/A'}
                    </TableCell>
                    {/* <TableCell
                      align="right"
                      className={classes.headerCellText}
                      width="40%"
                    >
                      <Button
                        // disabled
                        className={`${classes.buttonPurpleOpacity}`}
                        style={{
                          padding: ".6rem 1rem",
                          borderRadius: "9px",
                          fontSize: "11px",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        size="small"
                        disabled={val.isConverted ?? false}
                        // startIcon={<ReceiptIcon />}
                        onClick={() => handleAssignLead(val.leadId)}
                      >
                        Assign
                      </Button>
                    </TableCell> */}
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="20%"
                    >
                      <Button
                        className={classes.buttonPurpleOpacity}
                        onClick={() => openCustomerPaymentDetail(val)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CustomersListView;
