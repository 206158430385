import React, { useEffect } from 'react';

import GraphImage from '../assests/chartDesign.png';
import Footer from '../assests/footer.png';
import { Bar } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkProgress, graphsReducer } from 'src/redux/graphs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    borderRadius: '10px',

    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    textTransform: 'capitalize',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  cardPaddingRight1: {
    paddingRight: '1.5rem',
  },
  padRight: {
    paddingRight: '0.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '100%',
  },

  caroselHeight: {
    height: '38em',
  },
  cardMarginRight: {
    marginRight: '2rem',
  },
  cardHeight: {
    height: '450px',
    marginBottom: '0px',
  },
  paddingRight: {
    paddingRight: '1.5rem',
  },
  customWidth: {
    maxWidth: 80,
  },
  robotoFont: {
    fontFamily: 'Roboto',
  },
  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },

    padRight: {
      paddingRight: '0',
    },
    padLeft: {
      paddingLeft: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
    cardMarginRight: {
      marginRight: '0px',
    },
    cardHeight: {
      height: '40em',
      marginBottom: '1.5rem',
    },
    paddingRight: {
      paddingRight: '0',
    },
    cardPaddingRight1: {
      paddingRight: '0',
    },
  },
}));
const TempComponent = ({ projectId }) => {
  const classes = useStyles();
  let dispatch = useDispatch();

  const { workProgress } = useSelector(graphsReducer);

  useEffect(() => {
    dispatch(getWorkProgress(projectId));
  }, [projectId]);

  function trimLastWord(inputString, wordToRemove) {
    // Find the position of the last occurrence of the word
    const lastIndex = inputString.lastIndexOf(wordToRemove);

    // If the word is found, remove it from the string
    if (lastIndex !== -1) {
      // Extract parts of the string before and after the word
      const beforeWord = inputString.substring(0, lastIndex);
      const afterWord = inputString.substring(lastIndex + wordToRemove.length);

      // Trim any extra spaces left behind and return the result
      return (beforeWord + afterWord).trim();
    }

    // If the word is not found, return the original string
    return inputString;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    legend: {
      // position: 'top',
      display: false,
    },
    title: {
      display: false,
      // text: 'Work Progress',
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          const datasetIndex = tooltipItem[0].datasetIndex;
          const datasetLabel = data.datasets[datasetIndex].label || '';
          return datasetLabel.includes('Cumulative')
            ? trimLastWord(datasetLabel, 'Cumulative')
            : datasetLabel;
        },
        label: function (tooltipItem, data) {
          const actualValue =
            data?.datasets[tooltipItem.datasetIndex]?.actualValue[
              tooltipItem.index
            ];
          const label = data.datasets[tooltipItem.datasetIndex].label.includes(
            'Cumulative'
          )
            ? ''
            : 'Yesterday';
          const previousProgressValue =
            data?.datasets[tooltipItem.datasetIndex]?.previousProgressValue[
              tooltipItem.index
            ];

          return [
            `${actualValue} % ${label}`,
            '',
            `₹ ${previousProgressValue.toLocaleString()}`,
          ];
        },
        // afterLabel: function (tooltipItem, data) {
        //   const total = data.datasets.reduce((sum, dataset) => {
        //     return sum + dataset.data[tooltipItem.index];
        //   }, 0);
        //   return `Total: ${total}%`;
        // },
      },
      backgroundColor: '#000',
      titleFontSize: 16,
      titleFontColor: '#fff',
      bodyFontColor: '#fff',
      bodyFontSize: 14,
      displayColors: false,
    },
  };

  // Extracting data for the chart
  const labels = workProgress?.length
    ? workProgress.map((item) => {
        return `${item.sow}  ${
          item.overallProgress
        } % \n ₹${item.overallProgressValue.toLocaleString()}`;
      })
    : [];
  const contractors = new Set();

  workProgress?.forEach((item) => {
    item?.progressByContractor?.forEach((contractor) => {
      contractors.add(contractor.contractorName);
    });
  });

  const backgroundColors = [
    'rgba(129, 183, 52, 1)',
    'rgba(161, 121, 242, 1)',
    'rgba(129, 183, 52, 0.7)',
    'rgba(161, 121, 242, 0.7)',
    'rgba(129, 183, 52, 0.3)',
    'rgba(161, 121, 242, 0.3)',
  ];
  // Find the maximum value

  // Flatten the nested arrays and get the maximum values
  const maxPreviousProgressInPercent = Math.max(
    ...(workProgress?.flatMap((item) =>
      item.progressByContractor
        ? item.progressByContractor.map(
            (subItem) => subItem?.previousProgressInPercent ?? -Infinity
          )
        : []
    ) ?? [])
  );

  const maxYesterdayProgressInPercent = Math.max(
    ...(workProgress?.flatMap((item) =>
      item.progressByContractor
        ? item.progressByContractor.map(
            (subItem) => subItem?.yesterdayProgressInPercent ?? -Infinity
          )
        : []
    ) ?? [])
  );

  // Find the maximum value between maxPreviousProgressInPercent and maxYesterdayProgressInPercent
  const maxValue = Math.max(
    maxPreviousProgressInPercent,
    maxYesterdayProgressInPercent
  );

  const datasets = Array.from(contractors).flatMap((contractorName, index) => {
    const previousProgressData = labels.map((label) => {
      const sow = workProgress.find(
        (item) =>
          `${item.sow}  ${
            item.overallProgress
          } % \n ₹${item.overallProgressValue.toLocaleString()}` === label
      );
      const contractor = sow?.progressByContractor?.find(
        (c) => c.contractorName === contractorName
      );
      let percent =
        contractor?.previousProgressInPercent <= 0
          ? 0
          : (contractor?.previousProgressInPercent / maxValue) * 100;
      return contractor
        ? {
            percent,
            actualValue:
              contractor?.previousProgressInPercent <= 0
                ? 0
                : contractor?.previousProgressInPercent,
            previousProgressValue: contractor.previousProgressValue,
          }
        : { percent: 0, actualValue: 0 };
    });

    const yesterdayProgressData = labels.map((label) => {
      const sow = workProgress.find((item) => item.sow === label);
      const contractor = sow?.progressByContractor?.find(
        (c) => c.contractorName === contractorName
      );
      let percent =
        contractor?.yesterdayProgressInPercent <= 0
          ? 0
          : (contractor?.yesterdayProgressInPercent / maxValue) * 100;
      return contractor
        ? {
            percent,
            actualValue:
              contractor?.yesterdayProgressInPercent <= 0
                ? 0
                : contractor?.yesterdayProgressInPercent,
            previousProgressValue: contractor.previousProgressValue,
          }
        : { percent: 0, actualValue: 0 };
    });

    return [
      {
        label: `${contractorName} Cumulative`,
        data: previousProgressData?.map((item) =>
          item?.percent < 2 && item?.percent > 0 ? 4 : item?.percent
        ),
        // data: [12, 55, 12, 44],
        actualValue: previousProgressData?.map((item) => item.actualValue),
        previousProgressValue: previousProgressData?.map(
          (item) => item.previousProgressValue
        ),

        backgroundColor:
          backgroundColors[(index * 2) % backgroundColors.length], // Alternate colors
      },
      {
        label: `${contractorName}`,
        data: yesterdayProgressData?.map((item) =>
          item?.percent < 2 && item?.percent > 0 ? 4 : item?.percent
        ),
        actualValue: previousProgressData?.map((item) => item.actualValue),
        previousProgressValue: previousProgressData?.map(
          (item) => item.previousProgressValue
        ),

        // data: [12, 55, 12, 44],
        backgroundColor:
          backgroundColors[(index * 2 + 1) % backgroundColors.length], // Alternate colors
      },
    ];
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return (
    <Grid container style={{ paddingTop: '1rem' }}>
      <Grid item container xs={12}>
        <Card
          elevation={6}
          style={{
            borderRadius: 10,
            width: '100%',
            cursor: 'pointer',
            height: '450px',
          }}
        >
          <CardContent style={{ padding: '16px' }}>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="space-between"
            >
              <Grid item>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="space-between"
                  style={{ marginBottom: '2.5rem' }}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle"
                      style={{
                        fontSize: 20,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      {' '}
                      Work Progress
                    </Typography>
                  </Grid>
                  <Grid item container xs={5} justify="flex-end">
                    <Grid container item md={6} xs={12}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-around"
                        style={{
                          background: '#F9F9F9',
                          borderRadius: 10,
                          marginRight: '10px',
                          height: '60px',
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{ fontFamily: 'Roboto', fontSize: 12 }}
                          >
                            Total <br /> Days Left
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 25,
                              color: '#21D940',
                            }}
                          >
                            {true ? (
                              '0'
                            ) : (
                              <CircularProgress
                                style={{
                                  color: '#21D940',

                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      item
                      md={6}
                      xs={12}
                      className={classes.marginTop}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        width: '100%',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 12 }}
                        >
                          Total
                          <br />
                          Progress
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 25,
                            color: '#21D940',
                          }}
                        >
                          {true ? (
                            '0%'
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    height: '260px',
                    width: '100%',
                  }}
                >
                  <Bar options={options} data={data} />
                </Grid>
              </Grid>
              <Grid
                item
                container
                justify="flex-end"
                alignItems="center"
                style={{ marginTop: '2.6rem' }}
              >
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Cumulative
                  </Typography>
                  <div className="skilled-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Yesterday’s Progress
                  </Typography>
                  <div className="supervisor-box"></div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>{' '}
      </Grid>
      {/* <Grid item container xs={6}>
        <Card
          elevation={6}
          style={{
            borderRadius: 10,
            width: '100%',
            cursor: 'pointer',
            height: '450px',
          }}
        >
          <CardContent style={{ padding: '16px' }}>
            <Grid
              item
              container
              alignItems="center"
              justify="space-between"
              // style={{ margin: '1rem 0 1rem 0' }}
            >
              <Grid item>
                <Typography
                  variant="subtitle"
                  style={{
                    fontSize: 20,
                    fontWeight: '500',
                    fontFamily: 'Roboto',
                    // color: "#D9D9D9",
                  }}
                >
                  {' '}
                  Workforce Composition
                </Typography>
              </Grid>
              <Grid item container xs={7} justify="flex-end">
                <Grid container item md={6} xs={12}>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      // marginRight: '10px',
                      height: '60px',
                      // padding: '0 10px',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 12 }}
                      >
                        Avg: <br /> Time Worked
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 25,
                          color: '#21D940',
                          lineHeight: 0.5,
                        }}
                      >
                        {true ? (
                          '8.5'
                        ) : (
                          <CircularProgress
                            style={{
                              color: '#21D940',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                      <small>Hours</small>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                height: '300px',
              }}
            >
              {' '}
              <Doughnut data={workforceData} options={workforceOptions} />
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              alignItems="center"
              style={{ marginTop: '2rem' }}
            >
              <Grid
                item
                style={{
                  marginRight: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                    fontSize: '11px',
                  }}
                >
                  Helper
                </Typography>
                <div
                  className="supervisor-box"
                  style={{ backgroundColor: '#FF6384' }}
                ></div>
              </Grid>
              <Grid
                item
                style={{
                  marginRight: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                    fontSize: '11px',
                  }}
                >
                  Carpenter
                </Typography>
                <div
                  className="skilled-box"
                  style={{ backgroundColor: '#36A2EB' }}
                ></div>
              </Grid>
              <Grid
                item
                style={{
                  marginRight: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                    fontSize: '11px',
                  }}
                >
                  Barbender
                </Typography>
                <div
                  className="skilled-box"
                  style={{ backgroundColor: '#FFCE56' }}
                ></div>
              </Grid>
              <Grid
                item
                style={{
                  marginRight: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                    fontSize: '11px',
                  }}
                >
                  Mason
                </Typography>
                <div
                  className="skilled-box"
                  style={{ backgroundColor: '#FF9F40' }}
                ></div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>{' '}
      </Grid> */}
    </Grid>
  );
};

export default TempComponent;
