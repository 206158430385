import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkerForDetail, workersReducer } from 'src/redux/workers';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LockIcon from '@material-ui/icons/Lock';

import { useToasts } from 'react-toast-notifications';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { saveLead } from 'src/redux/lead';
import placeholder from '../../assests/worker2.png';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Page from 'src/components/Page';
import { default as ReactSelect, components } from 'react-select';
import { getAbbreviation } from 'src/App';
import {
  getLoggedInUserDetail,
  updateLoggedInUserProfile,
  usersReducer,
} from 'src/redux/users';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyleHeading: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '22px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle1: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    // color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      // fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#fff',
    },
  },
  floatingLabelFocusStyle: {
    color: '#F9F9F9',
  },
  input1: {
    borderRadius: '10px',
    // backgroundColor: 'rgba(126, 131, 134, 0.1)',
    backgroundColor: 'transparent',
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(126, 131, 134, 0.1)',
    // backgroundColor: 'transparent',
  },
  profilePictureContainer: {
    paddingLeft: '1rem',
    paddingTop: '2rem',
    // position: 'absolute',
    // top: '50%',
  },
  greenCard: {
    backgroundColor: '#81B734',
    height: '260px',
    borderRadius: '11px',
    marginRight: '10px',
    // position: 'relative',
  },
  hideSmallScreen: {
    display: 'block',
  },
  placeholderStyle: {
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
  hRow: {
    marginLeft: '25px',
    borderLeft: '1px solid rgba(200, 200, 200, 1)',
    padding: '10px 0',
    height: '40px',
  },
  skillAndAdharFieldContainer: {
    paddingLeft: '4rem',
    paddingTop: '4.5rem',
    // border: '2px solid red',
  },
  whiteFieldContainer: {
    paddingLeft: '4rem',
    paddingTop: '5rem',
  },
  rightPadding: {
    paddingRight: '1rem',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  showSmall: {
    display: 'none',
  },
  [theme.breakpoints.down('xs')]: {
    hideSmallScreen: {
      display: 'none',
    },
    showSmall: {
      display: 'block',
    },
  },
  [theme.breakpoints.down('sm')]: {
    greenCard: {
      backgroundColor: '#81B734',
      height: '550px',
      borderRadius: '11px',
      // marginRight: '10px',
      // position: 'relative',
    },
    hRow: {
      marginLeft: '15px',
      borderLeft: '1px solid #fff',
      padding: '10px 0',
      height: '30px',
    },
    skillAndAdharFieldContainer: {
      paddingLeft: '1rem',
      // paddingTop: "1rem",
    },
    profilePictureContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
      // position: 'absolute',
      // top: '50%',
    },
    whiteFieldContainer: {
      paddingLeft: '0', //'5.5rem',
      paddingTop: '0 ', //'1rem',
    },
    adharCardFiledContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
    },
    rightPadding: {
      paddingRight: '0',
    },
  },
}));
const ResetScreen = ({ classes, moveBackToProfileScreen }) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10} style={{ margin: ' 1rem 0' }}>
        <TextField
          // disabled={disabled}
          // id='lastName'
          // name='lastName'
          // value={formik.values.lastName}
          // onChange={formik.handleChange}
          // error={
          // formik.touched.lastName && Boolean(formik.errors.lastName)
          // }
          fullWidth
          InputProps={{
            disableUnderline: true,
            classes: {
              input: `${classes.textFieldStyle}  `,
              root: classes.input,
            },
          }}
          inputProps={{ style: { fontFamily: 'Roboto' } }}
          size="small"
          variant="filled"
          label="Old Password"
        />
      </Grid>

      <Grid item xs={10} style={{ margin: ' 1rem 0' }}>
        <TextField
          // disabled={disabled}
          // id='lastName'
          // name='lastName'
          // value={formik.values.lastName}
          // onChange={formik.handleChange}
          // error={
          // formik.touched.lastName && Boolean(formik.errors.lastName)
          // }
          fullWidth
          InputProps={{
            disableUnderline: true,
            classes: {
              input: `${classes.textFieldStyle}  `,
              root: classes.input,
            },
          }}
          inputProps={{ style: { fontFamily: 'Roboto' } }}
          size="small"
          variant="filled"
          label="New Password"
        />
      </Grid>

      <Grid item xs={10} container justify="flex-end">
        <Button
          size="small"
          className={classes.buttonGreen}
          style={{ padding: '7px 1.5rem', marginRight: '1rem' }}
          onClick={moveBackToProfileScreen}
        >
          Cancel
        </Button>
        <Button
          size="small"
          className={classes.buttonPurple}
          style={{ padding: '7px 1.5rem', borderRadius: '0px' }}
        >
          Reset Password
        </Button>
      </Grid>
    </Grid>
  );
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const UserProfile = () => {
  const [disabled, setDisabled] = useState(true);

  //! INSTANCES
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { addToast } = useToasts();
  let { user } = JSON.parse(localStorage.getItem('userDetails'));
  const [optionSelected, setOptionSelected] = useState(null);

  let leadObject = user?.lead;
  let generalList = [
    'Earthwork',
    'Civil Works',
    'Finishes',
    'Electrical Works - LT',
    'Electrical Works - HT',
    'Plumbing Works',
    'HVAC Works',
    'Glazing',
    'Fixed Millworks',
    'Swimming Pools & Water Bodies',
    'Mechanical Works',
    'Miscellaneous',
  ];
  let subContactor = [
    'Earthwork',
    'Civil Works',
    'Finishes',
    'Electrical Works - LT',
    'Electrical Works - HT',
    'Plumbing Works',
    'HVAC Works',
    'Glazing',
    'Fixed Millworks',
    'Swimming Pools & Water Bodies',
    'Mechanical Works',
    'Miscellaneous',
    'Diaphragm Wall',
    'Slope Protection',
    'Special Dewateri',
    'Housekeeping',
    'Tree-Cutting',
    'Soil Testing',
    'House Keeping',
    'Grading',
    'Landscaping',
    'Demolition',
  ];

  let labourContactor = [
    'Earthwork',
    'Civil Works',
    'Excavation',
    'Backfilling',
    'Piling',
    'Waterproofing',
    'Structural Formwor',
    'Barbending',
    'Roads & Highways',
    'Flooring',
    'Cladding',
    'Skirting',
    'Dadoing',
    'Doors',
    'Windows',
    'Painting',
    'Polishing',
    'Structural Glazing',
    'Grills, Louvres and Railings',
    'Rolling Shutters',
    'False Ceiling',
    'Cornice',
    'Pylons',
    'Special Elevation Features',
    'Roofing',
    'Countertops',
    'Fire Doors, Service shaft doors (Material + Labor)',
    'Shower Glass Partition, Mirrors',
    'Counter Slab',
    'Miscellaneous Finishes',
    'Metal Fabrication',
    'UPVC Works',
    'Furnishings',
    'Marblework',
  ];

  //! SELECTORS
  const { loggedInUserDetail } = useSelector(usersReducer);

  const [preview, setPreview] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);

  //! LIFECYCLE
  useEffect(() => {
    dispatch(getLoggedInUserDetail());
  }, []);

  const validationSchema = yup.object({
    // firstName: yup.string().required("First name is required"),
    // lastName: yup.string().required("Last name is required"),
    // companyName: yup.string().required("Company Name is required"),
    // email: yup.string().required("Email is required"),
    // phoneNumber: yup.string().required("Phone Number is required"),
    // panNumber: yup.string().required("PAN number is required"),
  });

  const formik = useFormik({
    initialValues: {
      leadId: 0,
      firstName: loggedInUserDetail?.lead?.firstName ?? 'N/A',
      lastName: loggedInUserDetail?.lead?.lastName ?? 'N/A',
      fullName: loggedInUserDetail?.fullName ?? 'N/A',
      userTypeId: user?.userTypeId ?? 'N/A',
      companyName: loggedInUserDetail?.companyName ?? 'N/A',
      email: loggedInUserDetail?.email ?? 'N/A',
      phoneNumber: loggedInUserDetail?.phoneNumber ?? 'N/A',
      leadTypeId: loggedInUserDetail?.leadTypeId ?? 'N/A',
      accountType: loggedInUserDetail?.leadTypeId ?? 'N/A',
      customerId: loggedInUserDetail?.leadTypeId
        ? getAbbreviation(loggedInUserDetail?.leadTypeId) +
          loggedInUserDetail?.userId
        : loggedInUserDetail?.userTypeId + loggedInUserDetail?.userId,
      address: loggedInUserDetail?.address ?? 'N/A',
      city: loggedInUserDetail?.city ?? 'N/A',
      state: loggedInUserDetail?.state ?? 'N/A',
      bankName: loggedInUserDetail?.bankName ?? 'N/A',
      bankAccountNumber: loggedInUserDetail?.bankAccountNumber ?? 'N/A',
      ifscCode: loggedInUserDetail?.ifscCode ?? 'N/A',
      pinCode: loggedInUserDetail?.pinCode ?? 'N/A',
      country: loggedInUserDetail?.country ?? 'N/A',
    },
    enableReinitialize: true,

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // let resp = await dispatch(saveLead(values));
      let resp = await dispatch(
        updateLoggedInUserProfile({
          fullName: values.fullName,
          companyName: values.companyName,
          phoneNumber: values.phoneNumber,
          scopeOfWork: ['string'],
          address: values.address,
          city: values.city,
          country: values.country,
          state: values.state,
          pinCode: values.pinCode,
          bankName: values.bankName,
          bankAccountNumber: values.bankAccountNumber,
          ifscCode: values.ifscCode,
        })
      );
      if (resp.status === 200) {
        dispatch(getLoggedInUserDetail());

        // formik.resetForm();
        // navigate("/app/dashboard");
        setDisabled(true);
        return addToast('User Saved Successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      if (resp?.response?.data) {
        return addToast(resp.response.data, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
  });

  const handleBack = () => {
    navigate(-1);
    dispatch(setWorkerForDetail(null));
  };

  const handleProjectImage = (e) => {
    // let selectedImage = e.target.files[0];
    setPreview(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue('image', e.target.files[0], true);
  };
  const openResetScreen = () => {
    setResetPassword(true);
  };
  const closeResetScreen = () => {
    setResetPassword(false);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#81B734' : '#F4F6F8',
      color: '#222',
      // zIndex: 9999, // Set a high zIndex for the options
      ':hover': {
        backgroundColor: '#81B734',
      },
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#986FEF',
      // zIndex: 9999, // Set a high zIndex for multiValueLabel
      // backgroundColor: "rgba(161, 120, 242, 0.20)"
    }),
    control: (provided, state) => ({
      ...provided,
      height: '56px',
      zIndex: 9999,
      borderRadius: '10px',
      backgroundColor: '#e8eaed',
      border: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6B818C', // Change the color of the placeholder text
      fontSize: '12px', // Change the font size of the placeholder text
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Set the z-index to a high value to ensure it's above other elements
    }),
  };
  const handleChange = (selected) => {
    setOptionSelected(selected);

    // formik.setFieldValue("projectIds", selected);
  };

  const submitUserProfile = () => {
    formik.submitForm();
  };
  return (
    <>
      <Page className={classes.root} id="zoom-level" title="Profile">
        {resetPassword ? (
          <ResetScreen
            moveBackToProfileScreen={closeResetScreen}
            classes={classes}
          />
        ) : (
          <Grid container style={{ zoom: '90%' }}>
            <Grid container xs={12} className={classes.greenCard}>
              <Grid xs={12} className={classes.showSmall}>
                <Grid style={{ marginTop: '10px' }}>
                  <Grid
                    container
                    justify="flex-end"
                    style={{ padding: '0 10px' }}
                  >
                    {disabled ? (
                      <Grid item>
                        <Button
                          className={classes.buttonPurple}
                          onClick={() => setDisabled(false)}
                        >
                          Edit
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button className={classes.buttonGreen}>Update</Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                // container
                // jusity='center'
                className={classes.profilePictureContainer}
              >
                <Grid item style={{ paddingTop: '4rem' }}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={handleProjectImage}
                  />
                  <label
                    htmlFor="icon-button-file"
                    className={classes.ImagePlaceHolder}
                  >
                    {user?.profile?.url && !preview ? (
                      <img
                        src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${user?.profile?.url}`}
                        alt="project  url"
                        style={{
                          width: '250px',
                          height: '250px',
                          borderRadius: '8px',
                          boxShadow:
                            '3px 5px 20px 0px rgba(156, 176, 195, 0.15)',

                          // border: Boolean(formik.errors.image) && '1px solid red',
                        }}
                      />
                    ) : preview ? (
                      <img
                        src={preview}
                        alt="project  prev"
                        style={{
                          width: '250px',
                          height: '250px',
                          borderRadius: '5px',
                          // border: Boolean(formik.errors.image) && '1px solid red',
                        }}
                      />
                    ) : (
                      <img
                        src={placeholder}
                        alt="project  plac"
                        style={{
                          width: '250px',
                          height: '250px',
                          borderRadius: '5px',
                          // border: Boolean(formik.errors.image) && '1px solid red',
                        }}
                      />
                    )}
                  </label>
                  <p
                    style={{
                      margin: '4px 14px 0px',
                      color: 'red',
                      fontSize: '12px',
                      fontWeight: '0',
                    }}
                  >
                    {' '}
                    {formik.touched.image && formik.errors.image}
                  </p>
                </Grid>
              </Grid>
              <Grid
                md={6}
                xs={12}
                className={classes.skillAndAdharFieldContainer}
              >
                <Grid item md={6} xs={8}>
                  <TextField
                    className={classes.placeholderStyle}
                    label="CUSTOMER ID"
                    fullWidth
                    variant="filled"
                    value={formik.values.customerId}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    // onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyleHeading} `,
                        root: classes.input1,
                      },
                      // endAdornment: (
                      //   <InputAdornment position="start">
                      //     <img
                      //       src={tickSquare}
                      //       alt="square"
                      //       style={{ width: "25px" }}
                      //     />
                      //   </InputAdornment>
                      // ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={8}>
                  <TextField
                    className={classes.placeholderStyle}
                    label="FULL NAME"
                    fullWidth
                    variant="filled"
                    value={user?.fullName}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyleHeading} `,
                        root: classes.input1,
                      },
                      // endAdornment: (
                      //   <InputAdornment position="start">
                      //     <img
                      //       src={tickSquare}
                      //       alt="square"
                      //       style={{ width: "25px" }}
                      //     />
                      //   </InputAdornment>
                      // ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  // justify='space-between'
                  alignItems="center"
                >
                  <Grid item md={5}>
                    <TextField
                      label="EMAIL ADDRESS"
                      fullWidth
                      variant="filled"
                      className={classes.placeholderStyle}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={user?.emailAddress}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: `${classes.textFieldStyle1}  `,
                          root: classes.input1,
                        },
                      }}
                    />
                    {/* <TextField
								label='SKILL LEVEL'
								fullWidth
								variant='filled'
								className={classes.placeholderStyle}
								InputLabelProps={{
									className: classes.floatingLabelFocusStyle,
								}}
								value={selectedWorker?.workerSkills[0]?.skillTypeId ?? 'N/A'}
								InputProps={{
									disableUnderline: true,
									classes: {
										input: `${classes.textFieldStyle1}  `,
										root: classes.input1,
									},
								}}
							/> */}
                  </Grid>
                  {/* <Grid item xs={1} className={classes.hRow}></Grid> */}
                </Grid>
              </Grid>
              <Grid xs={4} className={classes.hideSmallScreen}>
                <Grid style={{ marginTop: '10px' }}>
                  <Grid
                    container
                    justify="flex-end"
                    style={{ padding: '0 10px' }}
                  >
                    {disabled ? (
                      <Grid item>
                        <Button
                          className={classes.buttonPurple}
                          onClick={() => setDisabled(false)}
                        >
                          Edit
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button
                          className={classes.buttonGreen}
                          onClick={submitUserProfile}
                        >
                          Update
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} justify="flex-end">
              <Grid
                container
                // flexDirection='column'
                md={10}
                sm={12}
                className={classes.whiteFieldContainer}
              >
                <div className="whiteFieldContainer2nd">
                  <Grid item container xs={12} justify="space-between">
                    <Grid
                      item
                      xs={12}
                      // md={6}
                      style={{
                        marginBottom: '1rem',
                      }}
                      // className={classes.rightPadding}
                    >
                      <TextField
                        label="FULL NAME"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        value={formik.values.fullName}
                        onChange={(e) =>
                          formik.setFieldValue('fullName', e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                          endAdornment: (
                            <InputAdornment>
                              <PersonOutlinedIcon
                                style={{ color: 'rgba(168, 175, 183, 1)' }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        disabled={disabled}
                      />
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <TextField
                        label="LAST NAME"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        value={formik.values.lastName}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                          endAdornment: (
                            <InputAdornment>
                              <PersonOutlinedIcon
                                style={{ color: "rgba(168, 175, 183, 1)" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      // md={6}
                      style={{
                        marginBottom: '1rem',
                      }}
                      // className={classes.rightPadding}
                    >
                      {/* <TextField
                        label="ACCOUNT TYPE"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        value={leadObject?.packageType ?? "N/A"}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                          endAdornment: (
                            <InputAdornment>
                              <PersonOutlinedIcon
                                style={{ color: "rgba(168, 175, 183, 1)" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        /> */}
                      {/* value={leadObject?.packageType ?? "N/A"} */}
                      <FormControl
                        variant="filled"
                        style={{ width: '100%', borderRadius: '10px' }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          ACCOUNT TYPE
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formik.values.accountType}
                          onChange={(e) =>
                            formik.setFieldValue('accountType', e.target.value)
                          }
                          // variant="filled"
                          style={{ width: '100%' }}
                        >
                          <MenuItem value={'Developer'}>Developer</MenuItem>
                          <MenuItem value={'Contractor'}>
                            General Contractor
                          </MenuItem>
                          <MenuItem value={'SubContractor'}>
                            Sub Contractor
                          </MenuItem>
                          <MenuItem value={'LabourContractor'}>
                            Labour Contractor
                          </MenuItem>
                          <MenuItem value={'Consultant'}>Consultant</MenuItem>
                          <MenuItem value={'Individual'}>Individual</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        label="COMAPNY NAME"
                        fullWidth
                        variant="filled"
                        id="companyName"
                        name="companyName"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                          endAdornment: (
                            <InputAdornment>
                              <BusinessOutlinedIcon
                                style={{ color: 'rgba(168, 175, 183, 1)' }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid itme xs={12} style={{ marginBottom: '1rem' }}>
                    <TextField
                      label="EMAIL"
                      fullWidth
                      variant="filled"
                      // disabled

                      // style={{ width: '715px' }}
                      value={user.username}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: `${classes.textFieldStyle}  `,
                          root: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment>
                            <PersonOutlinedIcon
                              style={{ color: 'rgba(168, 175, 183, 1)' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                    <TextField
                      fullWidth
                      inputProps={{ style: { fontFamily: 'Roboto' } }}
                      // size="small"
                      variant="filled"
                      label="PHONE NUMBER"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: `${classes.textFieldStyle}  `,
                          root: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment>
                            <PhoneOutlinedIcon
                              style={{ color: 'rgba(168, 175, 183, 1)' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className={classes.rightPadding}
                      style={{ marginBottom: '1rem' }}
                    >
                      <TextField
                        label="PLAN TYPE"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        value={user?.lead?.packageType ?? 'N/A'}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        label="GSTIN"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        value={'N/A'}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                          // endAdornment: (
                          //   <InputAdornment>
                          //     <EventNoteOutlinedIcon
                          //       style={{ color: "rgba(168, 175, 183, 1)" }}
                          //     />
                          //   </InputAdornment>
                          // ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: '1rem',
                      zIndex: 9999, // Set a high zIndex for the surrounding Grid
                    }}
                  >
                    <span
                      className="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Please select scope work"
                      style={{
                        zIndex: 9999,

                        backgroundColor: '#e8eaed',
                        borderRadius: '10px',
                      }} // Set a high zIndex for the span
                    >
                      <ReactSelect
                        options={
                          formik.values.accountType === 'Sub Contractor'
                            ? subContactor?.map((item) => ({
                                value: item,
                                label: item,
                              }))
                            : formik.values.accountType === 'Labour Contractor'
                            ? labourContactor?.map((item) => ({
                                value: item,
                                label: item,
                              }))
                            : generalList?.map((item) => ({
                                value: item,
                                label: item,
                              }))
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={handleChange}
                        allowSelectAll={true}
                        value={optionSelected}
                        styles={customStyles}
                        placeholder="SCOPE OF WORK"
                      />
                    </span>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className={classes.rightPadding}
                      style={{ marginBottom: '1rem' }}
                    >
                      <TextField
                        label="ADDRESS"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        id="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        label="CITY"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                    <TextField
                      fullWidth
                      inputProps={{ style: { fontFamily: 'Roboto' } }}
                      // size="small"
                      variant="filled"
                      label="COUNTRY"
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      disabled={disabled}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: `${classes.textFieldStyle}  `,
                          root: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment>
                            <PhoneOutlinedIcon
                              style={{ color: 'rgba(168, 175, 183, 1)' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className={classes.rightPadding}
                      style={{ marginBottom: '1rem' }}
                    >
                      <TextField
                        label="STATE"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        id="state"
                        name="state"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        label="PINCODE"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        id="pinCode"
                        name="pinCode"
                        value={formik.values.pinCode}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.pinCode &&
                          Boolean(formik.errors.pinCode)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className={classes.rightPadding}
                      style={{ marginBottom: '1rem' }}
                    >
                      <TextField
                        label="BANK NAME"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        id="bankName"
                        name="bankName"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.bankName &&
                          Boolean(formik.errors.bankName)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}`,
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        label="BANK ACCOUNT NUMBER"
                        fullWidth
                        variant="filled"
                        // style={{ width: '715px' }}
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                        value={formik.values.bankAccountNumber}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.bankAccountNumber &&
                          Boolean(formik.errors.bankAccountNumber)
                        }
                        disabled={disabled}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: `${classes.textFieldStyle}  `,
                            root: classes.input,
                          },
                          // endAdornment: (
                          //   <InputAdornment>
                          //     <EventNoteOutlinedIcon
                          //       style={{ color: "rgba(168, 175, 183, 1)" }}
                          //     />
                          //   </InputAdornment>
                          // ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                    <TextField
                      fullWidth
                      inputProps={{ style: { fontFamily: 'Roboto' } }}
                      // size="small"
                      variant="filled"
                      label="IFCS"
                      id="ifscCode"
                      name="ifscCode"
                      value={formik.values.ifscCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.ifscCode &&
                        Boolean(formik.errors.ifscCode)
                      }
                      disabled={disabled}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: `${classes.textFieldStyle}  `,
                          root: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment>
                            <PhoneOutlinedIcon
                              style={{ color: 'rgba(168, 175, 183, 1)' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginBottom: '1rem' }}
                    justify="flex-end"
                  >
                    <Grid item>
                      <Button
                        // fullWidth
                        variant="contained"
                        style={{
                          borderRadius: '10px',
                          color: 'rgba(152, 111, 239, 1)',
                          backgroundColor: 'rgba(245, 241, 253, 1)',
                          textTransform: 'capitalize',
                          // height: "46px"
                        }}
                        onClick={openResetScreen}
                        startIcon={<LockIcon />}
                      >
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <div style={{ height: '500px' }}></div>
          </Grid>
        )}
      </Page>
    </>
  );
};

export default UserProfile;
