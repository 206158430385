import React, { useEffect, useState } from "react";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  const [sec, setSec] = useState(5);

  useEffect(() => {
    let time = setTimeout(() => {
      localStorage.clear();
      window.location.reload();
    }, 2000);

    return () => {
      clearTimeout(time);
    };
  }, []);

  setInterval(() => {
    setSec(sec - 1);
  }, 1000);

  return (
    <Page className={classes.root} id="zoom-level" title="404">
      <Box display="flex" flexDirection="column" height="100%" justify="center">
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            403: Sorry you are restricted to view this page .
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle1"
            style={{ fontWeight: 700 }}
          >
            You will be redirect to login page in {sec} seconds . . .
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/bettamintImages/noun-page-403-197642.png"
            />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
