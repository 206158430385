//! DEPENDENCIES
import React from "react";
import {
  Grid,
  Typography,
  CardMedia,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Zoom,
} from "@material-ui/core";
import ProjectImage from "src/assests/Rectangle 4242.png";

//! REDUX
import { selectProject } from "src/redux/projects";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProjectViewListItem = (props) => {
  let { classes, project } = props;

  //!INSTANCES
  let navigate = useNavigate();
  let dispatch = useDispatch();
  //! METHODS
  const showDetailsOfProject = () => {
    dispatch(selectProject(project));
    navigate("details");
  };

  const projectType = () => {
    return project.projectTypeId === "Residential"
      ? "Residential"
      : project.projectTypeId === "Commercial"
      ? "Commercial"
      : project.projectTypeId === "Hospitality"
      ? "Hospitality"
      : project.projectTypeId === "Infrastructure"
      ? "Infrastructure "
      : "N/A";
  };

  return (
    <Zoom in={project ? true : false}>
      <Card className={classes.root} style={{ boxSizing: "border-box" }}>
        <CardActionArea onClick={showDetailsOfProject}>
          <CardContent style={{ padding: "10px" }}>
            <Grid
              container
              // alignItems='center'
              justify="space-between"
            >
              <Grid item md={12} lg={4} xs={12}>
                {project ? (
                  <CardMedia
                    className={classes.media}
                    image={
                      project.url === ""
                        ? ProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${project.url}`
                    }
                    title="Project Image"
                  />
                ) : (
                  "Loading..."
                )}
              </Grid>
              <Grid item md={12} lg={7}>
                <Typography
                  // gutterBottom
                  variant="h5"
                  component="h1"
                  className={classes.projectTitle}
                >
                  {project.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.projectSubtitle}
                  gutterBottom
                >
                  {/* asd */}
                  {projectType()}
                </Typography>
                {/* <Typography
									variant='body2'
									component='p'
									className={classes.projectrera}>
									RERA ID:
									<Typography
										variant='body2'
										component='p'
										display='inline'
										className={`${classes.projectrera} ${classes.projectReraNumber}`}>
										{' '}
										{project.reraNumber}
									</Typography>
								</Typography> */}
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.remaningDays}
                    >
                      Remaining Work Days:{" "}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Typography
                      variant="h2"
                      component="h3"
                      // display='inline'
                      // noWrap
                      className={classes.remaningDays}
                      style={{
                        fontSize: false ? "42px" : "41px",
                      }}
                    >
                      {project.remainingDays ?? "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider variant="fullWidth" component="hr" />

            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={4}
                style={{ position: "relative", marginTop: ".5rem" }}
              >
                <Typography component="p" className={classes.info}>
                  Present Workers:{" "}
                </Typography>
                <Typography component="p" className={classes.infoNumber}>
                  {project.presentWorkers}
                </Typography>
                <Divider
                  variant="fullWidth"
                  component="hr"
                  orientation="vertical"
                  style={{ position: "absolute", top: "10px", right: "1px" }}
                />
              </Grid>

              <Grid
                item
                xs={4}
                style={{ position: "relative", marginTop: ".5rem" }}
              >
                <Typography component="p" className={classes.info}>
                  Percent Completion:{" "}
                </Typography>
                <Typography component="p" className={classes.infoNumber}>
                  {project.percentCompletion} %
                </Typography>
                <Divider
                  variant="fullWidth"
                  component="hr"
                  orientation="vertical"
                  style={{ position: "absolute", top: "10px", right: "1px" }}
                />
              </Grid>
              <Grid item xs={4} style={{ marginTop: ".5rem" }}>
                <Typography component="p" className={classes.info}>
                  Committed Cost / Sqft:{" "}
                </Typography>
                <Typography component="p" className={classes.infoNumber}>
                  ₹ {project.committedCostPerSqft}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

export default ProjectViewListItem;

// 					<Grid container style={{ marginTop: '.5rem', marginBottom: '1rem' }}>
// 						<Grid item xs={6}>
// 							<Chip
// 								label='Supervisor'
// 								size='small'
// 								className={classes.projectBadge}
// 							/>
//
// 							<Typography component='p' className={classes.badgeContent}>
// 								{project.supervisorName ?? 'N/A'}
// 							</Typography>
// 						</Grid>
// 						<Grid item xs={6}>
// 							<Chip
// 								label='Project Manager'
// 								size='small'
// 								className={classes.projectBadge}
// 							/>
//
// 							<Typography className={classes.badgeContent} component='p'>
// 								{project.projectManagerName ?? 'N/A'}
// 							</Typography>
// 						</Grid>
// 					</Grid>
