import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../services/config";

const initialState = {
  loading: false,
  error: null,
  counts: null,
  workforce: null,
  mandays: null,
  workerSkill: null,
  attendanceTrendlineLoading: false,
  attendanceTrendline: null,
  contractorsStatsLoading: false,
  contractorsStats: null,
  paymentsLoading: false,
  payments: null,
  attendanceMetrics: null,
  workforceMetrics: null,
  fieldNoteMetrics: null,
  fieldNoteMetricsLoading: false,
  labourExpenseMetrics: null,
  labourExpenseMetricsLoading: false,
  financialProgressMetrics: null,
  labourTurnoverMetrics: null,
  userMetrics: null,
  projectMetrics: null,
};

const slice = createSlice({
  name: "count",
  initialState,
  reducers: {
    gettingCountData(state, action) {
      state.loading = true;
      state.error = null;
      state.counts = null;
    },
    gettingCountDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.counts = action.payload;
    },
    gettingCountDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.counts = null;
    },
    gettingWorkforce(state, action) {
      // state.loading = true;
      state.error = null;
      state.workforce = null;
    },
    gettingWorkforceSuccess(state, action) {
      // state.loading = false;
      state.error = null;
      state.workforce = action.payload;
    },
    gettingWorkforceFailure(state, action) {
      // state.loading = false;
      state.error = action.payload;
      state.workforce = null;
    },
    gettingMandays(state, action) {
      // state.loading = true;
      state.error = null;
      state.mandays = null;
    },
    gettingMandaysSuccess(state, action) {
      // state.loading = false;
      state.error = null;
      state.mandays = action.payload;
    },
    gettingMandaysFailure(state, action) {
      // state.loading = false;
      state.error = action.payload;
      state.mandays = null;
    },
    gettingWorkerSkills(state, action) {
      // state.loading = true;
      state.error = null;
      state.workerSkill = null;
    },
    gettingWorkerSkillsSuccess(state, action) {
      // state.loading = false;
      state.error = null;
      state.workerSkill = action.payload;
    },
    gettingWorkerSkillsFailure(state, action) {
      // state.loading = false;
      state.error = action.payload;
      state.atten = null;
    },
    gettingAttendanceTrendline(state, action) {
      state.attendanceTrendlineLoading = true;
      state.error = null;
      state.attendanceTrendline = null;
    },
    gettingAttendanceTrendlineSuccess(state, action) {
      state.attendanceTrendlineLoading = false;
      state.error = null;
      state.attendanceTrendline = action.payload;
    },
    gettingAttendanceTrendlineFailure(state, action) {
      state.attendanceTrendlineLoading = false;
      state.error = action.payload;
      state.attendanceTrendline = null;
    },
    gettingContractorsStats(state, action) {
      state.contractorsStatsLoading = true;
      state.error = null;
      state.contractorsStats = null;
    },
    gettingContractorsStatsSuccess(state, action) {
      state.contractorsStatsLoading = false;
      state.error = null;
      state.contractorsStats = action.payload;
    },
    gettingContractorsStatsFailure(state, action) {
      state.contractorsStatsLoading = false;
      state.error = action.payload;
      state.contractorsStats = null;
    },

    gettingPayments(state, action) {
      state.paymentsLoading = true;
      state.error = null;
      state.payments = null;
    },
    gettingPaymentsSuccess(state, action) {
      state.paymentsLoading = false;
      state.error = null;
      state.payments = action.payload;
    },
    gettingPaymentsFailure(state, action) {
      state.paymentsLoading = false;
      state.error = action.payload;
      state.payments = null;
    },

    gettingAttendanceMetrics(state, action) {
      state.paymentsLoading = true;
      state.error = null;
      state.attendanceMetrics = null;
    },
    gettingAttendanceMetricsSuccess(state, action) {
      state.paymentsLoading = false;
      state.error = null;
      state.attendanceMetrics = action.payload;
    },
    gettingAttendanceMetricsFailure(state, action) {
      state.paymentsLoading = false;
      state.error = action.payload;
      state.attendanceMetrics = null;
    },
    gettingWorkforceMetrics(state, action) {
      state.paymentsLoading = true;
      state.error = null;
      state.workforceMetrics = null;
    },
    gettingWorkforceMetricsSuccess(state, action) {
      state.paymentsLoading = false;
      state.error = null;
      state.workforceMetrics = action.payload;
    },
    gettingWorkforceMetricsFailure(state, action) {
      state.paymentsLoading = false;
      state.error = action.payload;
      state.workforceMetrics = null;
    },
    gettingFieldNoteMetrics(state, action) {
      state.fieldNoteMetricsLoading = true;
      state.error = null;
      state.fieldNoteMetrics = null;
    },
    gettingFieldNoteMetricsSuccess(state, action) {
      state.fieldNoteMetricsLoading = false;
      state.error = null;
      state.fieldNoteMetrics = action.payload;
    },
    gettingFieldNoteMetricsFailure(state, action) {
      state.fieldNoteMetricsLoading = false;
      state.error = action.payload;
      state.fieldNoteMetrics = null;
    },
    gettingLabourExpenseMetrics(state, action) {
      state.labourExpenseMetricsLoading = true;
      state.error = null;
      state.labourExpenseMetrics = null;
    },
    gettingLabourExpenseMetricsSuccess(state, action) {
      state.labourExpenseMetricsLoading = false;
      state.error = null;
      state.labourExpenseMetrics = action.payload;
    },
    gettingLabourExpenseMetricsFailure(state, action) {
      state.labourExpenseMetricsLoading = false;
      state.error = action.payload;
      state.labourExpenseMetrics = null;
    },
    gettingFinancialProgressMetrics(state, action) {
      state.error = null;
      state.financialProgressMetrics = null;
    },
    gettingFinancialProgressMetricsSuccess(state, action) {
      state.error = null;
      state.financialProgressMetrics = action.payload;
    },
    gettingFinancialProgressMetricsFailure(state, action) {
      state.error = action.payload;
      state.labourExpenseMetrics = null;
    },
    gettingLabourTurnoverMetrics(state, action) {
      state.error = null;
      state.labourTurnoverMetrics = null;
    },
    gettingLabourTurnoverMetricsSuccess(state, action) {
      state.error = null;
      state.labourTurnoverMetrics = action.payload;
    },
    gettingLabourTurnoverMetricsFailure(state, action) {
      state.error = action.payload;
      state.labourTurnoverMetrics = null;
    },
    gettingUsersMetrics(state, action) {
      state.error = null;
      state.userMetrics = null;
    },
    gettingUsersMetricsSuccess(state, action) {
      state.error = null;
      state.userMetrics = action.payload;
    },
    gettingUsersMetricsFailure(state, action) {
      state.error = action.payload;
      state.userMetrics = null;
    },
    gettingProjectMetrics(state, action) {
      state.error = null;
      state.projectMetrics = null;
    },
    gettingProjectMetricsSuccess(state, action) {
      state.error = null;
      state.projectMetrics = action.payload;
    },
    gettingProjectMetricsFailure(state, action) {
      state.error = action.payload;
      state.projectMetrics = null;
    },
  },
});
const {
  gettingCountData,
  gettingCountDataSuccess,
  gettingCountDataFailure,
  gettingWorkforce,
  gettingWorkforceSuccess,
  gettingWorkforceFailure,
  gettingMandays,
  gettingMandaysSuccess,
  gettingMandaysFailure,
  gettingWorkerSkills,
  gettingWorkerSkillsSuccess,
  gettingWorkerSkillsFailure,
  gettingAttendanceTrendline,
  gettingAttendanceTrendlineSuccess,
  gettingAttendanceTrendlineFailure,
  gettingContractorsStats,
  gettingContractorsStatsSuccess,
  gettingContractorsStatsFailure,
  gettingPayments,
  gettingPaymentsSuccess,
  gettingPaymentsFailure,
  gettingAttendanceMetrics,
  gettingAttendanceMetricsSuccess,
  gettingAttendanceMetricsFailure,
  gettingWorkforceMetrics,
  gettingWorkforceMetricsSuccess,
  gettingWorkforceMetricsFailure,
  gettingFieldNoteMetrics,
  gettingFieldNoteMetricsSuccess,
  gettingFieldNoteMetricsFailure,
  gettingLabourExpenseMetrics,
  gettingLabourExpenseMetricsSuccess,
  gettingLabourExpenseMetricsFailure,
  gettingFinancialProgressMetrics,
  gettingFinancialProgressMetricsSuccess,
  gettingFinancialProgressMetricsFailure,
  gettingLabourTurnoverMetrics,
  gettingLabourTurnoverMetricsSuccess,
  gettingLabourTurnoverMetricsFailure,
  gettingUsersMetrics,
  gettingUsersMetricsSuccess,
  gettingUsersMetricsFailure,
  gettingProjectMetrics,
  gettingProjectMetricsSuccess,
  gettingProjectMetricsFailure,
} = slice.actions;
export const countReducer = (state) => state.count;

export const getLabourTurnoverMetrics =
  (projectId, dateFilter = "") =>
  async (dispatch) => {
    dispatch(gettingLabourTurnoverMetrics());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/labour-turnover-metrics?projectId=${projectId}&filterDate=${dateFilter}`
      );
      if (response.status === 200) {
        dispatch(gettingLabourTurnoverMetricsSuccess(response.data.result));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingLabourTurnoverMetricsFailure(
          "Something went wrong while getting count data!"
        )
      );
    }
  };
export const getCountsData = () => async (dispatch) => {
  dispatch(gettingCountData());
  try {
    const response = await axios.get(`${config.baseUrl}/Dashboard/stats/count`);
    if (response.status === 200) {
      dispatch(gettingCountDataSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingCountDataFailure("Something went wrong while getting count data!")
    );
  }
};

export const getFinancialProgressMetrics = (projectId) => async (dispatch) => {
  dispatch(gettingFinancialProgressMetrics());
  try {
    const response = await axios.get(
      `${config.baseUrl}/Dashboard/stats/financial-progress-metrics?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingFinancialProgressMetricsSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingFinancialProgressMetricsFailure(
        "Something went wrong while getting count data!"
      )
    );
  }
};

export const getWorkeforce = (projectId) => async (dispatch) => {
  dispatch(gettingWorkforce());
  try {
    const response = await axios.get(
      `${config.baseUrl}/Dashboard/stats/workforce?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingWorkforceSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingWorkforceFailure(
        "Something went wrong while getting workforce data!"
      )
    );
  }
};
export const getMandays = (projectId) => async (dispatch) => {
  dispatch(gettingMandays());
  try {
    const response = await axios.get(
      `${config.baseUrl}/Dashboard/stats/mandays?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingMandaysSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingMandaysFailure("Something went wrong while getting mandays data!")
    );
  }
};
export const getWorkerSkills =
  (startDate, endDate, projectId) => async (dispatch) => {
    dispatch(gettingWorkerSkills());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/workerskill?startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`
      );
      if (response.status === 200) {
        dispatch(gettingWorkerSkillsSuccess(response.data));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingWorkerSkillsFailure(
          "Something went wrong while getting workerskill data!"
        )
      );
    }
  };

export const getAttendanceTrendline =
  (projectId, startDate, endDate, createdBy = 0) =>
  async (dispatch) => {
    dispatch(gettingAttendanceTrendline());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/attendancetrendline?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}&createdBy=${createdBy}`
      );
      if (response.status === 200) {
        dispatch(gettingAttendanceTrendlineSuccess(response.data));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingAttendanceTrendlineFailure(
          "Something went wrong while getting attendance trendline data!"
        )
      );
    }
  };

export const getContractorsStats =
  (projectId, startDate, endDate, createdBy = 0) =>
  async (dispatch) => {
    dispatch(gettingContractorsStats());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/contractors?projectId=${projectId}&startDate=${startDate}`
      );
      if (response.status === 200) {
        dispatch(gettingContractorsStatsSuccess(Object.values(response?.data)));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingContractorsStatsFailure(
          "Something went wrong while getting attendance contractors data!"
        )
      );
    }
  };

export const getPayments =
  (projectId, startDate, endDate, createdBy = 0) =>
  async (dispatch) => {
    dispatch(gettingPayments());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/payments?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}&createdBy=${createdBy}`
      );
      if (response.status === 200) {
        dispatch(gettingPaymentsSuccess(Object.values(response?.data)));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingPaymentsFailure(
          "Something went wrong while getting attendance contractors data!"
        )
      );
    }
  };

export const getAttendanceMetrics = (projectId) => async (dispatch) => {
  dispatch(gettingAttendanceMetrics());
  try {
    const response = await axios.get(
      `${config.baseUrl}/Dashboard/stats/attendance-metrics?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingAttendanceMetricsSuccess(response.data?.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingAttendanceMetricsFailure(
        "Something went wrong while getting count data!"
      )
    );
  }
};

export const getWorkforceMetrics = (projectId) => async (dispatch) => {
  dispatch(gettingWorkforceMetrics());
  try {
    const response = await axios.get(
      `${config.baseUrl}/Dashboard/stats/workforce-metrics?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingWorkforceMetricsSuccess(response.data?.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingWorkforceMetricsFailure(
        "Something went wrong while getting count data!"
      )
    );
  }
};

export const getFieldNoteMetrics =
  (projectId = 0, contractorId = 0) =>
  async (dispatch) => {
    dispatch(gettingFieldNoteMetrics());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/fieldnotes?projectId=${projectId}&contractorId=${contractorId}`
      );
      if (response.status === 200) {
        dispatch(gettingFieldNoteMetricsSuccess(response.data?.result));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingFieldNoteMetricsFailure(
          "Something went wrong while getting count data!"
        )
      );
    }
  };

export const getLabourExpenseMetrics =
  (projectId = 0, contractorId = 0) =>
  async (dispatch) => {
    dispatch(gettingLabourExpenseMetrics());
    try {
      const response = await axios.get(
        `${config.baseUrl}/Dashboard/stats/labour-expense-metrics?projectId=${projectId}&contractorId=${contractorId}`
      );
      if (response.status === 200) {
        dispatch(gettingLabourExpenseMetricsSuccess(response.data?.result));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingLabourExpenseMetricsFailure(
          "Something went wrong while getting count data!"
        )
      );
    }
  };
export const getUsersMetrics = () => async (dispatch) => {
  dispatch(gettingUsersMetrics());
  try {
    const response = await axios.get(`${config.baseUrl}/Dashboard/User/stats`);
    if (response.status === 200) {
      dispatch(gettingUsersMetricsSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingUsersMetricsFailure(
        "Something went wrong while getting count data!"
      )
    );
  }
};

export const getProjectMetrics = () => async (dispatch) => {
  dispatch(gettingProjectMetrics());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Project/stats`
    );
    if (response.status === 200) {
      dispatch(gettingProjectMetricsSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingProjectMetricsFailure(
        "Something went wrong while getting count data!"
      )
    );
  }
};

export default slice.reducer;
