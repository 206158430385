import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import Billing from '../assests/billing.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
    overflow: {
      overflowX: 'hidden',
    },
  },
  square: {
    width: '120px',
    height: '120px',
    borderRadius: '20px',
  },
  squareProject: {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
  },
  projectInput: {
    fontSize: '12px !important',
  },
  marginTop: {
    marginTop: '0',
  },
  appBar: {
    position: 'relative',
  },
  pricingCard: {
    cursor: 'pointer',
    borderRadius: '10px',
    boxSizing: 'border-box',

    '&:hover': {
      border: '1px solid #A179F2',
    },
  },
  planButton: {
    backgroundColor: '#908DA6',
    borderRadius: '7px',
    textTransform: 'capitalize',
    color: 'white',
    '&:hover': {
      backgroundColor: '#80b634',
      // color: '#3c52b2',
    },
  },

  marginRight: {
    marginRight: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
    marginTop: {
      marginTop: '15px',
    },
    marginRight: {
      marginRight: '0',
    },
  },
}));

const PlaneDetails = () => {
  const [paymentPlanModal, setPaymentPlanModal] = useState(false);
  const [paymentFormModal, setPaymentFormModal] = useState(false);
  const classes = useStyles();
  const [jsObject, setJsObject] = useState(null);

  let { user } = JSON.parse(localStorage.getItem('userDetails'));
  let leadObject = user?.lead;

  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const user = userDetails?.user?.lead?.customData;
    if (user) {
      const jsObject = JSON.parse(user);
      setJsObject(jsObject);
    }
  }, []);

  const handleChangePaymentPlan = () => {
    setPaymentPlanModal(true);
  };
  const openPaymentDetailsForm = () => {
    setPaymentFormModal(true);
  };
  return (
    <Grid item container xs={12} justify="center">
      <Dialog
        maxWidth
        fullWidth
        open={paymentPlanModal}
        onClose={() => setPaymentPlanModal(false)}
      >
        <AppBar
          className={classes.appBar}
          style={{ backgroundColor: '#A179F2' }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setPaymentPlanModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid container justify="center">
          <Grid item xs={12} style={{ marginTop: '12px' }}>
            <Typography
              variant="h4"
              component="h4"
              align="center"
              style={{ fontWeight: 600 }}
            >
              Pricing
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ marginTop: '12px' }}>
            <Typography
              variant="subtitle1"
              component="h5"
              align="center"
              style={{ fontWeight: 400, fontSize: '1.3rem' }}
            >
              Access attendance, muster roll, payroll, wage distribution,
              compliance data, field notes, insights, project dashboards and
              more. Tiered subscription plans tailored for your workforce needs.
            </Typography>
          </Grid>
        </Grid>

        {/* 1.Pricing */}
        <Grid container xs={12} justify="center" style={{ padding: '1rem 0 ' }}>
          <Grid item container xs={9} style={{ borderRadius: '10px' }}>
            <Grid
              item
              container
              md={3}
              style={{ padding: '1rem', height: '250px' }}
              className={classes.pricingCard}
            >
              <Typography
                style={{ width: '100%', color: 'rgba(129, 183, 52, 1)' }}
              >
                BASIC
              </Typography>
              <div
                style={{
                  borderTop: '1px solid black',
                  width: '50px',
                  marginTop: '10px',
                }}
              ></div>

              <Grid container xs={12} alignItems="center" alignContent="center">
                <Grid item style={{ paddingRight: '10px' }}>
                  <Typography style={{ fontSize: '25px', fontWeight: 700 }}>
                    Free
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', paddingTop: '5px' }}>
                    / Month
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item style={{ marginBottom: '10px' }}>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Upto 100 Workers
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Max 1 User
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  <Button
                    fullWidth
                    className={classes.planButton}
                    onClick={openPaymentDetailsForm}
                  >
                    Choose Plan
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              md={3}
              style={{ padding: '1rem' }}
              className={classes.pricingCard}
            >
              <Typography
                style={{ width: '100%', color: 'rgba(129, 183, 52, 1)' }}
              >
                STANDARD
              </Typography>
              <div
                style={{
                  borderTop: '1px solid black',
                  width: '50px',
                  marginTop: '10px',
                }}
              ></div>

              <Grid container xs={12} alignItems="center" alignContent="center">
                <Grid item style={{ paddingRight: '10px' }}>
                  <Typography style={{ fontSize: '25px', fontWeight: 700 }}>
                    ₹5,000
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', paddingTop: '5px' }}>
                    / Month
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item style={{ marginBottom: '10px' }}>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Upto 500 Workers
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Max 5 User
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  <Button
                    fullWidth
                    className={classes.planButton}
                    onClick={openPaymentDetailsForm}
                  >
                    Choose Plan
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              md={3}
              style={{ padding: '1rem' }}
              className={classes.pricingCard}
            >
              <Typography
                style={{ width: '100%', color: 'rgba(129, 183, 52, 1)' }}
              >
                PLUS
              </Typography>
              <div
                style={{
                  borderTop: '1px solid black',
                  width: '50px',
                  marginTop: '10px',
                }}
              ></div>

              <Grid container xs={12} alignItems="center" alignContent="center">
                <Grid item style={{ paddingRight: '10px' }}>
                  <Typography style={{ fontSize: '25px', fontWeight: 700 }}>
                    ₹10,000
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', paddingTop: '5px' }}>
                    / Month
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item style={{ marginBottom: '10px' }}>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Upto 1000 Workers
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Max 10 User
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  <Button
                    fullWidth
                    className={classes.planButton}
                    onClick={openPaymentDetailsForm}
                  >
                    Choose Plan
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              md={3}
              style={{ padding: '1rem' }}
              className={classes.pricingCard}
            >
              <Typography
                style={{ width: '100%', color: 'rgba(129, 183, 52, 1)' }}
              >
                ENTERPRISE
              </Typography>
              <div
                style={{
                  borderTop: '1px solid black',
                  width: '50px',
                  marginTop: '10px',
                }}
              ></div>

              <Grid
                item
                xs={12}
                container
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <Typography style={{ fontSize: '25px', fontWeight: 700 }}>
                    Get in Touch
                  </Typography>
                </Grid>
                <Grid item style={{ paddingTop: '10px' }}></Grid>
              </Grid>

              <Grid container>
                <Grid item xs={2}>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={10} style={{ marginBottom: '10px' }}>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    For cusstom quote 1000 + Workers
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item>
                  <ArrowForwardRoundedIcon
                    style={{ color: '#848199' }}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#848199', fontSize: '14px' }}>
                    {' '}
                    Unlimited Users
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  <Button
                    fullWidth
                    className={classes.planButton}
                    onClick={openPaymentDetailsForm}
                  >
                    Choose Plan
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        // fullScreen
        maxWidth={'lg'}
        fullWidth={'lg'}
        open={paymentFormModal}
        onClose={() => setPaymentFormModal(false)}
        // TransitionComponent={ Transition }
      >
        <AppBar
          className={classes.appBar}
          style={{ backgroundColor: '#A179F2' }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setPaymentFormModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <img src={Billing} alt="form payment" />
      </Dialog>

      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Grid item>
          <Typography
            style={{
              color: 'rgba(161, 121, 242, 1)',
              fontWeight: '500',
              fontSize: '20px',
            }}
          >
            Plan Details
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: 'rgba(126, 131, 134, 1)',
            }}
          >
            HELP?
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ margin: '1rem 0 ' }}>
        {' '}
        <Divider />
      </Grid>

      <Grid item xs={12} container justify="space-between">
        <Grid item container md={7} justify="space-between" xs={12}>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>
                Payment Plan - {user?.lead?.packageType ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '400' }}
              >
                {jsObject?.amount
                  ? `₹ ${jsObject?.amount} N/A Workers/month ${
                      user?.lead?.packageType ?? 'N/A'
                    } Plan `
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>
                Subscription Charges
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '700' }}
              >
                ₹ 60,000
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>Users</Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '700' }}
              >
                30
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              item
              style={{ marginTop: '1.5rem' }}
              className={classes.marginRight}
              md={5}
              xs={12}
            >
              <Button
                fullWidth
                style={{
                  backgroundColor: 'rgba(129, 183, 52, 1)',
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  fontWeight: 500,
                  width: '205px',
                  height: '46px',
                }}
                // onClick={ handleChangePaymentPlan }
              >
                <a
                  href={'http://bettamint.com/payment.php'}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'white' }}
                >
                  Change Payment Plan
                </a>
              </Button>
            </Grid>
            {/* <Grid item style={{ marginTop: "1.5rem" }} xs={12} md={5}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "rgba(129, 183, 52, 1)",
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  padding: ".5rem 1.5rem",
                }}
                // onClick={openPaymentDetailsForm}
              >
                <a
                  href={`https://bettamint.com/payment.php?package=${
                    leadObject?.packageType === "Standard"
                      ? "Premium"
                      : "Standard"
                  }&lead_id=${leadObject?.leadId}&first_name=${
                    leadObject?.firstName
                  }&last_name=${leadObject?.lastName}&email=${
                    leadObject?.email
                  }&company=${leadObject?.companyName}&phone=${
                    leadObject?.phoneNumber
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  {" "}
                  Change Payment Method
                </a>
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          className={classes.marginTop}
          container
          justify="space-between"
        >
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>Start Date</Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '400' }}
              >
                Nov 01, 2023
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>End Date</Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '400' }}
              >
                Nov 30, 2023
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlaneDetails;
