import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { featuresReducer, getFeatures, sendFeature } from 'src/redux/features';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '1rem',
    borderRadius: '10px',
    background: '#fff',
  },
  btn: {
    fontSize: '12px',
    // backgroundColor: 'rgba(129, 183, 52, 1)',
    // color: '#fff',
    fontFamily: 'Roboto',
    padding: '.6rem 1.3rem',
    textTransform: 'capitalize',
    borderRadius: '10px',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  table: {
    minWidth: 650,
  },
  [theme.breakpoints.down('sm')]: {},
}));

const Features = () => {
  //! INSTANCES
  let dispatch = useDispatch();
  let classes = useStyles();
  const { addToast } = useToasts();

  //! SELECTORS
  const { loading, featuresList, error } = useSelector(featuresReducer);

  const getFeatureCall = async () => {
    await dispatch(getFeatures());
  };

  //! LIFE-CYCLES
  useEffect(() => {
    if (featuresList.length === 0) {
      getFeatureCall();
    }
  }, [featuresList.length]);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    route: yup.string().required('Route is required'),
  });

  const formik = useFormik({
    initialValues: {
      featureSetId: 0,
      name: '',
      route: '',
      icon: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let resp = await dispatch(sendFeature(values));
      if (resp?.status === 200) {
        formik.resetForm();
        getFeatureCall();
        return addToast('Feature Set Successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      }
    },
  });

  return (
    <Grid container justify="center">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item container xs={11}>
        <TableContainer
          component={Paper}
          style={{
            // marginTop: '2rem',
            borderRadius: '10px',
            padding: '.5rem',
          }}
        >
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
              <Grid
                item
                container
                className={`${classes.mainContainer}`}
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Grid item md={3} xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    placeholder="Feature Name"
                    fullWidth
                    inputProps={{
                      style: { fontFamily: 'Roboto' },
                    }}
                    size="small"
                    variant="outlined"
                  />{' '}
                  <p
                    style={{
                      margin: '4px 14px 0px',
                      color: 'red',
                      fontSize: '12px',
                      fontWeight: '0',
                    }}
                  >
                    {formik.touched.name && formik.errors.name}
                  </p>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    id="route"
                    name="route"
                    value={formik.values.route}
                    onChange={formik.handleChange}
                    error={formik.touched.route && Boolean(formik.errors.route)}
                    placeholder="Route/Path"
                    fullWidth
                    // margin='normal'
                    // backgroundColor: '#F4F6F8',
                    inputProps={{ style: { fontFamily: 'Roboto' } }}
                    size="small"
                    variant="outlined"
                  />{' '}
                  <p
                    style={{
                      margin: '4px 14px 0px',
                      color: 'red',
                      fontSize: '12px',
                      fontWeight: '0',
                    }}
                  >
                    {formik.touched.route && formik.errors.route}
                  </p>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={`${classes.btn} `}
                    startIcon={<AddCircleIcon />}
                    // type='submit'
                    onClick={() => formik.submitForm()}
                    disabled={loading}
                  >
                    Add Feature
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item style={{ padding: '0 0 0 1rem	' }}>
            {' '}
            Feature Set List
          </Grid>
          <Divider style={{ margin: '1rem 0' }} />

          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Route</TableCell>
                <TableCell>Icon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featuresList.map((row) => (
                <TableRow key={row.featureSetId}>
                  <TableCell component="th" scope="row">
                    {row.name ?? 'N/A'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.route ?? 'N/A'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.icon ?? 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Features;
