import React from 'react';

import {
  FormControl,
  NativeSelect,
  makeStyles,
  InputBase,
  withStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SortSelection = ({
  name = 'sort',
  initialValue = 'Sort',
  value,
  handleFilters,
  data,
}) => {
  //! INSTANCES
  const classes = useStyles();

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 13,
      padding: '10px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      // fontFamily: [
      // 	'-apple-system',
      // 	'BlinkMacSystemFont',
      // 	'"Segoe UI"',
      // 	'Roboto',
      // 	'"Helvetica Neue"',
      // 	'Arial',
      // 	'sans-serif',
      // 	'"Apple Color Emoji"',
      // 	'"Segoe UI Emoji"',
      // 	'"Segoe UI Symbol"',
      // ].join(','),

      fontFamily: 'Roboto',
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

  return (
    <FormControl className={classes.margin} fullWidth>
      <NativeSelect
        name={name}
        value={value}
        onChange={handleFilters}
        input={<BootstrapInput />}
        size="small"
        fullWidth
      >
        <option value={0}>{initialValue}</option>
        {data?.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default SortSelection;
