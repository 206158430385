import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import React from 'react';
import arrow from '../../../assests/arrow.png';

import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import SelectInput from 'src/views/Billing/components/SelectInput';
import Label from 'src/views/Billing/components/Label';
import TextInput from 'src/views/Billing/components/TextInput';

const MaterialIssueModal = (props) => {
  const { classes, open, handleClose } = props;

  const renderMaterialDetails = () => {
    const boqOptions = [
      { label: 'Cement', value: 1 },
      { label: 'Sand', value: 2 },
      { label: 'Concrete', value: 3 },
    ];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container justify="space-between">
          <Label
            title="Material Details"
            subTitle="Select Material & Quanity"
          />
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid
            item
            alignItems="center"
            style={{ marginTop: '10px', minWidth: '49%' }}
          >
            <SelectInput
              label="Select Material"
              variant="filled"
              options={boqOptions}
            />
          </Grid>
          <Grid
            item
            alignItems="center"
            style={{ marginTop: '10px', minWidth: '49%' }}
          >
            <TextInput
              label="Indicate Issue Quantity"
              variant="filled"
              handleChange={() => {}}
              endAdornment={<InputAdornment position="end">KG</InputAdornment>}
            />
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid
              item
              alignItems="center"
              style={{ marginTop: '10px', minWidth: '49%' }}
            >
              <SelectInput
                label="Select Material"
                variant="filled"
                options={boqOptions}
              />
            </Grid>
            <Grid
              item
              alignItems="center"
              style={{ marginTop: '10px', minWidth: '49%' }}
            >
              <TextInput
                label="Indicate Issue Quantity"
                variant="filled"
                handleChange={() => {}}
                endAdornment={
                  <InputAdornment position="end">TON</InputAdornment>
                }
              />
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid
                item
                alignItems="center"
                style={{ marginTop: '10px', minWidth: '49%' }}
              >
                <SelectInput
                  label="Select Material"
                  variant="filled"
                  options={boqOptions}
                />
              </Grid>
              <Grid
                item
                alignItems="center"
                style={{ marginTop: '10px', minWidth: '49%' }}
              >
                <TextInput
                  label="Indicate Issue Quantity"
                  variant="filled"
                  handleChange={() => {}}
                  endAdornment={
                    <InputAdornment position="end">TON</InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderWorkPermitDetails = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label
            title="Work Permit Details"
            subTitle="Add Work Permit Description"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Work Permit Description"
              variant="filled"
              handleChange={() => {}}
              maxRows={3}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAddContactor = () => {
    const contractorOptions = [
      { label: 'Pawn K. Builders & Developers', value: 1 },
    ];
    const woID = [{ label: 'KG', value: 1 }];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12}>
          <Label title="Add Contactor" subTitle="Add Contractor Details" />
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3} className={classes.imageContainer}>
            {/* <img
              height="23px"
              width="32px"
              src={vinodShuklaIMG}
              alt="placeholder"
              style={{ position: 'absolute', top: 11, left: 9 }}
            /> */}
          </Grid>
          <Grid item xs={9}>
            <SelectInput
              label="Contractor"
              variant="standard"
              options={contractorOptions}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Select Work Order ID"
            variant="filled"
            options={woID}
          />
        </Grid>
      </Grid>
    );
  };

  const renderStepOne = () => {
    return (
      <Grid
        item
        container
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Divider style={{ width: '100%' }} />
        {renderAddContactor()}
        <Divider style={{ width: '100%' }} />
        {renderMaterialDetails()}
        <Divider style={{ width: '100%' }} />
        {renderWorkPermitDetails()}
        <Divider style={{ width: '100%' }} />
      </Grid>
    );
  };

  const addMaterialButton = (
    <Button
      className={classes.buttonDotted}
      startIcon={
        <AddIcon
          style={{
            fill: 'white',
            width: '15px',
            height: '15px',
            borderRadius: '5px',
            background: '#1069E3',
          }}
        />
      }
      onClick={() => {}}
    >
      Add Material
    </Button>
  );

  const createButton = (
    <Button
      className={classes.buttonPurple}
      endIcon={<img src={arrow} alt="submit" />}
      onClick={() => {}}
    >
      Issue
    </Button>
  );

  const renderActionButton = () => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>{addMaterialButton}</Grid>
        <Grid>{createButton}</Grid>
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <DialogTitle>
        <Grid
          container
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography style={{ fontSize: 26 }}>Material Issue</Typography>
          </Grid>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    return (
      <DialogActions>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ margin: '15px 20px' }}
        >
          {renderActionButton()}
        </Grid>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      className={classes.modal}
      PaperProps={{ style: { width: 700, height: '90%' } }}
    >
      {renderTitle()}
      {renderStepOne()}
      {renderFooter()}
    </Dialog>
  );
};

export default MaterialIssueModal;
