import axios from 'axios';

const config = {};
// const url = `${process.env.REACT_APP_IMAGE_PATH}api`;
// const url = 'https://api-prod.bettamint.com/api';
const url = 'https://api-sandbox.bettamint.com/api';
// const url = 'http://54.151.8.182/api';

config.baseUrl = url;

axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    let user = JSON.parse(localStorage.getItem('userDetails'));

    if (user) {
      config.headers.Authorization = `Bearer ${user?.token}`;
      return config;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.log('error', error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log("response", error);
    // return;

    if (error?.response?.status == 401) {
      localStorage.clear();
      window.location.reload();
    }
    return error.response;
  }
);

export default config;
