import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Button,
  TableContainer,
  Typography,
} from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },

  DARAndDLRColums: {
    color: '#80b634',
    fontSize: '11px',
    fontWeight: 'bold',
  },

  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: '#A179F2',
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  marginLeft: {
    marginLeft: '5px',
  },
  marginTop: { marginTop: '0' },
  overflow: { overflowX: 'hidden' },
  headerButton: {
    fontSize: '12px',
    // padding: '5px 15px',
    // "&:hover": {
    // 	backgroundColor: "#81B734",
    // 	color: "white",
    // },
  },
  statusPaddingLeft: {
    paddingLeft: '10px',
  },
  paddingLeftMark: {
    paddingLeft: '10px',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  searchBarMarginBotto: {
    marginBottom: '0',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: { marginTop: '1rem' },
    marginLeft: {
      marginLeft: '0',
    },
    overflow: { overflowX: 'scroll' },
    statusPaddingLeft: {
      paddingLeft: '0',
    },
    paddingLeftMark: {
      paddingLeft: '0',
    },
    searchBarMarginBotto: {
      marginBottom: '10px',
    },
  },
  [theme.breakpoints.down('md')]: {
    marginDownloadButton: {
      marginTop: '15px',
    },
    paddingLeftMark: {
      paddingLeft: '0',
    },
    statusPaddingLeft: {
      paddingLeft: '0',
    },
  },
}));
const BillingHistoryTable = () => {
  const classes = useStyles();

  const dummyHistoryTable = [
    {
      name: 'Bhaavik Vijay Kumar',
      plan: 'Standard',
      startDate: '01 Apr, 2023',
      endDate: '01 Sep, 2023',
      paid: '₹24,999',
      sNo: 1,
      status: (
        <span
          style={{
            color: '#EC9717',
            backgroundColor: 'rgba(236, 151, 23, 0.10)',
            fontSize: '11px',
            fontWeight: 500,
            borderRadius: '4px',
            padding: '5px 10px',
            marginLeft: '5px',
            width: '71px',
            display: 'block',
            textAlign: 'center',
          }}
        >
          Inprocess
        </span>
      ),
    },
    {
      name: 'Bhaavik Vijay Kumar',
      plan: 'Standard',
      startDate: '01 Apr, 2023',
      endDate: '01 Sep, 2023',
      paid: '₹24,999',
      sNo: 2,
      status: (
        <span
          style={{
            color: '#81B734',
            backgroundColor: 'rgba(129, 183, 52, 0.10)',
            fontSize: '11px',
            fontWeight: 500,
            borderRadius: '4px',
            padding: '5px 10px',
            marginLeft: '5px',
            width: '71px',
            display: 'block',
            textAlign: 'center',
          }}
        >
          Paid
        </span>
      ),
    },
    {
      name: 'Bhaavik Vijay Kumar',
      plan: 'Standard',
      startDate: '01 Apr, 2023',
      endDate: '01 Sep, 2023',
      paid: '₹24,999',
      sNo: 3,
      status: (
        <span
          style={{
            color: '#81B734',
            backgroundColor: 'rgba(129, 183, 52, 0.10)',
            fontSize: '11px',
            fontWeight: 500,
            borderRadius: '4px',
            padding: '5px 10px',
            marginLeft: '5px',
            width: '71px',
            display: 'block',
            textAlign: 'center',
          }}
        >
          Paid
        </span>
      ),
    },
    {
      name: 'Bhaavik Vijay Kumar',
      plan: 'Standard',
      startDate: '01 Apr, 2023',
      endDate: '01 Sep, 2023',
      paid: '₹24,999',
      sNo: 4,
      status: (
        <span
          style={{
            color: '#EC9717',
            backgroundColor: 'rgba(236, 151, 23, 0.10)',
            fontSize: '11px',
            fontWeight: 500,
            borderRadius: '4px',
            padding: '5px 10px',
            marginLeft: '5px',
            width: '71px',
            display: 'block',
            textAlign: 'center',
          }}
        >
          Inprocess
        </span>
      ),
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              size="small"
              align="left"
              // style={{ fontSize: 12, fontFamily: 'Roboto' }}
              className={classes.headerCellText1}
            >
              S.No
            </TableCell>
            <TableCell
              size="small"
              align="left"
              // style={{ fontSize: 12, fontFamily: 'Roboto' }}
              className={classes.headerCellText1}
            >
              INVOICE DATE
            </TableCell>
            <TableCell
              size="small"
              align="left"
              // style={{ fontSize: 12, fontFamily: 'Roboto' }}
              className={classes.headerCellText1}
            >
              INVOICE AMOUNT
            </TableCell>
            <TableCell
              size="small"
              align="left"
              // style={{ fontSize: 12, fontFamily: 'Roboto' }}
              className={classes.headerCellText1}
            >
              USERS
            </TableCell>

            <TableCell
              size="small"
              align="left"
              // style={{ fontSize: 12, fontFamily: 'Roboto' }}
              className={classes.headerCellText1}
            >
              PAYMENT DETAILS
            </TableCell>
            <TableCell
              size="small"
              align="left"
              // style={{ fontSize: 12, fontFamily: 'Roboto' }}
              className={classes.headerCellText1}
            >
              ACTION
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {dummyHistoryTable?.map((val, index) => (
            <TableRow hover key={index}>
              <TableCell
                align="left"
                // width="25%"
                style={{ fontSize: 12, fontFamily: 'Roboto' }}
              >
                <Grid container xs={12} alignItems="center">
                  {/* <Grid item>
                    {" "}
                    <AccountBoxIcon style={{ fontSize: "30px" }} />
                  </Grid> */}

                  <Grid item> {val.sNo ?? 'N/A'}</Grid>
                </Grid>
              </TableCell>
              <TableCell
                align="left"
                style={{ fontSize: 12, fontFamily: 'Roboto' }}
              >
                {val?.startDate ?? 'N/A'}
              </TableCell>

              <TableCell
                align="left"
                style={{ fontSize: 12, fontFamily: 'Roboto' }}
              >
                {val?.paid ?? 'N/A'}
              </TableCell>
              <TableCell
                align="left"
                style={{ fontSize: 12, fontFamily: 'Roboto' }}
              >
                {30}
              </TableCell>

              <TableCell
                align="left"
                style={{ fontSize: 12, fontFamily: 'Roboto' }}
              >
                {val.status}
              </TableCell>

              <TableCell align="left" width="13%">
                <Grid container alignItems="center" justify="flex-start">
                  <Grid item>
                    <Button
                      className={classes.buttonPurpleOpacity}
                      style={{
                        padding: '6px 0',
                        textTransform: 'capitalize',
                        fontSize: '11px',
                      }}
                    >
                      View
                    </Button>
                  </Grid>
                  <Grid item style={{ marginLeft: '5px' }}>
                    <Button
                      style={{
                        backgroundColor: 'rgba(129, 183, 52, 0.10)',
                        padding: '6px 0',
                        minWidth: '33px',
                        height: '37px',
                      }}
                    >
                      <GetAppIcon
                        style={{
                          color: '#81B734',
                        }}
                        fontSize="small"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingHistoryTable;
