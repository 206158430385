import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import SOWTable from './SOWTable';
import SOWTable1 from './SOWTable1';

const BOQAccordian1 = (props) => {
  const { classes } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Grid
      style={{
        overflowY: 'auto',
        width: '100%',
        borderBottom: '1px solid #A1C3E5',
        borderTop: '1px solid #A1C3E5',
        margin: 0,
        minWidth: '100%',
      }}
    >
      <SOWTable1 classes={classes} {...props} />
    </Grid>
  );
};

export default BOQAccordian1;
