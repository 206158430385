import { Grid, Typography } from '@material-ui/core';

import React from 'react';
import projectImage from '../../../assests/ExampleProjectImage.png';
import defaultProjectImage from '../../../assests/Union.png';
import defaultGroup from '../../../assests/group.png';
import currency from '../../../assests/₹.png';
import callender from '../../../assests/Calendar.png';

const ProjectHighlights = (props) => {
  const { classes } = props;

  const renderProperty = (amount, label, variation) => {
    const renderChit = () => {
      if (variation.includes('%')) {
        return (
          <Grid
            item
            container
            style={{
              borderRadius: 4,
              fontSize: 10,
              maxWidth: '15%',
              background: 'white',
              color: variation.includes('+') ? '#81B734' : '#EC1717',
              height: 20,
            }}
            justify="center"
            alignItems="center"
          >
            {variation}
          </Grid>
        );
      }
      return (
        <Grid
          item
          container
          style={{
            borderRadius: 4,
            fontSize: 10,
            maxWidth: '15%',
            height: 20,
          }}
          justify="center"
          alignItems="center"
        >
          <img src={variation} alt="placeholder" />
        </Grid>
      );
    };
    return (
      <Grid container item xs={4} style={{ padding: 5 }}>
        <Grid
          item
          container
          style={{
            background: 'rgba(255,255,255,0.3)',
            padding: 10,
            borderRadius: 10,
          }}
        >
          <Grid item container style={{ flexGrow: 1, maxWidth: '85%' }}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                {amount}
              </Typography>
            </Grid>
            <Typography style={{ fontSize: 10 }}>{label}</Typography>
          </Grid>
          {renderChit()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: '#81B734',
        height: '215px',
        width: 'auto',
        margin: '0px 20px 10px 20px',
        padding: '0px 0px 10px 0px',
        borderRadius: '0px 0px 10px 10px',
        overflow: 'hidden',
      }}
    >
      <Grid item style={{ maxHeight: 220, maxWidth: '18%' }}>
        <img
          src={projectImage}
          alt="building"
          style={{
            objectFit: 'contain',
            height: '100%',
            width: '100%',
            padding: '20px 0px 0px 5px',
            scale: '1.3',
          }}
        />
      </Grid>
      <Grid
        container
        item
        justify="space-between"
        style={{
          margin: '15px auto auto -10px',
          maxWidth: '82%',
        }}
      >
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          style={{ paddingRight: 10, marginTop: 5, minWidth: 780 }}
        >
          <Grid
            item
            container
            style={{ maxWidth: '33%', flexWrap: 'nowrap' }}
            alignItems="center"
          >
            <Grid item style={{ minWidth: 50, textAlign: 'center' }}>
              <img
                src={defaultProjectImage}
                alt="default"
                style={{
                  width: 40,
                  height: 40,
                  background: 'rgba(1, 1, 1, 0.2)',
                  objectFit: 'none',
                  borderRadius: 25,
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: 24,
                  color: 'white',
                  textWrap: 'nowrap',
                  lineHeight: '20px',
                }}
              >
                K2K Hill Apartments
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  opacity: 0.7,
                  color: 'white',
                  fontWeight: 300,
                }}
              >
                Residential
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={4} justify="flex-end">
            <Grid item>
              <Typography
                style={{
                  fontSize: 24,
                  color: 'white',
                  textWrap: 'nowrap',
                  lineHeight: '15px',
                }}
                align="right"
              >
                45%
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  opacity: 0.7,
                  color: 'white',
                  fontWeight: 300,
                }}
              >
                Completion
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="space-between"
          style={{ paddingRight: 10, minWidth: 780 }}
        >
          <Grid
            container
            item
            style={{
              maxWidth: '30%',
              borderRight: '1px solid rgba(255,255,255,0.4)',
              paddingRight: 10,
            }}
          >
            <Grid
              item
              container
              xs={10}
              alignItems="center"
              style={{ marginBottom: 15, flexWrap: 'nowrap', minWidth: 780 }}
            >
              <Grid
                container
                item
                style={{ width: 50, height: 40, textAlign: 'center' }}
              >
                <img
                  src={defaultGroup}
                  alt="default"
                  style={{
                    width: 25,
                    margin: 'auto',
                  }}
                />
              </Grid>
              <Grid item style={{ placeSelf: 'flex-end' }}>
                <Typography
                  style={{
                    fontSize: 24,
                    color: 'white',
                    textWrap: 'nowrap',
                    lineHeight: '20px',
                  }}
                >
                  12
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    opacity: 0.7,
                    color: 'white',
                    fontWeight: 300,
                  }}
                >
                  Contractors
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={10}
              alignItems="center"
              style={{ flexWrap: 'nowrap' }}
            >
              <Grid
                item
                style={{ minWidth: 50, height: 40, textAlign: 'center' }}
              >
                <img
                  src={currency}
                  alt="default"
                  style={{
                    width: 25,
                    objectFit: 'none',
                  }}
                />
              </Grid>
              <Grid item style={{ placeSelf: 'flex-end' }}>
                <Typography
                  style={{
                    fontSize: 24,
                    color: 'white',
                    textWrap: 'nowrap',
                    lineHeight: '20px',
                  }}
                >
                  Rs. 245,697,000
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    opacity: 0.7,
                    color: 'white',
                    fontWeight: 300,
                  }}
                >
                  Committed Costs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container style={{ maxWidth: '67%' }}>
            <Grid item container xs={12}>
              {renderProperty('Rs. 42,343,000', 'Billed Value', '+2%')}
              {renderProperty('Rs. 5,700,000', 'Certified Value', '+2%')}
              {renderProperty('23 Mar, 2023', 'As on Date', callender)}
            </Grid>
            <Grid item container xs={12}>
              {renderProperty('Rs. 343,000', 'Cost of Poor Quality', '-7%')}
              {renderProperty('Rs. 1,000,000', 'Workmanship Issues', '-3%')}
              {renderProperty('Rs. 904,000', 'Varations Cost', '-5%')}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectHighlights;
