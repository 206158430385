import React, { useState } from 'react';
import {
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import PlaneDetails from 'src/components/PlaneDetails';
import BillingDetails from 'src/components/BillingDetails';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import BillingHistoryTable from 'src/components/BillingHistoryTable';
import Page from 'src/components/Page';
import BettaPayLogo from '../../assests/BettaPayLogo.png';
import defect from '../../assests/defect.png';
import issue from '../../assests/issue.png';

import SetupBillingModal from '../Billing/SetupBillingModal';
import LandingCartTile from '../../views/Billing/components/LandingCardTile';
import SummaryChart from '../../views/Billing/components/SummaryChart';
import BillSummary from '../../views/Billing/components/BillSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontSize: '0.8rem', // change the font size to make the text smaller
      padding: theme.spacing(1), // adjust the padding to make the text field smaller
      // width: '10ch', // set the width to make the text field smaller
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.6rem', // change the font size to make the label smaller
    },
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  buttonGreen: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  imageContainer: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'rgba(1,1,1,0.2)',
    position: 'relative',
    marginRight: 15,
    flex: 'none',
  },
  tocAction: {
    borderRadius: 10,
    background: 'rgba(1,1,1,0.1)',
    height: '100%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));

const Subscription = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(true);

  let cardData = [
    {
      title: 'Total Projects',
      count: `12`,
      icon: <img src={issue} alt="issue" style={{ width: '50%' }} />,
      filter: 'WorkmanshipCost',
      variations: '+10%',
    },
    {
      title: 'Total Order Value',
      count: `4,200,000`,
      icon: <img src={defect} alt="defect" style={{ width: '50%' }} />,
      filter: 'MaterialDefect',
      variations: '+10%',
    },
    {
      title: 'Paid Value',
      count: `900,000`,
      icon: <img src={defect} alt="defect" style={{ width: '50%' }} />,
      filter: 'Variations',
      variations: '+10%',
    },
    {
      title: 'Deductions',
      count: `900,000`,
      icon: <img src={defect} alt="defect" style={{ width: '50%' }} />,
      filter: 'Variations',
      variations: '-2.5%',
    },
  ];
  const renderTiles = () => {
    return (
      <Grid container>
        {cardData.map((card) => (
          <LandingCartTile
            isLoading={false}
            icon={card.icon}
            title={card.title}
            subTitle={card.count}
            filter={card.filter}
            variations={card.variations}
          />
        ))}
      </Grid>
    );
  };

  const renderChart = () => {
    return <SummaryChart />;
  };

  const renderBillSummary = () => {
    return <BillSummary classes={classes} />;
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Billing">
      <Grid container xs={12}>
        <Grid
          container
          item
          xs={12}
          justify="center"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 1)',
            paddingBottom: '2rem',
            borderRadius: '11px',
            // height: "calc(100vh - 50px)",
            boxShadow: '7px 9px 36px 0px #9CB0C333',
          }}
        >
          <Grid
            container
            item
            xs={12}
            style={{
              height: '131px',
              backgroundColor: 'rgba(129, 183, 52, 1)',
              borderRadius: '11px',
            }}
          ></Grid>

          <Grid
            item
            xs={11}
            style={{
              borderRadius: '10px',
              marginTop: '-6rem',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              padding: '1rem 1.5rem',
              boxShadow: '0px 5px 10px rgba(139, 152, 162, 0.15)',
            }}
          >
            <PlaneDetails />
          </Grid>

          <Grid
            item
            xs={11}
            style={{
              borderRadius: '10px',
              marginTop: '2rem',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              padding: '1rem 1.5rem',
              boxShadow: '0px 5px 10px rgba(139, 152, 162, 0.15)',
              border: '1px solid rgba(1,1,1,0.1)',
            }}
          >
            <BillingDetails />
          </Grid>

          <Grid
            item
            xs={11}
            style={{
              borderRadius: '10px',
              marginTop: '2rem',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              padding: '1rem 1.5rem',
              boxShadow: '0px 5px 10px rgba(139, 152, 162, 0.15)',
              border: '1px solid rgba(1,1,1,0.1)',
            }}
          >
            <Grid
              container
              xs={12}
              style={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                // marginTop: "2rem",
                borderRadius: '11px',
                // padding: "1rem 0",
              }}
              justify="center"
              alignItems="center"
            >
              <Grid xs={12} container justify="space-between">
                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      style={{
                        color: 'rgba(161, 121, 242, 1)',
                        fontWeight: '500',
                        fontSize: '20px',
                      }}
                    >
                      Billing History
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        color: 'rgba(126, 131, 134, 1)',
                      }}
                    >
                      HELP?
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ margin: '1rem 0 ' }}>
                  {' '}
                  <Divider />
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  justify="flex-end"
                  style={{
                    backgroundColor: 'rgba(240, 243, 245, 1)',
                    padding: '.8rem 0',
                    // marginTop: "15px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={11}
                    alignItems="center"
                    justify="flex-end"
                    // style={{ border: "2px solid red" }}
                  >
                    <Grid
                      item
                      style={{ paddingRight: '15px', fontSize: '12px' }}
                    >
                      Search:
                    </Grid>
                    <Grid item md={4} xs={8} style={{ marginRight: '10px' }}>
                      <TextField
                        className={classes.root}
                        // InputProps={ {
                        // 	endAdornment: (
                        // 		<InputAdornment position="end">
                        // 			<SearchIcon />
                        // 		</InputAdornment>
                        // 	),
                        // } }
                        // label="Search"
                        placeholder="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        style={{ backgroundColor: '#FFFFFF' }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <BillingHistoryTable />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ marginTop: '10px' }}
          >
            <Grid xs={11} item container alignItems="center" justify="flex-end">
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '11px',
                  marginRight: '2px',
                  fontWeight: '400',
                }}
              >
                Powered By:
              </Typography>
              <img src={BettaPayLogo} alt="BettaPay Logo" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Subscription;
