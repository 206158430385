import React from 'react';
import {
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

const SelectInput = (props) => {
  const { label, startAdornment, endAdornment, variant, options } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultInputProps = {
    disableUnderline: true,
    style: {
      borderRadius: '7px',
      backgroundColor: variant !== 'standard' ? 'rgba(0,0,0,0.05)' : '',
    },
  };
  const defaultLabelProps = {
    style: {
      color: '#8E99A8',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: isSmallScreen ? '170px' : 'auto',
    },
  };

  if (endAdornment) {
    defaultInputProps.endAdornment = endAdornment;
  }
  if (startAdornment) {
    defaultInputProps.startAdornment = endAdornment;
  }

  return (
    <TextField
      select
      size="small"
      label={label}
      variant={variant}
      InputProps={defaultInputProps}
      InputLabelProps={defaultLabelProps}
      style={{ width: '100%' }}
    >
      {options.map((option) => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>;
      })}
    </TextField>
  );
};

export default SelectInput;
