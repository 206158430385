import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import DashboardView from "src/views/dashboard";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import Projects from "src/views/Project";
import Jobs from "./views/Jobs";
import Attendance from "./views/Attendance";
import WorkerProfile from "./views/WorkerProfile";
import Payments from "./views/Payments";
import Users from "./views/Users";
import Communication from "./views/Communication";
import Reports from "./views/Reports";
import UserDetail from "./views/Users/userDetail";
import DeveloperLayout from "src/layouts/DeveloperLayout";
import ContractorLayout from "src/layouts/ContractorLayout";
import ProjectDetails from "./views/Project/projectDetails";
import ProjectManagerLayout from "src/layouts/ProjectManagerLayout";
import ProjectEdit from "./views/Project/createProjectView";
import SiteAdminLayout from "./layouts/SiteAdminLayout";
import SupervisorLayout from "./layouts/SupervisorLayout";
import AttendanceDetail from "./views/Attendance/attendanceDetail";
import WorkerDetail from "./views/WorkerProfile/workerDetail";
import OnboardingLayout from "src/layouts/OnboardingLayout";
import EditWorkerDetails from "./views/WorkerProfile/editWorkerDetails";
import UserProfile from "./views/Lead/userProfile";
import CreateJob from "./views/Jobs/createJob";
import JobDetails from "./views/Jobs/jobDetails";
import CreateUser from "./views/Users/createUser";
import LeadsList from "./views/Lead/index";
import CreateWorker from "./views/WorkerProfile/createWorker";
import Features from "./views/features/index";
import Roles from "./views/roles/index";
import AddRoles from "./views/roles/add-roles";
import MapFullView from "./views/Project/MapFullView";
import ForgotPassword from "./views/auth/forgot-password";
import NewPassword from "./views/auth/new-password";

const admin = [
  {
    path: "/app",
    element: <DashboardLayout />,
    children: [
      { index: true, path: "dashboard", element: <DashboardView /> },
      { path: "profile", element: <UserProfile /> },
      { path: "leads", element: <LeadsList /> },
      { path: "project", element: <Projects /> },
      { path: "project/details", element: <ProjectDetails /> },
      { path: "project/edit", element: <ProjectEdit /> },
      { path: "project/create", element: <ProjectEdit /> },

      { path: "jobs", element: <Jobs /> },
      { path: "jobs/create", element: <CreateJob /> },
      { path: "jobs/detail", element: <JobDetails /> },
      { path: "jobs/edit", element: <CreateJob /> },

      { path: "attendance", element: <Attendance /> },
      { path: "attendance/detail", element: <AttendanceDetail /> },

      { path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      { path: "payments", element: <Payments /> },
      { path: "users", element: <Users /> },
      { path: "users/detail", element: <UserDetail /> },
      { path: "users/create", element: <CreateUser /> },
      { path: "users/edit", element: <CreateUser /> },

      { exact: true, path: "users/detail", element: <UserDetail /> },
      { path: "communication", element: <Communication /> },
      { path: "reports", element: <Reports /> },
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "*", element: <Navigate to="dashboard" replace /> },

      { path: "features", element: <Features /> },

      { path: "roles", element: <Roles /> },
      { path: "roles/add", element: <AddRoles /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="app" />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
];
const developer = [
  {
    path: "/developer",
    element: <DeveloperLayout />,
    children: [
      { index: true, path: "dashboard", element: <DashboardView /> },
      { path: "profile", element: <UserProfile /> },

      { path: "project", element: <Projects /> },
      { path: "project/details", element: <ProjectDetails /> },
      { path: "project/edit", element: <ProjectEdit /> },
      { path: "project/create", element: <ProjectEdit /> },

      { path: "attendance", element: <Attendance /> },
      { path: "attendance/detail", element: <AttendanceDetail /> },

      { path: "jobs", element: <Jobs /> },
      { path: "jobs/create", element: <CreateJob /> },
      { path: "jobs/detail", element: <JobDetails /> },
      { path: "jobs/edit", element: <CreateJob /> },

      { path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      { path: "payments", element: <Payments /> },
      { path: "users", element: <Users /> },
      { path: "users/detail", element: <UserDetail /> },
      { path: "users/create", element: <CreateUser /> },
      { path: "users/edit", element: <CreateUser /> },

      { path: "communication", element: <Communication /> },
      { path: "reports", element: <Reports /> },
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "*", element: <Navigate to="dashboard" replace /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="developer" />,
  },
  {
    path: "*",
    element: <Navigate to="developer" />,
  },
];

const projectManager = [
  {
    path: "/projectManager",
    element: <ProjectManagerLayout />,
    children: [
      { index: true, path: "dashboard", element: <DashboardView /> },
      { path: "profile", element: <UserProfile /> },

      { path: "project", element: <Projects /> },
      { path: "project/details", element: <ProjectDetails /> },
      { path: "project/edit", element: <ProjectEdit /> },
      { path: "project/create", element: <ProjectEdit /> },

      { path: "attendance", element: <Attendance /> },
      { path: "attendance/detail", element: <AttendanceDetail /> },

      { path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      { path: "payments", element: <Payments /> },

      { path: "communication", element: <Communication /> },
      { path: "reports", element: <Reports /> },
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "*", element: <Navigate to="dashboard" replace /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="projectManager" />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
];

const siteAdmin = [
  {
    path: "/siteAdmin",
    element: <SiteAdminLayout />,
    children: [
      { index: true, path: "dashboard", element: <DashboardView /> },
      { path: "profile", element: <UserProfile /> },

      { path: "project", element: <Projects /> },
      { path: "project/details", element: <ProjectDetails /> },
      { path: "project/edit", element: <ProjectEdit /> },
      { path: "project/create", element: <ProjectEdit /> },

      { path: "attendance", element: <Attendance /> },
      { path: "attendance/detail", element: <AttendanceDetail /> },

      { path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      { path: "payments", element: <Payments /> },

      { path: "communication", element: <Communication /> },
      { path: "reports", element: <Reports /> },
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "*", element: <Navigate to="dashboard" replace /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="siteAdmin" />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
];

const supervisor = [
  {
    path: "/supervisor",
    element: <SupervisorLayout />,
    children: [
      { index: true, path: "dashboard", element: <DashboardView /> },
      { path: "profile", element: <UserProfile /> },

      { path: "project", element: <Projects /> },
      { path: "project/details", element: <ProjectDetails /> },
      { path: "project/edit", element: <ProjectEdit /> },
      { path: "project/create", element: <ProjectEdit /> },

      { path: "attendance", element: <Attendance /> },
      { path: "attendance/detail", element: <AttendanceDetail /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      { path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },

      { path: "payments", element: <Payments /> },

      { path: "communication", element: <Communication /> },
      { path: "reports", element: <Reports /> },
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "*", element: <Navigate to="dashboard" replace /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="supervisor" />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
];

const contractor = [
  {
    path: "/contractor",
    element: <ContractorLayout />,
    children: [
      { index: true, path: "dashboard", element: <DashboardView /> },
      { path: "profile", element: <UserProfile /> },

      { path: "project", element: <Projects /> },
      { path: "project/details", element: <ProjectDetails /> },
      { path: "project/edit", element: <ProjectEdit /> },
      { path: "project/create", element: <ProjectEdit /> },

      { path: "jobs", element: <Jobs /> },
      { path: "jobs/create", element: <CreateJob /> },
      { path: "jobs/detail", element: <JobDetails /> },
      { path: "jobs/edit", element: <CreateJob /> },

      { path: "attendance", element: <Attendance /> },
      { path: "attendance/detail", element: <AttendanceDetail /> },

      { path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      { path: "payments", element: <Payments /> },
      { path: "users", element: <Users /> },

      { path: "users/detail", element: <UserDetail /> },

      { path: "communication", element: <Communication /> },
      { path: "reports", element: <Reports /> },
      { path: "/", element: <Navigate to="dashboard" replace /> },
      { path: "*", element: <Navigate to="dashboard" replace /> },
    ],
  },

  {
    path: "/",
    element: <Navigate to="contractor" />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
];

const onBoarding = [
  {
    path: "/onboarding",
    element: <OnboardingLayout />,
    children: [
      // { index: true, path: 'dashboard', element: <DashboardView /> },
      // { path: 'contractors', element: <Contractors /> },
      // { path: 'project', element: <Projects /> },
      // { path: 'jobs', element: <Jobs /> },
      // { path: 'attendance', element: <Attendance /> },
      // { path: 'attendance/detail', element: <AttendanceDetail /> },
      { path: "profile", element: <UserProfile /> },

      { index: true, path: "workerprofile", element: <WorkerProfile /> },
      { path: "workerprofile/detail", element: <WorkerDetail /> },
      { path: "workerprofile/edit", element: <EditWorkerDetails /> },
      { path: "workerprofile/create-worker", element: <CreateWorker /> },

      // { path: 'payments', element: <Payments /> },
      // { path: 'users', element: <Users /> },
      // { path: 'users/detail', element: <UserDetail /> },

      // { path: 'communication', element: <Communication /> },
      // { path: 'reports', element: <Reports /> },
      { path: "/", element: <Navigate to="workerprofile" replace /> },
      { path: "*", element: <Navigate to="workerprofile" replace /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="onboarding" />,
  },
  {
    path: "*",
    // element: <NotFoundView />,
    element: <Navigate to="/onboarding/workerprofile" replace />,
  },
];

const auth = [
  {
    path: "/auth",
    element: <MainLayout />,
    children: [
      { index: true, path: "login", element: <LoginView /> },
      {
        path: "view",
        element: <MapFullView />,
      },
      { index: true, path: "forgot-password", element: <ForgotPassword /> },
      { index: true, path: "new-password", element: <NewPassword /> },

      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="login" /> },
      { path: "*", element: <Navigate to="login" /> },
    ],
  },
  {
    path: "/",
    element: <Navigate to="auth/layout" />,
  },
  {
    path: "*",
    element: <Navigate to="auth/layout" />,
  },
];

export {
  admin,
  auth,
  contractor,
  developer,
  projectManager,
  siteAdmin,
  supervisor,
  onBoarding,
};
