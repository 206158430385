import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  attendanceList: null,
  workersAttendanceList: null,
  detailLoading: false,
  detailError: null,
  selectedProject: null,
  selectedWorkerId: null,
  selectedJobId: null,
  redirected: false,
  selectedWorker: null,
  redirectFromPayment: false,
  advancePaymentLoading: false,
  attendanceReportLoading: false,
  attendanceReportData: null,
  approvingLoading: false,
  markingAttendanceLoading: false,
  musterCard: [],
};

const slice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    gettingAttendance(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingAttendanceSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.attendanceList = action.payload;
    },
    gettingAttendanceSuccessModal(state, action) {
      state.loading = false;
      state.error = null;
      // state.attendanceList = action.payload;
    },
    gettingAttendanceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingWorkerAttendance(state, action) {
      state.detailLoading = true;
      state.detailError = null;
      state.selectedWorkerId = action.payload.workerId;
      state.selectedJobId = action.payload.jobId;
    },
    gettingWorkerAttendanceSuccess(state, action) {
      state.detailLoading = false;
      state.detailError = null;
      state.workersAttendanceList = action.payload;
    },
    gettingWorkerAttendanceFailure(state, action) {
      state.detailLoading = false;
      state.detailError = action.payload;
      state.workersAttendanceList = null;
    },
    redirectTo(state, action) {
      state.redirected = action.payload;
    },
    approvingAttendance(state, action) {
      state.approvingLoading = true;
    },
    approvingAttendanceSuccess(state, action) {
      state.approvingLoading = false;
    },
    approvingAttendanceFailure(state, action) {
      state.approvingLoading = false;
    },
    saveValue(state, action) {
      state.selectedWorker = action.payload;
    },
    redirectingFromPayment(state, action) {
      state.redirectFromPayment = action.payload;
    },
    gettingAttendanceMusterCard(state, action) {
      state.detailLoading = true;
      state.detailError = null;
    },
    gettingAttendanceMusterCardSuccess(state, action) {
      state.detailLoading = false;
      state.detailError = null;
      state.musterCard = action.payload;
    },
    gettingAttendanceMusterCardFailure(state, action) {
      state.detailLoading = false;
      state.detailError = action.payload;
      state.musterCard = null;
    },
    sendingAdvancePayment(state, action) {
      state.advancePaymentLoading = true;
    },
    sendingAdvancePaymentSuccess(state, action) {
      state.advancePaymentLoading = false;
    },
    sendingAdvancePaymentFailure(state, action) {
      state.advancePaymentLoading = false;
    },
    gettingAttendanceReport(state, action) {
      state.attendanceReportLoading = true;
    },
    gettingAttendanceReportSuccess(state, action) {
      state.attendanceReportLoading = false;
      state.attendanceReportData = action.payload;
    },
    gettingAttendanceReportFailure(state, action) {
      state.attendanceReportLoading = false;
      state.error = action.payload;
      state.attendanceReportData = null;
    },
    makingWorkerAttendanceNull(state, action) {
      state.workersAttendanceList = null;
    },
    selectingProject(state, action) {
      state.selectedProject = action.payload;
    },
    markingAttendance(state, action) {
      state.markingAttendanceLoading = true;
    },
    markingAttendanceSuccess(state, action) {
      state.markingAttendanceLoading = false;
    },
    markingAttendanceFailure(state, action) {
      state.markingAttendanceLoading = false;
    },
  },
});
const {
  gettingAttendance,
  gettingAttendanceSuccess,
  gettingAttendanceFailure,
  gettingAttendanceSuccessModal,
  gettingWorkerAttendance,
  gettingWorkerAttendanceSuccess,
  gettingWorkerAttendanceFailure,
  approvingAttendance,
  approvingAttendanceSuccess,
  approvingAttendanceFailure,
  redirectTo,
  saveValue,
  redirectingFromPayment,
  // gettingAttendanceMusterCard,
  // gettingAttendanceMusterCardSuccess,
  // gettingAttendanceMusterCardFailure,
  sendingAdvancePayment,
  sendingAdvancePaymentSuccess,
  sendingAdvancePaymentFailure,
  gettingAttendanceReport,
  gettingAttendanceReportSuccess,
  gettingAttendanceReportFailure,
  makingWorkerAttendanceNull,
  selectingProject,
  markingAttendance,
  markingAttendanceSuccess,
  markingAttendanceFailure,
} = slice.actions;

export const attendanceReducer = (state) => state.attendance;

export const getAttendance =
  (
    projectId = 0,
    createdBy = 0,
    modalCall = false,
    pageNumber = 1,
    SortOrder = 0,
    pageSize = 25,
    sortBy = 'worker-name'
  ) =>
  async (dispatch) => {
    dispatch(gettingAttendance());
    try {
      // if (projectId) {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Attendance/getbyproject/v2?projectId=${projectId}&createdBy=${createdBy}&pageNumber=${pageNumber}&pageSize=${pageSize}&SortBy=${sortBy}&SortOrder=${SortOrder}`
      );
      if (response.status === 200) {
        let data = response.data.result;

        if (!modalCall) {
          dispatch(gettingAttendanceSuccess(data));
        } else {
          dispatch(gettingAttendanceSuccessModal());
        }
        // return data;
      }
      return response;
      // }
    } catch (e) {
      dispatch(
        gettingAttendanceFailure(
          'Something went wrong while getting attendance!'
        )
      );
    }
  };

export const getWorkerAttendance =
  (
    jobId,
    workerId,
    redirected = false,
    startDate = moment().startOf('year').format('YYYY-MM-DD'),
    endDate = moment().endOf('year').format('YYYY-MM-DD')
  ) =>
  async (dispatch) => {
    dispatch(gettingWorkerAttendance({ jobId, workerId }));
    const d = new Date();
    let diff = d.getTimezoneOffset();
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Attendance/mustercard?workerId=${workerId}&jobId=${jobId}&startDate=${startDate}&endDate=${endDate}`
      );

      if (response.status === 200) {
        let newObj = {
          ...response.data,
          attendance: response.data.attendance.map((item) => ({
            ...item,
            check: false,
          })),
        };
        dispatch(gettingWorkerAttendanceSuccess(newObj));
        dispatch(redirectTo(redirected));
      } else {
        dispatch(
          gettingWorkerAttendanceFailure(
            'Something went wrong while getting attendance!'
          )
        );
      }
      return response;
    } catch (e) {
      // console.log("e: ", e);

      dispatch(
        gettingWorkerAttendanceFailure(
          'Something went wrong while getting attendance!'
        )
      );
    }
  };

export const makeFalseRedirect =
  (redirected = false) =>
  async (dispatch) => {
    dispatch(redirectTo(redirected));
  };

export const selectWorker = (worker) => async (dispatch) => {
  dispatch(saveValue(worker));
};

export const approveAttendance =
  (jobId, workerId, dateTime, hours = 8) =>
  async (dispatch) => {
    dispatch(approvingAttendance());
    try {
      // if (projectId) {
      const response = await axios.post(
        `${config.baseUrl}/dashboard/Attendance/approve?jobId=${jobId}&workerId=${workerId}&dateTime=${dateTime}&hours=${hours}`
      );
      if (response.status === 200) {
        dispatch(approvingAttendanceSuccess());
      }
      return response;
      // }
    } catch (e) {
      dispatch(
        approvingAttendanceFailure(
          'Something went wrong while approving attendance!'
        )
      );
      return e;
    }
  };

export const showPayment = (show) => async (dispatch) => {
  dispatch(redirectingFromPayment(show));
};

export const sendAdvancePayment =
  (jobId, workerId, transactionType, requestAmount) => async (dispatch) => {
    dispatch(sendingAdvancePayment());
    try {
      // if (projectId) {
      const response = await axios.post(
        `${config.baseUrl}/dashboard/Payment/advance?jobId=${jobId}&WorkerId=${workerId}&transactionType=${transactionType}&requestAmount=${requestAmount}`
      );
      if (response?.status === 200) {
        dispatch(sendingAdvancePaymentSuccess());
      }
      if (response?.data?.error) {
        dispatch(sendingAdvancePaymentFailure(response?.data?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        sendingAdvancePaymentFailure(
          'Something went wrong while sending advance payment!'
        )
      );
      return e;
    }
  };

export const getAttendanceReport =
  (projectId, startDate, endDate) => async (dispatch) => {
    dispatch(gettingAttendanceReport());
    try {
      // if (projectId) {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Attendance/getbyprojectreport?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}&timezone=0`
      );
      if (response?.status === 200) {
        dispatch(gettingAttendanceReportSuccess({ ...response?.data }));
      }
      if (response?.data?.error) {
        dispatch(gettingAttendanceReportFailure(response?.data?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingAttendanceReportFailure(
          'Something went wrong while getting attendance report!'
        )
      );
      return e;
    }
  };
export const putNullWorkerAttendance = () => async (dispatch) => {
  dispatch(makingWorkerAttendanceNull());
};

export const selectAProject = (project) => async (dispatch) => {
  dispatch(selectingProject(project));
};

export const markAttendance =
  (workerId, jobId, attendanceType, lat, lng) => async (dispatch) => {
    dispatch(markingAttendance());
    try {
      let url =
        lat && lng
          ? `${config.baseUrl}/dashboard/Attendance/markattendance?workerId=${workerId}&jobId=${jobId}&attendanceType=${attendanceType}&latitude=${lat}&longitude=${lng}`
          : `${config.baseUrl}/dashboard/Attendance/markattendance?workerId=${workerId}&jobId=${jobId}&attendanceType=${attendanceType}`;
      // if (projectId) {

      const response = await axios.post(url);
      if (response?.status === 200) {
        dispatch(markingAttendanceSuccess());
      }
      if (response?.data?.error) {
        dispatch(markingAttendanceFailure(response?.data?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        markingAttendanceFailure(
          'Something went wrong while marking attendance!'
        )
      );
      return e;
    }
  };

export default slice.reducer;
