import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
} from '@material-ui/core';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import GradientIcon from '@material-ui/icons/Gradient';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import ChatIcon from '@material-ui/icons/Chat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import GroupIcon from '@material-ui/icons/Group';
import NavItem from './NavItem';
import Logo from 'src/components/Logo';
// import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
// import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import NotesIcon from '@material-ui/icons/Notes';
import UserContext from 'src/context/UserContext';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ReceiptIcon from '@material-ui/icons/Receipt';
import InventoryIcon from '../../../assests/Inventory Icon.png';
import useAccessPerView from 'src/common/Access';
import { getLoggedInUserData } from 'src/App';

export const dashboardItems = [
  {
    href: '/app/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard',
    module: 'ALL',
  },
  {
    href: '/app/users',
    icon: PeopleIcon,
    title: 'Users',
    module: ['USER_RIGHTS_MANAGEMENT'],
  },
  {
    href: '/app/project',
    icon: AssignmentIcon,
    title: 'Projects',
    module: [
      'PROJECT_INFORMATION',
      'PROJECT_BUDGET',
      'PROJECT_BOUNDARY',
      'PROJECT_LINKING',
    ],
  },
  {
    href: '/app/workerprofile',
    icon: AssignmentIndIcon,
    // title: 'Worker Profile',
    title: 'Workers',
    module: ['WORKER_ONBOARDING'],
  },
  {
    href: '/app/jobs',
    icon: WorkOutlineIcon,
    title: 'Jobs',
    module: ['WORKER_ONBOARDING'],
  },
  {
    href: '/app/attendance',
    icon: GradientIcon,
    title: 'Attendance',
    module: ['ATTENDANCE'],
  },
  {
    href: '/app/communication',
    icon: ChatIcon,
    title: 'Communication',
    module: ['IN_APP_CHAT_FEATURES'],
  },
  {
    href: '/app/field-notes',
    icon: NotesIcon,
    title: 'Field Notes',
    module: ['QUALITY_MANAGEMENT', 'CHANGE_MANAGEMENT'],
  },
  {
    href: '/app/rewards',
    icon: AccountBalanceWalletIcon,
    title: 'Rewards',
    module: ['GOAL_SETTING', 'GOAL_APPROVAL'],
  },
  {
    href: '/app/productivity',
    icon: DonutLargeIcon,
    title: 'Productivity',
    module: [
      'MEASUREMENT_APPROVAL',
      'MEASUREMENT_SUBMISSION',
      'CHANGE_MANAGEMENT',
      'QUALITY_MANAGEMENT',
      'VARIATION_MANAGEMENT',
    ],
  },
  {
    href: '/app/inventory',
    icon: AssignmentIcon,
    title: 'Inventory',
    module: [
      'MATERIAL_MASTERS',
      'MATERIAL_INDENTING',
      'MATERIAL_ISSUANCE',
      'MATERIAL_RECEIVING',
    ],
  },
  {
    href: '/app/payments',
    icon: PaymentIcon,
    title: 'Payments',
    module: ['PAYROLL', 'PAYMENT', 'PAYMENTS'],
  },
  {
    href: '/app/billing',
    icon: ReceiptIcon,
    title: 'Billing',
    module: ['BILL_MANAGEMENT', 'PAYOUT_MANAGEMENT'],
  },

  {
    href: '/app/reports',
    icon: TrendingUpIcon,
    title: 'Reports',
    module: ['PROGRESS_REPORTS', 'COMPLIANCE_REPORTS'],
  },
];
let superAdminOption = [
  {
    href: '/app/leads',
    icon: GroupIcon,
    title: 'Leads',
    module: 'ALL',
  },
  {
    href: '/app/customers',
    icon: GroupIcon,
    title: 'Customers',
    module: 'ALL',
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logo: {
    width: 'inherit',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [userData] = useContext(UserContext);

  const { getAccesRightPerModule } = useAccessPerView();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const isModuleEnabled = (module) => {
    const { view, edit, LEVEL1, LEVEL2 } = getAccesRightPerModule(module);
    return [view, edit, LEVEL1, LEVEL2].some((access) => access);
  };

  let itemsToBeRendered =
    getLoggedInUserData()?.user?.role?.name === 'SuperAdmin'
      ? [...dashboardItems, ...superAdminOption]
      : dashboardItems;
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ background: '#F3F6F8', zoom: '95%' }}
    >
      <Box p={2}>
        <Hidden lgUp>
          <Logo style={{ width: 180 }} />
        </Hidden>
        <List>
          {itemsToBeRendered.map((item, index) => {
            let moduleEnabled;
            if (item.module === 'ALL') {
              moduleEnabled = true;
            } else {
              if (typeof item.module === 'object') {
                item.module.some((module) => {
                  moduleEnabled = isModuleEnabled(module);
                  return moduleEnabled;
                });
              } else {
                moduleEnabled = isModuleEnabled(item.module);
              }
            }
            return (
              <NavItem
                href={item.href}
                key={index}
                title={item.title === 'Payments' ? 'Payroll' : item.title}
                icon={item.icon}
                disabled={!moduleEnabled}
              />
            );
          })}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
