import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  //   useMediaQuery,
  //   useTheme,
} from '@material-ui/core';
import React from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import dummaryImage from '../../assests/LogoWithAddress.png';
import logo from '../../assests/logo.png';
import logoText from '../../assests/logo-text.png';
import downloadIcon from '../../assests/DownloadIcon.png';
import bettaPayLogo from '../../assests/BettaPayLogo.png';
import { Print as PrintIcon } from '@material-ui/icons';

const CreateBill = (props) => {
  const { classes, open, handleClose } = props;

  //   const theme = useTheme();
  //   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderTitle = () => {
    return (
      <DialogTitle style={{ borderTop: '10px solid #81B734' }}>
        <Grid
          container
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: 2,
          }}
        >
          <Grid item></Grid>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
        {renderLogoWithAddress()}
        {renderMainTitle()}
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
        style={{
          backgroundColor: '#E6F1D9',
          height: 100,
          padding: '0px 25px',
        }}
      >
        <Grid item xs={10}>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
              <img src={logo} style={{ objectFit: 'contain' }} alt="logo" />
            </Grid>
            <Grid item container style={{ marginLeft: 10 }}>
              <Grid item xs={12}>
                <img
                  src={logoText}
                  style={{ objectFit: 'contain', display: 'block' }}
                  alt="logo text"
                />
              </Grid>
              <Grid item>
                <Typography>Terms & Conditions apply</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <Grid item>
            <img src={downloadIcon} alt="download" />
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <PrintIcon />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderLogoWithAddress = () => {
    return (
      <Grid
        item
        xs={12}
        container
        justify="center"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <img
          src={dummaryImage}
          style={{ objectFit: 'contain' }}
          alt="logo of the company"
        />
      </Grid>
    );
  };

  const renderMainTitle = () => {
    return (
      <Grid item xs={12} container justify="center" alignItems="center">
        <Typography
          align="center"
          style={{
            padding: '10px 20px',
            fontSize: 20,
            borderRadius: 10,
            backgroundColor: 'rgba(1,1,1,0.1)',
            fontWeight: 600,
            letterSpacing: '-0.011em',
          }}
        >
          Receipt for the Payment Received
        </Typography>
      </Grid>
    );
  };

  const renderUserInfo = () => {
    const userInfo = [
      { label: 'Remitter Name:', value: 'Satiya Parkash', fontSize: 12 },
      { label: 'GC Name:', value: 'Ashish Parkash Developer', fontSize: 12 },
      {
        label: 'GC Address:',
        value: 'Bengaluru, 560001 Karnataka, India',
        fontSize: 10,
      },
    ];
    return (
      <Grid item container xs={6} style={{ flexGrow: 1 }}>
        {userInfo.map((row) => {
          return (
            <Grid
              container
              alignItems="center"
              justify="space-between"
              style={{ margin: '2px 0px' }}
            >
              <Grid item xs={3}>
                <Typography style={{ fontSize: 10 }}>{row.label}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ fontSize: row.fontSize, fontWeight: 600 }}>
                  {row.value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderDates = () => {
    return (
      <Grid
        item
        container
        justify="flex-end"
        xs={6}
        style={{ marginBottom: 'auto' }}
      >
        <Grid item xs={12} style={{ margin: '2px 0px' }}>
          <Typography align="right" style={{ fontSize: 12 }}>
            Date: February 05, 2024
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: '2px 0px' }}>
          <Typography align="right" style={{ fontSize: 12 }}>
            Bill Period: 3 Feb, 2024 to 5 Mar, 2024.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderInfo = () => {
    return (
      <Grid
        item
        xs={12}
        container
        justify="center"
        alignItems="center"
        style={{ marginBottom: 'auto', padding: '0px 25px' }}
      >
        {renderUserInfo()}
        {renderDates()}
      </Grid>
    );
  };

  const renderItemsTable = () => {
    const items = [
      { sr: 1, description: 'RA Number', details: 'RA-007' },
      { sr: 2, description: 'Invoice Number', details: 'IBC-3098' },
      { sr: 3, description: 'Amount', details: (43567000.0).toLocaleString() },
      {
        sr: 4,
        description: 'Amount In Words',
        details:
          'Forty-three million five hundred sixty-seven thousand RupeesOnly',
      },
    ];

    return (
      <Grid
        item
        xs={12}
        container
        justify="center"
        alignItems="center"
        style={{ padding: '0px 25px' }}
      >
        <Table className={classes.bordered}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#EBEDF0' }}>
              <TableCell
                style={{
                  width: '3%',
                  padding: '10px 5px',
                  backgroundColor: 'inherit',
                }}
              >
                <Typography style={{ fontSize: 10 }}>Sr No.</Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '30%',
                  padding: '10px 5px',
                  backgroundColor: 'inherit',
                }}
              >
                <Typography style={{ fontSize: 10 }}>
                  Item Description
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '15%',
                  padding: '10px 5px',
                  backgroundColor: 'inherit',
                }}
              >
                <Typography style={{ fontSize: 10 }}>Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow
                style={{
                  backgroundColor:
                    item.description.toLowerCase() === 'amount'
                      ? '#EBEDF0'
                      : 'white',
                }}
              >
                <TableCell
                  style={{ padding: '5px 0px', backgroundColor: 'inherit' }}
                  align="center"
                >
                  <Typography style={{ fontSize: 9 }}>{item.sr}</Typography>
                </TableCell>
                <TableCell
                  style={{ padding: '0px 5px', backgroundColor: 'inherit' }}
                >
                  <Typography style={{ fontSize: 10 }}>
                    {item.description}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ padding: '0px 5px', backgroundColor: 'inherit' }}
                >
                  <Typography style={{ fontSize: 10 }}>
                    {item.details}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  };
  const renderAdditionalInfo = () => {
    return (
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        style={{
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          padding: '0px 25px 10px 25px',
        }}
      >
        <Grid item xs={4}>
          <Typography style={{ textWrap: 'nowrap', fontSize: 12 }}>
            Payment Method:
          </Typography>
          <Typography style={{ textWrap: 'nowrap', fontSize: 12 }}>
            Transaction Reference Number:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            style={{
              textWrap: 'nowrap',
              fontSize: 9,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={bettaPayLogo}
              alt="bettapay"
              style={{ objectFit: 'contain', marginRight: 5 }}
            />
            [Bettapay Online Payment]
          </Typography>
          <Typography style={{ textWrap: 'nowrap', fontSize: 12 }}>
            (UTR): [UTR Number]
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderCourtesyInfo = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ margin: 'auto' }}
      >
        <Grid item xs={12}>
          <Typography variant="body2" align="center" style={{ fontSize: 10 }}>
            Thank you for your payment.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" style={{ fontSize: 10 }}>
            If you have any questions or concerns regarding this receipt, please
            feel free to contact us at hello@bettamint.com
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" style={{ fontSize: 10 }}>
            Thank you for choosing Bettamint!
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderBody = () => {
    return (
      <Grid
        item
        container
        style={{ flexGrow: 1, alignContent: 'space-evenly' }}
      >
        {renderInfo()}
        {renderItemsTable()}
        {renderAdditionalInfo()}
        {renderCourtesyInfo()}
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      className={classes.modal}
      PaperProps={{ style: { width: 700, height: '90%' } }}
    >
      {renderTitle()}
      {renderBody()}
      {renderFooter()}
    </Dialog>
  );
};

export default CreateBill;
