import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
  Zoom,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, usersReducer, pickAUser, craeteUser } from 'src/redux/users';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

//! CUSTOM-COMPONENTS
import RenderUsersList from './userListView';
import { useNavigate } from 'react-router';
import {
  countReducer,
  getLabourExpenseMetrics,
  getUsersMetrics,
} from 'src/redux/count';
import useAccessPerView from 'src/common/Access';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    padding: '.6rem 1rem',
    textTransform: 'capitalize',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Users() {
  const [modals, setModals] = useState({
    addUserModal: false,
    userDetails: false,
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const { isViewMode } = useAccessPerView('USER_RIGHTS_MANAGEMENT');

  //! INSTANCES
  let dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  //! SELECTORS
  const { loading, error, usersList } = useSelector(usersReducer);
  const { userMetrics } = useSelector(countReducer);

  //! LIFE CYCLE METHODS
  useEffect(() => {
    if (!usersList) {
      dispatch(getUsers());
    }
    dispatch(getUsersMetrics());
    dispatch(pickAUser(null));
  }, []);

  //! METHODS
  const workersCardObj = [
    {
      id: 3,
      name: 'Total Users',
      workers: userMetrics?.totalUsers,
      img: '/static/images/bettamintImages/vector2.png',
      // img: "/static/images/bettamintImages/dashboard1.png",

      // percent: "+12%",
      // link: `/${layout}/project`,
    },
  ];

  return (
    <Page className={classes.root} id="zoom-level" title="Users">
      <Container maxWidth={false}>
        <Grid
          container
          item
          xs={12}
          md={6}
          className={classes.cardPaddingRight1}
          alignItems="center"
          justify="space-between"
          // style={{ border: "2px solid red" }}
        >
          {workersCardObj.map(
            (val, index) =>
              index <= 1 && (
                <Grid
                  container
                  item
                  md={6}
                  xs={12}
                  style={{
                    marginBottom: '1em',
                  }}
                  key={index + 1}
                  // justify='center'
                  alignItems="center"
                  className={`${
                    index === 0 ? classes.padRight : classes.padLeft
                  }`}
                >
                  <Card
                    elevation={6}
                    style={{
                      borderRadius: 10,
                      width: '100%',
                      cursor: 'pointer',
                      height: '70px',
                    }}
                    onClick={() =>
                      val?.link
                        ? navigate(val.link, {
                            replace: true,
                            // state: { from: location },
                          })
                        : null
                    }
                  >
                    <CardContent style={{ padding: '10px' }}>
                      <Grid
                        container
                        justify="space-between"
                        // alignItems="center"
                        item
                        xs={12}
                      >
                        <Grid item>
                          <Grid item xs>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                color: '#B2BEC7',
                              }}
                            >
                              {val.name}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            direction="row"
                            justify="space-between"
                            item
                            xs={12}
                          >
                            <Grid item xs={4}>
                              <Typography
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: 20,
                                  fontWeight: '400',
                                }}
                              >
                                {loading ? (
                                  <CircularProgress
                                    style={{
                                      color: '#81B734',
                                      width: '25px',
                                      height: '25px',
                                    }}
                                  />
                                ) : (
                                  val.workers
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{
                              background: '#A179F2',
                              height: 50,
                              width: 50,
                              borderRadius: 10,
                            }}
                          >
                            <PeopleOutlineIcon
                              style={{ color: '#fff' }}
                              fontSize="large"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ),
          )}
        </Grid>
        <Grid
          container
          style={{
            background: 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Grid
            container
            // style={{ height: showCard === false ? '600px' : '' }}
          >
            <Grid container item xs={12} justify="space-between">
              <Grid item xs={3}>
                <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
                  Users
                </Typography>
              </Grid>
              {/* <Grid item xs={6} /> */}
              <Grid container item xs={6} md={3} justify="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<PersonAddIcon />}
                  // onClick={() => setCreateNewProject(!createNewProject)}
                  onClick={() => navigate('create')}
                  className={classes.buttonPurpleOpacity}
                  style={{ fontFamily: 'Roboto' }}
                  disabled={isViewMode}
                >
                  Create New User
                </Button>
              </Grid>
            </Grid>
            {usersList?.length === 0 ? (
              <Grid
                container
                item
                xs={12}
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <Grid>
                  <img
                    src="/static/images/bettamintImages/user.png"
                    alt="project"
                    style={{
                      width: '80px',
                      height: '90px',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      color: '#ADBAC3',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                    }}
                  >
                    You Dont Have a user yet!
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    style={{
                      color: '#81B734',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                    }}
                    disabled={isViewMode}
                    onClick={() => navigate('create')}
                  >
                    + Create New User
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <RenderUsersList
                usersList={usersList}
                setSelectedUser={setSelectedUser}
                modals={modals}
                // filters={filters}
                // setFilters={setFilters}
                setModals={setModals}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Users;
