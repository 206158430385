import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  featuresList: [],
  featuresListV2: [],
};

const slice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    sendingFeatures(state, action) {
      state.loading = true;
      state.error = null;
    },
    sendingFeaturesSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    sendingFeaturesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingFeatures(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingFeaturesSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.featuresList = action.payload;
    },
    gettingFeaturesFailure(state, action) {
      state.loading = false;
      state.featuresList = [];
      state.error = action.payload;
    },
    gettingFeaturesV2(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingFeaturesV2Success(state, action) {
      state.loading = false;
      state.error = null;
      state.featuresListV2 = action.payload;
    },
    gettingFeaturesV2Failure(state, action) {
      state.loading = false;
      state.featuresListV2 = [];
      state.error = action.payload;
    },
  },
});
const {
  sendingFeatures,
  sendingFeaturesSuccess,
  sendingFeaturesFailure,
  gettingFeatures,
  gettingFeaturesSuccess,
  gettingFeaturesFailure,
  gettingFeaturesV2,
  gettingFeaturesV2Success,
  gettingFeaturesV2Failure,
} = slice.actions;
export const featuresReducer = (state) => state.features;

export const sendFeature = (feature) => async (dispatch) => {
  dispatch(sendingFeatures());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/FeatureSet`,
      feature,
    );

    if (response?.status === 200) {
      dispatch(sendingFeaturesSuccess());
    }
    return response;
  } catch (e) {
    dispatch(
      sendingFeaturesFailure('Something went wrong while sending feature!'),
    );
    return e;
  }
};
export const getFeatures = () => async (dispatch) => {
  dispatch(gettingFeatures());
  try {
    const response = await axios.get(`${config.baseUrl}/dashboard/FeatureSet`);

    if (response?.status === 200) {
      dispatch(gettingFeaturesSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingFeaturesFailure('Something went wrong while getting feature!'),
    );
    return e;
  }
};
export const getFeaturesV2 = () => async (dispatch) => {
  dispatch(gettingFeaturesV2());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/FeatureSet/access-rights`,
    );
    if (response?.status === 200) {
      dispatch(gettingFeaturesV2Success(response.data));
    }
    return response;
  } catch (error) {
    dispatch(
      gettingFeaturesV2Failure('Something went weong while getting roles v2!'),
    );
  }
};

export default slice.reducer;
