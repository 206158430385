import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import defaultProjectImage from '../../../assests/Union.png';

const ProjectHighlightsHead = (props) => {
  const {
    classes,
    activeMod,
    setOpenMaterialIssueModal,
    setOpenAddMaterialModal,
    setOpenGRNIssueModal,
    setOpenMaterialList,
  } = props;

  const contractorOptions = [{ label: 'K2K Hill Apartments', value: 1 }];

  return (
    <Grid
      container
      style={{
        borderRadius: '10px 10px 0px 0px',
        margin: '0px 20px',
        marginTop: 5,
        width: 'auto',
        height: 70,
        backgroundColor: 'white',
        padding: '0px 20px',
      }}
      justify="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item style={{ marginRight: 10 }}>
          <Typography style={{ fontSize: 16, textWrap: 'nowrap' }}>
            Select Project
          </Typography>
        </Grid>
        <Grid container item alignItems="center">
          <Grid item style={{ maxWidth: '12%' }}>
            <img
              alt="Project"
              src={defaultProjectImage}
              className={classes.projectAvatar}
            />
          </Grid>
          <Grid item xs={9} sm={9}>
            <TextField
              select
              placeholder="Project"
              style={{ width: 170, marginLeft: 10 }}
              defaultValue={1}
            >
              {contractorOptions.map((option) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6} justify="flex-end" gap={5}>
        {!['TRACEABILITY', 'MATERIAL_LIST'].includes(
          activeMod.toUpperCase(),
        ) && (
          <p></p>
          // <Button
          //   className={classes.buttonPurpleOpacity}
          //   style={{ textTransform: 'capitalize' }}
          //   onClick={() => setOpenTracebility()}
          // >
          //   Tracebility
          // </Button>
        )}
        {activeMod.toUpperCase() !== 'MATERIAL_LIST' && (
          <Button
            className={classes.buttonPurpleOpacity}
            style={{ textTransform: 'capitalize', marginLeft: 10 }}
            onClick={() => setOpenMaterialList()}
          >
            Material List
          </Button>
        )}
        <Button
          className={classes.buttonPurpleOpacity}
          style={{ textTransform: 'capitalize', marginLeft: 10 }}
          onClick={() => setOpenAddMaterialModal(true)}
        >
          {activeMod.toUpperCase() !== 'MATERIAL_LIST'
            ? '+ Add Vendor'
            : '+ Add Material'}
        </Button>
        {activeMod.toUpperCase() !== 'MATERIAL_LIST' && (
          <>
            <Button
              className={classes.buttonPurpleOpacity}
              style={{ textTransform: 'capitalize', marginLeft: 10 }}
              onClick={() => setOpenGRNIssueModal(true)}
            >
              + GRN Issue
            </Button>
            <Button
              className={classes.buttonPurpleOpacity}
              style={{ textTransform: 'capitalize', marginLeft: 10 }}
              onClick={() => setOpenMaterialIssueModal(true)}
            >
              + Material Issue
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectHighlightsHead;
