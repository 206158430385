import React, { useEffect, useState } from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {
  Grid,
  Typography,
  Divider,
  Button,
  makeStyles,
  Chip,
} from '@material-ui/core';
import moment from 'moment';
import BillingInvoiceModal from './BillingInvoiceModal';
import GetAppIcon from '@material-ui/icons/GetApp';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',

    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
    overflow: {
      overflowX: 'hidden',
    },
  },
  square: {
    width: '120px',
    height: '120px',
    borderRadius: '20px',
  },
  squareProject: {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
  },
  projectInput: {
    fontSize: '12px !important',
  },
  marginTop: {
    marginTop: '0',
  },
  appBar: {
    position: 'relative',
  },
  pricingCard: {
    cursor: 'pointer',
    borderRadius: '10px',
    boxSizing: 'border-box',

    '&:hover': {
      border: '1px solid #A179F2',
    },
  },
  planButton: {
    backgroundColor: '#908DA6',
    borderRadius: '7px',
    textTransform: 'capitalize',
    color: 'white',
    '&:hover': {
      backgroundColor: '#80b634',
      // color: '#3c52b2',
    },
  },

  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
    marginTop: {
      marginTop: '15px',
    },
  },
}));

const BillingDetails = () => {
  const [jsObject, setJsObject] = useState(null);
  const [isInvoiceVisible, setIsInvoiceVisible] = useState(null);

  //! INSTANCES
  const classes = useStyles();

  //! LIFE-CYCLE
  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const user = userDetails?.user?.lead?.customData;
    if (user) {
      const jsObject = JSON.parse(user);
      setJsObject(jsObject);
    }
  }, []);

  //! METHODS
  const openInvoice = () => {
    setIsInvoiceVisible(true);
  };
  const closeInvoice = () => {
    setIsInvoiceVisible(false);
  };

  return (
    <Grid item container xs={12} justify="center">
      <BillingInvoiceModal
        isInvoiceVisible={isInvoiceVisible}
        openInvoice={openInvoice}
        closeInvoice={closeInvoice}
      />
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Grid item>
          <Typography
            style={{
              color: 'rgba(161, 121, 242, 1)',
              fontWeight: '500',
              fontSize: '20px',
            }}
          >
            Billing Details
          </Typography>
        </Grid>
        <Grid container item alignItems="center" justify="flex-end" xs={2}>
          <Grid item>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: 'rgba(126, 131, 134, 1)',
              }}
            >
              HELP?{'   '}
              <span
                style={{
                  // width: "5px",
                  height: '50px',
                  border: '1px solid #E6E6E6',
                  margin: '0 10px 0 5px',
                }}
              ></span>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.buttonPurpleOpacity}
              style={{
                padding: '6px 0',
              }}
            >
              View
            </Button>
          </Grid>
          <Grid item style={{ marginLeft: '5px' }}>
            <Button
              style={{
                backgroundColor: 'rgba(129, 183, 52, 0.10)',
                padding: '6px 0',
                minWidth: '33px',
                height: '37px',
              }}
            >
              <GetAppIcon
                style={{
                  color: '#81B734',
                }}
                fontSize="small"
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ margin: '1rem 0 ' }}>
        {' '}
        <Divider />
      </Grid>

      <Grid item xs={12} container justify="space-between" alignItems="start">
        <Grid item container md={7} justify="space-between" xs={12}>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>
                Next Billing Date
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '400' }}
              >
                {jsObject?.trans_date
                  ? moment(jsObject?.trans_date, 'DD-MM-YYYY HH:mm:ss')
                      .add(1, 'month')
                      .format('LL')
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>
                Subscription Charges
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '700' }}
              >
                ₹ 60,000
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>
                Status
                <span
                  style={{
                    color: '#EC9717',
                    backgroundColor: 'rgba(236, 151, 23, 0.10)',
                    fontSize: '11px',
                    fontWeight: 500,
                    borderRadius: '4px',
                    padding: '5px 10px',
                    marginLeft: '5px',
                  }}
                >
                  Inprocess
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography style={{ fontWeight: '400' }}>Users</Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '700' }}
              >
                30
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid
              item
              style={{ marginTop: '1.5rem' }}
              className={classes.marginRight}
              md={5}
              xs={12}
            >
              <Button
                fullWidth
                style={{
                  backgroundColor: 'rgba(129, 183, 52, 1)',
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  fontWeight: 500,
                  width: '205px',
                  height: '46px',
                  color: '#fff',
                }}
                onClick={openInvoice}
              >
                Pay Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} xs={12}>
          <Grid item>
            <Typography style={{ fontWeight: '400' }}>
              Payment Profile
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item>
              <AccountBoxIcon style={{ fontSize: '50px' }} />
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '400' }}
              >
                {jsObject?.billing_name ?? 'N/A'}
              </Typography>
              <Typography
                style={{ color: 'rgba(21, 20, 17, 0.5)', fontWeight: '400' }}
              >
                {jsObject?.billing_email ?? 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingDetails;
