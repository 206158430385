//! DEPENDENCIES
import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  FormControl,
  Container,
  Select,
  MenuItem,
  LinearProgress,
  InputAdornment,
} from '@material-ui/core';
import Geocode from 'react-geocode';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import ImagePlaceHolder from 'src/assests/ImagePlaceHolder.png';
import moment from 'moment';
import '../../styles/MapDrawShape.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
//! REDUX
import { getContractors } from 'src/redux/contractors';
import {
  getProjects,
  projectReducer,
  selectProject,
  updateProject,
} from 'src/redux/projects';
import MapFullView from './MapFullView';
import {
  getScopeList,
  getUnitList,
  productivityReducer,
} from 'src/redux/productivity';
import ScopeOfWorkModal from './ScopeOfWorkModal';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem',
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    fontFamily: 'Roboto',
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: '1rem',
    fontFamily: 'Roboto',
  },
  input: {
    display: 'none',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  labels: {
    color: 'rgba(173, 186, 195, 1)',
    fontWeight: 600,
    fontSize: '10px',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    paddingBottom: '.5rem',
  },
  inputItem: {
    marginBottom: '1.3rem',
  },
  btn: {
    fontSize: '12px',
    backgroundColor: 'rgba(129, 183, 52, 1)',
    color: '#fff',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    padding: '.6rem 1.3rem',
  },
  btn1: {
    marginRight: '1rem',
  },
  colorPrimary: {
    color: '#1cf119',
  },
  projectName: {
    paddingRight: '1rem',
  },
  inputContainer: {
    paddingLeft: '15px',
    width: '80%',
  },
  imageContainer: {
    maxWidth: '20%',
  },
  controlContainer: {
    position: 'absolute',
    marginTop: '2rem',
    right: 0,
    left: 0,
    bottom: '5%',
  },
  datePicker: {
    paddingRight: '1rem',
    marginBottom: 10,
  },
  [theme.breakpoints.down('sm')]: {
    projectName: {
      paddingRight: 0,
    },
    inputContainer: {
      paddingLeft: 0,
      width: '100%',
    },
    btn1: {
      marginRight: 0,
      marginBottom: 10,
    },
    footerShadow: {
      boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 10%)',
      height: 75,
    },
    imageContainer: {
      maxWidth: '100%',
    },
    datePicker: {
      paddingRight: 0,
      marginBottom: 10,
    },
  },
}));

const CreateProjectView = (props) => {
  const [state, setState] = useState({
    mapLoaded: false,
    drawingMode: false,
    drawFreeHandMode: false,
    shape: [],
    polygons: [],
  });
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [preview, setPreview] = useState(null);
  //! INSTANCES
  const { addToast } = useToasts();
  let dispatch = useDispatch();
  let classes = useStyles();
  const formData = new FormData();
  let navigate = useNavigate();
  let location = useLocation();
  const { value, setValue } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const [sowModal, setSowModal] = useState(false);
  const [sow, setSow] = useState(null);

  //! SELECTORS
  const { loading, selectedProject } = useSelector(projectReducer);
  const { unitList, scopeList } = useSelector(productivityReducer);

  //! LIFE CYCLE
  useEffect(() => {
    if (unitList.length === 0) dispatch(getUnitList());
    if (scopeList.length === 0) dispatch(getScopeList());
    if (location.pathname === '/app/project/create') {
      dispatch(selectProject(null));
    }
    dispatch(getContractors());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (scopeList.length > 0 && unitList.length > 0) {
      const sowObject = convertScopeList(scopeList);
      setSow(sowObject);
    }
  }, [scopeList, unitList]); // eslint-disable-line

  useEffect(() => {
    if (selectedProject) {
      Geocode.fromLatLng(
        selectedProject?.latitude,
        selectedProject?.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // setLocation(address);
          setValue(address);
        },
        (error) => {
          // console.error('error', error.message);
          setValue(error.message);
        }
      );
    }
  }, []); // eslint-disable-line

  //   "scopeOfWorkProjects": [
  //   {
  //     "scopeOfWorkProjectId": 0,
  //     "scopeOfWorkId": 1,
  //     "projectId": 143,
  //     "unitId": 13,
  //     "costPerSqFeet": 0
  //   },
  //   {
  //     "scopeOfWorkProjectId": 0,
  //     "scopeOfWorkId": 2,
  //     "projectId": 143,
  //     "unitId": 3,
  //     "costPerSqFeet": 0
  //   },
  //   {
  //     "scopeOfWorkProjectId": 0,
  //     "scopeOfWorkId": 3,
  //     "projectId": 143,
  //     "unitId": 5,
  //     "costPerSqFeet": 0
  //   }
  // ]

  /**
   * Converts a list of scopes into an object with scope names as keys.
   * This function is indended to map each scope from the scopeList to a new object
   * with additional details retrieved from the selected project and unit list.
   *
   * @returns {Object} An object with scope names as keys and created scope objects as values.
   */
  function convertScopeList() {
    const scopeObject = {};
    scopeList.forEach((scope) => {
      const retrievedScope = selectedProject?.scopeOfWorks?.find(
        (projectScope) => projectScope.scopeOfWorkId === scope.scopeOfWorkId
      );

      const retrievedUnit = unitList.find(
        (unit) => unit.unitId === retrievedScope?.unitId
      );

      scopeObject[scope.name] = createScopeObject({
        scope,
        retrievedScope,
        retrievedUnit,
      });
    });
    return scopeObject;
  }

  /**
   * Creates an object with details for a specific scope.
   * This function is intended to construct an object containing the measurement,
   * touch status, and other relevant properties for a given scope.
   *
   * @param {Object} scope - Current scope
   * @param {Object} retrievedScope - Scope details retrieved from the selected project.
   * @param {Object} [retrievedUnit=null] - The unit details retrieved from the unit list.
   * @returns {Object} detailed information of a scope.
   */
  function createScopeObject({ scope, retrievedScope, retrievedUnit = null }) {
    const measurement = retrievedScope?.costPerSqFeet || '';
    const touched = Boolean(measurement);
    // const unit = retrievedUnit;
    // const touched = Boolean(measurement) || Boolean(unit);
    // const error = touched && (!measurement || !unit);

    return {
      measurement,
      touched,
      icon: scope.imageUrl,
      iconKey: scope.imageKey,
      scopeOfWorkId: scope.scopeOfWorkId,
      // unit,
      // error,
    };
  }

  /**
   * Formats the 'scopeOfWorks' object into an array of scope objects.
   * This function is intended to create an array of formatted scope objects
   * that only include scopes with a defined measurement value.
   *
   * @returns {Array} array of formatted scope objects.
   */
  function getFormattedScopeOfWorks() {
    const scopeOfWorkArray = [];

    Object.keys(sow).forEach((sowLabel) => {
      const scope = sow[sowLabel];

      // if (scope.unit && scope.measurement) {
      if (scope.measurement) {
        const scopeObject = createFormattedScopeObject(scope);
        scopeOfWorkArray.push(scopeObject);
      }
    });

    return scopeOfWorkArray;
  }

  /**
   * Creates a formatted scope object with necessary project and scope details.
   * This function is intended to format a single scope's details into an object
   * suitable for further processing or storage.
   *
   * @param {Object} scope - scope details to be formatted.
   * @returns {Object} object containing formatted details of a scope.
   */
  function createFormattedScopeObject(scope) {
    return {
      projectId: selectedProject?.projectId,
      scopeOfWorkId: scope.scopeOfWorkId,
      costPerSqFeet: Number(scope.measurement),
      // unitId: scope.unit.unitId,
    };
  }

  function validateScopeOfWorks() {
    if (Object.keys(sow).every((label) => !sow[label].measurement)) {
      throw new Error('Please provide cost for atleast one scope of work.');
    }
  }

  function validatedDrawnRegions() {
    if (state.polygons?.length === 0) {
      throw new Error('Map boundry is mandatory!');
    }
  }

  function validateProjectCompletionDate(dateString) {
    const convertedDate = moment(dateString, 'YYYY-MM-DD');
    if (convertedDate.isBefore(moment(), 'day')) {
      throw new Error("Project Completion Date can't be previous");
    }
  }

  function handleSuccess() {
    formik.resetForm();
    dispatch(getProjects());
    navigate('..');
    addToast('Project Saved Successfully', {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  function handleError(response) {
    throw new Error(`ERROR: ${response}`);
  }

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    cityName: yup.string().required('Location is required'),
    image: yup.string().required('Project Image is required'),
    projectTypeId: yup.string().required('Project Type is required'),
    projectCompletionDate: yup
      .string()
      .required('Project Completion Date is required'),
    projectArea: yup.number().required('Please enter project area in SQFT'),
  });

  const formik = useFormik({
    initialValues: {
      projectId: selectedProject?.projectId ?? 0,
      name: selectedProject?.name ?? '',
      projectTypeId: selectedProject?.projectTypeId ?? '',
      latitude: selectedProject?.latitude ?? 0,
      longitude: selectedProject?.longitude ?? 0,
      cityName: selectedProject ? value : '',
      // reraNumber: selectedProject?.reraNumber ?? '',
      developerId: selectedProject?.developerId ?? 0,
      contractorId: selectedProject?.contractorId ?? 0,
      projectManagerId: selectedProject?.projectManagerId ?? '',
      siteAdminId: selectedProject?.siteAdminId ?? '',
      supervisorId: selectedProject?.supervisorId ?? '',
      image: selectedProject?.url ?? '',
      projectCompletionDate: selectedProject?.projectCompletionDate
        ? moment(selectedProject?.projectCompletionDate).format('YYYY-MM-DD')
        : '',
      payrollOfficerId: selectedProject?.payrollOfficerId ?? '',
      projectArea: selectedProject?.projectArea ?? '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { cityName, ...rest } = values;
      try {
        //Completion Date
        validateProjectCompletionDate(rest['projectCompletionDate']);

        //Polygons
        validatedDrawnRegions();
        formData.append('geofencingList', JSON.stringify(state.polygons));

        //Scope of works
        // validateScopeOfWorks();
        const formattedScopeOfWOrk = getFormattedScopeOfWorks();
        formData.append('scopeOfWorks', JSON.stringify(formattedScopeOfWOrk));

        //prepare request data
        const objectKeys = Object.keys(rest);
        const objectValues = Object.values(rest);
        objectKeys.forEach((key, index) => {
          if (key === 'image') {
            if (objectValues[index] === selectedProject?.url) {
              return;
            }
          }
          // if (key === 'projectCompletionDate') {
          // 	return formData.append(key, moment(objectValues[index]).format());
          // }
          formData.append(key, objectValues[index]);
        });

        let resp = await dispatch(updateProject(formData));
        console.log('resp-project-create', resp);
        if (resp.status === 200) {
          handleSuccess();
        } else {
          handleError(resp?.data?.error);
        }
      } catch (err) {
        addToast(err.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
  });

  const handleLocationChange = (locationString) => {
    getGeocode({ address: locationString })
      .then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        formik.setFieldValue('latitude', lat);
        formik.setFieldValue('longitude', lng);
        formik.setFieldValue(
          'cityName',
          results[0].address_components[1].long_name,
          true
        );
      })
      .catch((error) => {
        console.error('Error during geocoding:', error);
      });
    setValue(locationString);
  };

  const handleProjectImage = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue('image', e.target.files[0], true);
  };
  const handleCancle = () => {
    dispatch(selectProject(null));
  };

  // const removeSOW = (sowLabel) => {
  //   setSow((prevSow) => ({
  //     ...prevSow,
  //     [sowLabel]: {
  //       ...prevSow[sowLabel],
  //       measurement: "",
  //       unit: null,
  //       touched: false,
  //       error: false,
  //     }
  //   }));
  // };

  //   const renderSOWField = () => {
  //   return (
  //     <Paper style={{
  //       minHeight: 40, display: 'flex', justifyContent: 'space-between',
  //       marginBottom: 20, flexWrap: 'wrap', padding: '0', alignItems: 'center',
  //       border: '1px solid rgba(1,1,1,0.23)',
  //       backgroundColor: 'transparent',
  //     }}>
  //       <Grid container alignItems="center" item xs={10} sm={10} style={{ display: 'flex', flexWrap: 'wrap', overflow: 'auto', maxHeight: 'inherit' }}>
  //         {sow && Object.keys(sow).map((chip, index) => {
  //           const hasData = sow[chip].measurement;
  //             // && sow[chip].unit;
  //           if (hasData) {
  //             const { measurement,
  //               // unit
  //             } = sow[chip];
  //             return (
  //               <Chip
  //                 key={index}
  //                 label={
  //                   <Typography component="div">
  //                     <span style={{ color: 'rgba(1,1,1,0.5)' }}>{chip}: </span><strong>
  //                       {measurement}
  //                       {/* {unit.code} */}
  //                     </strong>
  //                   </Typography>
  //                 }
  //                 onDelete={() => removeSOW(chip)}
  //                 style={{ margin: 4, borderRadius: 5 }}
  //               />
  //             );
  //           }
  //           return null;
  //         })}
  //       </Grid>
  //       <Grid item xs={2} sm={2} style={{ maxHeight: 'inherit' }}>
  //         <Button
  //           onClick={() => setSowModal(true)}
  //           style={{
  //             float: 'right', width: 'auto', textWrap: 'nowrap',
  //             display: 'flex',
  //             alignItems: 'center',
  //           }}
  //           disabled={!sow}
  //           startIcon={<AddIcon />}
  //           color="primary"
  //         >
  //           {isSmallScreen ? null : "Add New SOW"}
  //         </Button>
  //       </Grid>
  //     </Paper>
  //   );
  // };

  function handleMeasurementChange(label, value) {
    // const touched = value || SOWItems[label].unit;
    // const error = touched && (!value || !SOWItems[label].unit);
    // const touched = Boolean(value);

    setSow((prevState) => ({
      ...prevState,
      [label]: {
        ...prevState[label],
        measurement: value,
        // touched,
        // error,
      },
    }));
  }

  const renderPlaceholder = () => {
    return 'Loading scope of works';
  };

  const renderSows = () => {
    return Object.keys(sow).map((label) => (
      <Grid item xs={6} sm={4} md={2} style={{ marginBottom: 10 }}>
        <Typography
          variant="h2"
          style={{
            color: '#8E99A8',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '180px',
            margin: '0px 5px',
            fontSize: '10px',
            padding: '5px 0px',
            textTransform: 'capitalize',
          }}
        >
          {label}
        </Typography>
        <TextField
          InputProps={{
            endAdornment: (
              <Typography style={{ color: 'rgba(1,1,1,0.6)' }}>
                /sqft
              </Typography>
            ),
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
            style: { borderRadius: '7px', margin: '0px 5px' },
          }}
          size="small"
          variant="outlined"
          placeholder="--"
          defaultValue={sow[label].measurement}
          type="number"
          InputLabelProps={{
            style: {
              color: '#8E99A8',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '180px',
            },
          }}
          onChange={({ target }) =>
            handleMeasurementChange(label, target.value)
          }
        />
      </Grid>
    ));
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="h5" className={classes.mainTitle}>
        {selectedProject?.projectId ? 'Update Project' : 'Create New Project'}
      </Typography>
      {loading && <LinearProgress />}

      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ marginTop: '2rem' }}>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={12} md={2} className={classes.imageContainer}>
              <input
                accept="image/*"
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={handleProjectImage}
              />
              <label
                htmlFor="icon-button-file"
                className={classes.ImagePlaceHolder}
              >
                {selectedProject?.url && !preview ? (
                  <img
                    src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${selectedProject?.url}`}
                    alt="project url"
                    style={{
                      width: '100%',
                      maxWidth: '250px',
                      height: '150px',
                      borderRadius: '5px',
                    }}
                  />
                ) : preview ? (
                  <img
                    src={preview}
                    alt="project prev"
                    style={{
                      width: '100%',
                      maxWidth: '250px',
                      height: '150px',
                      borderRadius: '5px',
                      // border: Boolean(formik.errors.image) && '1px solid red',
                    }}
                  />
                ) : (
                  <img
                    src={ImagePlaceHolder}
                    alt="project plac"
                    style={{
                      width: '100%',
                      maxWidth: '250px',
                      height: '150px',
                      // border: Boolean(formik.errors.image) && '1px solid red',
                    }}
                  />
                )}
              </label>
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                }}
              >
                {' '}
                {formik.touched.image && formik.errors.image}
              </p>
            </Grid>

            <Grid item xs={12} md={10} className={classes.inputContainer}>
              <Grid container item>
                <Grid item xs={12} md={6} className={classes.inputItem}>
                  <Typography variant="h2" className={classes.labels}>
                    Project Name
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    placeholder="Enter your project name"
                    fullWidth
                    // margin='normal'
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { fontFamily: 'Roboto' } }}
                    className={classes.projectName}
                  />
                  <p
                    style={{
                      margin: '4px 14px 0px',
                      color: 'red',
                      fontSize: '12px',
                      fontWeight: '0',
                    }}
                  >
                    {formik.touched.name && formik.errors.name}
                  </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Typography variant="h2" className={classes.labels}>
                      Project Type
                    </Typography>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      variant="outlined"
                      id="projectTypeId"
                      name="projectTypeId"
                      value={formik.values.projectTypeId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.projectTypeId &&
                        Boolean(formik.errors.projectTypeId)
                      }
                      // onChange={handleChange}
                      // style={{ marginRight: '2rem' }}
                      size="small"
                      style={{ fontFamily: 'Roboto' }}
                    >
                      <MenuItem value="0">Select Project Type</MenuItem>
                      <MenuItem
                        value="Residential"
                        style={{ fontFamily: 'Roboto' }}
                      >
                        Residential
                      </MenuItem>
                      <MenuItem
                        value="Commercial"
                        style={{ fontFamily: 'Roboto' }}
                      >
                        Commercial
                      </MenuItem>
                      <MenuItem
                        value="Hospitality"
                        style={{ fontFamily: 'Roboto' }}
                      >
                        Hospitality
                      </MenuItem>
                      <MenuItem
                        value="Infrastructure"
                        style={{ fontFamily: 'Roboto' }}
                      >
                        Infrastructure
                      </MenuItem>
                    </Select>
                    <p
                      style={{
                        margin: '4px 14px 0px',
                        color: 'red',
                        fontSize: '12px',
                        fontWeight: '0',
                      }}
                    >
                      {formik.touched.projectTypeId &&
                        formik.errors.projectTypeId}
                    </p>
                  </FormControl>
                </Grid>
                {/* 
								<Grid
									item
									xs={12}
									md={6}
									className={`${classes.inputItem}, ${classes.projectName}`}>
									<Typography variant='h2' className={classes.labels}>
										Rera Number
									</Typography>
									<TextField
										id='reraNumber'
										name='reraNumber'
										value={formik.values.reraNumber}
										onChange={formik.handleChange}
										error={
											formik.touched.reraNumber &&
											Boolean(formik.errors.reraNumber)
										}
										placeholder='Enter rera number'
										fullWidth
										// margin='normal'
										// backgroundColor: '#F4F6F8',
										inputProps={{ style: { fontFamily: 'Roboto' } }}
										size='small'
										variant='outlined'
									/>{' '}
									<p
										style={{
											margin: '4px 14px 0px',
											color: 'red',
											fontSize: '12px',
											fontWeight: '0',
										}}>
										{formik.touched.reraNumber && formik.errors.reraNumber}
									</p>
								</Grid> */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h2" className={classes.labels}>
                    PROJECT COMPLETION DATE
                  </Typography>
                  {/* ADD DATE PICKER */}
                  <TextField
                    className={classes.datePicker}
                    id="projectCompletionDate"
                    name="projectCompletionDate"
                    value={formik.values.projectCompletionDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.projectCompletionDate &&
                      Boolean(formik.errors.projectCompletionDate)
                    }
                    helperText={
                      formik.touched.projectCompletionDate &&
                      formik.errors.projectCompletionDate
                    }
                    // placeholder='Number of project days'
                    fullWidth
                    type="date"
                    // margin='normal'
                    // backgroundColor: '#F4F6F8',
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        fontFamily: 'Roboto',
                      },
                      // min: new Date().toISOString().split("T")[0], // Set minimum date as today
                    }}
                    size="small"
                    variant="outlined"
                  />{' '}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h2" className={classes.labels}>
                    Total Built Up Area in Sqft
                  </Typography>
                  <TextField
                    style={{
                      marginBottom: 10,
                    }}
                    id="projectArea"
                    name="projectArea"
                    value={formik.values.projectArea}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'projectArea',
                        parseInt(e.target.value)
                      )
                    }
                    error={
                      formik.touched.projectArea &&
                      Boolean(formik.errors.projectArea)
                    }
                    helperText={
                      formik.touched.projectArea && formik.errors.projectArea
                    }
                    // placeholder='Number of project days'
                    fullWidth
                    type="number"
                    // margin='normal'
                    // backgroundColor: '#F4F6F8',
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: { fontFamily: 'Roboto' },
                    }}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 5 }}>
            <Grid container item xs={12} alignItems="center">
              <AddIcon />
              <Typography style={{ fontWeight: 600 }}>
                Add Project Budget
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              {sow ? renderSows() : renderPlaceholder()}
            </Grid>
          </Grid>
          <MapFullView
            miniVariant
            displayDoneAction={false}
            activeProject={selectedProject}
            handleLocationChange={handleLocationChange}
            showErrors={formik.touched.cityName && formik.errors.cityName}
            onShapeChangeCallback={({ updatedPolygons }) =>
              setState((prevState) => ({
                ...prevState,
                polygons: updatedPolygons,
              }))
            }
          />
        </Grid>
      </form>
      <Grid container justify="flex-end" style={{ marginTop: '1rem' }}>
        <Grid item md={2} sm={1.5} xs={12} className={`${classes.btn1}`}>
          <Button
            fullWidth
            variant="contained"
            className={`${classes.btn} `}
            // type='submit'
            onClick={() => formik.submitForm()}
            disabled={loading}
          >
            {selectedProject?.projectId ? 'Update Project' : 'Create Project'}
          </Button>
        </Grid>
        <Grid item md={2} sm={1.5} xs={12}>
          <Link to="..">
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              style={{
                backgroundColor: 'rgba(161, 121, 242, 1)',
                marginBottom: '1rem',
              }}
              onClick={handleCancle}
            >
              Cancel{' '}
            </Button>
          </Link>
        </Grid>
      </Grid>
      {sowModal && sow && (
        <ScopeOfWorkModal
          classes={classes}
          open={sowModal}
          handleClose={() => setSowModal(false)}
          handleSubmit={(updatedSOW) => setSow(updatedSOW)}
          unitList={unitList}
          sow={sow}
        />
      )}
    </Container>
  );
};

export default CreateProjectView;

CreateProjectView.defaultProps = {
  mapBootstrap: {
    libraries: ['drawing'],
  },
  mapOptions: {
    mapTypeId: 'roadmap',
    minZoom: 5,
    maxZoom: 20,
    gestureHandling: 'greedy',
    disableDefaultUI: true,
    scrollwheel: true,
    clickableIcons: false,
    rotateControl: false,
    tilt: 0,
  },

  defaultZoom: 7,
  polygonOptions: {
    clickable: false,
    fillColor: 'RGB(166, 116, 239)',
    fillOpacity: 0.1,
    strokeColor: 'RGB(166, 116, 239)',
    strokeWeight: 4,
    strokeOpacity: 1,
  },
  initialPointInnerHtml: `<button class="btn-initial-point" title="Initial Point"></button>`,
  deletePointInnerHtml: `<button class="btn-delete-point" title="Delete">X</button></div>`,
};
