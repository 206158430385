import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

const PaymentCardTiles = (props) => {
  const {
    classes,
    title,
    count,
    icon,
    isLoading,
    subtitle,
    variations,
    action,
    access,
  } = props;

  const varationColor = variations[0] === '-' ? '#FF0000' : '#81B734';

  const tileTitle = (
    <Typography
      style={{
        fontFamily: 'Roboto',
        fontSize: 15,
      }}
    >
      {title}
    </Typography>
  );

  const spinner = (
    <CircularProgress
      style={{
        color: '#81B734',
        width: '25px',
        height: '25px',
      }}
    />
  );

  const percentVariation = (
    <Typography
      variant="p"
      style={{
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: '400',
        color: varationColor,
      }}
    >
      {variations}
    </Typography>
  );

  const tilecount = (
    <Typography
      style={{
        fontFamily: 'Roboto',
        fontSize: 26,
        fontWeight: '400',
        textWrap: 'noWrap',
      }}
    >
      {`₹ ${count}`}
    </Typography>
  );

  const rupee = (
    <Typography style={{ fontSize: 12, color: '#B2BEC7' }}>
      {subtitle}
    </Typography>
  );

  const dailyValue = (
    <Typography
      style={{
        fontFamily: 'Roboto',
        fontSize: 10,
        fontWeight: '400',
        color: '#B2BEC7',
      }}
    >
      Daily value*
    </Typography>
  );

  const tileIcon = (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        height: 25,
        width: 25,
        borderRadius: 10,
      }}
    >
      {icon}
    </Grid>
  );

  const titleAction =
    title === 'Recharge Your Wallet' ? (
      <Button
        className={classes.buttonGreen}
        style={{
          textTransform: 'capitalize',
          fontWeight: 400,
          width: 120,
          height: 30,
          textWrap: 'nowrap',
          fontSize: 12,
        }}
        disabled={access.isViewMode}
        color="primary"
        onClick={action}
      >
        Recharge Wallet
      </Button>
    ) : (
      percentVariation
    );

  const rendercount = () => {
    return (
      <Grid
        container
        item
        alignItems="flex-start"
        style={{ flexDirection: 'column' }}
      >
        <Grid item>{rupee}</Grid>
        <Grid item>{tilecount}</Grid>
      </Grid>
    );
  };

  const renderTileHeader = () => {
    return (
      <Grid item alignItems="center" container xs={12}>
        <Grid item style={{ flexGrow: 1 }}>
          {tileTitle}
        </Grid>
        <Grid item>{renderTileIcon()}</Grid>
      </Grid>
    );
  };

  const renderTileBody = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{
          background: '#F5F6F8',
          borderRadius: 5,
          padding: '5px 10px',
          marginTop: 10,
        }}
      >
        <Grid container item alignItems="center">
          <Grid style={{ flexGrow: 1 }} item>
            {rendercount()}
          </Grid>
          <Grid item>{titleAction}</Grid>
        </Grid>
      </Grid>
    );
  };

  const renderTileIcon = () => <Grid item>{tileIcon}</Grid>;

  const renderContent = () => (
    <CardContent style={{ padding: '16px 16px 10px 16px' }}>
      <Grid container justify="space-between" alignItems="center" item xs={12}>
        {renderTileHeader()}
        {renderTileBody()}
      </Grid>
    </CardContent>
  );

  return (
    <Grid
      item
      container
      md={4}
      xs={12}
      style={{
        padding: '5px 5px 25px 5px',
      }}
      alignItems="center"
    >
      <Card
        style={{
          width: '100%',
          cursor: 'pointer',
          borderRadius: 10,
        }}
        elevation={5}
      >
        {renderContent()}
      </Card>
    </Grid>
  );
};

export default PaymentCardTiles;
