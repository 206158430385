import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  fieldNoteList: null,
  markingFieldNoteLoading: false,
  selectedFieldNote: null,
  costList: null,
};

const slice = createSlice({
  name: 'fieldNote',
  initialState,
  reducers: {
    gettingFieldNote(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingFieldNoteSuccess(state, action) {
      state.fieldNoteList = action.payload;
      state.loading = false;
      state.error = null;
    },
    gettingFieldNoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markingFieldNoteAction(state, action) {
      state.markingFieldNoteLoading = true;
      state.error = null;
    },
    markingFieldNoteActionSuccess(state, action) {
      state.markingFieldNoteLoading = false;
      state.error = null;
    },
    markingFieldNoteActionFailure(state, action) {
      state.markingFieldNoteLoading = false;
      state.error = action.payload;
    },
    assigningContractorFieldNoteAction(state, action) {
      state.markingFieldNoteLoading = true;
      state.error = null;
    },
    assigningContractorFieldNoteActionSuccess(state, action) {
      state.markingFieldNoteLoading = false;
      state.error = null;
    },
    assigningContractorFieldNoteActionFailure(state, action) {
      state.markingFieldNoteLoading = false;
      state.error = action.payload;
    },
    creatingFieldNote(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatingFieldNoteSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    creatingFieldNoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    deletingFieldNote(state, action) {
      state.loading = true;
      state.error = null;
    },
    deletingFieldNoteSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    deletingFieldNoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    selectingFieldNote(state, action) {
      state.selectedFieldNote = action.payload;
    },
    gettingFieldNoteCost(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingFieldNoteCostSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.costList = action.payload;
    },
    gettingFieldNoteCostFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
const {
  gettingFieldNote,
  gettingFieldNoteSuccess,
  gettingFieldNoteFailure,
  markingFieldNoteAction,
  markingFieldNoteActionSuccess,
  markingFieldNoteActionFailure,
  assigningContractorFieldNoteAction,
  assigningContractorFieldNoteActionSuccess,
  assigningContractorFieldNoteActionFailure,
  creatingFieldNote,
  creatingFieldNoteSuccess,
  creatingFieldNoteFailure,
  deletingFieldNote,
  deletingFieldNoteSuccess,
  deletingFieldNoteFailure,
  selectingFieldNote,
  gettingFieldNoteCost,
  gettingFieldNoteCostSuccess,
  gettingFieldNoteCostFailure,
} = slice.actions;
export const fieldNoteReducer = (state) => state.fieldNote;

export const getFieldNoteList =
  (projectId = 0, contractorId = 0) =>
  async (dispatch) => {
    dispatch(gettingFieldNote());
    try {
      const response = await axios.get(
        projectId
          ? `${config.baseUrl}/dashboard/FieldNote/project/${projectId}`
          : contractorId
          ? `${config.baseUrl}/dashboard/FieldNote?contractorId=${contractorId}`
          : `${config.baseUrl}/dashboard/FieldNote`
      );
      if (response.status === 200) {
        dispatch(gettingFieldNoteSuccess(response.data));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingFieldNoteFailure('Something went wrong while getting fieldNote!')
      );
    }
  };

export const getFieldNoteCost =
  (projectId, scopeOfWorkId, contractorId) => async (dispatch) => {
    dispatch(gettingFieldNoteCost());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/FieldNote/getcostcodes?projectId=${projectId}&scopeOfWorkId=${scopeOfWorkId}&contractorId=${contractorId}`
      );
      if (response.status === 200) {
        dispatch(gettingFieldNoteCostSuccess(response.data.result));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingFieldNoteCostFailure(
          'Something went wrong while getting fieldNote!'
        )
      );
    }
  };

export const markFieldNoteAction = (id, data) => async (dispatch) => {
  dispatch(markingFieldNoteAction());
  try {
    const response = await axios.put(
      `${config.baseUrl}/dashboard/FieldNote/action/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
        },
      }
    );
    if (response.status === 200) {
      dispatch(markingFieldNoteActionSuccess(response.data));
    } else {
      dispatch(
        markingFieldNoteActionFailure(
          'Something went wrong while marking fieldNote!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      markingFieldNoteActionFailure(
        'Something went wrong while marking fieldNote!'
      )
    );
  }
};

export const assignContractorFieldNote =
  (id, contractorId) => async (dispatch) => {
    dispatch(assigningContractorFieldNoteAction());
    try {
      const response = await axios.put(
        `${config.baseUrl}/dashboard/FieldNote/contractor/${id}?contractorId=${contractorId}`
      );
      if (response.status === 200) {
        dispatch(assigningContractorFieldNoteActionSuccess(response.data));
      } else {
        dispatch(
          assigningContractorFieldNoteActionFailure(
            'Something went wrong while assigning contractor!'
          )
        );
      }
      return response;
    } catch (e) {
      dispatch(
        assigningContractorFieldNoteActionFailure(
          'Something went wrong while assigning contractor!'
        )
      );
    }
  };

export const createFieldNoteEntry = (data) => async (dispatch) => {
  dispatch(creatingFieldNote());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/FieldNote`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
        },
      }
    );
    if (response.status === 200) {
      dispatch(creatingFieldNoteSuccess(response.data));
    } else {
      dispatch(
        creatingFieldNoteFailure(
          'Something went wrong while creating field note!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      creatingFieldNoteFailure(
        'Something went wrong while creating field note!'
      )
    );
  }
};

export const downloadFIeldNoteReport = (projectId) => async (dispatch) => {
  dispatch(creatingFieldNote());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Reports/fieldnotes?projectId=${projectId}`,
      {
        responseType: 'blob', // Treat response as binary data
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          // Add any other headers you need
        },
      }
    );
    if (response.status === 200) {
      dispatch(creatingFieldNoteSuccess(response.data));
    } else {
      dispatch(
        creatingFieldNoteFailure(
          'Something went wrong while creating field note!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      creatingFieldNoteFailure(
        'Something went wrong while creating field note!'
      )
    );
  }
};

export const deleteFielNote = (id) => async (dispatch) => {
  dispatch(deletingFieldNote());
  try {
    const response = await axios.delete(
      `${config.baseUrl}/dashboard/FieldNote?id=${id}`
    );
    if (response.status === 200) {
      dispatch(deletingFieldNoteSuccess(response.data));
    } else {
      dispatch(
        deletingFieldNoteFailure(
          'Something went wrong while deleting field note!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      deletingFieldNoteFailure(
        'Something went wrong while deleting field note!'
      )
    );
  }
};

export const selectFieldNote = (note) => async (dispatch) => {
  dispatch(selectingFieldNote(note));
};

export const verifyFieldNote = (id) => async (dispatch) => {
  // dispatch(deletingFieldNote());
  try {
    const response = await axios.put(
      `${config.baseUrl}/dashboard/FieldNote/verify/${id}`,
      {
        actionVerification: true,
      }
    );
    if (response.status === 200) {
      // dispatch(deletingFieldNoteSuccess(response.data));
    } else {
      // dispatch(
      //   deletingFieldNoteFailure(
      //     "Something went wrong while verifying field note!"
      //   )
      // );
    }
    return response;
  } catch (e) {
    dispatch(
      deletingFieldNoteFailure(
        'Something went wrong while verifying field note!'
      )
    );
  }
};

export default slice.reducer;
