import { makeStyles } from '@material-ui/core';
export const indexStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    overflowX: 'auto',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padRight: {
    paddingRight: '0.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
  [theme.breakpoints.down('sm')]: {
    padLeft: {
      paddingLeft: '0',
    },
    padRight: {
      paddingLeft: '0',
    },
  },
}));

export const projectDetailStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    minWidth: '300px',
    borderRadius: '4px',
    minHeight: '260px',
    // margin: '.2rem',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    padding: '10px 20px',
    borderRadius: '10px',

    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  media: {
    width: '123px',
    height: '118px',
    borderRadius: '5px',
  },
  projectTitle: {
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Roboto',
    color: '#000000',
  },
  projectSubtitle: {
    fontWeight: 400,
    fontSize: '11px',
    fontFamily: 'Roboto',
    color: '#000000',
  },
  projectrera: {
    fontWeight: 400,
    fontSize: '11px',
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  projectReraNumber: {
    color: '#81B734',
    fontFamily: 'Roboto',
  },
  remaningDays: {
    color: '#ADBAC3',
    fontFamily: 'Roboto',

    fontSize: '14px',
    fontWeight: 400,
  },
  projectBadge: {
    fontSize: '9px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    background: 'rgba(161, 121, 242, 0.1)',
    color: 'rgba(161, 121, 242, 1)',
    // lineHeight: '11.25px',
    height: '17px',
  },
  badgeContent: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: 'rgba(173, 186, 195, 1)',
    lineHeight: '17.5px',
    marginTop: '3px',
  },
  info: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '9px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  infoNumber: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 1)',
    textAlign: 'center',
  },
}));

export const inputStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'rgba(249, 249, 249, 1)',

    borderRadius: '10px',
  },
  stickyFooter: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper, // To ensure it doesn't become transparent
    zIndex: 2, // To ensure it stays above other elements if necessary
  },
  customOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
      borderRadius: '10px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
    },
  },
  projectType: {
    // paddingRight: '1.5rem',
    marginBottom: 10,
  },
  fieldRightPadding: {
    paddingRight: '1.5rem',
  },
  fieldBottomPadding: {
    // paddingBottom: '.1rem',
  },
  headerCellText1: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '11px',
    fontWeight: 600,
    height: '41px',
    padding: 0,
  },
  headerCellText2: {
    fontSize: '12px',
    fontWeight: 500,
  },

  [theme.breakpoints.down('sm')]: {
    projectType: {
      paddingRight: 0,
      marginBottom: 10,
    },
    // backgroundColor: theme.palette.primary.main,
    reraId: {
      marginBottom: 10,
    },
  },
}));

export const projectListView = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    // width: '350px',
    borderRadius: '4px',
    // minHeight: '270px',
    // margin: '1rem 0',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  media: {
    width: '100%',
    // height: '100%',
    // width: '120px',
    height: '115px',
    borderRadius: '5px',
    marginRight: '.5rem',
  },
  projectTitle: {
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Roboto',
    color: '#000000',
  },
  projectSubtitle: {
    fontWeight: 400,
    fontSize: '11px',
    fontFamily: 'Roboto',
    color: '#000000',
  },
  projectrera: {
    fontWeight: 400,
    fontSize: '11px',
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  projectReraNumber: {
    color: '#81B734',
    fontFamily: 'Roboto',
  },
  remaningDays: {
    color: '#ADBAC3',
    fontFamily: 'Roboto',

    // fontSize: '14px',
    fontWeight: 400,
  },
  projectBadge: {
    fontSize: '9px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    background: 'rgba(161, 121, 242, 0.1)',
    color: 'rgba(161, 121, 242, 1)',
    // lineHeight: '11.25px',
    height: '17px',
  },
  badgeContent: {
    fontSize: '11px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: 'rgba(173, 186, 195, 1)',
    lineHeight: '17.5px',
    marginTop: '3px',
  },
  info: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '9px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  infoNumber: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 1)',
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '0',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: {
      marginTop: '15px',
    },
  },
}));
