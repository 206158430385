import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BettamintLogo from '../assests/logo.png';
import BettamintLogoText from '../assests/BettamintlogoTaxInvoice.png';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  colorGreen: {
    color: '#81B734',
  },
  heading: {
    fontWeight: '600',
  },
  billToHeading: {
    fontSize: '16px',
    fontWeight: '600',
  },
  columnNames: {
    fontSize: '14px',
    fontWeight: 300,
    // paddingRight: "1.5rem",
  },
  columnNamesRight: {
    fontSize: '14px',
    fontWeight: 500,
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
    overflow: {
      overflowX: 'hidden',
    },
  },
  square: {
    width: '120px',
    height: '120px',
    borderRadius: '20px',
  },
  squareProject: {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
  },
  projectInput: {
    fontSize: '12px !important',
  },
  marginTop: {
    marginTop: '0',
  },
  appBar: {
    position: 'relative',
  },
  pricingCard: {
    cursor: 'pointer',
    borderRadius: '10px',
    boxSizing: 'border-box',

    '&:hover': {
      border: '1px solid #A179F2',
    },
  },
  planButton: {
    backgroundColor: '#908DA6',
    borderRadius: '7px',
    textTransform: 'capitalize',
    color: 'white',
    '&:hover': {
      backgroundColor: '#80b634',
      // color: '#3c52b2',
    },
  },

  marginRight: {
    marginRight: '15px',
  },

  primaryFontSize: {
    fontSize: '14px',
  },
  fontBoldRow: {
    fontWeight: '500',
  },
  tableColumn: {
    fontWeight: 300,
  },
  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
    marginTop: {
      marginTop: '15px',
    },
    marginRight: {
      marginRight: '0',
    },
  },
}));

const TaxInvoiceColumn = ({ leadObject, customData }) => {
  const classes = useStyles();

  return (
    <Grid item container direction="column" md={5}>
      <Grid
        item
        style={{
          marginBottom: '3rem',
        }}
      >
        <Typography
          className={`${classes.colorGreen} ${classes.heading}`}
          variant="h4"
        >
          TAX INVOICE
        </Typography>
        <Typography
          className={`${classes.heading} ${classes.primaryFontSize}`}
          variant="subtitle1"
        >
          Invoice No INV-000006{' '}
        </Typography>
        <Typography variant="body1" className={`${classes.primaryFontSize}`}>
          Invoice Date: 25/05/2023{' '}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>Bill To: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className={classes.billToHeading}>
              {leadObject?.companyName ?? 'N/A'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="flex-start">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>Address: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              {customData?.billing_address ?? 'N/A'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>GSTIN: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              N/A*
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>Terms: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              Due on Receipt
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>Due Date: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              {customData?.trans_date
                ? moment(customData?.trans_date).format('LL')
                : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>P.O No: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              N/A*
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>
              Place of Supply:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              N/A*
            </Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.columnNames}>Subject: </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              className={`${classes.primaryFontSize}`}
            >
              Quarterly Subscription Fees For Up to 2000 workers*
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const AddressColumn = () => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      justify="space-between"
      alignItems="flex-end"
      md={5}
    >
      <Grid container>
        <Grid item container alignItems="center" justify="flex-end">
          <Grid item style={{ paddingRight: '12px' }}>
            <Typography className={`${classes.billToHeading}`}>
              Bettamint India Private Limited
            </Typography>
          </Grid>
          <Grid item>
            <img src={BettamintLogo} alt="Bettamint Logo" />
          </Grid>
        </Grid>
        <Grid item container alignItems="flex-end" justify="flex-end">
          <Grid
            item
            container
            // alignItems="flex-end"
            // justify="center"
            // direction="column"
            xs={7}
          >
            <Grid item xs={12}>
              <Typography
                align="right"
                className={`${classes.primaryFontSize}`}
                style={{ fontWeight: 300 }}
              >
                HD-070, WeWork Galaxy, 43 Residency Road, Shanthala Nagar, Ashok
                Nagar
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="right"
                className={`${classes.primaryFontSize}`}
                style={{ fontWeight: 300 }}
              >
                Bengaluru Karnataka 560025 India
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" justify="flex-end">
          <Grid item xs={7}>
            <Typography
              align="right"
              className={`${classes.heading} ${classes.primaryFontSize}`}
            >
              GSTIN: 29AALCB0969P1ZT
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CommodityTable = () => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: 'rgba(248, 249, 249, 1) ' }}>
            <TableCell className={classes.tableColumn}>No.</TableCell>
            <TableCell align="left" className={classes.tableColumn} width="16%">
              Item & Description
            </TableCell>
            <TableCell align="left" className={classes.tableColumn}>
              HSN/SAC
            </TableCell>
            <TableCell align="left" className={classes.tableColumn}>
              Qty
            </TableCell>

            <TableCell align="left" className={classes.tableColumn}>
              Rate
            </TableCell>
            <TableCell align="left" className={classes.tableColumn}>
              CGST
            </TableCell>
            <TableCell align="left" className={classes.tableColumn}>
              SGST
            </TableCell>
            <TableCell align="left" className={classes.tableColumn}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            {
              id: 1,
              heading: 'Subscription Charges',
              item: 'Up to 2000 workers Unlimited Sites',
              date: '1 June 23 to 31 August 23',
              HSN: '997331',
              qty: '3.00',
              rate: '20,000.0',
              rate1: '0',
              CGST: '5,400.00',
              CGST1: '9%',

              SGST: '5,400.00',
              SGST1: '9%',

              amount: '₹60,000.00',
            },
          ].map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography
                  className={classes.fontBoldRow}
                  style={{ fontSize: '14px' }}
                >
                  {row.heading}
                </Typography>
                <Typography
                  // className={classes.fontBoldRow}
                  style={{ fontSize: '14px' }}
                >
                  {row.item}
                </Typography>
                <Typography
                  // className={classes.fontBoldRow}
                  style={{ fontSize: '14px' }}
                >
                  {row.date}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.fontBoldRow}>
                {row.HSN}
              </TableCell>
              <TableCell align="left" className={classes.fontBoldRow}>
                {row.qty}
              </TableCell>
              <TableCell align="left" className={classes.fontBoldRow}>
                {row.rate}
                <Typography
                  style={{ fontSize: '0.875rem' }}
                  className={classes.fontBoldRow}
                >
                  {row.rate1}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.fontBoldRow}>
                {row.CGST}
                <Typography
                  style={{ fontSize: '0.875rem' }}
                  className={classes.fontBoldRow}
                >
                  {row.CGST1}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.fontBoldRow}>
                {row.SGST}
                <Typography
                  style={{ fontSize: '0.875rem' }}
                  className={classes.fontBoldRow}
                >
                  {row.SGST1}
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                className={classes.fontBoldRow}
                style={{ color: 'rgba(129, 183, 52, 1)' }}
              >
                {row.amount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AmountSummary = () => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      xs={10}
      style={{ marginLeft: '8rem', paddingBottom: '5rem' }}
    >
      <Grid item container justify="flex-end" alignItems="center">
        <Grid item xs={2}>
          <Typography
            className={`${classes.primaryFontSize} ${classes.fontBoldRow}`}
          >
            Total Taxable Amount
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={`${classes.colorGreen} ${classes.primaryFontSize}`}
          >
            60,000.00
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justify="flex-end" alignItems="center">
        <Grid item xs={2}>
          <Typography
            className={`${classes.primaryFontSize} ${classes.fontBoldRow}`}
          >
            SGST9 (9%)
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={`${classes.colorGreen} ${classes.primaryFontSize}`}
          >
            5,400.00
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justify="flex-end" alignItems="center">
        <Grid item xs={2}>
          <Typography
            className={`${classes.primaryFontSize} ${classes.fontBoldRow}`}
          >
            CGST9 (9%)
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={`${classes.colorGreen} ${classes.primaryFontSize}`}
          >
            5,400.00
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justify="flex-end" alignItems="center">
        <Grid item xs={2}>
          <Typography
            className={`${classes.primaryFontSize} ${classes.fontBoldRow}`}
          >
            Total
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={`${classes.colorGreen} ${classes.primaryFontSize}`}
          >
            ₹70,800.00
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FooterSection = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      style={{
        backgroundColor: 'rgba(230, 241, 217, 1)',
        // paddingLeft: "5rem",
      }}
    >
      <Grid item container xs={10} style={{ padding: '3rem 4rem 0 4rem' }}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography
              style={{
                fontSize: '14px',
                // fontWeight: 400,
              }}
            >
              Balance Amount:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              className={`${classes.colorGreen} ${classes.fontBoldRow}`}
            >
              ₹70,800.00
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justify="space-between"
          style={{ paddingTop: '1rem' }}
        >
          <Grid item>
            <Typography
              style={{
                fontSize: '14px',
                // fontWeight: 400,
              }}
            >
              Total In Words:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={` ${classes.fontBoldRow}`}>
              Seventy Thousand Eight Hundred Rupees Only
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={10} style={{ margin: '2.5rem 0 ' }}>
        <Divider />
      </Grid>

      <Grid container xs={10} justify="space-between">
        <Grid container xs={5}>
          <Grid item container alignItems="center">
            <Grid item xs={3}>
              <Typography className={classes.columnNames}>
                Account Name:{' '}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" className={classes.columnNamesRight}>
                BETTAMINT INDIA PRIVATE LIMITED
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="flex-start">
            <Grid item xs={3}>
              <Typography className={classes.columnNames}>
                Account Number:{' '}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" className={classes.columnNamesRight}>
                000205035345
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={3}>
              <Typography className={classes.columnNames}>
                FSC & Branch:{' '}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" className={classes.columnNamesRight}>
                ICIC0000002 | MG ROAD, BENGALURU
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={3}>
              <Typography className={classes.columnNames}>PAN: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" className={classes.columnNamesRight}>
                AALCB0969P
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Typography style={{ fontSize: '10px', paddingTop: '10px' }}>
              GST on Reverse Charge Basis: NO
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={3} alignItems="center" justify="flex-end">
          <Grid item xs={12} style={{ marginBottom: '3.4rem' }}>
            <Typography
              align="center"
              className={classes.columnNames}
              style={{ fontSzie: '12px' }}
            >
              For Bettamint India Private Limited
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ padding: '0 1.5rem' }}>
            <Divider style={{ color: 'rgba(220, 234, 220, 1)' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              className={classes.columnNamesRight}
            >
              Authorized Signatory:
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ margin: '2.5rem 0 1rem 0' }}>
          <Divider />
        </Grid>

        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          xs={12}
          style={{ marginBottom: '2.5rem' }}
        >
          <Grid xs={5} item>
            <Grid xs={12} item>
              <img
                src={BettamintLogoText}
                alt="bettamint logo"
                style={{ width: '120px' }}
              />
            </Grid>
            <Grid xs={12} item>
              <Typography>Terms & Conditions applied</Typography>
            </Grid>
          </Grid>
          <Grid xs={5} item>
            <Typography align="right" className={classes.columnNames}>
              customersupport@bettamint.com
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

//!INDEX
const BillingInvoiceModal = ({
  isInvoiceVisible,
  // openInvoice,
  closeInvoice,
}) => {
  const [customData, setCustomData] = useState(null);
  const [leadObject, setLeadObject] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem('userDetails'));
    let lead = data?.user?.lead;
    if (lead?.customData) {
      const customDataParsed = JSON.parse(lead?.customData);
      setCustomData(customDataParsed);
    }

    setLeadObject(lead);
  }, []);

  return (
    <Dialog maxWidth fullWidth open={isInvoiceVisible} onClose={closeInvoice}>
      <AppBar className={classes.appBar} style={{ backgroundColor: '#A179F2' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeInvoice}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container justify="center" style={{ paddingTop: '2.5rem' }}>
        <TaxInvoiceColumn leadObject={leadObject} customData={customData} />
        <AddressColumn />
      </Grid>
      <Grid container justify="center">
        <Grid item xs={10} style={{ marginTop: '3rem' }}>
          <CommodityTable />
        </Grid>

        <Grid item xs={10} style={{ margin: '2rem 0' }}>
          <Divider />
        </Grid>

        <AmountSummary />
        <FooterSection />
      </Grid>
    </Dialog>
  );
};

export default BillingInvoiceModal;
