import React, { useState, useEffect } from "react";
import defaultProjectImage from "../../assests/Projects Icon.png";
import Page from "src/components/Page";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FilterProjectComponent from "src/components/FilterProjectComponent";
import { useDispatch, useSelector } from "react-redux";
import { getSkills, jobReducer } from "src/redux/jobs";
import {
  getProjectsClassificationList,
  projectReducer,
} from "src/redux/projects";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SortSelection from "src/components/SortSelection";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EventNoteIcon from "@material-ui/icons/EventNote";
import moment from "moment";
import { useRef } from "react";
import SearchInput from "src/components/SearchInput";
import { DateRangePicker } from "react-date-range";
import { useContext } from "react";
import UserContext from "src/context/UserContext";
import FilterByLabourContractorComponent from "src/components/FilterByLaourContractorComponent";
import PlayCircleFilledRoundedIcon from "@material-ui/icons/PlayCircleFilledRounded";
import personalInfo from "../../assests/Personal Info.png";
import CommingSoon from "../../assests/commingSoon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: "#81B734",
    "&:hover": {
      backgroundColor: "#81B734",
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: "#A179F2",
    "&:hover": {
      backgroundColor: "#A179F2",
    },
  },
  buttonPurpleOpacity: {
    color: "#986FEF",
    backgroundColor: "#f5f1fdff",
    "&:hover": {
      backgroundColor: "rgba(161, 120, 242, 0.20)",
    },
  },
  cardPaddingRight: {
    paddingRight: "2rem",
  },
  marginTop: {
    marginTop: "0px",
  },
  width: {
    width: "90%",
  },

  caroselHeight: {
    height: "38em",
  },
  table: {
    minWidth: 650,
  },
  DARAndDLRColums: {
    color: "#c2c1cdff",
    fontSize: "12px",
  },

  [theme.breakpoints.down("sm")]: {
    cardPaddingRight: {
      paddingRight: "0",
    },
    marginTop: {
      marginTop: "1.5rem",
    },
    width: {
      width: "100%",
    },
    caroselHeight: {
      height: "30em",
    },
  },
}));
const Rewards = () => {
  const [classifiedProject, setClassifiedProject] = useState("");
  const [currentProject, setCurrentProject] = useState("");
  const [range, setRange] = useState([
    {
      // startDate: new Date(),
      // endDate: addDays(new Date(), 7),
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      noValue: true,
    },
  ]);
  const [filters, setFilters] = useState({
    search: "",
    sortBy: 0,
    filterBy: 0,
    searchModal: "",
    filterModalBy: 0,
  });

  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const [userData, setUserData] = useContext(UserContext);
  const [LabourContractor, setLabourContractor] = useState(0);
  const [openNewTask, setOpenNewTask] = useState(false);

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  //! SELECTORS
  const { skillsList } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);

  //! LIFECYCLE
  useEffect(() => {
    dispatch(getSkills());
    dispatch(getProjectsClassificationList());
  }, []);

  useEffect(() => {
    if (projectClassificationList?.length) {
      setCurrentProject(projectClassificationList[0]);
    }
  }, [projectClassificationList?.length]);

  //! METHODS
  const classifyProject = (project) => {
    if (project) {
      setCurrentProject(project);
    } else {
      setCurrentProject("");
    }
  };
  const handleLabourContractor = (val) => {
    setLabourContractor(val);
    // dispatch(getAttendance(currentProject?.projectId, val?.userId));
  };

  const handleFiltersStatus = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, filterBy: value });
  };

  //! TEMP
  let items = [
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },

    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
    {
      scope: "Earthwork",
      uom: "Rmts",
      measurement: "200",
      reward: "₹200",
      noOfWorkers: "200",
    },
  ];
  return (
    <Page
      className={classes.root}
      id="zoom-level"
      title="Rewards"
      style={{ position: "relative" }}
    >
      <Container maxWidth={false}>
        <Dialog
          open={openNewTask}
          onClose={() => setOpenNewTask(false)}
          keepMounted
          // maxWidth="lg"
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenNewTask(false)}
          >
            Add New Task
          </DialogTitle>
          <DialogContent>
            <img
              src={personalInfo}
              alt="boq"
              style={{ width: "100%", height: "100%" }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.buttonPurple}
              style={{ color: "#fff" }}
              size="small"
              onClick={() => setOpenNewTask(false)}
            >
              <span style={{ marginRight: "5px" }}> Submit</span>
              <PlayCircleFilledRoundedIcon
                style={{ fontSize: "15px" }}
                // size="10"
              />
            </Button>
          </DialogActions>
        </Dialog>

        <img
          src={CommingSoon}
          alt="chat"
          style={{
            position: "absolute",
            top: "20%",
            width: "300px",
            height: "300px",
            left: "40%",
          }}
        />
        <Grid
          item
          container
          style={{
            background: "white",
            padding: "1em",
            borderRadius: "10px",
            opacity: 0.1,
          }}
        >
          <Grid container item xs={12}>
            <Grid xs={3}>
              <Typography variant="h6">Rewards</Typography>
            </Grid>
            <Grid
              item
              md={12}
              container
              justify="space-between"
              alignItems="center"
              className={`${classes.marginRight} ${classes.marginTop}`}
              style={{ margin: "10px 0" }}
            >
              <Grid container item md={6} xs={12}>
                <Grid item md={6} xs={12}>
                  <FilterProjectComponent
                    projectClassificationList={projectClassificationList}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                    value={currentProject}
                    onChange={classifyProject}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {userData?.user.leadTypeId !== "LabourContractor" && (
                    <FilterByLabourContractorComponent
                      value={LabourContractor}
                      onChange={handleLabourContractor}
                      setInitialUser={(user) => setLabourContractor(user)}
                      currentProject={classifiedProject[0]}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid md={3} xs={12} container justify="flex-end">
                <Button
                  size="small"
                  className={classes.buttonGreen}
                  style={{ padding: "5px 10px", textTransform: "capitalize" }}
                  onClick={() => setOpenNewTask(true)}
                >
                  Add New Task
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            // spacing={3}
            // style={{ marginTop: '1rem' }}
            alignItems="center"
            className="input-container-nav"
            justify="space-between"
            // style={ { border: '2px solid green' } }
          >
            {/* container */}

            <Grid
              item
              md={3}
              xs={12}
              container
              alignItems="center"
              justify="space-between"
              // className='padding5'
              // style={{ padding: '2px' }}
              className={classes.marginTop}
            >
              <Grid item xs={1}>
                <small>Skills: </small>
              </Grid>
              <Grid item md={10} xs={9}>
                <SortSelection
                  initialValue="Skills"
                  // value={filters.sortBy}
                  // handleFilters={handleFilters}
                  data={skillsList?.map((skill) => ({
                    value: skill.name,
                    label: skill.name,
                  }))}
                />
              </Grid>
            </Grid>

            {/* //! 2.DatePickerInput */}
            <Grid
              item
              md={3}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
              className={`${classes.marginRight} ${classes.marginTop}`}
              // style={ { border: '2px solid red' } }
              // className={ classes.marginTop }
            >
              <Grid item md={1} xs={3} className={classes.marginLeft}>
                <small>Date:</small>
              </Grid>
              <Grid
                item
                md={10}
                xs={9}
                style={{
                  position: "relative",
                }}
              >
                <div className="calendarWrap">
                  <input
                    value={
                      !range[0]?.noValue
                        ? `${moment(range[0]?.startDate).format(
                            "Do MMM"
                          )} - ${moment(range[0]?.endDate).format("Do MMM")}`
                        : ""
                    }
                    readOnly
                    className="inputBox"
                    // type='search'
                    onClick={() => setOpen((open) => !open)}
                  />
                  {!range[0].noValue ? (
                    <HighlightOffIcon
                      onClick={() => {
                        setRange([
                          {
                            startDate: new Date(),
                            endDate: new Date(),
                            key: "selection",
                            noValue: true,
                          },
                        ]);
                        // setFilteredArray([]);
                      }}
                      style={{
                        position: "absolute",
                        top: "7px",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <EventNoteIcon
                      onClick={() => setOpen((open) => !open)}
                      style={{
                        position: "absolute",
                        top: "7px",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    />
                  )}

                  <div ref={refOne}>
                    {open && (
                      <DateRangePicker
                        onChange={(item) => {
                          setRange([{ ...item.selection, noValue: false }]);
                          // handleDateRange([
                          //   { ...item.selection, noValue: false },
                          // ]);
                        }}
                        // style={{ width: '100px' }}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        // months={2}
                        // direction='horizontal'
                        className="calendarElement"
                      />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              md={2}
              xs={12}
              container
              alignItems="center"
              justify="space-between"
              // className='padding5'

              // style={{ padding: '2px', border: '1px solid red' }}
              className={`${classes.marginLeft} ${classes.marginTop}`}
            >
              {/* <Grid item md={3} xs={3}> */}
              <small>Status:</small>
              {/* </Grid> */}
              <Grid
                item
                xs={9}
                md={9}
                // className={ classes.statusPaddingLeft }
              >
                <SortSelection
                  value={filters.filterBy}
                  initialValue={"Status"}
                  handleFilters={handleFiltersStatus}
                  data={[
                    {
                      value: "Online",
                      label: "Online",
                    },
                    {
                      value: "Offline",
                      label: "Offline",
                    },
                    // {
                    // 	value: 'Active',
                    // 	label: 'Active',
                    // },
                    // {
                    // 	value: 'NonActive',
                    // 	label: 'Non Active',
                    // },
                  ]}
                />
              </Grid>
            </Grid>

            {/* //! 3.SearchInput */}
            <Grid
              item
              md={3}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
              className={classes.marginTop}
            >
              <Grid item md={1} xs={3}>
                <small style={{ paddingRight: "15px" }}>Search:</small>
              </Grid>
              <Grid item xs={9} md={10} style={{ position: "relative" }}>
                <SearchInput
                  search={filters.search}
                  // handleSearch={handleSearch}
                  // className='font12'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "5px" }}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="DAR Table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      SCOPE OF WORK
                    </TableCell>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      UoM
                    </TableCell>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      MEASUREMENT
                    </TableCell>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      TARGET DATE
                    </TableCell>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      REWARD
                    </TableCell>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      NO. OF WORKERS
                    </TableCell>
                    <TableCell align="left" className={classes.DARAndDLRColums}>
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.map((item, index) => (
                    <TableRow key={index + 1}>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        {item.scope}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{ fontSize: "12px" }}
                      >
                        {item?.uom}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        {item.measurement}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        {moment().format("LLL")}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        {item.reward}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        {item.noOfWorkers}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        <Button
                          className={classes.buttonPurpleOpacity}
                          size="small"
                          style={{
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            padding: "5px 20px",
                          }}
                        >
                          Achieved
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Rewards;
