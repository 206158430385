import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import React from 'react';
import WalletColored from '../../../assests/WalletColored.png';

export const RechargeBanner = (props) => {
  const { handleSubmit, open } = props;

  const renderImage = () => (
    <Grid item>
      <img src={WalletColored} alt="wallet" />
    </Grid>
  );
  const renderTitle = () => (
    <Grid item xs={12}>
      <Typography
        style={{
          fontSize: 32,
          fontWeight: 500,
          textAlign: 'center',
          fontFamily: 'roboto',
        }}
      >
        Recharge Your Wallet first to proceed further!
      </Typography>
    </Grid>
  );

  const renderAction = () => (
    <Grid item>
      <Button
        color="primary"
        style={{ textTransform: 'capitalize', border: '1px solid' }}
        onClick={handleSubmit}
      >
        Recharge Wallet
      </Button>
    </Grid>
  );

  return (
    <Dialog open={open}>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ gap: 10, padding: 100 }}
      >
        {renderImage()}
        {renderTitle()}
        {renderAction()}
      </Grid>
    </Dialog>
  );
};
