import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  featuresReducer,
  getFeatures,
  getFeaturesV2,
} from 'src/redux/features';
import { createRole } from 'src/redux/roles';

const parentMap = {
  Project: [
    'Projects',
    'Project Budget',
    'Project Boundary',
    'Project Linking',
  ],
  User: ['User Rights Management'],
  Billing: [
    'Contract and Contractor Management',
    'Bill Management',
    'Payout Management',
  ],
  Productivity: [
    'Measurement Submission',
    'Measurement Approval',
    'Change Management',
    'Quality Management',
    'Variation Management',
  ],
  Inventory: [
    'Material Masters',
    'Material Indenting',
    'Material Issuance',
    'Material Receiving',
  ],
  Workforce: [
    'Worker Onboarding',
    'Attendance Marking',
    'Muster Roll Management',
    'Payroll',
    'Payments',
  ],
  Rewards: ['Goal Setting', 'Goal Approval'],
  Communication: ['In App Chat Features'],
  Reports: ['Progress Reports', 'Compliance Reports'],
};

const AddRolesv2 = (props) => {
  const [activeRolesAndRights, setActiveRolesAndRights] = useState({});
  const [activeRoleName, setActiveRoleName] = useState('');
  const [activeRoleId, setActiveRoleId] = useState(0);
  const [errors, setErrors] = useState(false);

  const dispatch = useDispatch();
  const {
    loading,
    featuresList,
    featuresListV2: userRights,
  } = useSelector(featuresReducer);

  useEffect(() => {
    dispatch(getFeatures());
    dispatch(getFeaturesV2());
  }, []);

  // Initially mark all the visible features as read only
  useEffect(() => {
    if (featuresList.length > 0 && !props.isEditMode) {
      const initialFeatures = {};
      featuresList.forEach((feature) => {
        Object.keys(parentMap).forEach((parent) => {
          if (parentMap[parent].includes(feature.name)) {
            initialFeatures[feature.featureSetId] = [1];
          }
        });
      });
      setActiveRolesAndRights(initialFeatures);
    }
  }, [featuresList]);

  useEffect(() => {
    props.fetchFunction({ handleSubmit });
  }, [activeRoleName, activeRolesAndRights]);

  useEffect(() => {
    if (props.isEditMode) {
      const { featureSet, roleId, roleName } = props.activeRoleFeatures;
      setActiveRolesAndRights(featureSet);
      setActiveRoleId(roleId);
      setActiveRoleName(roleName);
      props.fetchFunction({ handleSubmit });
    }
  }, [props.activeRoleFeatures]);

  const noUpdatesPerformed = () => {
    const initialFeatureSets = JSON.stringify(
      props.activeRoleFeatures.featureSet
    );
    const updatedFeatureSets = JSON.stringify(activeRolesAndRights);

    return (
      initialFeatureSets === updatedFeatureSets &&
      props.activeRoleFeatures.roleName ===
        props.activeRoleFeatures.editedRoleName
    );
  };

  //Iterate through all the featuresets and return the active featureSets;
  const createFeatureList = () => {
    const selectedFeatures = [];
    featuresList.forEach((feature) => {
      const featureExtensible = { ...feature };
      const activeFeature =
        activeRolesAndRights[featureExtensible.featureSetId];
      if (
        activeFeature &&
        activeFeature.length > 0 &&
        !activeFeature.includes(null)
      ) {
        delete featureExtensible.name;
        delete featureExtensible.route;
        featureExtensible.accessRightList = activeFeature;
        selectedFeatures.push(featureExtensible);
      }
    });
    return selectedFeatures;
  };

  const handleSubmit = async () => {
    if (!activeRoleName) {
      setErrors(true);
      return;
    }

    if (noUpdatesPerformed()) {
      if (activeRoleId !== 0 || props.isEditMode) {
        return;
      }
    }

    const featureListArray = createFeatureList();

    const role = {
      name: props.isEditMode
        ? props.activeRoleFeatures.editedRoleName ||
          props.activeRoleFeatures.roleName
        : activeRoleName,
      roleId: activeRoleId,
      featureSets: featureListArray,
    };

    await dispatch(createRole(role));
  };

  const groupFeaturesByParent = (features) => {
    const groupedFeatures = new Map();
    features.forEach((feature) => {
      Object.keys(parentMap).forEach((parent) => {
        if (parentMap[parent].includes(feature.name)) {
          if (!groupedFeatures.has(parent)) {
            groupedFeatures.set(parent, [feature]);
          } else {
            groupedFeatures.get(parent).push(feature);
          }
        }
      });
    });
    return groupedFeatures;
  };

  const groupedFeatures = groupFeaturesByParent(featuresList);

  //ADD or REMOVE selected access right to the featureset array.
  const handleCheckbox = (featureSetId, accessRightId, value) => {
    const action = value ? 'ADD' : 'REMOVE';
    setActiveRolesAndRights((prev) => {
      const deepCopy = JSON.parse(JSON.stringify(prev));
      if (!deepCopy[featureSetId]) {
        deepCopy[featureSetId] = [];
      }
      if (action === 'ADD') {
        deepCopy[featureSetId] = [accessRightId];
      } else {
        deepCopy[featureSetId] = [];
      }
      return deepCopy;
    });
  };

  const renderUserRights = () => {
    return (
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Module</TableCell>
        {userRights.map((right) => (
          <TableCell align="center">{right.name}</TableCell>
        ))}
      </TableRow>
    );
  };

  const rendeCheckbox = (featureSetId, accessRightId) => {
    const feature = JSON.parse(JSON.stringify(activeRolesAndRights));
    const featureSetAccess = feature[featureSetId];
    if (!featureSetAccess) {
      if (!props.isEditMode) {
        feature[featureSetId] = [1];
      } else {
        feature[featureSetId] = [];
      }
    }
    return (
      <Checkbox
        onChange={(e) =>
          handleCheckbox(featureSetId, accessRightId, e.target.checked)
        }
        checked={feature[featureSetId][0] === accessRightId}
        disabled={[4, 5].includes(accessRightId)}
        defaultChecked={accessRightId === 1}
      />
    );
  };

  const renderRightsOptions = (featureSetId) => {
    return (
      <>
        {userRights.map((right) => {
          return (
            <TableCell align="center">
              {rendeCheckbox(featureSetId, right.accessRightId)}
            </TableCell>
          );
        })}
      </>
    );
  };

  const renderBody = () => {
    if (groupedFeatures.entries().length === 0) {
      return <></>;
    }
    return (
      <TableBody>
        {Array.from(groupedFeatures.entries()).map(
          ([parent, childFeatures]) => (
            <React.Fragment key={parent}>
              {childFeatures.map((feature, index) => (
                <TableRow key={feature.featureSetId}>
                  {index === 0 && (
                    <TableCell rowSpan={childFeatures.length}>
                      {parent}
                    </TableCell>
                  )}
                  <TableCell
                    style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}
                  >
                    {feature.name}
                  </TableCell>
                  {renderRightsOptions(feature.featureSetId)}
                </TableRow>
              ))}
            </React.Fragment>
          )
        )}
      </TableBody>
    );
  };

  const renderLoading = () => loading && <>Loading ...</>;

  const renderRoleNameField = () => {
    if (props.isEditMode) {
      return <div style={{ margin: '20px 0px', width: '100%' }} />;
    }
    const renderError = () => {
      return (
        <p
          style={{
            margin: '4px 14px 0px',
            color: 'red',
            fontSize: '12px',
            fontWeight: '0',
          }}
        >
          {errors && 'Name is required'}
        </p>
      );
    };
    return (
      <Grid item md={12} xs={12} style={{ marginBottom: '1rem' }}>
        <TextField
          fullWidth
          id="name"
          name="name"
          error={errors}
          value={activeRoleName}
          variant="outlined"
          placeholder="Enter New Role Name Here"
          inputProps={{
            style: { fontFamily: 'Roboto' },
          }}
          onChange={(e) => {
            setActiveRoleName(e.target.value);
            setErrors(false);
          }}
        />
        {renderError()}
      </Grid>
    );
  };
  return (
    <>
      {renderRoleNameField()}
      <Table size="small" style={{ border: '1px solid rgba(1,1,1,0.1)' }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={7} align="center">
              User Rights
            </TableCell>
          </TableRow>
          {renderUserRights()}
        </TableHead>
        {renderLoading()}
        {renderBody()}
      </Table>
    </>
  );
};

export default AddRolesv2;
