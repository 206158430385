import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const Label = (props) => {
  const { title, subTitle, emphasis = 'title' } = props;

  const fontSizeTitle = emphasis === 'title' ? 18 : 10;
  const fontSizeSubTitle = emphasis === 'subtitle' ? 18 : 10;

  const opacityTitle = emphasis === 'title' ? 1 : 0.6;
  const opacitySubTitle = emphasis === 'subtitle' ? 1 : 0.6;

  return (
    <Grid item>
      <Typography style={{ fontSize: fontSizeTitle, opacity: opacityTitle }}>
        {title}
      </Typography>
      <Typography
        style={{ fontSize: fontSizeSubTitle, opacity: opacitySubTitle }}
      >
        {subTitle}
      </Typography>
    </Grid>
  );
};
export default Label;
