import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useContext } from 'react';
import UserContext from 'src/context/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    justify: 'center',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    padding: '8px 20px',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  justify: {
    display: 'flex',
    alignItems: 'center',
    justify: 'space-between',
    top: '0%',
  },
  left: {
    left: '5%',
  },
  [theme.breakpoints.down('sm')]: {
    alignText: {
      textAlign: 'center',
    },
    justify: {
      display: 'flex',
      alignItems: 'center',
      justify: 'center',
      top: '6%',
    },
    left: {
      left: '0%',
    },
  },
}));
const PaymentRenewAlert = ({ classes }) => {
  return (
    // <Grid
    //   container
    //   xs={12}
    //   justify="center"
    //   style={{}}
    //   className={classes.left}
    // >
    <Grid
      item
      container
      style={{
        position: 'absolute',
        zIndex: 100,
        opacity: 0.9,
        border: '1px solid red',
        padding: '1rem',
        color: 'white',
        backgroundColor: '#ff726f',
      }}
      className={classes.justify}
      // justify="space-between"
      // alignItems="center"
      xs={8}
    >
      <Typography>
        Your payment plan is about to expire. Please renew to avoid any
        interuption in the services.
      </Typography>
      <a
        href="http://bettamint.com/payment.php"
        target="_blank"
        style={{ color: 'white' }}
      >
        <Button className={classes.buttonPurple}>Renew Now</Button>
      </a>
    </Grid>
    // </Grid>
  );
};
const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  let [userData, setUserData] = useContext(UserContext);
  let location = useLocation();

  if (Object.keys(userData).length == 0) {
    return <Navigate to="auth/login" replace state={{ from: location }} />;
  }

  return (
    <div className={classes.root}>
      {/* {[10, 3].includes(userData?.user?.roleId) && (
        <PaymentRenewAlert classes={classes} />
      )} */}
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
