import React, { useState } from 'react';
import { ExpandMore, ExpandLess, Edit } from '@material-ui/icons';

const { TableCell, Grid, IconButton, TableRow } = require('@material-ui/core');
const MaterialListRow = (props) => {
  const { rowData, rowIndex } = props;
  const [expandedRows, setExpandedRows] = useState({});

  const renderRow = (data, index) => {
    const handleExpandClick = (contractorIndex) => {
      setExpandedRows((prev) => ({
        ...prev,
        [contractorIndex]: !prev[contractorIndex],
      }));
    };

    const maxMaterialRowSpan = data.Description.reduce(
      (acc, child) =>
        acc + (expandedRows[child.Description] ? child.Brand.length : 1),
      0,
    );

    const rows = data.Description.map((description, descriptionIndex) => {
      const isExpanded = expandedRows[description.Description];
      const maxDescriptionRowSpan = description.Brand.length;

      return (
        <React.Fragment key={descriptionIndex}>
          <TableRow>
            {descriptionIndex === 0 && (
              <>
                <TableCell
                  rowSpan={maxMaterialRowSpan}
                  style={{ fontWeight: 'bold', verticalAlign: 'top' }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  rowSpan={maxMaterialRowSpan}
                  style={{ fontWeight: 'bold', verticalAlign: 'top' }}
                >
                  {data.Material}
                </TableCell>
              </>
            )}
            <TableCell
              rowSpan={isExpanded ? maxDescriptionRowSpan : 1}
              style={{
                fontWeight: 'bold',
                verticalAlign: 'top',
                borderLeft: '1px solid rgba(1,1,1,0.1)',
              }}
            >
              <Grid container style={{ flexWrap: 'nowrap' }}>
                <p>{description.Description}</p>
                <IconButton
                  onClick={() => handleExpandClick(description.Description)}
                  style={{ padding: 0 }}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell
              style={{
                fontWeight: 'bold',
                borderLeft: '1px solid rgba(1,1,1,0.1)',
              }}
            >
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ flexWrap: 'nowrap' }}
              >
                <img
                  src={description.Brand[0].Profile}
                  alt="contractor profile"
                />
                <p
                  style={{
                    margin: '0px 10px',
                    fontWeight: 'bold',
                    textWrap: 'nowrap',
                  }}
                >
                  {description.Brand[0].BrandName}
                </p>
              </Grid>
            </TableCell>
            <TableCell>
              <p style={{ fontWeight: 'bold' }}>{description.Brand[0].Make}</p>
            </TableCell>
            <TableCell>
              <IconButton onClick={() => {}} style={{ padding: 0 }}>
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
          {isExpanded &&
            description.Brand.slice(1).map((brand, brandIndex) => (
              <TableRow key={brandIndex}>
                <TableCell style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <img
                      src={description.Brand[0].Profile}
                      alt="contractor profile"
                    />
                    <p
                      style={{
                        margin: '0px 10px',
                        fontWeight: 'bold',
                        textWrap: 'nowrap',
                      }}
                    >
                      {brand.BrandName}
                    </p>
                  </Grid>
                </TableCell>
                <TableCell>{brand.Make}</TableCell>
                <TableCell>
                  <IconButton onClick={() => {}} style={{ padding: 0 }}>
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      );
    });
    return rows;
  };
  return renderRow(rowData, rowIndex);
};

export default MaterialListRow;
