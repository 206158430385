import { Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import SearchInput from 'src/components/SearchInput';
import SortSelection from 'src/components/SortSelection';
import EventNoteIcon from '@material-ui/icons/EventNote';

import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { useDispatch, useSelector } from 'react-redux';
import NestedTable from './NestedTable';

const InventoryManagementSummary = (props) => {
  const { tableHead, tableData, tableRow } = props;
  const { projectClassificationList } = useSelector(projectReducer);

  //! INSTANCES
  let dispatch = useDispatch();

  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, []); //eslint-disable-line

  const renderFilters = () => {
    return (
      <Grid item container className="input-container-nav">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          style={{
            background: '#f0f3f5',
            border: '1px solid #ddd',
          }}
        >
          <Grid item container xs={12} md={6} justify="space-between">
            <Grid
              item
              md={5}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4} md={3}>
                <small className="font11">Sort By:</small>
              </Grid>
              <Grid item xs={8} md={9}>
                <SortSelection
                  handleFilters={() => {}}
                  value={''}
                  data={[{ value: 'some', label: 'Scope of Work' }]}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <small className="font11">Search Filters:</small>
              </Grid>
              <Grid item xs={8}>
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  selectsRange={true}
                  startDate={new Date()}
                  isClearable={true}
                  className="font12 date-picker "
                />
                <EventNoteIcon
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '7px',
                    color: '#ADBAC3',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={5}
            xs={12}
            item
            container
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={2}>
              <small className="font11">Search:</small>
            </Grid>
            <Grid item md={10} xs={8} style={{ position: 'relative' }}>
              <SearchInput search={''} handleSearch={() => {}} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper style={{ margin: 10, borderRadius: 10 }}>
      {renderFilters()}
      <Grid item container style={{ overflow: 'auto' }}>
        <NestedTable
          data={tableData}
          renderRow={tableRow}
          headers={tableHead}
        />
      </Grid>
    </Paper>
  );
};

export default InventoryManagementSummary;
