import {
	Button,
	FormControlLabel,
	Grid,
	Radio,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	buttonGreen: {
		color: theme.palette.common.white,
		backgroundColor: '#81B734',
		'&:hover': {
			backgroundColor: '#81B734',
		},
	},
	buttonPurple: {
		color: theme.palette.common.white,
		backgroundColor: '#A179F2',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: '#A179F2',
		},
	},
	text: {
		color: '#888',
		fontWeight: 500,
	},
	container: {
		backgroundColor: '#fff',
		padding: '1.3rem 10px',
		boxShadow: '3px 3px 5px 1px rgba(0, 0, 0, 0.2)',
		borderRadius: '10px',
		margin: '10px',
		cursor: 'pointer',
	},
	buttonPurpleOpacity: {
		color: '#986FEF',
		backgroundColor: 'rgba(161, 120, 242, 0.20)',
		'&:hover': {
			backgroundColor: 'rgba(161, 120, 242, 0.20)',
		},
	},
	textArea: {
		height: '50px',
	},
}));

const FineCard = ({ setFineCard }) => {
	const classes = useStyles();

	return (
		// <ThemeProvider theme={theme}>
		<Grid container>
			<Grid item xs={12}>
				<Button
					variant='outlined'
					startIcon={<ArrowBackIcon />}
					style={{ marginLeft: '20px' }}
					onClick={() => setFineCard(false)}>
					Back
				</Button>
			</Grid>
			<Grid container justify='center'>
				<Grid item xs={12}>
					<Typography align='center' variant='h4' component='h1'>
						Register of Fines
					</Typography>
				</Grid>
				<Grid container xs={10} style={{ marginTop: '2rem' }}>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Name and Address of Contractor</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea className='textArea'></textarea>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>
								Name and address of establishment in/under which contract is
								carried on
							</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea className='textArea'></textarea>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Nature of work and location of work</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea className='textArea'></textarea>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Name and address of Principal Employer</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea className='textArea'></textarea>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Serial No</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Name of Workmen</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Father's/Husband's Name</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Nature of Employment/ Designation</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Act / Omission for which fine is imposed</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea className='textArea'></textarea>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Date of Offences</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Whether Workman Showed Cause Against Fine</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>
								Name of Person in Whose Presence Employees' Explanation Was
								Heard
							</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Wage Period and Wages Payable</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Amount of Fine Imposed</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Date on Which Fine Realized</Typography>
						</Grid>
						<Grid item xs={9}>
							<TextField
								fullWidth
								variant='outlined'
								style={{ marginLeft: '20px' }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							<Typography>Remarks</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea className='textArea'></textarea>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justify='space-between'
						xs={12}
						style={{ marginBottom: '15px' }}>
						<Grid item xs={3}>
							{/* <Typography>Remarks</Typography> */}
						</Grid>

						<Grid item xs={9}>
							<Button
								className={classes.buttonPurple}
								style={{ marginLeft: '20px' }}
								variant='contained'>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		// </ThemeProvider>
	);
};

export default FineCard;
