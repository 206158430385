import React, { useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const { TableCell, Grid, IconButton, TableRow } = require('@material-ui/core');

const {
  SummaryPercentageBar,
} = require('src/views/Billing/components/ContractorLedgerSummary');

const TraceabilityRow = (props) => {
  const { rowData, rowIndex } = props;
  const [expandedRows, setExpandedRows] = useState({});

  const renderRow = (data) => {
    const handleExpandClick = (contractorIndex) => {
      setExpandedRows((prev) => ({
        ...prev,
        [contractorIndex]: !prev[contractorIndex],
      }));
    };

    const maxCostCodeRowSpan = data.Title.reduce(
      (acc, child) =>
        acc + (expandedRows[child.Title] ? child.Material.length : 1),
      0,
    );

    const rows = data.Title.map((title, titleIndex) => {
      const isExpanded = expandedRows[title.Title];
      const maxMaterialRowSpan = title.Material.length;
      return (
        <React.Fragment key={titleIndex}>
          <TableRow>
            {titleIndex === 0 && (
              <>
                <TableCell
                  rowSpan={maxCostCodeRowSpan}
                  style={{ fontWeight: 'bold', verticalAlign: 'top' }}
                >
                  {data.CostCode}
                </TableCell>
              </>
            )}
            <TableCell
              rowSpan={isExpanded ? maxMaterialRowSpan : 1}
              style={{
                borderLeft: '1px solid rgba(1,1,1,0.1)',
                verticalAlign: 'top',
              }}
            >
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ flexWrap: 'nowrap' }}
              >
                <p
                  style={{
                    margin: '0px 10px',
                    fontWeight: 'bold',
                    textWrap: 'nowrap',
                  }}
                >
                  {title.Title}
                </p>
                <IconButton
                  onClick={() => handleExpandClick(title.Title)}
                  style={{ padding: 0 }}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell
              rowSpan={isExpanded ? maxMaterialRowSpan : 1}
              style={{ fontWeight: 'bold', verticalAlign: 'top' }}
            >
              <Grid container style={{ flexWrap: 'nowrap' }}>
                <p>{title.description}</p>
                <IconButton
                  onClick={() => handleExpandClick(title.Title)}
                  style={{ padding: 0 }}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}>
              {title.Material[0].material}
            </TableCell>
            <TableCell>{title.Material[0].UOM}</TableCell>
            <TableCell align="center">{title.Material[0].quantity}</TableCell>
            <TableCell align="center">
              <SummaryPercentageBar
                completionPercent={title.Material[0].utilizedQuantity}
                emptyColor="linear-gradient(to right, #4426a8ff, #c0429eff)"
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          {isExpanded &&
            title.Material.slice(1).map((material, materialIndex) => (
              <TableRow key={materialIndex}>
                <TableCell style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}>
                  {material.material}
                </TableCell>
                <TableCell>{material.UOM}</TableCell>
                <TableCell align="center">{material.quantity}</TableCell>
                <TableCell align="center">
                  <Grid>
                    <SummaryPercentageBar
                      completionPercent={material.utilizedQuantity}
                      emptyColor="linear-gradient(to right, #4426a8ff, #c0429eff)"
                    />
                  </Grid>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      );
    });
    return rows;
  };
  return renderRow(rowData, rowIndex);
};

export default TraceabilityRow;
