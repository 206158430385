import React, { useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "src/components/Logo";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";
import UserContext from "src/context/UserContext";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const navigation = useNavigate();
  const [userData, setUserData] = useContext(UserContext);

  const handleLogout = () => {
    localStorage.removeItem("userDetails");
    setUserData(null);
    navigation("/auth/login", { replace: true });
    window.location.reload();
  };
  return (
    <AppBar
      elevation={0}
      {...rest}
      style={{ background: "#F3F6F8", zIndex: "1" }}
    >
      <Toolbar>
        <RouterLink to="/app/dashboard">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />

        <Button onClick={handleLogout}>
          <ExitToAppIcon style={{ color: "black" }} />
          Logout
        </Button>
        <IconButton color="black" component={RouterLink} to="profile">
          <PersonIcon style={{ color: "black" }} />
        </IconButton>
        {/* <IconButton color='black' onClick={onMobileNavOpen}>
					<SettingsIcon style={{ color: 'black' }} />
				</IconButton>
				<IconButton color='black' onClick={onMobileNavOpen}>
					<NotificationsIcon style={{ color: 'black' }} />
				</IconButton> */}
        <Hidden lgUp>
          <IconButton color="black" onClick={onMobileNavOpen}>
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
