//! DEPENDENCIES
import React, { useState } from 'react';
import {
  Chip,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ProjectImage from 'src/assests/ProjectImage.jpg';
import Geocode from 'react-geocode';
import { useEffect } from 'react';
import { inputStyles } from 'src/views/Project/theme.project';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ProjectDetailsInput = ({ selectedProject, scopeOfWorks }) => {
  const [location, setLocation] = useState('Fetching Location');
  const classes = inputStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpand, setSowExpand] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState([]);

  //! LIFE-CYCLE
  useEffect(() => {
    Geocode.fromLatLng(
      selectedProject?.latitude,
      selectedProject?.longitude
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setLocation(address);
      },
      (error) => {
        // console.error('error', error.message);
        setLocation(error.message);
      }
    );
  }, []);

  useEffect(() => {
    if (scopeOfWorks) {
      setSowExpand(Object.keys(scopeOfWorks).length > 4 || isSmallScreen);
    }
  }, [scopeOfWorks, isSmallScreen]);

  const renderscopeOfWorksField = () => {
    const contentWidthXs = isExpanded ? 12 : 9;
    const contentWidthSm = isExpanded ? 12 : 10;
    const upperCap = isExpanded ? Object.keys(scopeOfWorks || {}).length : 4;
    const wrapContent = isExpanded ? 'wrap' : 'nowrap';
    return (
      <Paper
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 20,
          flexWrap: 'wrap',
          padding: '0',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          borderRadius: 10,
          width: '100%',
          minHeight: 60,
          height: isExpanded ? 'auto' : 60,
        }}
      >
        <Grid
          container
          alignItems="center"
          item
          xs={contentWidthXs}
          sm={contentWidthSm}
          style={{
            display: 'flex',
            flexWrap: wrapContent,
            overflow: 'hidden',
            height: 'inherit',
          }}
        >
          {scopeOfWorks ? (
            Object.keys(scopeOfWorks)
              .slice(0, upperCap)
              .map((chip, index) => {
                const hasData = scopeOfWorks[chip].measurement;
                if (hasData) {
                  const { measurement } = scopeOfWorks[chip];
                  return (
                    <Chip
                      key={index}
                      label={
                        <Typography component="div">
                          <span style={{ color: 'rgba(1,1,1,0.5)' }}>
                            {chip}:{' '}
                          </span>
                          <strong>{measurement}</strong>
                        </Typography>
                      }
                      style={{ margin: 4, borderRadius: 5 }}
                      clickable={false}
                    />
                  );
                }
                return null;
              })
          ) : (
            <Typography style={{ padding: 10, color: 'rgba(1,1,1,0.5)' }}>
              Cannot fetch scope of works for this project
            </Typography>
          )}
        </Grid>
        {showExpand && !isExpanded && (
          <div style={{ width: '95px' }}>
            <Chip
              label={'show more'}
              onClick={() => setIsExpanded((prev) => !prev)}
            />
          </div>
        )}
      </Paper>
    );
  };
  const handleClose = (index) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = null;
    setAnchorEl(newAnchorEls);
  };
  const handleClick = (event, index) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEl(newAnchorEls);
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justify="center"
        style={{ margin: ' 0 .8rem' }}
      >
        <Grid
          container
          item
          md={4}
          xs={12}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Grid item xs={12} justify="center">
            <img
              src={
                selectedProject?.url === ''
                  ? ProjectImage
                  : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${selectedProject?.url}`
              }
              alt="project"
              style={{
                maxWidth: '340px',
                // height: '250px',
                borderRadius: '11px',
              }}
            />
            <Grid
              item
              md={10}
              sm={11}
              container
              justify="space-evenly"
              style={{ marginTop: 5 }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: 10,
                    color: '#ADBAC3',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                  }}
                >
                  Present Workers:
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                  }}
                >
                  {selectedProject?.presentWorkers ?? 'N/A'}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontSize: 10,
                    color: '#ADBAC3',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                  }}
                >
                  Percent Completion:
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                  }}
                >
                  {selectedProject?.percentCompletion} %
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontSize: 10,
                    color: '#ADBAC3',

                    fontFamily: 'Roboto',

                    textAlign: 'center',
                  }}
                >
                  Committed cost / sqft:
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    textAlign: 'center',

                    fontFamily: 'Roboto',
                  }}
                >
                  ₹ {selectedProject?.committedCostPerSqft}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={12} md={8}>
          <Grid item xs={12} className={classes.fieldBottomPadding}>
            <TextField
              size="small"
              placeholder="Enter your project name"
              label="PROJECT NAME"
              fullWidth
              variant="filled"
              value={selectedProject?.name}
              InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.input,
                },
              }}
              inputProps={{ style: { fontFamily: 'Roboto' } }}
              disabled
            />
          </Grid>
          <Grid
            item
            container
            className={classes.fieldBottomPadding}
            xs={12}
            justify="space-between"
          >
            <Grid item md={6} className={classes.fieldRightPadding}>
              <TextField
                size="small"
                variant="filled"
                label="PROJECT TYPE"
                fullWidth
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                // style={{ width: "350px" }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.input,
                  },
                  style: { fontFamily: '1rem' },
                }}
                value={
                  selectedProject?.projectTypeId === 0
                    ? 'N/A'
                    : selectedProject?.projectTypeId
                }
                inputProps={{ style: { fontFamily: 'Roboto' } }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                placeholder="Enter your project name"
                label="REMAINING WORK DAYS"
                fullWidth
                variant="filled"
                value={selectedProject?.remainingDays}
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.input,
                  },
                  style: { fontFamily: 'Roboto' },
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid container item md={6} xs={12}>
              <Grid item xs={12} className={classes.fieldRightPadding}>
                <TextField
                  size="small"
                  variant="filled"
                  label="SUPERVISOR"
                  InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.input,
                    },
                    style: { fontFamily: 'Roboto' },
                  }}
                  value={selectedProject?.supervisorName}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container item md={6} xs={12} justify="center">
              <TextField
                size="small"
                placeholder="Enter your project name"
                label="TOTAL BUILT UP AREA IN SQFT"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                value={selectedProject?.projectArea}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.input,
                  },
                  style: { fontFamily: 'Roboto' },
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={classes.fieldBottomPadding}
            justify="center"
          >
            <TextField
              size="small"
              placeholder="Enter your project name"
              label="LOCATION"
              fullWidth
              variant="filled"
              // style={{ width: '715px' }}
              InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
              value={location}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.input,
                },
                style: { fontFamily: 'Roboto' },
              }}
              disabled
            />
          </Grid>

          {/* <Grid
            container
            item
            xs={12}
            style={{ marginBottom: 10 }}
            justify="center"
          >
            {renderscopeOfWorksField()}
          </Grid> */}
        </Grid>
        <Grid
          container
          item
          style={{
            backgroundColor: '#fff',
            borderRadius: '11px',
            padding: '10px',
            marginTop: '1rem',
          }}
        >
          <Grid item container>
            <Typography
              variant="h6"
              style={{ fontSize: '20px', marginBottom: '1rem' }}
            >
              Project Budget
            </Typography>
          </Grid>

          <Grid item container>
            <TableContainer
              className={classes.container}
              style={{ height: '250px' }}
            >
              <Table>
                <TableHead style={{ backgroundColor: 'rgb(240,243,245)' }}>
                  <TableRow>
                    <TableCell align="left" className={classes.headerCellText1}>
                      Cost Code
                    </TableCell>
                    <TableCell
                      align="left"
                      width="50%"
                      className={classes.headerCellText1}
                      style={{ padding: 0 }}
                    >
                      SCOPE OF WORK
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      COST PER Sqft
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      AMOUNT
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProject?.scopeOfWorks?.map((val, index) => (
                    <TableRow hover key={index + 1}>
                      <TableCell
                        align="left"
                        className={classes.headerCellText2}
                      >
                        {val.scopeOfWorkId ?? 'N/A'}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText2}
                      >
                        {val.scopeOfWorkName ?? 'N/A'}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.headerCellText2}
                      >
                        ₹ {val.costPerSqFeet ?? 'N/A'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.headerCellText2}
                      >
                        ₹{' '}
                        {val.costPerSqFeet * selectedProject?.projectArea ??
                          'N/A'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.headerCellText2}
                      >
                        <div>
                          <MoreVertIcon
                            onClick={(event) => handleClick(event, index)}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            style={{
                              color: 'rgb(152,111,239)',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter className={classes.stickyFooter}>
                  <TableRow hover>
                    <TableCell
                      align="left"
                      className={classes.headerCellText2}
                    ></TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText2}
                      style={{ fontWeight: 600, fontSize: '18px' }}
                    >
                      Total
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.headerCellText2}
                      style={{ fontWeight: 600, fontSize: '18px' }}
                    >
                      ₹{' '}
                      {selectedProject?.scopeOfWorks
                        ?.reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue.costPerSqFeet,
                          0
                        )
                        .toLocaleString()}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText2}
                      style={{ fontWeight: 600, fontSize: '18px' }}
                    >
                      ₹{' '}
                      {selectedProject?.scopeOfWorks
                        ?.reduce(
                          (accumulator, currentValue) =>
                            accumulator +
                            currentValue.costPerSqFeet *
                              selectedProject?.projectArea,
                          0
                        )
                        .toLocaleString()}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText2}
                    ></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectDetailsInput;

{
  /* <Grid container style={{ marginTop: 15, marginBottom: 15 }}>
				<Grid item xs={12} style={{ paddingLeft: '1rem' }}>
					<Typography variant='h5' style={{ fontFamily: 'Roboto' }}>
						Roles
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={12}
					justify='space-between'
					style={{ marginTop: 15, padding: '0 .7rem' }}>
					<Grid
						item
						xs={12}
						md={4}
						style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
						<TextField
							variant='filled'
							label='PROJECT MANAGER'
							fullWidth
							InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
							// style={{ width: "350px" }}
							InputProps={{
								disableUnderline: true,
								classes: {
									root: classes.input,
								},
								style: { fontFamily: '1rem' },
							}}
							value={selectedProject?.projectManagerName}
							inputProps={{ style: { fontFamily: 'Roboto' } }}
							disabled
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
						<TextField
							variant='filled'
							label='SITE ADMIN'
							fullWidth
							InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
							// style={{ width: "350px" }}
							InputProps={{
								disableUnderline: true,
								classes: {
									root: classes.input,
								},
								style: { fontFamily: '1rem' },
							}}
							value={selectedProject?.siteAdminName}
							inputProps={{ style: { fontFamily: 'Roboto' } }}
							disabled
						/>
					</Grid>
					<Grid item xs={12} md={4} style={{ marginBottom: '1rem' }}>
						<TextField
							variant='filled'
							label='SUPERVISOR'
							fullWidth
							InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
							// style={{ width: "350px" }}
							InputProps={{
								disableUnderline: true,
								classes: {
									root: classes.input,
								},
								style: { fontFamily: '1rem' },
							}}
							value={selectedProject?.supervisorName}
							inputProps={{ style: { fontFamily: 'Roboto' } }}
							disabled
						/>
					</Grid>
					<Grid item xs={12} style={{ marginBottom: '1rem' }}>
						<TextField
							variant='filled'
							label='PAYROLL OFFICER'
							fullWidth
							InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
							// style={{ width: "350px" }}
							InputProps={{
								disableUnderline: true,
								classes: {
									root: classes.input,
								},
								style: { fontFamily: '1rem' },
							}}
							value={selectedProject?.payrollOfficerName ?? 'N/A'}
							inputProps={{ style: { fontFamily: 'Roboto' } }}
							disabled
						/>
					</Grid>
				</Grid>
			</Grid> */
}
