import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

const SearchInput = ({ search, handleSearch, ...rest }) => {
  return (
    <>
      <input
        {...rest}
        id="bootstrap-input"
        type="search"
        placeholder="Type here ..."
        value={search}
        onChange={handleSearch}
        className="input"
      />
      {!search && (
        <SearchIcon
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        />
      )}
    </>
  );
};

export default SearchInput;
