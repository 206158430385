import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
  withStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import PersonIcon from '@material-ui/icons/Person';
import UserContext from 'src/context/UserContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  // root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const navigation = useNavigate();
  const [userData, setUserData] = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userDetails');
    setUserData(null);
    navigation('/auth/login', { replace: true });
    window.location.reload();
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      // elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: 'RGB( 129, 183, 52 )',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  // console.log( "name", userData?.user?.fullName )
  return (
    <AppBar
      elevation={0}
      {...rest}
      style={{ background: '#F3F6F8', zIndex: '1' }}
    >
      <Toolbar>
        <RouterLink to="/app/dashboard">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <div>
          <Typography
            // aria-controls="customized-menu"
            // aria-haspopup="true"
            // variant="contained"
            // color="primary"
            align="center"
            style={{
              color: '#333',
              cursor: 'pointer',
              fontSize: '12px',
              display: 'flex',
              justify: 'center',
              alignItems: 'center',
            }}
            onClick={handleClick}
          >
            {userData?.user?.fullName}
            <ArrowDropDownIcon style={{ color: '#333' }} />
          </Typography>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              selected={1 === selectedIndex}
              onClick={() => {
                handleClose();
                setSelectedIndex(1);
              }}
              color="black"
              component={RouterLink}
              to="profile"
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handleClose();
                setSelectedIndex(2);
              }}
              selected={2 === selectedIndex}
              component={RouterLink}
              to="subscription"
            >
              <ListItemIcon>
                <ReceiptIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Subscription" />
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem
              onClick={() => {
                handleLogout();
                handleClose();
              }}
            >
              <ListItemIcon>
                {/* <SendIcon fontSize="small" /> */}
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </StyledMenu>
        </div>

        {/* <Button onClick={ handleLogout }>
					<ExitToAppIcon style={ { color: 'black' } } />
					Logout
				</Button>
				<IconButton color='black' component={ RouterLink } to='profile'>
					<PersonIcon style={ { color: 'black' } } />
				</IconButton> */}

        {/* <IconButton color='black' onClick={onMobileNavOpen}>
					<SettingsIcon style={{ color: 'black' }} />
				</IconButton>
				<IconButton color='black' onClick={onMobileNavOpen}>
					<NotificationsIcon style={{ color: 'black' }} />
				</IconButton> */}
        <Hidden lgUp>
          <IconButton color="black" onClick={onMobileNavOpen}>
            <MenuIcon style={{ color: 'black' }} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

// import { withStyles } from '@material-ui/core/styles';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';

// const StyledMenu = withStyles( {
// 	paper: {
// 		border: '1px solid #d3d4d5',
// 	},
// } )( ( props ) => (
// 	<Menu
// 		elevation={ 0 }
// 		getContentAnchorEl={ null }
// 		anchorOrigin={ {
// 			vertical: 'bottom',
// 			horizontal: 'center',
// 		} }
// 		transformOrigin={ {
// 			vertical: 'top',
// 			horizontal: 'center',
// 		} }
// 		{ ...props }
// 	/>
// ) );

// const StyledMenuItem = withStyles( ( theme ) => ( {
// 	root: {
// 		'&:focus': {
// 			backgroundColor: theme.palette.primary.main,
// 			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
// 				color: theme.palette.common.white,
// 			},
// 		},
// 	},
// } ) )( MenuItem );

// export default function CustomizedMenus () {
// 	const [ anchorEl, setAnchorEl ] = React.useState( null );

// 	const handleClick = ( event ) => {
// 		setAnchorEl( event.currentTarget );
// 	};

// 	const handleClose = () => {
// 		setAnchorEl( null );
// 	};

// 	return (
// 		<div>
// 			<Button
// 				aria-controls="customized-menu"
// 				aria-haspopup="true"
// 				variant="contained"
// 				color="primary"
// 				onClick={ handleClick }
// 			>
// 				Open Menu
// 			</Button>
// 			<StyledMenu
// 				id="customized-menu"
// 				anchorEl={ anchorEl }
// 				keepMounted
// 				open={ Boolean( anchorEl ) }
// 				onClose={ handleClose }
// 			>
// 				<StyledMenuItem>
// 					<ListItemIcon>
// 						<SendIcon fontSize="small" />
// 					</ListItemIcon>
// 					<ListItemText primary="Sent mail" />
// 				</StyledMenuItem>
// 				<StyledMenuItem>
// 					<ListItemIcon>
// 						<DraftsIcon fontSize="small" />
// 					</ListItemIcon>
// 					<ListItemText primary="Drafts" />
// 				</StyledMenuItem>
// 				<StyledMenuItem>
// 					<ListItemIcon>
// 						<InboxIcon fontSize="small" />
// 					</ListItemIcon>
// 					<ListItemText primary="Inbox" />
// 				</StyledMenuItem>
// 			</StyledMenu>
// 		</div>
// 	);
// }
