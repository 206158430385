import config from 'src/services/config';
import fetchAPIData from './fetchUtil';
import axios from 'axios';
/**
 * Fetches users tokens
 *
 */
const getToken = async (user) => {
  let userCred = null;
  // Encoding query variables to allow special characters.
  const username = encodeURIComponent(user?.username);
  const password = encodeURIComponent(user?.password);

  let url = `${config.baseUrl}/dashboard/User/Login?username=${username}&password=${password}`;
  try {
    let response = await axios.post(url);
    if (response.status === 404) {
      return new Error('Incorrect username or password');
    }
    userCred = response;
  } catch (error) {
    if (error.status === 400) {
      throw new Error('Incorrect username or password');
    }
    throw new Error('Unable to login, Please try again later');
  }
  return userCred;
};
export default getToken;
