//! DEPENDICES
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useDispatch, useSelector } from 'react-redux';
import { projectDetailStyles } from './theme.project';
import { Link } from 'react-router-dom';

//! COMPONENTS
import ProjectDetailsInput from 'src/components/ProjectDetailsInput';
//! REDUX
import { projectReducer, selectProject } from 'src/redux/projects';
import {
  getScopeList,
  getUnitList,
  productivityReducer,
} from 'src/redux/productivity';
import useAccessPerView from 'src/common/Access';

const ProjectDetails = () => {
  //! INSTANCES
  const classes = projectDetailStyles();
  let dispatch = useDispatch();

  const { isEditMode, hasAccess } = useAccessPerView('PROJECT_INFORMATION');

  //! SELECTORS
  const { selectedProject } = useSelector(projectReducer);
  const { unitList, scopeList } = useSelector(productivityReducer);

  const [sow, setSow] = useState(null);

  useEffect(() => {
    if (unitList.length === 0) dispatch(getUnitList());
    if (scopeList.length === 0) dispatch(getScopeList());
  });

  useEffect(() => {
    if (selectedProject && scopeList.length > 0 && unitList.length > 0) {
      const sowObject = convertScopeList(scopeList);
      setSow(sowObject);
    }
  }, [selectedProject, scopeList, unitList]);

  //! METHODS
  const goBack = () => {
    dispatch(selectProject(null));
  };

  function convertScopeList() {
    const scopeObject = {};
    scopeList.forEach((scope) => {
      const retrievedScope = selectedProject?.scopeOfWorks?.find(
        (projectScope) => projectScope.scopeOfWorkId === scope.scopeOfWorkId
      );

      if (!retrievedScope) return;

      scopeObject[scope.name] = createScopeObject({
        scope,
        retrievedScope,
      });
    });

    return scopeObject;
  }

  function createScopeObject({ scope, retrievedScope }) {
    const measurement = retrievedScope?.costPerSqFeet || '';
    const touched = Boolean(measurement);

    return {
      measurement,
      touched,
      icon: scope.imageUrl,
      iconKey: scope.imageKey,
      scopeOfWorkId: scope.scopeOfWorkId,
    };
  }

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: '.8rem' }}
      >
        <Grid item xs={8} container alignItems="center">
          <Link to=".." relative="path">
            <IconButton onClick={goBack}>
              <ArrowBackIcon color="black" fontSize="default" />
            </IconButton>
          </Link>
          <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
            {selectedProject?.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ marginRight: '.98rem' }}
          container
          justify="flex-end"
        >
          {(isEditMode || hasAccess.LEVEL1) && (
            <Link to="../edit">
              <Button
                startIcon={<BorderColorIcon />}
                className={classes.buttonPurpleOpacity}
                // fullWidth
                // onClick={() =>
                // 	setModals({ ...modals, createProject: true, editMode: true })
                // }
              >
                Edit
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>

      <ProjectDetailsInput
        selectedProject={selectedProject}
        scopeOfWorks={sow}
      />
    </Grid>
  );
};

export default ProjectDetails;
