import React from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import dummyImage from "../assests/Honeycomb-in-concrete-1.webp";
import HourglassFullRoundedIcon from "@material-ui/icons/HourglassFullRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getFieldNoteList, markFieldNoteAction } from "src/redux/fieldNote";
import toast, { Toaster } from "react-hot-toast";
import TimerIcon from "@material-ui/icons/Timer";
import RestoreIcon from "@material-ui/icons/Restore";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  buttonPurple: {
    color: theme.palette.common.white,
    textTransform: "capitalize",
    borderRadius: "10px",
    padding: "5px 14px",
    backgroundColor: "#A179F2",
    "&:hover": {
      backgroundColor: "#A179F2",
    },
  },
}));
const FieldNoteCardComponent = ({ fieldNoteList, item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const emptyItems = [null, null];
  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const markFieldNote = async (id, value) => {
    let resp = await dispatch(markFieldNoteAction(id, value));
    if (resp?.status === 200) {
      dispatch(getFieldNoteList());
      toast.success("Field Note Mark Successfully!", {
        position: "top-right",
      });
      handleClose();
    } else {
      toast.error("Field Note Marked unsuccessful!", {
        position: "top-right",
      });
    }
  };
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     // height: "100%",
    //   }}
    // >
    <Grid
      item
      container
      style={{
        // width: "100%",
        borderRadius: "10px",
        // boxShadow: "5px 5px  10px rgba(0, 0, 0, 0.2)",
        boxShadow: "0px 20px 30px 0px #00000033",

        width: "500px",
        height: "600px",
        backgroundColor: "#f9f9f9",
        margin: fieldNoteList?.length > 3 ? "0" : "0 10px",
        // border: "1px solid red",
      }}
      // xs={12}
    >
      <Grid
        item
        style={{
          borderRadius: "10px 10px 0 0 ",
          position: "relative",
          width: "100%",
          // border: "3px solid red",
          height: "400px",
        }}
      >
        {item?.imageUrl ? (
          <img
            src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item?.imageUrl}`}
            alt="Field Note"
            className="field-note-img"
          />
        ) : (
          <div
            style={{
              maxWidth: "100%", // Adjust the max width if needed
              // minWidth: "550px", // Use the common width
              height: "400px",
            }}
          >
            No Image URL
          </div>
        )}
        <Grid
          container
          justify="space-between"
          style={{
            width: "130px",
            backgroundColor: "rgba(99,99,99, 0.5)",
            borderRadius: "10px",
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#fff",
          }}
        >
          <Grid
            style={{
              width: "67%",
              textAlign: "right",
              padding: "5px 0",
            }}
            container
          >
            <p
              align="right"
              style={{
                fontSize: "10px",
                fontWeight: 300,
                width: "100%",
              }}
              // variant="subtitle1"
            >
              STATUS
            </p>
            <p
              align="right"
              style={{
                fontSize: "12px",
                width: "100%",
              }}
            >
              {item?.action === "" || !item?.action
                ? "N/A"
                : item.action === "MarkAsResolved"
                ? "Resolved"
                : item.action === "ReworkRequired"
                ? "Rework"
                : item.action === "DecisionPending" && "Pending"}
            </p>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#f5f1fdff",
              // height: "42px",
              // width: "120px",
              position: "relative",
              // border: "1px solid red",
              borderRadius: "10px",
              width: "30%",
            }}
          >
            {item.action === "MarkAsResolved" ? (
              <CheckCircleOutlinedIcon
                style={{
                  color: "#986FEF",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  fontSize: "26px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : item.action === "ReworkRequired" ? (
              <RestoreIcon
                style={{
                  color: "#986FEF",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  fontSize: "26px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : item.action === "DecisionPending" ? (
              <TimerIcon
                style={{
                  color: "#986FEF",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  fontSize: "26px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : (
              <ErrorOutlineOutlinedIcon
                style={{
                  color: "red",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  fontSize: "26px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{
            padding: "0 1rem",
            position: "absolute",
            bottom: "20px",
            zIndex: 100,
          }}
        >
          <Grid
            item
            container
            xs={8}
            style={{
              color: "#e0e1e1ff",
              marginTop: "1rem",
              backgroundColor: "rgba(99,99,99, 0.3)",
              borderRadius: "10px",
              padding: "5px 0px 5px 5px ",
            }}
            alignItems="center"
            // justify="flex-start"
          >
            <Grid item style={{ marginRight: "5px" }}>
              <RoomOutlinedIcon style={{ fontSize: "30px" }} />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                align="left"
                style={{ fontSize: "10px", textShadow: "2px 2px 4px #000000" }}
              >
                LOCATION
              </Typography>{" "}
              <Typography
                style={{
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                {item?.location ?? "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {/* <Button
                // size="small"
                className={classes.buttonPurple}
                endIcon={<KeyboardArrowDownRoundedIcon />}
              >
                Action
              </Button> */}
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.buttonPurple}
                endIcon={<KeyboardArrowDownRoundedIcon />}
              >
                Action
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() =>
                    markFieldNote(item.fieldNoteId, "MarkAsResolved")
                  }
                  // style={{ width: "210px" }}
                >
                  <Grid container alignItems="center">
                    <CheckCircleOutlinedIcon />
                    <span style={{ marginLeft: "18px" }}> Resolved</span>
                  </Grid>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    markFieldNote(item.fieldNoteId, "ReworkRequired")
                  }
                  // style={{ width: "210px" }}
                >
                  <Grid container alignItems="center">
                    <RestoreIcon />

                    <span style={{ marginLeft: "18px" }}> Required</span>
                  </Grid>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    markFieldNote(item.fieldNoteId, "DecisionPending")
                  }
                  // style={{ width: "120px" }}
                >
                  <Grid container alignItems="center">
                    <TimerIcon />

                    <span style={{ marginLeft: "18px" }}> Pending</span>
                  </Grid>
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item style={{ padding: "1rem" }}>
        <Grid item>
          <Typography
            variant="subtitle1"
            align="left"
            style={{
              fontSize: "10px",
              textTransform: "uppercase",
            }}
          >
            {/* 3:45PM Monday | 13 Dec, 2023 */}
            {item?.dateTime
              ? moment(item?.dateTime).format("h:mma dddd")
              : "N/A"}{" "}
            |{" "}
            {item?.dateTime
              ? moment(item?.dateTime).format("D MMM, YYYY")
              : "N/A"}{" "}
          </Typography>
          <Typography
            align="left"
            variant="subtitle1"
            style={{ fontSize: "15px", fontWeight: 400 }}
          >
            {item?.description && item?.description?.split(" ")?.length > 15
              ? item?.description?.split(" ")?.slice(0, 15).join(" ") + "..."
              : item?.description ?? "N/A"}
            {/* {item?.description ?? "N/A"} */}
          </Typography>
        </Grid>

        <Grid
          container
          justify="space-between"
          item
          xs={12}
          style={{ marginTop: "1rem" }}
        >
          <Grid item xs={6}>
            <Typography
              align="left"
              variant="subtitle1"
              style={{
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#7E8386B2",
              }}
            >
              Project
            </Typography>
            <Typography
              align="left"
              variant="subtitle1"
              style={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              {item?.project?.name ?? "N/A"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              // padding: "10px 0",
              height: "45px",
            }}
          >
            <Divider
              orientation="vertical"
              style={{
                color: "#7E8386B2",
              }}
            />{" "}
          </Grid>
          <Grid item xs={5}>
            <Typography
              align="left"
              variant="subtitle1"
              style={{
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#7E8386B2",
              }}
            >
              Assigned to
            </Typography>
            <Typography
              align="left"
              variant="subtitle1"
              style={{
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              {item?.contractor?.fullName ?? "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Toaster /> */}
    </Grid>
    // </div>
  );
};

export default FieldNoteCardComponent;
