import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import TitleRow from './TitleRow';
import { useDispatch, useSelector } from 'react-redux';
import {
  gettingBoqTitlesBySOW,
  productivityReducer,
} from 'src/redux/productivity';

const WorkOrderRow = (props) => {
  const { workOrder, onSave, classes, grossProgress, sowID } = props;
  const dispatch = useDispatch();

  const { BOQTitles } = useSelector(productivityReducer);

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [titles, setTitles] = useState([]);

  const observerRef = useRef(null);

  const handleWorkOrderExpansion = (boqId, sowID, pageNumber, pageSize) => {
    dispatch(gettingBoqTitlesBySOW(boqId, sowID, pageNumber, pageSize));
    setLoading(true);
  };

  // TODO: The Dynamic loading is still in progress
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log(
            `reached the end. total content: ${titles?.Titles?.length}`
          );
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (BOQTitles.BOQId === workOrder.boqId) {
      setTitles(BOQTitles);
    }
  }, [BOQTitles]);

  const handleToggle = (boqId, sowID) => {
    if (!expanded) {
      handleWorkOrderExpansion(boqId, sowID);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    setExpanded(expanded ? false : boqId);
  };

  const renderTitles =
    expanded === workOrder.boqId &&
    titles?.Titles?.map((titleData, index) => {
      const isLastElement = index === titles?.Titles?.length - 1;
      return (
        <TitleRow
          ref={isLastElement ? observerRef : null}
          key={titleData.id}
          index={index}
          onSave={onSave}
          title={titleData}
          classes={classes}
          grossProgress={grossProgress}
        />
      );
    });

  const renderWorkOrderRow = () => {
    return (
      <Grid item>
        <Typography>{`WORKORDER # ${workOrder?.workOrder}`}</Typography>
      </Grid>
    );
  };
  // console.log(sowID, 'sowID');
  return (
    <Accordion
      style={{ width: '100%', border: 'none', boxShadow: 'none' }}
      onChange={() => handleToggle(workOrder.boqId, sowID)}
      expanded={expanded}
    >
      <AccordionSummary
        style={{
          padding: 0,
          margin: 0,
          borderTop: '1px solid rgba(1,1,1,0.2)',
        }}
      >
        {renderWorkOrderRow()}
      </AccordionSummary>
      <AccordionDetails
        style={{
          width: '100%',
          display: 'inline-block',
          padding: 0,
          margin: 0,
        }}
      >
        {loading ? (
          <Typography variant="subtitle1" align="center">
            Loading...
          </Typography>
        ) : (
          renderTitles
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default WorkOrderRow;
