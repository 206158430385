import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import React from 'react';
import arrow from '../../../assests/arrow.png';
import vinodShuklaIMG from '../../../assests/Vinod Shukla Builders IMG.png';

import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import SelectInput from 'src/views/Billing/components/SelectInput';
import Label from 'src/views/Billing/components/Label';
import TextInput from 'src/views/Billing/components/TextInput';

const GRNIssueModal = (props) => {
  const { classes, open, handleClose } = props;

  const renderVendorDetails = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Label
          title="Vendor Details"
          subTitle="Vendor Details & Contact Information"
        />
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          <Grid item sm={3} xs={12}>
            <img src={vinodShuklaIMG} alt="vendor profile" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            alignItems="center"
            style={{ marginLeft: -30 }}
          >
            <Label
              title="Vendor/Supplier Name"
              subTitle="Vinod Shukla Builders & Meterial Suppliers pvt ltd"
              emphasis="subtitle"
            />
            <Label
              title="Contact Number"
              subTitle="+91 1234 5678 91"
              emphasis="subtitle"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMaterialDetails = () => {
    const boqOptions = [{ label: 'Cement', value: 1 }];
    const uowOptions = [{ label: 'KG', value: 1 }];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container justify="space-between">
          <Label
            title="Material Details"
            subTitle="Select Material & Quanity"
          />
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={7} alignItems="center" style={{ marginTop: '10px' }}>
            <SelectInput
              label="Select Material"
              variant="filled"
              options={boqOptions}
            />
          </Grid>
          <Grid item xs={2} alignItems="center" style={{ marginTop: '10px' }}>
            <SelectInput label="UOW" variant="filled" options={uowOptions} />
          </Grid>
          <Grid item xs={2} alignItems="center" style={{ marginTop: '10px' }}>
            <TextInput
              label="Quanity"
              variant="filled"
              handleChange={() => {}}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderRatesAndAmounts = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label title="Rates & Amount" subTitle="Rates & Amount of Material" />
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Label title="Rate" subTitle="₹ 375" emphasis="subtitle" />
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Label
            title="Total Amount"
            subTitle="₹ 1,305,060"
            emphasis="subtitle"
          />
        </Grid>
      </Grid>
    );
  };

  const renderRejectedQuantityAndRemarks = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label
            title="Rejected Quantity & Remarks"
            subTitle="Add Rejected Details & Comment"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Rejected Quantity"
              variant="filled"
              handleChange={() => {}}
              endAdornment={<InputAdornment position="end">KG</InputAdornment>}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Comments & Remarks"
              variant="filled"
              handleChange={() => {}}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDireactIssue = () => {
    const contractorOptions = [
      { label: 'Pawn K. Builders & Developers', value: 1 },
    ];
    const woID = [{ label: 'KG', value: 1 }];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12}>
          <Label
            title="Direct Issue"
            subTitle="Add Contractor Details to Direct Issue"
          />
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3} className={classes.imageContainer}>
            <img
              height="23px"
              width="32px"
              src={vinodShuklaIMG}
              alt="placeholder"
              style={{ position: 'absolute', top: 11, left: 9 }}
            />
          </Grid>
          <Grid item xs={9}>
            <SelectInput
              label="Contractor"
              variant="standard"
              options={contractorOptions}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Select Work Order ID"
            variant="filled"
            options={woID}
          />
        </Grid>
        <Grid item xs={12}>
          <Label
            title="Work Permit Details"
            subTitle="Add Work Permit description"
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label="WORK PERMIT DESCRIPTION"
            variant="filled"
            handleChange={() => {}}
          />
        </Grid>
      </Grid>
    );
  };

  const renderStepOne = () => {
    return (
      <Grid
        item
        container
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Divider style={{ width: '100%' }} />
        {renderVendorDetails()}
        <Divider style={{ width: '100%' }} />
        {renderMaterialDetails()}
        <Divider style={{ width: '100%' }} />
        {renderRatesAndAmounts()}
        <Divider style={{ width: '100%' }} />
        {renderRejectedQuantityAndRemarks()}
        <Divider style={{ width: '100%' }} />
        {renderDireactIssue()}
        <Divider style={{ width: '100%' }} />
      </Grid>
    );
  };

  const directIssueButton = (
    <Button
      className={classes.buttonDotted}
      startIcon={
        <AddIcon
          style={{
            fill: 'white',
            width: '15px',
            height: '15px',
            borderRadius: '5px',
            background: '#1069E3',
          }}
        />
      }
      onClick={() => {}}
    >
      Direct Issue
    </Button>
  );

  const createButton = (
    <Button
      className={classes.buttonPurple}
      endIcon={<img src={arrow} alt="submit" />}
      onClick={() => {}}
    >
      Create
    </Button>
  );

  const renderActionButton = () => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>{directIssueButton}</Grid>
        <Grid>{createButton}</Grid>
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <DialogTitle>
        <Grid
          container
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography style={{ fontSize: 26 }}>GRN Issue</Typography>
          </Grid>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    return (
      <DialogActions>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ margin: '15px 20px' }}
        >
          {renderActionButton()}
        </Grid>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      className={classes.modal}
      PaperProps={{ style: { width: 700, height: '90%' } }}
    >
      {renderTitle()}
      {renderStepOne()}
      {renderFooter()}
    </Dialog>
  );
};

export default GRNIssueModal;
