import React, { useState } from 'react';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const { TableCell, Grid, IconButton, TableRow } = require('@material-ui/core');

const {
  SummaryPercentageBar,
} = require('src/views/Billing/components/ContractorLedgerSummary');

const MaterialIndentRow = (props) => {
  const { rowData, rowIndex } = props;
  const [expandedRows, setExpandedRows] = useState({});

  const renderRow = (data, index) => {
    const handleExpandClick = (contractorIndex) => {
      setExpandedRows((prev) => ({
        ...prev,
        [contractorIndex]: !prev[contractorIndex],
      }));
    };

    const maxMaterialRowSpan = data.Contractors.reduce(
      (acc, child) =>
        acc +
        (expandedRows[child.Contractor] ? child.WorkOrders.length + 1 : 1),
      0,
    );

    const rows = data.Contractors.map((contractor, contractorIndex) => {
      const isExpanded = expandedRows[contractor.Contractor];
      const maxWorkerIdRowSpan = contractor.WorkOrders.length + 1;

      const totalQuantity = contractor.WorkOrders.reduce(
        (acc, cur) => {
          acc.totalQty += Number(cur.quantity);
          acc.avgPercentage = acc.avgPercentage + cur.issuedQUantity;
          return acc;
        },
        { totalQty: 0, avgPercentage: 0 },
      );

      return (
        <React.Fragment key={contractorIndex}>
          <TableRow>
            {contractorIndex === 0 && (
              <>
                <TableCell
                  rowSpan={maxMaterialRowSpan}
                  style={{ fontWeight: 'bold', verticalAlign: 'top' }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  rowSpan={maxMaterialRowSpan}
                  style={{ fontWeight: 'bold', verticalAlign: 'top' }}
                >
                  {data.Material}
                </TableCell>
              </>
            )}
            <TableCell
              rowSpan={isExpanded ? maxWorkerIdRowSpan : 1}
              style={{
                borderLeft: '1px solid rgba(1,1,1,0.1)',
                verticalAlign: 'top',
              }}
            >
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ flexWrap: 'nowrap' }}
              >
                <img src={contractor.Profile} alt="contractor profile" />
                <p
                  style={{
                    margin: '0px 10px',
                    fontWeight: 'bold',
                    textWrap: 'nowrap',
                  }}
                >
                  {contractor.Contractor}
                </p>
                <IconButton
                  onClick={() => handleExpandClick(contractor.Contractor)}
                  style={{ padding: 0 }}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell
              rowSpan={isExpanded ? maxWorkerIdRowSpan : 1}
              style={{ fontWeight: 'bold', verticalAlign: 'top' }}
            >
              <Grid container style={{ flexWrap: 'nowrap' }}>
                <p>{contractor.WorkOrderId}</p>
                <IconButton
                  onClick={() => handleExpandClick(contractor.Contractor)}
                  style={{ padding: 0 }}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell
              style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}
            ></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {contractor.WorkOrders[0].UOM}
            </TableCell>
            <TableCell align="center">
              <p align="center" style={{ fontWeight: 'bold' }}>
                {totalQuantity.totalQty}
              </p>
              <p align="center">TOTAL QTY</p>
            </TableCell>
            <TableCell>
              <img src={contractor.WorkOrders[0].GRN} alt="GRN" />
            </TableCell>
            <TableCell>
              <SummaryPercentageBar
                completionPercent={
                  totalQuantity.avgPercentage / contractor.WorkOrders.length
                }
                emptyColor="linear-gradient(to right, #4426a8ff, #c0429eff)"
                alignLabel="center"
              />
            </TableCell>
            <TableCell>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          {isExpanded &&
            contractor.WorkOrders.map((workOrder, workOrderIndex) => (
              <TableRow key={workOrderIndex}>
                <TableCell style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}>
                  {workOrder.costCode}
                </TableCell>
                <TableCell>{workOrder.UOM}</TableCell>
                <TableCell align="center">{workOrder.quantity}</TableCell>
                <TableCell>
                  <img src={workOrder.GRN} alt="GRN" />
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    <SummaryPercentageBar
                      completionPercent={workOrder.issuedQUantity}
                      emptyColor="linear-gradient(to right, #4426a8ff, #c0429eff)"
                    />
                  </Grid>
                </TableCell>
                <TableCell>
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      );
    });
    return rows;
  };
  return renderRow(rowData, rowIndex);
};

export default MaterialIndentRow;
