import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#A179F2',
    },
    secondary: {
      main: colors.indigo[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography: {
    fontFamily: 'Roboto',
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: '#fff',
      },
      colorSecondary: {
        '&$checked': {
          color: '#fff',
        },
      },

      track: {
        color: '#fff',
        backgroundColor: '#DBE9F6',
        boxShadow: '0px 4px 4px 0px #00000040',
        '$checked$checked + &': {
          opacity: 0.7,
          background:
            'linear-gradient(251.29deg, #6372EC 14.43%, #A17AF3 100%)',
          color: '#fff',
        },
      },
    },
  },
});

export default theme;
