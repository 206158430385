import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import dummaryImage from '../../assests/IBCEngineers.png';
import logoText from '../../assests/logo-text.png';

import logo from '../../assests/logo.png';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  colorGreen: {
    color: '#81B734',
  },
  heading: {
    fontWeight: '600',
  },
  billToHeading: {
    fontSize: '16px',
    fontWeight: '600',
  },
  columnNames: {
    fontSize: '14px',
    fontWeight: 300,
    // paddingRight: "1.5rem",
  },
  columnNamesRight: {
    fontSize: '14px',
    fontWeight: 500,
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
    overflow: {
      overflowX: 'hidden',
    },
  },
  square: {
    width: '120px',
    height: '120px',
    borderRadius: '20px',
  },
  squareProject: {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
  },
  projectInput: {
    fontSize: '12px !important',
  },
  marginTop: {
    marginTop: '0',
  },
  appBar: {
    position: 'relative',
  },
  pricingCard: {
    cursor: 'pointer',
    borderRadius: '10px',
    boxSizing: 'border-box',

    '&:hover': {
      border: '1px solid #A179F2',
    },
  },
  planButton: {
    backgroundColor: '#908DA6',
    borderRadius: '7px',
    textTransform: 'capitalize',
    color: 'white',
    '&:hover': {
      backgroundColor: '#80b634',
      // color: '#3c52b2',
    },
  },

  marginRight: {
    marginRight: '15px',
  },

  primaryFontSize: {
    fontSize: '14px',
  },
  fontBoldRow: {
    fontWeight: '500',
  },
  tableColumn: {
    fontWeight: 300,
  },
  fontSize12: {
    fontSize: '12px',
    color: 'rgba(46, 63, 68, 1)',
  },
  fontWeight: {
    fontWeight: 400,
  },
  tableColumnSize: {
    fontSize: '9px',
    color: 'rgba(46, 63, 68, 1)',
    fontWeight: 300,
  },
  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
    marginTop: {
      marginTop: '15px',
    },
    marginRight: {
      marginRight: '0',
    },
  },
}));

const BillSummaryModal = ({ open, closeModal, openReceiptModal }) => {
  let classes = useStyles();
  const ModalTitle = (
    <Grid container justify="space-between">
      <Grid item>
        {' '}
        <Typography style={{ fontSize: '26px', fontWeight: 400 }}>
          Bill Summary
        </Typography>
      </Grid>
      <Grid item>
        <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
      </Grid>
    </Grid>
  );
  const BillSummaryDetail = (
    <Grid container xs={10}>
      <Grid item container alignItems="center">
        <Grid item xs={3}>
          <Typography className={classes.columnNames}>GC Name: </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" className={classes.billToHeading}>
            Ashish Parkash Builders & Developers
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="flex-start">
        <Grid item xs={3}>
          <Typography className={classes.columnNames}>GC Address: </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" className={`${classes.primaryFontSize}`}>
            Bengaluru, 560001 Karnataka, India
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={3}>
          <Typography className={classes.columnNames}>GSTIN: </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" className={`${classes.primaryFontSize}`}>
            ---
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={3}>
          <Typography className={classes.columnNames}>
            Project Name:{' '}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" className={`${classes.primaryFontSize}`}>
            Prestige Apartments
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
  const BillSummaryHeader = (
    <Grid container justify="space-between">
      <Grid item>
        <img src={dummaryImage} alt="logo of the company" />
      </Grid>
      <Grid item>
        <Typography
          align="right"
          className={`${classes.fontSize12} ${classes.fontWeight}`}
        >
          Date: February 20, 2024
        </Typography>
        <Typography
          align="right"
          className={`${classes.fontSize12} ${classes.fontWeight}`}
        >
          Bill Period: 3 Feb, 2024 to 5 Mar, 2024
        </Typography>
      </Grid>
    </Grid>
  );
  let data = [
    {
      sNo: 'A',
      title: 'Earth Work',
      rows: [
        {
          sNo: 'A-1',
          description: 'Earth work up to depth - 2 Meters',
          unit: 'Cum',
          rate: '₹ 3098.50',
          previousQty: '0.000',
          currentBillQty: '3098.50',
          commutativeQty: '3098.50',
          previousAmount: '0.000',
          currentAmount: '₹ 3098.50',
          commutative: '₹ 3098.50',
          remark: '--',
        },
      ],
    },
    {
      sNo: 'B',
      title: 'Plan & Cement Concrete',
      rows: [
        {
          sNo: 'B-1',
          description: 'M1.0 / M 7.0 PCC',
          unit: 'Cft',
          rate: '₹ 3098.50',
          previousQty: '0.000',
          currentBillQty: '3098.50',
          commutativeQty: '3098.50',
          previousAmount: '0.000',
          currentAmount: '₹ 3098.50',
          commutative: '₹ 3098.50',
          remark: '--',
        },
        {
          sNo: 'B-2',
          description: 'Up to Plinth Beam',
          unit: 'Cft',
          rate: '₹ 3098.50',
          previousQty: '0.000',
          currentBillQty: '3098.50',
          commutativeQty: '3098.50',
          previousAmount: '0.000',
          currentAmount: '₹ 3098.50',
          commutative: '₹ 3098.50',
          remark: '--',
        },
        {
          sNo: 'B-3',
          description: 'From 0 mtr to 10 mtr',
          unit: 'Cft',
          rate: '₹ 3098.50',
          previousQty: '0.000',
          currentBillQty: '3098.50',
          commutativeQty: '3098.50',
          previousAmount: '0.000',
          currentAmount: '₹ 3098.50',
          commutative: '₹ 3098.50',
          remark: '--',
        },
      ],
    },
    {
      sNo: 'C',
      title: 'Steel Reinforcement',
      rows: [
        {
          sNo: 'C-1',
          description: 'Earth work up to depth - 2 Meters',
          unit: 'Cum',
          rate: '₹ 3098.50',
          previousQty: '0.000',
          currentBillQty: '3098.50',
          commutativeQty: '3098.50',
          previousAmount: '0.000',
          currentAmount: '₹ 3098.50',
          commutative: '₹ 3098.50',
          remark: '--',
        },
      ],
    },
  ];
  const BillSummaryTable = (
    <TableContainer component={Paper} style={{ marginTop: '1.5rem' }}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableColumnSize}>Sr. No.</TableCell>
            <TableCell align="left" className={classes.tableColumnSize}>
              Description
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Unit
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Rate
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Previous Qty
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Current Bill Qty
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Commutative Qty
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Previous Amount
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Current Amount
            </TableCell>
            <TableCell align="right" className={classes.tableColumnSize}>
              Commutative
            </TableCell>
            <TableCell
              align="right"
              className={classes.tableColumnSize}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <>
              <TableRow
                key={row.sNo}
                style={{
                  backgroundColor: 'rgba(235, 237, 240, 1)',
                  height: '10px',
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    color: 'rgba(46, 63, 68, 1)',
                    fontSize: '12px',
                    fontWeight: 400,
                    padding: '0px 24px 0px 16px',
                  }}
                >
                  {row.sNo}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: 'rgba(46, 63, 68, 1)',
                    fontSize: '12px',
                    fontWeight: 400,
                    padding: '0px 24px 0px 16px',
                  }}
                >
                  {row.title}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: 'rgba(46, 63, 68, 1)',
                    fontSize: '12px',
                    fontWeight: 400,
                    padding: '0px 24px 0px 16px',
                  }}
                >
                  <Checkbox style={{ color: 'rgb(107,161,30)' }} />
                </TableCell>
              </TableRow>{' '}
              {row.rows.map((subItem) => (
                <TableRow key={subItem.sNo}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    style={{
                      color: 'rgba(46, 63, 68, 1)',
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                  >
                    {subItem.sNo}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: 'rgba(46, 63, 68, 1)',
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                  >
                    {subItem.description}
                  </TableCell>
                  <TableCell align="center">{subItem.unit}</TableCell>
                  <TableCell align="left"> {subItem.rate}</TableCell>
                  <TableCell align="left"> {subItem.previousQty}</TableCell>
                  <TableCell align="left"> {subItem.currentBillQty}</TableCell>
                  <TableCell align="left"> {subItem.commutativeQty}</TableCell>
                  <TableCell align="left"> {subItem.previousAmount}</TableCell>
                  <TableCell align="left"> {subItem.currentAmount}</TableCell>
                  <TableCell align="left"> {subItem.commutative}</TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: 'rgba(46, 63, 68, 1)',
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                  >
                    {subItem.remark}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
      <Grid container justify="flex-end">
        {' '}
        <Button
          onClick={openReceiptModal}
          style={{
            textTransform: 'capitalize',
            fontSize: '12px',
            marginRight: '10px',
          }}
          size="small"
        >
          Create Bill
        </Button>
      </Grid>
    </TableContainer>
  );
  const CheckStatus = (
    <Grid container style={{ marginTop: '2.5rem' }}>
      <Grid item xs={3}>
        <Grid item>
          <small style={{ fontSize: '7px', color: 'rgba(46, 63, 68, 1)' }}>
            Checked By:
          </small>
          <Typography
            style={{
              color: 'rgba(46, 63, 68, 1)',
              fontSize: '9px',
              fontWeight: 600,
            }}
          >
            QC Name/ Signature
          </Typography>
          <TextField value="ibc - Engineers" />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid item>
          <small style={{ fontSize: '7px', color: 'rgba(46, 63, 68, 1)' }}>
            Checked By:
          </small>
          <Typography
            style={{
              color: 'rgba(46, 63, 68, 1)',
              fontSize: '9px',
              fontWeight: 600,
            }}
          >
            GC Name/ Signature
          </Typography>
          <TextField value="Ashish Parkash Developers" />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid item>
          <small style={{ fontSize: '7px', color: 'rgba(46, 63, 68, 1)' }}>
            Checked By:
          </small>
          <Typography
            style={{
              color: 'rgba(46, 63, 68, 1)',
              fontSize: '9px',
              fontWeight: 600,
            }}
          >
            Date
          </Typography>
          <TextField value="15 March, 2024  " />
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onClose={closeModal}
      PaperProps={{ style: { width: 1300, height: '90%' } }}
    >
      <DialogTitle>{ModalTitle}</DialogTitle>

      <DialogContent style={{ padding: 0 }}>
        <Grid
          container
          style={{
            height: '170px',
            backgroundColor: 'rgba(230, 241, 217, 1)',
            padding: '1rem',
          }}
        >
          {BillSummaryHeader}
          <Grid container> {BillSummaryDetail}</Grid>
        </Grid>
        <Grid
          container
          style={{
            padding: '1rem',
            marginTop: '1rem',
          }}
        >
          {BillSummaryTable}

          {CheckStatus}
        </Grid>
        <Grid
          justify="space-between"
          container
          style={{
            marginTop: '1rem',
            padding: '1rem',
            backgroundColor: 'rgba(230, 241, 217, 1)',
          }}
        >
          <Grid item>
            <img
              src={logoText}
              style={{ objectFit: 'contain', display: 'block' }}
              alt="logo text"
            />
            <Typography>Terms & Conditions apply</Typography>
          </Grid>
          <img src={logo} alt="logo of bettamint" />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BillSummaryModal;
