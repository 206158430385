import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import React from 'react';
import arrow from '../../../assests/arrow.png';
import placeholderIMG from '../../../assests/ImagePlaceHolder.png';

import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import SelectInput from 'src/views/Billing/components/SelectInput';
import Label from 'src/views/Billing/components/Label';
import TextInput from 'src/views/Billing/components/TextInput';

const AddMaterialModal = (props) => {
  const { classes, open, handleClose, requestFrom } = props;

  const renderVendorDetails = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Label title="Vendor Details" subTitle="Add Vendor Details" />
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          <Grid item sm={3} xs={12}>
            <img src={placeholderIMG} alt="vendor profile" width="130px" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            alignItems="center"
            style={{ marginLeft: -30 }}
          >
            <Grid style={{ marginBottom: 5 }}>
              <TextInput
                label="Vendor/Supplier Name"
                variant="filled"
                handleChange={() => {}}
              />
            </Grid>
            <Grid style={{ marginTop: 5 }}>
              <TextInput
                label="Contact Number"
                variant="filled"
                handleChange={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderBrandDetails = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Label title="Brand Details" subTitle="Add Brand Details" />
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          <Grid item sm={3} xs={12}>
            <img src={placeholderIMG} alt="vendor profile" width="130px" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            alignItems="center"
            style={{ marginLeft: -30 }}
          >
            <Grid style={{ marginBottom: 5 }}>
              <TextInput
                label="Brand Name"
                variant="filled"
                handleChange={() => {}}
              />
            </Grid>
            <Grid style={{ marginTop: 5 }}>
              <TextInput
                label="Make/Model"
                variant="filled"
                handleChange={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMaterialDetails = () => {
    const boqOptions = [{ label: 'Cement', value: 1 }];
    const uowOptions = [{ label: 'Kilograms', value: 1 }];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container justify="space-between">
          <Label
            title="Material Details"
            subTitle="Select Material & Quanity"
          />
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid
            item
            alignItems="center"
            style={{ minWidth: '45%', marginTop: '10px', marginRight: 2 }}
          >
            <SelectInput
              label="Select Material"
              variant="filled"
              options={boqOptions}
            />
          </Grid>
          <Grid
            item
            xs={6}
            alignItems="center"
            style={{ minWidth: '45%', marginTop: '10px', marginLeft: 2 }}
          >
            <SelectInput
              label="Unit Of Measurment"
              variant="filled"
              options={uowOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMaterialDescription = () => {
    const boqOptions = [{ label: 'Cement', value: 1 }];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container justify="space-between">
          <Label
            title="Material Details"
            subTitle="Select Material & Quanity"
          />
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid
            item
            alignItems="center"
            style={{ minWidth: '45%', marginTop: '10px', marginRight: 2 }}
          >
            <SelectInput
              label="Select Material"
              variant="filled"
              options={boqOptions}
            />
          </Grid>
          <Grid
            item
            xs={6}
            alignItems="center"
            style={{ minWidth: '45%', marginTop: '10px', marginLeft: 2 }}
          >
            <TextInput label="Description" variant="filled" />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderRatesAndAmounts = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label
            title="Rates & Amount"
            subTitle="Add Rate & Amount of Material"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
          justifyContent="space-between"
        >
          <Grid item xs={4} style={{ padding: '0px 10px 0px 0px' }}>
            <TextInput
              label="Enter Rate"
              variant="filled"
              endAdornment={<InputAdornment position="end">₹</InputAdornment>}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Enter Quantity"
              variant="filled"
              endAdornment={<InputAdornment position="end">KG</InputAdornment>}
            />
          </Grid>
          <Grid item xs={4} style={{ padding: '0px 0px 0px 10px' }}>
            <TextInput
              label="Total Amount"
              variant="filled"
              endAdornment={<InputAdornment position="end">₹</InputAdornment>}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderPermissibleWastage = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label
            title="Permissible Wastage"
            subTitle="PERMISSIBLE WASTAGE IN PERCENTAGE - %"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Permissible Wastage"
            variant="filled"
            handleChange={() => {}}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </Grid>
      </Grid>
    );
  };

  const renderStepOne = () => {
    if (requestFrom.toUpperCase() === 'MATERIAL_LIST') {
      return (
        <Grid item container style={{ height: '100%', overflow: 'auto' }}>
          <Divider style={{ width: '100%' }} />
          {renderMaterialDescription()}
          <Divider style={{ width: '100%' }} />
          {renderBrandDetails()}
        </Grid>
      );
    }

    return (
      <Grid
        item
        container
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Divider style={{ width: '100%' }} />
        {renderMaterialDetails()}
        <Divider style={{ width: '100%' }} />
        {renderVendorDetails()}
        {renderRatesAndAmounts()}
        {renderPermissibleWastage()}
      </Grid>
    );
  };

  const addVendorButton = (
    <Button
      className={classes.buttonDotted}
      startIcon={
        <AddIcon
          style={{
            fill: 'white',
            width: '15px',
            height: '15px',
            borderRadius: '5px',
            background: '#1069E3',
          }}
        />
      }
      onClick={() => {}}
    >
      Add New Vendor
    </Button>
  );

  const createButton = (
    <Button
      className={classes.buttonPurple}
      endIcon={<img src={arrow} alt="submit" />}
      onClick={() => {}}
    >
      Add
    </Button>
  );

  const renderActionButton = () => {
    const vendorButton =
      requestFrom.toUpperCase() !== 'MATERIAL_LIST' ? addVendorButton : <></>;
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>{vendorButton}</Grid>
        <Grid>{createButton}</Grid>
      </Grid>
    );
  };

  const renderTitle = () => {
    let title = '';
    if (requestFrom.toUpperCase() !== 'MATERIAL_LIST') {
      title = <Typography style={{ fontSize: 26 }}>Add Vendor</Typography>;
    } else {
      title = <Typography style={{ fontSize: 26 }}>Add Material</Typography>;
    }
    return (
      <DialogTitle>
        <Grid
          container
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography style={{ fontSize: 26 }}>{title}</Typography>
          </Grid>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    return (
      <DialogActions>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ margin: '15px 20px' }}
        >
          {renderActionButton()}
        </Grid>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      className={classes.modal}
      PaperProps={{
        style: {
          width: 700,
          height:
            requestFrom.toUpperCase() === 'MATERIAL_LIST' ? 'auto' : '90%',
        },
      }}
    >
      {renderTitle()}
      {renderStepOne()}
      {renderFooter()}
    </Dialog>
  );
};

export default AddMaterialModal;
