import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import arrow from '../assests/arrow.png';
import { Calendar } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    // width: '8em',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',

    backgroundColor: '#A179F2',
    padding: '10px 20px',

    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
}));

const PayrollModalComponent = ({ isOpen, closeModal }) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="sm"
      // fullWidth
      // style={{ width: "700px" }}
      open={isOpen}
      onClose={closeModal}
    >
      <DialogTitle onClose={closeModal}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item container xs={5}>
            <Grid item>
              <Typography style={{ fontSize: '26px' }}>
                Payroll Setup
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: '#8E99A8', fontSize: '10px' }}>
                One Time Process
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={closeModal} />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item container xs={12}>
            <Grid item>
              <Typography style={{ fontSize: '18px' }}>Bank Setup</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: '#8E99A8', fontSize: '10px' }}>
                One Time Process
              </Typography>
            </Grid>
          </Grid>

          <Grid item container style={{ marginTop: '1rem' }}>
            <Grid container item xs={12} style={{ marginBottom: '10px' }}>
              <Grid md={6}>
                <TextField
                  fullWidth
                  label="ACCOUNT HOLDER NAME"
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: '7px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#8E99A8',
                    },
                  }}
                  value="ICC Bank of India"
                />
              </Grid>
              <Grid md={6} style={{ paddingLeft: '15px' }}>
                <TextField
                  fullWidth
                  label="BANK NAME"
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: '7px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#8E99A8',
                    },
                  }}
                  value="ICC Bank of India"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginBottom: '10px' }}>
              <Grid md={6}>
                <TextField
                  fullWidth
                  label="BANK ACCOUNT NUMBER"
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: '7px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#8E99A8',
                    },
                  }}
                  value="0012 - 0934 - 0254 -12"
                />
              </Grid>
              <Grid md={6} style={{ paddingLeft: '15px' }}>
                <TextField
                  fullWidth
                  label="IFSC"
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: '7px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#8E99A8',
                    },
                  }}
                  value="ICIC0000106"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ margin: '10px 0' }} />

        <Grid container>
          <Grid item container xs={12}>
            <Grid item>
              <Typography style={{ fontSize: '18px' }}>
                Payment Setup
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: '#8E99A8', fontSize: '10px' }}>
                One Time Process
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            style={{ marginTop: '10px' }}
            // alignContent="flex-end"
          >
            <Grid item xs={6}>
              <Grid container alignItems="center" item xs={12}>
                <Grid xs={2}>
                  <DateRangeIcon
                    style={{
                      backgroundColor: '#A179F2',
                      color: '#fff',
                      borderRadius: '6px',
                      padding: '3px',
                      width: '27px',
                      height: '27px',
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography style={{ color: '#8E99A8', fontSize: '12px' }}>
                    Start Date <sup style={{ color: 'red' }}>*</sup>
                  </Typography>
                  <Typography style={{ fontSize: '12px' }}>
                    October 27, 2023
                  </Typography>
                </Grid>
              </Grid>
              <Calendar className="calendarPayment" />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '10px' }}>
              <Grid container alignItems="center" item xs={12}>
                <Grid xs={2}>
                  <DateRangeIcon
                    style={{
                      backgroundColor: '#A179F2',
                      color: '#fff',
                      borderRadius: '6px',
                      padding: '3px',
                      width: '27px',
                      height: '27px',
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography style={{ color: '#8E99A8', fontSize: '12px' }}>
                    End Date <sup style={{ color: 'red' }}>*</sup>
                  </Typography>
                  <Typography style={{ fontSize: '12px' }}>
                    Fortnightly
                  </Typography>
                </Grid>
              </Grid>

              <Calendar className="calendarPayment" />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '10px' }}>
          <Grid item container>
            <Grid item>
              <Typography style={{ fontSize: '18px' }}>
                Statutory Deductions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: '#8E99A8', fontSize: '10px' }}>
                One Time Process
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems="center">
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Grid container alignItems="center">
                    EPF
                    <span
                      style={{
                        color: '#8E99A8',
                        fontSize: '9px',
                        paddingLeft: '10px',
                      }}
                    >
                      (Employees Provident Fund)
                    </span>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Grid container alignItems="center">
                    ESI
                    <span
                      style={{
                        color: '#8E99A8',
                        fontSize: '9px',
                        paddingLeft: '10px',
                      }}
                    >
                      (Employees' State Insurance)
                    </span>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Grid container alignItems="center">
                    PT
                    <span
                      style={{
                        color: '#8E99A8',
                        fontSize: '9px',
                        paddingLeft: '10px',
                      }}
                    >
                      (Professional Tax)
                    </span>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Grid container alignItems="center">
                    LWF
                    <span
                      style={{
                        color: '#8E99A8',
                        fontSize: '9px',
                        paddingLeft: '10px',
                      }}
                    >
                      (Labour Welfare Fund)
                    </span>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Grid container alignItems="center">
                    None of the above
                  </Grid>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justify="flex-end" alignItems="center">
          <Button
            className={classes.buttonPurple}
            endIcon={<img src={arrow} />}
            onClick={closeModal}
          >
            Submit Setup{' '}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default PayrollModalComponent;
