import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  historyLoading: false,
  history: [],
  historyError: null,
  paymentSummary: null,
  paymentSummaryLoading: false,
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    sendingPayment(state, action) {
      state.loading = true;
      state.error = null;
    },
    sendingPaymentSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    sendingPaymentFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
    gettingHistory(state, action) {
      state.historyLoading = true;
      state.history = [];
    },
    gettingHistorySuccess(state, action) {
      state.historyLoading = false;
      state.history = action.payload;
    },
    gettingHistoryFailure(state, action) {
      state.historyLoading = false;
      state.history = [];
      state.historyFailure = action.payload;
    },
    gettingPaymentSummary(state, action) {
      state.paymentSummaryLoading = true;
      state.paymentSummary = null;
    },
    gettingPaymentSummarySuccess(state, action) {
      state.paymentSummaryLoading = false;
      state.paymentSummary = action.payload;
    },
    gettingPaymentSummaryFailure(state, action) {
      state.paymentSummaryLoading = false;
      state.paymentSummary = null;
      state.historyFailure = action.payload;
    },
  },
});
const {
  sendingPayment,
  sendingPaymentSuccess,
  sendingPaymentFailure,
  gettingHistory,
  gettingHistorySuccess,
  gettingHistoryFailure,
  gettingPaymentSummary,
  gettingPaymentSummarySuccess,
  gettingPaymentSummaryFailure,
} = slice.actions;

export const paymentReducer = (state) => state.payment;

export const sendPayment =
  (jobId, workerId, transactionType = 'Offline') =>
  async (dispatch) => {
    dispatch(sendingPayment());
    // if (projectId) {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Payment/process?jobId=${jobId}&workerId=${workerId}&transactionType=${transactionType}`
    );
    if (response?.status === 200) {
      dispatch(sendingPaymentSuccess(response.data));
    } else {
      dispatch(
        sendingPaymentFailure(
          'Something went wrong while sending offline payment!'
        )
      );
    }
    return response;
  };
export const getPaymentHistory =
  (projectId, startDate, endDate) => async (dispatch) => {
    dispatch(gettingHistory());
    // if (projectId) {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Payment/history?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}`
    );
    if (response?.status === 200) {
      dispatch(gettingHistorySuccess(response.data));
    } else {
      dispatch(
        gettingHistoryFailure(
          'Something went wrong while getting payment history!'
        )
      );
    }
    return response;
  };
export const getPaymentSummary =
  (projectId, workersList) => async (dispatch) => {
    dispatch(gettingPaymentSummary());
    const queryParams = workersList
      .map((workerId) => `WorkersList=${workerId}`)
      .join('&');

    const response = await axios.get(
      `${config.baseUrl}/dashboard/Payment/summary?ProjectId=${projectId}&${queryParams}`
    );
    if (response?.status === 200) {
      dispatch(gettingPaymentSummarySuccess(response.data));
    } else {
      dispatch(
        gettingPaymentSummaryFailure(
          'Something went wrong while getting payment summary!'
        )
      );
    }
    return response;
  };

export default slice.reducer;
