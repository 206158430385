import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeads, leadReducer } from 'src/redux/lead';
import Page from 'src/components/Page';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import LeadListView from './leadListView';

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // maxWidth: '400px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    padding: '.5rem 1rem ',
    textTransform: 'capitalize',
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
}));

const IndexLead = () => {
  //! INSTANCES
  let dispatch = useDispatch();
  const classes = useStyles();

  //! SELECTORS
  const { loading, leads } = useSelector(leadReducer);

  //! LIFE-CYCLES
  useEffect(() => {
    dispatch(getLeads());
  }, []);

  return (
    <Page className={classes.root} title="Leads" id="zoom-level">
      <Container maxWidth={false}>
        <Grid
          container
          style={{
            // background: modals.createNewJob ? '' : 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Grid
            container
            // style={{ height: showCard === false ? '600px' : '' }}
          >
            {leads?.length === 0 && (
              <Grid
                container
                item
                xs={12}
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '70vh' }}
              >
                <Grid>
                  <img
                    src="/static/images/bettamintImages/job.png"
                    alt="project"
                    style={{
                      width: '80px',
                      height: '90px',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      color: '#ADBAC3',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                    }}
                  >
                    You Dont Have a Lead yet!
                  </Typography>
                </Grid>
              </Grid>
            )}
            {loading ? (
              <div
                style={{
                  textAlign: 'center',
                  height: '76vh',
                  width: '100%',
                }}
              >
                <CircularProgress style={{ color: '#81B734' }} />
              </div>
            ) : (
              leads?.length !== 0 && <LeadListView leads={leads} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default IndexLead;
