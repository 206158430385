import Geocode from 'react-geocode';

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    //     Geocode.setApiKey('YOUR_GOOGLE_MAPS_API_KEY');
    //     Geocode.enableDebug();
    //     Geocode.setLanguage('en');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await Geocode.fromLatLng(latitude, longitude);
            const address = response.results[0].formatted_address;

            const locationObject = {
              latitude: latitude,
              longitude: longitude,
              address: address,
            };

            resolve(locationObject);
          } catch (error) {
            console.error('Error getting address details:', error);
            reject(error);
          }
        },
        (error) => {
          console.error('Error getting user location:', error);
          reject(error);
        }
      );
    } else {
      const errorMessage = 'Geolocation is not supported by this browser.';
      console.error(errorMessage);
      reject(errorMessage);
    }
  });
};
