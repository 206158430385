import React from 'react';
import DashboardView from 'src/views/dashboard/index';
import DashboardLayout from 'src/layouts/DashboardLayout';
import Attendance from 'src/views/Attendance';
import AttendanceDetail from 'src/views/Attendance/attendanceDetail';
import Billing from 'src/views/Billing';
import Communication from 'src/views/Communication';
import CustomersIndex from 'src/views/Customers';
import NotFoundView from 'src/views/errors/NotFoundView';
import Features from 'src/views/features/index';
import FieldNotes from 'src/views/FieldNotes';
import CreateFieldNote from 'src/views/FieldNotes/createFieldNote';
import EditFieldNote from 'src/views/FieldNotes/editFieldNote';
import InventoryManagement from 'src/views/Inventory';
import Jobs from 'src/views/Jobs';
import CreateJob from 'src/views/Jobs/createJob';
import JobDetails from 'src/views/Jobs/jobDetails';
import UserProfile from 'src/views/Lead/userProfile';
import Productivity from 'src/views/Productivity';
import Projects from 'src/views/Project';
import ProjectDetails from 'src/views/Project/projectDetails';
import Reports from 'src/views/Reports';
import Rewards from 'src/views/Rewards';
import Roles from 'src/views/roles/index';
import AddRoles from 'src/views/roles/add-roles';
import Subscription from 'src/views/subscription/index';
import Users from 'src/views/Users';
import CreateUser from 'src/views/Users/createUser';
import UserDetail from 'src/views/Users/userDetail';
import WorkerProfile from 'src/views/WorkerProfile';
import CreateWorker from 'src/views/WorkerProfile/createWorker';
import EditWorkerDetails from 'src/views/WorkerProfile/editWorkerDetails';
import WorkerDetail from 'src/views/WorkerProfile/workerDetail';
import Payments from 'src/views/Payments';
import ProjectEdit from 'src/views/Project/createProjectView';
import LeadsList from 'src/views/Lead/index';
import { auth } from 'src/routes';
import { Navigate, useRoutes } from 'react-router-dom';

const allRoutes = [
  {
    path: '/app/dashboard',
    route: {
      index: true,
      path: 'dashboard',
      element: <DashboardView />,
    },
  },
  {
    path: '/app/profile',
    route: { path: 'profile', element: <UserProfile /> },
  },
  {
    path: '/app/leads',
    route: { path: 'leads', element: <LeadsList /> },
  },

  {
    path: '/app/project',
    route: { path: 'project', element: <Projects /> },
  },
  {
    path: '/app/project/details',
    route: {
      path: 'project/details',
      element: <ProjectDetails />,
    },
  },
  {
    path: '/app/project/edit',
    route: { path: 'project/edit', element: <ProjectEdit /> },
  },
  {
    path: '/app/project/create',
    route: { path: 'project/create', element: <ProjectEdit /> },
  },

  {
    path: '/app/jobs',
    route: { path: 'jobs', element: <Jobs /> },
  },
  {
    path: '/app/jobs/create',
    route: { path: 'jobs/create', element: <CreateJob /> },
  },

  {
    path: '/app/jobs/detail',
    route: { path: 'jobs/detail', element: <JobDetails /> },
  },
  {
    path: '/app/jobs/create',
    route: { path: 'jobs/edit', element: <CreateJob /> },
  },

  {
    path: '/app/attendance',
    route: { path: 'attendance', element: <Attendance /> },
  },
  {
    path: '/app/attendance/detail',
    route: {
      path: 'attendance/detail',
      element: <AttendanceDetail />,
    },
  },

  {
    path: '/app/workerprofile',
    route: { path: 'workerprofile', element: <WorkerProfile /> },
  },
  {
    path: '/app/workerprofile/detail',
    route: {
      path: 'workerprofile/detail',
      element: <WorkerDetail />,
    },
  },
  {
    path: '/app/workerprofile/create-worker',
    route: {
      path: 'workerprofile/create-worker',
      element: <CreateWorker />,
    },
  },
  {
    path: '/app/workerprofile/edit',
    route: {
      path: 'workerprofile/edit',
      element: <EditWorkerDetails />,
    },
  },

  {
    path: '/app/payments',
    route: { path: 'payments', element: <Payments /> },
  },
  {
    path: '/app/users',
    route: { path: 'users', element: <Users /> },
  },
  {
    path: '/app/users/detail',
    route: { path: 'users/detail', element: <UserDetail /> },
  },
  {
    path: '/app/users/create',
    route: { path: 'users/create', element: <CreateUser /> },
  },
  {
    path: '/app/users/edit',
    route: { path: 'users/edit', element: <CreateUser /> },
  },

  {
    path: '/app/communication',
    route: { path: 'communication', element: <Communication /> },
  },
  {
    path: '/app/reports',
    route: { path: 'reports', element: <Reports /> },
  },

  {
    path: '/app/features',
    route: { path: 'features', element: <Features /> },
  },

  {
    path: '/app/roles',
    route: { path: 'roles', element: <Roles /> },
  },
  {
    path: '/app/roles/add',
    route: { path: 'roles/add', element: <AddRoles /> },
  },
  {
    path: '/app/billing',
    route: { path: 'billing', element: <Billing /> },
  },
  {
    path: '/app/customers',
    route: { path: 'customers', element: <CustomersIndex /> },
  },
  {
    path: '/app/field-notes',
    route: { path: 'field-notes', element: <FieldNotes /> },
  },
  {
    path: '/app/field-notes/create',
    route: {
      path: 'field-notes/create',
      element: <CreateFieldNote />,
    },
  },
  {
    path: '/app/field-notes/edit',
    route: {
      path: 'field-notes/edit',
      element: <EditFieldNote />,
    },
  },
  {
    path: '/app/rewards',
    route: { path: 'rewards', element: <Rewards /> },
  },
  {
    path: '/app/productivity',
    route: {
      path: 'productivity',
      element: <Productivity />,
    },
  },
  {
    path: '/app/subscription',
    route: { path: 'subscription', element: <Subscription /> },
  },
  {
    path: '/app/inventory',
    route: {
      path: 'inventory',
      element: <InventoryManagement />,
    },
  },
];

const RoutesConfig = () => {
  const userData = localStorage.getItem('userDetails');

  const accessPath = [];
  for (let existingRoute of allRoutes) {
    accessPath.push(existingRoute.route);
  }

  const accessRoutes = [
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [...accessPath],
    },
    {
      path: '/',
      element: <Navigate to="/app/dashboard" />,
    },
    {
      path: '*',
      element: <NotFoundView />,
    },
  ];

  return useRoutes(userData ? accessRoutes : auth);
};

export default RoutesConfig;
