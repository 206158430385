// ** Redux Imports
import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
// import thunkMiddleware from 'redux-thunk';
const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware({
			serializableCheck: false,
		});
	},
});

export { store };
