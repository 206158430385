import React from 'react';
import {
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
} from '@material-ui/core';

const NestedTable = (props) => {
  const { renderRow, data, headers } = props;
  return (
    <TableContainer component={Paper}>
      <Table style={{ overflow: 'auto' }}>
        <TableHead>
          {headers.map((title) => (
            <TableCell style={{ textWrap: 'nowrap' }}>{title.label}</TableCell>
          ))}
        </TableHead>
        <TableBody>{data.map(renderRow)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default NestedTable;
