import React, { useState } from 'react';
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Page from 'src/components/Page';

import issue from '../../assests/issue.png';
import tick from '../../assests/tickMark.png';
import note from '../../assests/note.png';
import checklist from '../../assests/checklist.png';
import GHIMAGE from '../../assests/G.H Builders IMG.png';
import RMIMAGE from '../../assests/R.M Contractors IMG.png';
import SRIMAGE from '../../assests/S.R Contractors IMG.png';
import VKIMAGE from '../../assests/V.K Builders IMG.png';
import IGN from '../../assests/IGN IMG.png';
import TRACEABILITY_VIEW from '../../assests/Tracebility_IMG.png';

// import useAccessPerView from 'src/common/Access';
import SummaryChart from '../Billing/components/SummaryChart';
import ProjectHighlightsHead from './components/ProjectHighlightHead';
import ProjectHighlightsWithChart from './components/ProjectHighlightsWithChart';
import InventoryManagementSummary from './components/InventoryManagementSummary';
import GRNIssueModal from './components/GRNIssueModal';
import AddMaterialModal from './components/AddMaterialModal';
import MaterialIssueModal from './components/MaterialIssueModal';
import MaterialIndentRow from './components/MaterialIndetRow';
import TraceabilityRow from './components/TraceabilityRow';
import MaterialListRow from './components/MaterialListRow';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontSize: '0.8rem', // change the font size to make the text smaller
      padding: theme.spacing(1), // adjust the padding to make the text field smaller
      // width: '10ch', // set the width to make the text field smaller
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.6rem', // change the font size to make the label smaller
    },
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  buttonGreen: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  imageContainer: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'rgba(1,1,1,0.2)',
    position: 'relative',
    marginRight: 15,
    flex: 'none',
  },
  tocAction: {
    borderRadius: 10,
    background: 'rgba(1,1,1,0.1)',
    height: '100%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  bordered: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  projectAvatar: {
    width: '40px',
    height: '40px',
    background: 'rgba(1,1,1,0.2)',
    objectFit: 'none',
    borderRadius: 25,
  },
  buttonDotted: {
    border: '1px dotted rgba(1,1,1,0.2)',
    backgroundColor: 'white',
    padding: '5px 15px',
    fontSize: 11,
    fontWeight: 'normal',
    textTransform: 'capitalize',
    height: 45,
    borderRadius: 8,
    marginRight: '5px',
  },
}));

const MATERIAL_INDENT = {
  tableHeaders: [
    { label: 'S.NO' },
    { label: 'Material' },
    { label: 'Contractor' },
    { label: 'Work Order Id' },
    { label: 'Cost Code' },
    { label: 'UOM' },
    { label: 'QUANTITY' },
    { label: 'GRN' },
    { label: 'ISSUED & UTILIZED QUANTITY' },
    { label: 'ACTION' },
  ],
  tableData: [
    {
      Material: 'Cement',
      Contractors: [
        {
          Profile: VKIMAGE,
          Contractor: 'V.K Builders',
          WorkOrderId: '123456',
          WorkOrders: [
            {
              costCode: '2.1.1',
              UOM: 'KG',
              quantity: '72000',
              GRN: IGN,
              issuedQUantity: 60,
              action: '...',
            },
            {
              costCode: '3.1.2',
              UOM: 'KG',
              quantity: '4800',
              GRN: IGN,
              issuedQUantity: 80,
              action: '...',
            },
          ],
        },
        {
          Profile: GHIMAGE,
          Contractor: 'G.H Builders',
          WorkOrderId: '654321',
          WorkOrders: [
            {
              costCode: '2.1.1',
              UOM: 'KG',
              quantity: '72000',
              GRN: IGN,
              issuedQUantity: 60,
              action: '...',
            },
            {
              costCode: '3.1.2',
              UOM: 'KG',
              quantity: '4800',
              GRN: IGN,
              issuedQUantity: 80,
              action: '...',
            },
          ],
        },
      ],
    },
    {
      Material: 'Tiles and Marble',
      Contractors: [
        {
          Profile: SRIMAGE,
          Contractor: 'S.R Contractors',
          WorkOrderId: '123456',
          WorkOrders: [
            {
              costCode: '2.1.1',
              UOM: 'KG',
              quantity: '72000',
              GRN: IGN,
              issuedQUantity: 60,
              action: '...',
            },
            {
              costCode: '3.1.2',
              UOM: 'KG',
              quantity: '4800',
              GRN: IGN,
              issuedQUantity: 80,
              action: '...',
            },
          ],
        },
        {
          Profile: RMIMAGE,
          Contractor: 'R.M Contractors',
          WorkOrderId: '654321',
          WorkOrders: [
            {
              costCode: '2.1.1',
              UOM: 'KG',
              quantity: '72000',
              GRN: IGN,
              issuedQUantity: 60,
              action: '...',
            },
            {
              costCode: '3.1.2',
              UOM: 'KG',
              quantity: '4800',
              GRN: IGN,
              issuedQUantity: 80,
              action: '...',
            },
          ],
        },
      ],
    },
  ],
};

const TRACEABILITY = {
  tableHeaders: [
    { label: 'Cost Code' },
    { label: 'Title' },
    { label: 'Description' },
    { label: 'Material' },
    { label: 'UOM' },
    { label: 'QUANTITY' },
    { label: 'UTILIZED QUANTITY' },
    { label: '' },
  ],
  tableData: [
    {
      CostCode: '2.1.1',
      Title: [
        {
          Title: 'Field Work',
          description: 'Standard text here',
          Material: [
            {
              material: 'cement',
              UOM: 'KG',
              quantity: '700',
              utilizedQuantity: 85,
            },
            {
              material: 'concrete',
              UOM: 'Ton',
              quantity: '10',
              utilizedQuantity: 12,
            },
            {
              material: 'STEEL & METAL',
              UOM: 'Ton',
              quantity: '12',
              utilizedQuantity: 65,
            },
          ],
        },
        {
          Title: 'Dummy Text',
          description: 'Standard text here',
          Material: [
            {
              material: 'cement',
              UOM: 'KG',
              quantity: '700',
              utilizedQuantity: 85,
            },
            {
              material: 'concrete',
              UOM: 'Ton',
              quantity: '10',
              utilizedQuantity: 12,
            },
            {
              material: 'STEEL & METAL',
              UOM: 'Ton',
              quantity: '12',
              utilizedQuantity: 65,
            },
          ],
        },
      ],
    },
  ],
};

const MATERIAL_LIST = {
  tableHeaders: [
    { label: 'S.NO' },
    { label: 'Material' },
    { label: 'Description' },
    { label: 'Brand Name' },
    { label: 'Make/Model' },
    { label: 'Action' },
  ],
  tableData: [
    {
      Material: 'Cement',
      Description: [
        {
          Description: 'Plaster Cement',
          Brand: [
            {
              Profile: GHIMAGE,
              BrandName: 'G.k Cement',
              Make: 'xx-xx-xx',
              action: '...',
            },
            {
              Profile: RMIMAGE,
              BrandName: 'R.Taj Cement',
              Make: 'xx-xx-xx',
              action: '...',
            },
            {
              Profile: SRIMAGE,
              BrandName: 'S.M Cements',
              Make: 'xx-xx-xx',
              action: '...',
            },
          ],
        },
        {
          Description: 'Block Cement',
          Brand: [
            {
              Profile: GHIMAGE,
              BrandName: 'G.H Cement',
              Make: 'xx-xx-xx',
              action: '...',
            },
            {
              Profile: SRIMAGE,
              BrandName: 'S.R Tiles',
              Make: 'xx-xx-xx',
              action: '...',
            },
          ],
        },
      ],
    },
    {
      Material: 'Tiles & Marbles',
      Description: [
        {
          Description: 'Washroom Tiles',
          Brand: [
            {
              Profile: GHIMAGE,
              BrandName: 'G.k Cement',
              Make: 'xx-xx-xx',
              action: '...',
            },
            {
              Profile: RMIMAGE,
              BrandName: 'R.Taj Cement',
              Make: 'xx-xx-xx',
              action: '...',
            },
            {
              Profile: SRIMAGE,
              BrandName: 'S.M Cements',
              Make: 'xx-xx-xx',
              action: '...',
            },
          ],
        },
        {
          Description: 'Kitchen',
          Brand: [
            {
              Profile: GHIMAGE,
              BrandName: 'G.H Cement',
              Make: 'xx-xx-xx',
              action: '...',
            },
            {
              Profile: SRIMAGE,
              BrandName: 'S.R Tiles',
              Make: 'xx-xx-xx',
              action: '...',
            },
          ],
        },
      ],
    },
  ],
};

const InventoryManagement = () => {
  const classes = useStyles();

  const [openAddMaterialModal, setOpenAddMaterialModal] = useState(false);
  const [openGRNModal, setOpenGRNModal] = useState(false);
  const [openMaterialIssueModal, setOpenMaterialIssueModal] = useState(false);
  const [activeMod, setActiveMod] = useState('inventory_management');

  const MOD_INVENTORY = 'inventory_management';
  const MOD_TRACEABILITY = 'traceability';
  const MOD_MATERIAL_LIST = 'material_list';

  const handleClick = () => {};

  const breadcrumbsBase = [
    <Typography key="1" color="primary">
      Inventory Management
    </Typography>,
  ];

  const breadcrumbsTracebility = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => setActiveMod(MOD_INVENTORY)}
      to="/"
      style={{ cursor: 'pointer' }}
    >
      Inventory Management
    </Link>,
    <Typography key="2" color="primary">
      Tracebility
    </Typography>,
  ];

  const breadcrumbsMaterialList = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => setActiveMod(MOD_INVENTORY)}
      style={{ cursor: 'pointer' }}
    >
      Inventory Management
    </Link>,
    <Typography key="2" color="primary">
      Material List
    </Typography>,
  ];

  const breadcrumbsGeneralStoreVendors = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => setActiveMod(MOD_INVENTORY)}
      style={{ cursor: 'pointer' }}
    >
      Inventory Management
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      General Store
    </Link>,
    <Typography key="3" color="primary">
      Vendor
    </Typography>,
  ];

  const handleOpenTracebility = () => setActiveMod(MOD_TRACEABILITY);
  const handleOpenMaterialList = () => setActiveMod(MOD_MATERIAL_LIST);

  const renderChart = () => {
    // props
    const insightData = [
      {
        percentage: 64,
        color: 'linear-gradient(to right, #4426a8ff, #c0429eff)',
        heading: 'Material Available',
      },
      {
        percentage: 28,
        color: '#EAB401',
        heading: 'Material Used',
      },
    ];

    const grossTotal = 'N/A';

    return (
      <SummaryChart
        insightData={insightData}
        date={<></>}
        summaryLabel={<></>}
        grossTotal={grossTotal}
        renderPercentage
        renderAmount={false}
      />
    );
  };

  const renderInventorySummary = () => {
    let tableHead, tableData, rowComponent;

    if (activeMod === MOD_MATERIAL_LIST) {
      tableHead = MATERIAL_LIST.tableHeaders;
      tableData = MATERIAL_LIST.tableData;
      rowComponent = (data, index) => (
        <MaterialListRow rowData={data} rowIndex={index} />
      );
    }
    if (activeMod === MOD_TRACEABILITY) {
      tableHead = TRACEABILITY.tableHeaders;
      tableData = TRACEABILITY.tableData;
      rowComponent = (data, index) => (
        <TraceabilityRow rowData={data} rowIndex={index} />
      );
    }
    if (activeMod === MOD_INVENTORY) {
      tableHead = MATERIAL_INDENT.tableHeaders;
      tableData = MATERIAL_INDENT.tableData;
      rowComponent = (data, index) => (
        <MaterialIndentRow rowData={data} rowIndex={index} />
      );
    }
    return (
      <InventoryManagementSummary
        classes={classes}
        openBillSummaryModal={() => {}}
        tableHead={tableHead}
        tableData={tableData}
        tableRow={rowComponent}
      />
    );
  };

  const renderBreadCrumbs = () => {
    let breadcrumbs = '';
    if (activeMod === MOD_INVENTORY) {
      breadcrumbs = breadcrumbsBase;
    }
    if (activeMod === MOD_MATERIAL_LIST) {
      breadcrumbs = breadcrumbsMaterialList;
    }
    if (activeMod === MOD_TRACEABILITY) {
      breadcrumbs = breadcrumbsTracebility;
    }
    return (
      <Grid style={{ margin: '10px 20px' }}>
        <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
      </Grid>
    );
  };

  const renderContractorLedgerHead = () => {
    return (
      <ProjectHighlightsHead
        classes={classes}
        activeMod={activeMod}
        setOpenAddMaterialModal={setOpenAddMaterialModal}
        setOpenGRNIssueModal={setOpenGRNModal}
        setOpenMaterialIssueModal={setOpenMaterialIssueModal}
        setOpenTracebility={handleOpenTracebility}
        setOpenMaterialList={handleOpenMaterialList}
      />
    );
  };

  const renderProjectHighlights = () => {
    return (
      <ProjectHighlightsWithChart classes={classes} renderChart={renderChart} />
    );
  };

  const renderInventoryManagementView = () => {
    return (
      <Page>
        {renderBreadCrumbs()}
        {renderContractorLedgerHead()}
        {renderProjectHighlights()}
        {renderInventorySummary()}
      </Page>
    );
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Billing">
      {renderInventoryManagementView()}
      <GRNIssueModal
        open={openGRNModal}
        classes={classes}
        handleClose={() => setOpenGRNModal(false)}
      />
      <AddMaterialModal
        open={openAddMaterialModal}
        classes={classes}
        handleClose={() => setOpenAddMaterialModal(false)}
        requestFrom={activeMod}
      />
      <MaterialIssueModal
        open={openMaterialIssueModal}
        classes={classes}
        handleClose={() => setOpenMaterialIssueModal(false)}
      />
    </Page>
  );
};

export default InventoryManagement;
