import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
// import UserContext from 'src/context/UserContext';
// import getToken from 'src/services/getToken';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    // justify: 'center',
    // display: 'flex',
    alignItems: 'center',
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
}));

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  //! INSTANCES
  const navigate = useNavigate();
  const classes = useStyles();
  let location = useLocation();

  const handleOnSubmit = () => {
    console.log('You Requested for Password Reset');
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Login">
      <Grid
        container
        style={{ width: '100%', height: '100%' }}
        // xs={12}
        alignItems="center"
        justify="center"
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          md={6}
          sm={12}
        >
          <Grid
            container
            style={{ width: '100%' }}
            justify="center"
            item
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="70%"
              justify="center"
            >
              <Logo style={{ width: '80%' }} />
              <Typography
                variant="h6"
                style={{
                  fontSize: '20px',
                  color: '#81B734',
                  width: '80%',
                  fontFamily: 'Roboto',
                  marginTop: '1em',
                }}
              >
                Enabling the next generation <br /> of construction firms.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          md={6}
          xs={12}
        >
          <Formik
            initialValues={{
              email: email,
            }}
            onSubmit={() => handleOnSubmit()}
          >
            {({ errors, handleBlur, handleSubmit, touched }) => (
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                {/* <Container> */}
                <Grid item xs={12} md={9} style={{ padding: '0 1rem 0 1rem' }}>
                  <Typography variant="h2" style={{ fontFamily: 'Roboto' }}>
                    Forgot Password?
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      marginBottom: '2em',
                      fontFamily: 'Roboto',
                    }}
                  >
                    Please enter your email address to set new password!
                  </Typography>
                  <Grid>
                    <TextField
                      // size="small
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      type="email"
                      name="Email"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setEmail(event.target.value);
                        setIsSubmitting(false);
                      }}
                      // type='email'
                      value={email}
                      variant="outlined"
                    />
                  </Grid>

                  <Link to="../login">
                    <small>Login?</small>
                  </Link>

                  <Button
                    // loading={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.buttonPurple}
                    style={{ marginTop: '1em' }}
                    // onClick={() => navigate("/app/dashboard")}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        size="1.3rem"
                        style={{ color: 'green', marginRight: '10px' }}
                      />
                    )}
                    Submit
                  </Button>
                </Grid>
                {/* </Container> */}
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ResetPassword;
