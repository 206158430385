import React from "react";
import Page from "src/components/Page";
import { Container, Grid, makeStyles } from "@material-ui/core";
import CommingSoon from "../../assests/commingSoon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: "#81B734",
    "&:hover": {
      backgroundColor: "#81B734",
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: "#A179F2",
    "&:hover": {
      backgroundColor: "#A179F2",
    },
  },
  buttonPurpleOpacity: {
    color: "#986FEF",
    backgroundColor: "rgba(161, 120, 242, 0.20)",
    "&:hover": {
      backgroundColor: "rgba(161, 120, 242, 0.20)",
    },
  },
}));

function Communication() {
  const classes = useStyles();

  return (
    <Grid container xs={12} style={{ position: "relative" }}>
      <img
        src={CommingSoon}
        alt="chat"
        style={{
          position: "absolute",
          top: "20%",
          width: "300px",
          height: "300px",
          left: "40%",
        }}
      />
      <img
        src="/static/images/bettamintImages/chat.png"
        alt="chat"
        style={{
          marginTop: "-1%",
          marginLeft: "-1%",
          width: "100%",
          opacity: 0.1,
        }}
      />
    </Grid>
  );
}

export default Communication;
