import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';
import moment from 'moment';

const initialState = {
  loading: false,
  error: null,
  projectsList: null,
  projectDetailsLoading: false,
  projectDetails: null,
  projectClassificationList: [],
  selectedProject: null,
  mappingProjectList: [],
  GCList: null,
  GCLoading: false,
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    gettingProjects(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingProjectsSuccess(state, action) {
      state.projectsList = action.payload;
      state.loading = false;
      state.error = null;
    },
    gettingProjectsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    gettingProjectsMapping(state, action) {},
    gettingProjectsMappingSuccess(state, action) {
      state.mappingProjectList = action.payload;
      state.error = null;
    },
    gettingProjectsMappingFailure(state, action) {
      state.error = action.payload;
    },

    creatitngProject(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatitngProjectSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    creatitngProjectFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingProjectDetails(state, action) {
      state.projectDetailsLoading = true;
      state.error = null;
    },
    gettingProjectDetailsSuccess(state, action) {
      state.projectDetailsLoading = false;
      state.projectDetails = action.payload;
    },
    gettingProjectDetailsFailure(state, action) {
      state.projectDetailsLoading = false;
      state.error = action.payload;
    },
    gettingProjectClassificationList(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingProjectClassificationListSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.projectClassificationList = action.payload;
    },
    gettingProjectClassificationListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },

    submittingProjectId(state, action) {
      state.loading = true;
      state.error = null;
    },
    submittingProjectIdSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    submittingProjectIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingGCList(state, action) {
      state.GCLoading = true;
      state.error = null;
    },
    gettingGCListSuccess(state, action) {
      state.GCLoading = false;
      state.error = null;
      state.GCList = action.payload;
    },
    gettingGCListFailure(state, action) {
      state.GCLoading = false;
      state.error = action.payload;
      state.GCList = null;
    },
  },
});
const {
  gettingProjects,
  gettingProjectsSuccess,
  gettingProjectsFailure,
  creatitngProject,
  creatitngProjectSuccess,
  creatitngProjectFailure,
  gettingProjectDetails,
  gettingProjectDetailsSuccess,
  gettingProjectDetailsFailure,
  gettingProjectClassificationList,
  gettingProjectClassificationListSuccess,
  gettingProjectClassificationListFailure,
  gettingProjectsMapping,
  gettingProjectsMappingSuccess,
  gettingProjectsMappingFailure,
  setSelectedProject,
  submittingProjectId,
  submittingProjectIdSuccess,
  submittingProjectIdFailure,
  gettingGCList,
  gettingGCListSuccess,
  gettingGCListFailure,
} = slice.actions;
export const projectReducer = (state) => state.projects;

export const getProjectsClassificationList = () => async (dispatch) => {
  dispatch(gettingProjectClassificationList());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Project/getallsimple`
    );
    if (response.status === 200) {
      dispatch(gettingProjectClassificationListSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingProjectClassificationListFailure(
        'Something went wrong while getting users!'
      )
    );
  }
};

export const getProjectsMapping = () => async (dispatch) => {
  dispatch(gettingProjectsMapping());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Project/getformapping`
    );
    if (response.status === 200) {
      dispatch(gettingProjectsMappingSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingProjectsMappingFailure(
        'Something went wrong while getting project mapping!'
      )
    );
  }
};

export const getProjects =
  (createdBy = 0) =>
  async (dispatch) => {
    dispatch(gettingProjects());
    try {
      const response = await axios.get(
        `${
          config.baseUrl
        }/dashboard/Project/getall?startDate=${moment().format()}&endDate=${moment().format()}&createdBy=${createdBy}`
      );
      if (response.status === 200) {
        dispatch(gettingProjectsSuccess(response.data));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingProjectsFailure('Something went wrong while getting users!')
      );
    }
  };

export const getProjectDetails = (projectId) => async (dispatch) => {
  dispatch(gettingProjectDetails());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Project?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingProjectDetailsSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingProjectDetailsFailure(
        'Something went wrong while getting project details!'
      )
    );
  }
};

export const updateProject = (worker) => async (dispatch) => {
  dispatch(creatitngProject());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Project/addupdateproject`,
      worker
    );

    if (response.status === 200) {
      dispatch(creatitngProjectSuccess(response.data));
    } else {
      dispatch(
        creatitngProjectFailure(
          'Something went wrong while creating/updaing project!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      creatitngProjectFailure(
        'Something went wrong while creating/updaing project!'
      )
    );
    return e;
  }
};

export const submitProjectId =
  (projectId, leadType, ownerId) => async (dispatch) => {
    dispatch(submittingProjectId());
    try {
      const response = await axios.post(
        leadType === 'Developer'
          ? `${config.baseUrl}/dashboard/User/project?projectId=${projectId}&leadType=${leadType}`
          : `${config.baseUrl}/dashboard/User/project?projectId=${projectId}&leadType=${leadType}&ownerid=${ownerId}`
      );

      if (response.status === 200) {
        dispatch(submittingProjectIdSuccess(response.data));
      }
      return response;
    } catch (e) {
      dispatch(
        submittingProjectIdFailure(
          'Something went wrong while submitting projectId!'
        )
      );
      return e;
    }
  };

export const selectProject = (project) => async (dispatch) => {
  dispatch(setSelectedProject(project));
};

export const getGCList = (projectId) => async (dispatch) => {
  dispatch(gettingGCList());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/User/generalcontractor?projectId=${projectId}`
    );
    if (response.status === 200) {
      dispatch(gettingGCListSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingGCListFailure(
        'Something went wrong while getting GC user mapping!'
      )
    );
  }
};

export default slice.reducer;
