import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractors, getUsers, usersReducer } from 'src/redux/users';

const useStyles = makeStyles((theme) => ({
  inputColor: {
    color: '#ddd',
  },
  floatingLabelFocusStyle: {
    color: 'white',
  },
  // autocomplete: {
  // 	'& .MuiAutocomplete-listbox .Mui-selected': {
  // 		backgroundColor: 'white',
  // 		color: 'white',
  // 	},
  // },
}));
const FilterByLabourContractorComponent = ({
  value,
  onChange,
  color = 0,
  setInitialUser = null,
  showFilterByProject = null,
  currentProject = null,
  label = 'Filter By Contractor',
}) => {
  // const [list, setList] = useState(null);
  let dispatch = useDispatch();
  const classes = useStyles();

  const { contractorList } = useSelector(usersReducer);

  // useEffect(() => {
  //   if (!contractorList) {
  //     // dispatch(getUsers());
  //     if (currentProject) {
  //       dispatch(getContractors(currentProject?.projectId));
  //     } else {
  //       dispatch(getContractors());
  //     }

  //     // return
  //   }
  //   // else {
  //   //   if (setInitialUser) {
  //   //     setInitialUser({ fullName: "Select Contractor", userId: 0 });

  //   //     let renderingList = showFilterByProject && [
  //   //       { fullName: "Select Contractor", userId: 0 },
  //   //       ...(usersList?.filter(
  //   //         (item) =>
  //   //           item.userProjects?.length &&
  //   //           item.leadTypeId === "LabourContractor" &&
  //   //           item.userProjects.find(
  //   //             (project) => project.projectId === currentProject?.projectId
  //   //           )
  //   //       ) || []),
  //   //     ];

  //   //     setList(
  //   //       showFilterByProject
  //   //         ? renderingList
  //   //         : usersList?.filter(
  //   //             (item) => item.leadTypeId === "LabourContractor"
  //   //           )
  //   //     );
  //   //   }
  //   // }
  // }, [contractorList?.length, currentProject?.projectId]);

  return contractorList ? (
    <Autocomplete
      autoSelect
      size="small"
      options={contractorList?.filter(
        (item) => item.leadTypeId === 'LabourContractor'
      )}
      style={{ width: '100%' }}
      getOptionLabel={(option) => option?.fullName}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      noOptionsText={'No Record Found'}
      // sx={ { color: 'white' } }

      // classes={ {
      // 	root: classes.autocomplete,
      // } }

      renderInput={(params) => (
        <TextField
          {...params}
          // InputProps={ {
          // className: color === "white" ? classes.inputColor : "black"
          // } }

          InputLabelProps={{
            className: color === 'white' && classes.floatingLabelFocusStyle,
          }}
          style={{
            fontSize: '12px !important',
            // color: "#ddd"
          }}
          size="small"
          variant="standard"
          label={label}
          // className={classes.projectInput}
        />
      )}
    />
  ) : (
    <Autocomplete
      autoSelect
      size="small"
      options={[{ fullName: 'Select Contractor', userId: 0 }]}
      getOptionLabel={(option) => option?.fullName}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      noOptionsText={'No Record Found'}
      // sx={ { color: 'white' } }

      // classes={ {
      // 	root: classes.autocomplete,
      // } }

      renderInput={(params) => (
        <TextField
          {...params}
          // InputProps={ {
          // className: color === "white" ? classes.inputColor : "black"
          // } }

          InputLabelProps={{
            className: color === 'white' && classes.floatingLabelFocusStyle,
          }}
          style={{
            fontSize: '12px !important',
            // color: "#ddd"
          }}
          size="small"
          variant="standard"
          label={label}
          // className={classes.projectInput}
        />
      )}
    />
  );
};

export default FilterByLabourContractorComponent;
