import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  Typography,
} from '@material-ui/core';
import { assignLead, getLeads, leadReducer } from 'src/redux/lead';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import SearchInput from 'src/components/SearchInput';

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // maxWidth: '400px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonStyle: {
    textTransform: 'capitalize',
    borderRadius: '10px',
    // color: "white",
    fontSize: '12px',
    fontFamily: 'Roboto',
    // "&:hover": {
    //   color:"white",
    //   // backgroundColor: "#908DA6",
    // },
  },

  filterButton: {
    // backgroundColor: "#908DA6",
    // color: "white",
    '&:hover': {
      backgroundColor: '#81B734',
      color: 'white',
    },
  },
  selectedButton: {
    color: 'white',

    backgroundColor: '#81B734',
  },

  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    padding: '.5rem 1rem ',
    textTransform: 'capitalize',
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  marginBottom: {
    marginBottom: '0',
  },
  textFieldMarginBottom: {
    marginBottom: '15px',
  },
  marginTop: { marginTop: '0' },
  [theme.breakpoints.down('sm')]: {
    marginBottom: {
      marginBottom: '10px',
    },
    overflow: {
      overflowX: 'scroll',
    },
    marginTop: { marginTop: '10px' },
  },
}));
const LeadDetailModalComponent = ({
  isLeadDetailModal,
  closeLeadDetailModal,
  selectedLead,
}) => {
  //!INSTANCES
  const classes = useStyles();
  return (
    <Dialog open={isLeadDetailModal} onClose={closeLeadDetailModal}>
      <DialogTitle>Lead Detail </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              className={`${classes.textFieldMarginBottom}`}
              value={selectedLead?.firstName ?? 'N/A'}
              label="FIRST NAME"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.lastName ?? 'N/A'}
              label="LAST NAME"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.companyName ?? 'N/A'}
              label="COMPANY NAME"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.email ?? 'N/A'}
              label="EMAIL"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.phoneNumber ?? 'N/A'}
              label="PHONE NUMBER"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.Profession ?? 'N/A'}
              label="PROFESSION"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.panNumber ?? 'N/A'}
              label="PAN NUMBER"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.message ?? 'N/A'}
              label="MESSAGE"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.packageType ?? 'N/A'}
              label="PACKAGE TYPE"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <Divider />

            <Typography align="center" style={{ margin: '5px 0' }}>
              Billing Details
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_name ?? 'N/A'}
              label="BILLING NAME"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.merchant_param3 ?? 'N/A'}
              label="MERCHANT PARAM 3"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.merchant_param1 ?? 'N/A'}
              label="MERCHANT PARAM 1"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_email ?? 'N/A'}
              label="BILLING EMAIL"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.number ?? 'N/A'}
              label="NUMBER"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.package ?? 'N/A'}
              label="PACKAGE"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_address ?? 'N/A'}
              label="BILLING ADDRESS"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_city ?? 'N/A'}
              label="BILLING CITY"
            />
          </Grid>
          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_state ?? 'N/A'}
              label="BILLING STATE"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_country ?? 'N/A'}
              label="BILLING COUNTRY"
            />
          </Grid>

          <Grid item xs={12} className={`${classes.textFieldMarginBottom}`}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedLead?.customData?.billing_zip ?? 'N/A'}
              label="BILLING ZIP"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const LeadListView = ({ leads }) => {
  const [filteredArray, setFilteredArray] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    range: '',
    project: 0,
  });
  const [selectedLead, setSelectedLead] = useState(null);
  const [isLeadDetailModal, setIsLeadDetailModal] = useState(false);
  const [currentSelectedButton, setCurrentSelectedButton] = useState(1);

  //! 1 = Total Lead, 2 = Assigned Lead, 3 = Unassigned Lead

  //!INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  const { addToast } = useToasts();

  //! SELECTORS
  const { assignLoading } = useSelector(leadReducer);

  //! METHODS
  const handleAssignLead = async (id) => {
    let resp = await dispatch(assignLead(id));
    if (resp.status === 200) {
      addToast('Lead Assigned Successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      dispatch(getLeads());
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      const data = filteredArray.length ? filteredArray : leads;
      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }
    if (value === '') {
      setFilteredArray([]);
    }
  };

  const openLeadDetailModal = (lead) => {
    let customData = JSON.parse(lead?.customData);

    setSelectedLead({ ...lead, customData });

    setIsLeadDetailModal(true);
  };
  const closeLeadDetailModal = () => {
    setSelectedLead(null);

    setIsLeadDetailModal(false);
  };

  const handleLeadFilter = (number) => {
    setCurrentSelectedButton(number);
    if (number === 1) {
      setFilteredArray([]);
    } else if (number === 2) {
      let newData = leads?.filter((item) => item.isConverted);
      setFilteredArray(newData);
    } else if (number === 3) {
      let newData = leads?.filter((item) => !item.isConverted);
      setFilteredArray(newData);
    }
  };

  return (
    <Grid container justify="flex-start" alignItems="center" xs={12}>
      <LeadDetailModalComponent
        isLeadDetailModal={isLeadDetailModal}
        closeLeadDetailModal={closeLeadDetailModal}
        selectedLead={selectedLead}
      />
      {leads?.length && (
        <Grid
          container
          md={5}
          sm={12}
          style={{ paddingBottom: '10px' }}
          alignItems="center"
        >
          <Grid item style={{ paddingRight: '10px' }}>
            <Button
              className={`${classes.buttonStyle} ${
                currentSelectedButton === 1
                  ? classes.selectedButton
                  : classes.filterButton
              } `}
              size="small"
              variant="outlined"
              onClick={() => handleLeadFilter(1)}
            >
              {' '}
              Total Leads:{' '}
              <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                {leads?.length}
              </span>
            </Button>
          </Grid>
          <Grid item style={{ paddingRight: '10px' }}>
            <Button
              className={`${classes.buttonStyle} ${
                currentSelectedButton === 2
                  ? classes.selectedButton
                  : classes.filterButton
              }`}
              size="small"
              variant="outlined"
              onClick={() => handleLeadFilter(2)}
            >
              {' '}
              Assigned Leads:{' '}
              <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                {leads?.filter((item) => item?.isConverted)?.length}
              </span>
            </Button>
          </Grid>
          <Grid item className={classes.marginTop}>
            <Button
              className={`${classes.buttonStyle} ${
                currentSelectedButton === 3
                  ? classes.selectedButton
                  : classes.filterButton
              }`}
              size="small"
              variant="outlined"
              onClick={() => handleLeadFilter(3)}
            >
              Unassigned Leads:{' '}
              <span style={{ fontWeight: 700, paddingLeft: '10px' }}>
                {leads?.filter((item) => !item?.isConverted)?.length}
              </span>
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} container justify="flex-end" alignItems="center">
        <Grid item style={{ padding: '0 5px' }}>
          <small>Search:</small>
        </Grid>
        <Grid item style={{ position: 'relative' }} md={4} xs={8}>
          <SearchInput
            search={filters.search}
            handleSearch={handleSearch}
            // className='font12'
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: '1rem' }}
        className={classes.overflow}
      >
        <Table
          // stickyHeader
          style={{
            position: 'relative',
            opacity: assignLoading ? '.3' : 1,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.headerCellText1}>
                #
              </TableCell>
              <TableCell align="left" className={classes.headerCellText1}>
                FIRST NAME
              </TableCell>
              <TableCell align="left" className={classes.headerCellText1}>
                LAST NAME
              </TableCell>
              <TableCell align="left" className={classes.headerCellText1}>
                COMPANY NAME
              </TableCell>
              <TableCell align="left" className={classes.headerCellText1}>
                EMAIL
              </TableCell>
              <TableCell align="left" className={classes.headerCellText1}>
                PROFESSION{' '}
              </TableCell>

              <TableCell align="left" className={classes.headerCellText1}>
                PHONE NUMBER{' '}
              </TableCell>

              <TableCell align="left" className={classes.headerCellText1}>
                SCOPE
              </TableCell>

              <TableCell align="left" className={classes.headerCellText1}>
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              >
                <CircularProgress style={{ color: '#81B734' }} />
              </div>
            )}
            {leads?.length &&
            filteredArray.length === 0 &&
            filters.search === '' ? (
              leads?.map((val, index) => (
                <TableRow hover key={index}>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.firstName ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.lastName ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.companyName ?? 'N/A'}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.email}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.Profession}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.phoneNumber}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.scopeOfWork ?? 'N/A'}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="13%"
                  >
                    <Grid container direction="column">
                      {val.isConverted ? (
                        <Button
                          // disabled
                          className={`${classes.buttonPurpleOpacity} ${classes.marginBottom}`}
                          style={{
                            padding: '.6rem 1rem',
                            borderRadius: '9px',
                            fontSize: '11px',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                            marginBottom: '12px',
                            backgroundColor: 'RGBA(144, 141, 166, 0.6)',
                            color: 'white',
                          }}
                          size="small"
                          disabled={val.isConverted ?? false}
                          // startIcon={<ReceiptIcon />}
                          onClick={() => handleAssignLead(val.leadId)}
                        >
                          Assigned
                        </Button>
                      ) : (
                        <Button
                          // disabled
                          className={`${classes.buttonPurpleOpacity} ${classes.marginBottom}`}
                          style={{
                            padding: '.6rem 1rem',
                            borderRadius: '9px',
                            fontSize: '11px',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                            marginBottom: '12px',
                            backgroundColor: '#A179F2',
                            color: 'white',
                          }}
                          size="small"
                          // disabled={val.isConverted ?? false}
                          // startIcon={<ReceiptIcon />}
                          onClick={() => handleAssignLead(val.leadId)}
                        >
                          Assign
                        </Button>
                      )}
                      <Button
                        // disabled
                        className={`${classes.buttonGreen} `}
                        style={{
                          padding: '.6rem 1rem',
                          borderRadius: '9px',
                          fontSize: '11px',
                          fontWeight: 500,
                          textTransform: 'capitalize',
                        }}
                        size="small"
                        onClick={() => openLeadDetailModal(val)}
                      >
                        View
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            ) : filters.search !== '' && filteredArray.length === 0 ? (
              <TableRow>No Search Found!</TableRow>
            ) : (
              filteredArray?.map((val, index) => (
                <TableRow hover key={index}>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.firstName ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.lastName ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.companyName ?? 'N/A'}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.email}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="15%"
                  >
                    {val.Profession}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.phoneNumber}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    {val.scopeOfWork ?? 'N/A'}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="13%"
                  >
                    <Grid container direction="column">
                      {val.isConverted ? (
                        <Button
                          // disabled
                          className={`${classes.buttonPurpleOpacity} ${classes.marginBottom}`}
                          style={{
                            padding: '.6rem 1rem',
                            borderRadius: '9px',
                            fontSize: '11px',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                            marginBottom: '12px',
                            backgroundColor: 'RGBA(144, 141, 166, 0.6)',
                            color: 'white',
                          }}
                          size="small"
                          disabled={val.isConverted ?? false}
                          // startIcon={<ReceiptIcon />}
                          onClick={() => handleAssignLead(val.leadId)}
                        >
                          Assigned
                        </Button>
                      ) : (
                        <Button
                          // disabled
                          className={`${classes.buttonPurpleOpacity} ${classes.marginBottom}`}
                          style={{
                            padding: '.6rem 1rem',
                            borderRadius: '9px',
                            fontSize: '11px',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                            marginBottom: '12px',
                            backgroundColor: '#A179F2',
                            color: 'white',
                          }}
                          size="small"
                          // disabled={val.isConverted ?? false}
                          // startIcon={<ReceiptIcon />}
                          onClick={() => handleAssignLead(val.leadId)}
                        >
                          Assign
                        </Button>
                      )}
                      <Button
                        // disabled
                        className={`${classes.buttonGreen} `}
                        style={{
                          padding: '.6rem 1rem',
                          borderRadius: '9px',
                          fontSize: '11px',
                          fontWeight: 500,
                          textTransform: 'capitalize',
                        }}
                        size="small"
                        onClick={() => openLeadDetailModal(val)}
                      >
                        View
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default LeadListView;
