import React, { useContext } from 'react';
import {
  Button,
  createMuiTheme,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  responsiveFontSizes,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UserContext from 'src/context/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  text: {
    color: '#888',
    fontWeight: 500,
  },
  container: {
    backgroundColor: '#fff',
    padding: '1.3rem 10px',
    boxShadow: '3px 3px 5px 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    margin: '10px',
    cursor: 'pointer',
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  textArea: {
    height: '50px',
  },
}));

const EmployementCard = ({ setEmploymentCard }) => {
  const [userData, setUserData] = useContext(UserContext);

  const classes = useStyles();

  let theme = createMuiTheme({
    typography: {
      fontFamily: 'Roboto',
    },
  });
  theme = responsiveFontSizes(theme);
  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        style={{ marginLeft: '20px' }}
        onClick={() => setEmploymentCard(false)}
      >
        Back
      </Button>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Register of Workmen Employed by Contractor
          </Typography>
        </Grid>

        <Grid container xs={10} style={{ marginTop: '2rem' }}>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Name and Address of Contractor</Typography>
            </Grid>
            <Grid item xs={9}>
              <textarea
                className="textArea"
                value={userData?.user.fullName}
              ></textarea>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>
                Name and address of establishment in/under which contract is
                carried on
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <textarea className="textArea"></textarea>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Name and location of work </Typography>
            </Grid>
            <Grid item xs={9}>
              <textarea className="textArea"></textarea>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Name and address of Principal Employer</Typography>
            </Grid>
            <Grid item xs={9}>
              <textarea className="textArea"></textarea>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Serial No</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Name and Surname of Workmen</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Age</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Sex</Typography>
            </Grid>
            <Grid item xs={9}>
              <div>
                <FormControlLabel
                  style={{ marginLeft: '20px' }}
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Father's/Husband's Name</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Nature of Employment/ Designation</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>
                Permanent Home address of workmen Village and Tahsil/Taluk and
                District
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <textarea className="textArea"></textarea>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Local Address</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Date of Commencement of Employment</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Signature or Thumb Impression of Workman</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Date of Termination of Employment</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Reasons for Termination</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                variant="outlined"
                style={{ marginLeft: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              <Typography>Remarks</Typography>
            </Grid>
            <Grid item xs={9}>
              <textarea className="textArea"></textarea>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            xs={12}
            style={{ marginBottom: '15px' }}
          >
            <Grid item xs={3}>
              {/* <Typography>Remarks</Typography> */}
            </Grid>
            <Grid item xs={9}>
              <Button
                className={classes.buttonPurple}
                style={{ marginLeft: '20px' }}
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default EmployementCard;
