import React, { useEffect } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import uncertifiedWork from '../assests/uncertifiedWork.png';
import workmanshipCost from '../assests/poorWorkmanship.png';
import UnbilledWorkers from '../assests/unbilledWorkers.png';
import materialDefect from '../assests/materialDefects.png';
import variationCost from '../assests/variationCost.png';
import changeRequest from '../assests/changeRequest.png';
import { useDispatch, useSelector } from 'react-redux';
import { getBOQMetrics, productivityReducer } from 'src/redux/productivity';

const useStyles = makeStyles((theme) => ({
  cardPaddingRight: {
    paddingRight: '2rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '90%',
  },

  caroselHeight: {
    height: '38em',
  },
  table: {
    minWidth: 650,
  },
  DARAndDLRColums: {
    color: '#c2c1cdff',
    fontSize: '12px',
  },
  marginRight: {
    marginRight: '10px',
  },
  greenContainer: {
    backgroundColor: '#81B734',
    height: '250px',
  },
  containerPadding: {
    padding: '1em',
  },

  progressBar: {
    padding: '10px',
    borderRadius: '40px',
    height: '25px',
    '& .MuiLinearProgress-bar': {
      background: 'linear-gradient(to right, #4426a8ff, #c0429eff)',
    },
  },
  progressBarColorPrimary: {
    backgroundColor: 'white', // Set the background color of the bar
  },

  customSelect: {
    border: 'none',
    borderBottom: 'none',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  label: {
    color: '#8E99A8',
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
  },
}));

const TielsComponenet = ({ currentProject }) => {
  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();

  //! SELECTORS
  const { metrics, boqProgressLoading } = useSelector(productivityReducer);
  //! LIFECYCLE
  useEffect(() => {
    if (currentProject?.projectId) {
      dispatch(getBOQMetrics(currentProject?.projectId));
    }
  }, [currentProject?.projectId]);

  let cardData = [
    // {
    //   title: 'Uncertified Works',
    //   count: `₹ 0`,
    //   icon: <img src={uncertifiedWork} alt="issue" />,
    //   filter: 'UncertifiedWorkers',
    // },
    // {
    //   title: ' Unbilled Works',
    //   count: `₹ 0`,
    //   icon: <img src={UnbilledWorkers} alt="issue" />,
    //   filter: 'UnbilledWorkers',
    // },
    {
      title: ' Poor Workmanship',
      count: `₹ ${metrics?.workmanshipCost?.toLocaleString() ?? 0}`,
      icon: <img src={workmanshipCost} alt="issue" />,
      filter: 'WorkmanshipCost',
    },
    {
      title: 'Material Defects',
      count: `₹ ${metrics?.qualityCost?.toLocaleString() ?? 0}`,
      icon: <img src={materialDefect} alt="defect" />,
      filter: 'MaterialDefect',
    },
    {
      title: 'Variations',
      count: `₹ ${metrics?.excessCost?.toLocaleString() ?? 0}`,
      icon: <img src={variationCost} alt="defect" />,
      filter: 'Variations',
    },
    {
      title: 'Change Requests',
      count: `₹ 0`,

      icon: <img src={changeRequest} alt="defect" />,
      filter: 'changeRequest',
    },
  ];

  return (
    <Grid item container>
      <Grid item container>
        {cardData.map((val, index) => (
          <Grid
            item
            container
            md={3}
            xs={12}
            style={{
              padding: '1em 0',
              paddingRight: index === 0 ? '10px' : '0',
              paddingLeft:
                index === 2 || index === 3 || index === 4 || index === 5
                  ? '10px'
                  : '0',
            }}
            key={index + 1}
            alignItems="center"
            className={`${index === 0 ? classes.padRight : classes.padLeft}`}
            // onClick={() => openGCListModal(val.filter)}
          >
            <Card
              elevation={6}
              style={{
                borderRadius: 10,
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <CardContent style={{ padding: '10px' }}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  item
                  xs={12}
                >
                  <Grid item xs={7}>
                    <Grid item xs>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 11,
                          color: '#B2BEC7',
                        }}
                      >
                        {val.title}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      justify="space-between"
                      item
                      xs={12}
                    >
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 15,
                            fontWeight: '400',
                          }}
                        >
                          {boqProgressLoading ? (
                            <CircularProgress
                              style={{
                                color: '#81B734',
                                width: '25px',
                                height: '25px',
                              }}
                            />
                          ) : (
                            val.count ?? 0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{
                        background: '#A179F2',
                        height: 50,
                        width: 50,
                        borderRadius: 10,
                      }}
                    >
                      {val.icon}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TielsComponenet;
