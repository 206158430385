import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import arrow from '../../assests/arrow.png';
import { useToasts } from 'react-toast-notifications';

const ScopeOfWorkModal = (props) => {
  const {
    classes,
    open,
    handleClose,
    handleSubmit,
    sow,
    // unitList,
  } = props;
  const { addToast } = useToasts();
  const [SOWItems, setSOWItems] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (sow) {
      setSOWItems(sow);
    }
  }, [sow]);

  function handleMeasurementChange(label, value) {
    // const touched = value || SOWItems[label].unit;
    // const error = touched && (!value || !SOWItems[label].unit);
    const touched = Boolean(value);

    setSOWItems((prevState) => ({
      ...prevState,
      [label]: {
        ...prevState[label],
        measurement: value,
        touched,
        // error,
      },
    }));
  }

  // const handleUnitChange = (label, value) => {
  //   let touched;
  //   if (!value && !SOWItems[label].measurement) {
  //     touched = false;
  //   } else {
  //     touched = true;
  //   }
  //   const unit = value && unitList.find((unit) => unit.unitId === value);
  //   const unitObject = unit ? { unitId: value, code: unit.code } : null;
  //   setSOWItems((prevState) => {
  //     return {
  //       ...prevState,
  //       [label]: {
  //         ...prevState[label],
  //         unit: unitObject,
  //         touched,
  //         error: touched && (!value || !prevState[label].measurement)
  //       },
  //     };
  //   });
  // };

  const renderTitle = () => {
    return (
      <DialogTitle>
        <Grid
          container
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography style={{ fontSize: 24 }}>Scope Of Work</Typography>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
    );
  };

  const renderRow = (label) => {
    return (
      <Grid
        container
        style={{ padding: '5px 30px', justifyContent: 'space-between' }}
        alignItems="center"
      >
        {renderLabel(label)}
        {renderFields(label)}
      </Grid>
    );
  };

  const renderLabel = (label) => {
    const { icon, iconKey } = SOWItems[label];
    return (
      <Grid item container xs={12} sm={6}>
        <Grid item style={{ width: 45 }}>
          <img
            loading="lazy"
            src={icon}
            style={{ width: 25, marginRight: 5 }}
            alt={`${iconKey}`}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderFields = (label) => {
    const {
      touched,
      measurement,
      // unit
    } = SOWItems[label];

    // dynamic styles
    const justifyContent = isSmallScreen ? 'start' : 'center';
    const marginLeft = isSmallScreen ? 45 : 0;

    return (
      <Grid
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        style={{ marginLeft, justifyContent }}
      >
        <Grid item sm={10} xs={10}>
          {renderMeasurmentField(label, touched && !measurement)}
        </Grid>
        {/* <Grid item sm={5} xs={6} style={{ marginLeft: isSmallScreen ? 0 : 15 }}>
        {renderUnitField(label, touched && !unit)}
        </Grid> */}
      </Grid>
    );
  };

  //  const renderUnitField = (label, showError) => {
  //   const { unit } = SOWItems[label];
  //    const displayId = unit ? unit.unitId : '';
  //   return (
  //     <TextField
  //       select
  //       label="Unit"
  //       size="small"
  //       variant="filled"
  //       InputProps={{
  //         disableUnderline: !showError,
  //         style: { borderRadius: '7px' },
  //       }}
  //       InputLabelProps={{
  //         style: { color: '#8E99A8' },
  //       }}
  //       error={showError}
  //       value={displayId}
  //       style={{ width: '125px' }}
  //       required
  //       className={classes.error}
  //       onChange={(event) => handleUnitChange(label, event.target.value)}
  //     >
  //       <MenuItem value="">
  //         <em>None</em>
  //       </MenuItem>
  //       {unitList?.length ? (
  //         unitList.map((item) => (
  //           <MenuItem key={item.unitId} value={item.unitId} style={{ fontFamily: 'Roboto' }}>
  //             {item.code}
  //           </MenuItem>
  //         ))
  //       ) : null}
  //     </TextField>
  //   );
  // };

  const renderMeasurmentField = (label, showError) => {
    const { measurement } = sow[label];
    return (
      <TextField
        id="filled-basic"
        label="Cost"
        size="small"
        variant="filled"
        error={showError}
        InputProps={{
          disableUnderline: !showError,
          style: {
            borderRadius: '7px',
          },
        }}
        type="tel"
        defaultValue={measurement || ''}
        InputLabelProps={{
          style: {
            color: '#8E99A8',
          },
        }}
        onChange={(e) => handleMeasurementChange(label, e.target.value)}
        style={{ width: '185px' }}
        required
      />
    );
  };

  const renderDialogFooter = () => {
    return (
      <DialogActions className={classes.footerShadow}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ margin: '15px 20px' }}
        >
          <Button
            className={classes.buttonPurple}
            style={{
              fontSize: 12,
              padding: '15px 45px',
              borderRadius: 8,
            }}
            endIcon={<img src={arrow} alt="submit" />}
            onClick={() => {
              if (
                Object.keys(SOWItems || {}).every(
                  (label) => !SOWItems[label].measurement
                )
              ) {
                return addToast(
                  'Please provide cost for atleast one scope of work.',
                  {
                    appearance: 'error',
                    autoDismiss: true,
                  }
                );
              }
              handleSubmit(SOWItems);
              handleClose();
            }}
          >
            Submit{' '}
          </Button>
        </Grid>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      className={classes.modal}
      PaperProps={{ style: { width: 570, height: '80%' } }}
    >
      {renderTitle()}
      <Grid style={{ maxWidth: 750, overflow: 'auto' }}>
        {SOWItems &&
          Object.keys(SOWItems).map((sowLabel) => renderRow(sowLabel))}
      </Grid>
      {renderDialogFooter()}
    </Dialog>
  );
};

export default ScopeOfWorkModal;
