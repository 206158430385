import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import React, { memo, useRef, useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';

import useAccessPerView from 'src/common/Access';
import { useDispatch } from 'react-redux';
import { closeCostCode } from 'src/redux/productivity';
//import toast from 'react-hot-toast';

export const DynamicCell = (props) => {
  const { value, fontSize } = props;
  const [showMoreEnabled, setShowMoreEnabled] = useState(false);

  const renderActionLabel = () => {
    if (showMoreEnabled) {
      return 'show less';
    }
    return 'show more';
  };

  const handleToggle = (event) => {
    event.stopPropagation();
    setShowMoreEnabled((prev) => !prev);
  };

  const renderValue = () => {
    if (showMoreEnabled) {
      return value;
    }
    return value.length > 15
      ? value.slice(0, 10)
      : value?.split(' ')?.slice(0, 10).join(' ');
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      justify={showMoreEnabled ? 'space-between' : 'left'}
      style={{ gap: 5, flexWrap: 'nowrap' }}
    >
      <Typography
        style={{
          fontSize: 13,
          overflow: 'auto',
          maxHeight: 50,
          color: '#000000',
          fontWeight: 500,
        }}
        align="left"
      >
        {renderValue()}
      </Typography>
      <small
        style={{
          color: 'blue',
          cursor: 'pointer',
          fontSize: fontSize ?? 'auto',
          textWrap: 'nowrap',
        }}
        onClick={handleToggle}
      >
        {value.split(' ').length > 4 && renderActionLabel()}
      </small>
    </Grid>
  );
};

const Row = memo((props) => {
  const { classes, index, item, handleEdit, closeItem, rowColor } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState();

  const Accesses = {};
  Accesses['CHANGE_MANAGEMENT'] = useAccessPerView('CHANGE_MANAGEMENT');

  const renderIcon = () => {
    if (item.descriptions.length <= 1) {
      return <></>;
    }
    const toggleExpand = () => setIsExpanded((prev) => !prev);
    if (isExpanded) {
      return (
        <IconButton onClick={toggleExpand}>
          <ExpandLessIcon color="black" />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={toggleExpand}>
        <ExpandMoreIcon color="inherit" />
      </IconButton>
    );
  };

  const renderACONumber = () => {
    //console.log(item);
    //console.log(item?.descriptions[0]);
    //console.log(item?.descriptions[0]?.boqCode);
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <Typography
          style={{
            textWrap: 'nowrap',
            color: '#000000',
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          {item?.descriptions[0]?.boqCode}
        </Typography>
        {renderIcon()}
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <DynamicCell value={item.title} />
        {renderIcon()}
      </Grid>
    );
  };

  const getValueInLakhsString = (value) => {
    if (value < 100000) {
      return `₹ ${value}`;
    }

    const totalCostInLakhs = value / 100000;
    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const openChangeRequestMenu = (e, curRowIndex) => {
    setAnchorEl(e.currentTarget);
    setOpen(curRowIndex);
  };

  const renderRow = () => {
    //console.log(item.descriptions[0]);
    //console.log(item.descriptions[0].percentage);
    return (
      <TableRow style={props.showLabel ? { background: rowColor.bg } : {}}>
        {props.showLabel && (
          <TableCell
            align="center"
            style={{
              textWrap: 'nowrap',
              fontSize: 13,
              color: '#000000',
              fontWeight: 500,
              padding: '30px 0px',
            }}
          >
            <Grid
              className={classes.clippingLabel}
              style={{ background: rowColor.color }}
            >
              {rowColor.label}
              <span></span>
            </Grid>
          </TableCell>
        )}
        <TableCell align="center">{renderACONumber()}</TableCell>
        <TableCell style={{ fontSize: 11 }}>{renderTitle()}</TableCell>
        <TableCell align="center" style={{ fontSize: 11 }}>
          <Grid
            item
            container
            alignItems="center"
            style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
          >
            <DynamicCell value={item.descriptions[0].description} />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].unitCode}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`₹ ${item.descriptions[0].rate}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(item.descriptions[0].amount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].actualQuantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(item.descriptions[0].actualAmount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${item.descriptions[0].percentage.toFixed(1)}%`}
        </TableCell>
        {!props.showLabel && (
          <TableCell
            style={{ textWrap: 'nowrap', fontSize: 10 }}
            align="center"
            width="14%"
            justify="space-between"
          >
            {/* Only Active user and Level1 can see the row buttons */}
            {Accesses['CHANGE_MANAGEMENT'].hasAccess.LEVEL1 &&
              Accesses['CHANGE_MANAGEMENT'].isEditMode && (
                <Grid container alignItems="center" justify="center">
                  <Button
                    onClick={(e) => openChangeRequestMenu(e, index)}
                    className={classes.buttonPurpleOpacity}
                  >
                    Change Request
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open === index}
                    onClose={() => {
                      setAnchorEl(null);
                      setOpen(-1);
                    }}
                    style={{ borderRadius: 20, padding: 10 }}
                    PaperProps={{
                      style: { padding: '0px 10px', borderRadius: 12 },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        const extensible = JSON.parse(JSON.stringify(item));
                        extensible.scopeOfWork = props.sowItem.scopeOfWork;
                        extensible.scopeOfWorkId = props.sowItem.scopeOfWorkId;
                        extensible.workOrderNumber = props.boqItem.workOrder;
                        extensible.contractorId = props.contractorId;
                        extensible.curProject = props.curProject;
                        handleEdit(extensible, item.descriptions[0]);
                        setOpen(-1);
                      }}
                      className={classes.buttonGreenOpacity}
                      style={{
                        borderRadius: 5,
                        margin: '5px 0px',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Add
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const extensible = JSON.parse(JSON.stringify(item));
                        extensible.scopeOfWork = props.sowItem.scopeOfWork;
                        extensible.scopeOfWorkId = props.sowItem.scopeOfWorkId;
                        extensible.workOrderNumber = props.boqItem.workOrder;
                        extensible.contractorId = props.contractorId;
                        extensible.curProject = props.curProject;
                        extensible.editMode = true;
                        handleEdit(extensible, item.descriptions[0]);
                        setOpen(-1);
                      }}
                      className={classes.buttonPurpleOpacity}
                      style={{
                        borderRadius: 5,
                        margin: '5px 0px',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeItem(item.descriptions[0]?.contractorBOQId);
                        setOpen(-1);
                      }}
                      style={{
                        margin: '5px 0px',
                        textAlign: 'center',
                        backgroundColor: 'rgba(247, 186, 175, 0.8)',
                        color: 'rgba(196, 30, 0, 0.8)',
                        textTransform: 'capitalize',
                        borderRadius: 5,
                        '&:hover': {
                          backgroundColor: 'rgba(255, 99, 71, 0.8)',
                        },
                        justifyContent: 'center',
                      }}
                    >
                      Short Close
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderSubRows = (description, subRowIndex) => {
    const height = isExpanded ? 40 : 0;
    return (
      <TableRow
        style={{
          transition: 'height 0.3s ease',
          height,
          visibility: isExpanded ? 'visible' : 'collapse',
        }}
      >
        {props.showLabel && (
          <TableCell
            align="center"
            style={{
              textWrap: 'nowrap',
              fontSize: 13,
              color: '#000000',
              fontWeight: 500,
              textAlign: 'left',
            }}
          ></TableCell>
        )}
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
            textAlign: 'left',
          }}
        >
          {description.boqCode}
        </TableCell>
        <TableCell />
        <TableCell
          align="center"
          style={{
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            style={{
              flexWrap: 'nowrap',
              fontSize: 13,
              color: '#000000',
              fontWeight: 500,
            }}
          >
            <DynamicCell value={description.description} />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {description.unitCode}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {description.quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`₹ ${description.rate}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(description.amount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {description.actualQuantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(description.actualAmount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${description.percentage.toFixed(1)}%`}
        </TableCell>
        {!props.showLabel && (
          <TableCell
            style={{ textWrap: 'nowrap', fontSize: 10 }}
            align="center"
            width="14%"
            justify="space-between"
          >
            {/* Only Active user and Level1 can see the row buttons */}
            {Accesses['CHANGE_MANAGEMENT'].hasAccess.LEVEL1 &&
              Accesses['CHANGE_MANAGEMENT'].isEditMode && (
                <Grid container alignItems="center" justify="center">
                  <Button
                    onClick={(e) => openChangeRequestMenu(e, subRowIndex)}
                    className={classes.buttonPurpleOpacity}
                  >
                    Change Request
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open === subRowIndex}
                    onClose={() => {
                      setAnchorEl(null);
                      setOpen(-1);
                    }}
                    style={{ borderRadius: 20, padding: 10 }}
                    PaperProps={{
                      style: { padding: '0px 10px', borderRadius: 12 },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpen(-1);

                        const extensible = JSON.parse(JSON.stringify(item));
                        extensible.scopeOfWork = props.sowItem.scopeOfWork;
                        extensible.scopeOfWorkId = props.sowItem.scopeOfWorkId;
                        extensible.workOrderNumber = props.boqItem.workOrder;
                        extensible.contractorId = props.contractorId;
                        extensible.curProject = props.curProject;
                        handleEdit(extensible, item.descriptions[0]);
                      }}
                      className={classes.buttonGreenOpacity}
                      style={{
                        borderRadius: 5,
                        margin: '5px 0px',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Add
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpen(-1);
                        const extensible = JSON.parse(JSON.stringify(item));
                        extensible.scopeOfWork = props.sowItem.scopeOfWork;
                        extensible.scopeOfWorkId = props.sowItem.scopeOfWorkId;
                        extensible.workOrderNumber = props.boqItem.workOrder;
                        extensible.contractorId = props.contractorId;
                        extensible.curProject = props.curProject;
                        extensible.editMode = true;
                        handleEdit(extensible, item.descriptions[0]);
                      }}
                      className={classes.buttonPurpleOpacity}
                      style={{
                        borderRadius: 5,
                        margin: '5px 0px',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpen(-1);
                        closeItem(item.descriptions[0]?.contractorBOQId);
                      }}
                      style={{
                        margin: '5px 0px',
                        textAlign: 'center',
                        backgroundColor: 'rgba(247, 186, 175, 0.8)',
                        color: 'rgba(196, 30, 0, 0.8)',
                        textTransform: 'capitalize',
                        borderRadius: 5,
                        '&:hover': {
                          backgroundColor: 'rgba(255, 99, 71, 0.8)',
                        },
                        justifyContent: 'center',
                      }}
                    >
                      Short Close
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <>
      {renderRow()}
      {item.descriptions
        .slice(1)
        .map((description, subIndex) => renderSubRows(description, subIndex))}
    </>
  );
});

const SOWTable = memo((props) => {
  const { classes, handleEdit } = props;
  const noWrapColumns = ['WORK ORDER NO', 'COMPLETION %'];
  const TABLE_COLUMNS = [
    { label: 'COST CODE', width: '5%' },
    { label: 'TITLE', width: '5%' },
    { label: 'DESCRIPTION', width: '5%' },
    { label: 'UOW', width: '5%' },
    { label: 'QUANTITY', width: '5%' },
    { label: 'RATE', width: '5%' },
    { label: 'AMOUNT', width: '5%' },
    { label: 'ACTUAL QUANTITY', width: '5%' },
    { label: 'ACTUAL AMOUNT', width: '5%' },
    { label: '% PERCENTAGE', width: '5%' },
    { label: 'ACTIONS', width: '5%' },
  ];
  if (props.showLabel) {
    TABLE_COLUMNS.unshift({ label: 'LABEL', width: '10%' });
  }
  const colors = [
    { label: 'Rate', bg: '#e2feeb', color: '#81B734' },
    {
      label: 'Short Close',
      bg: ' linear-gradient(to top, #e2feeb, white)',
      color: '#81B734',
    },
    {
      label: 'Variation',
      bg: 'linear-gradient(to top, #ffe6e6, white)',
      color: '#FC3620',
    },
  ];

  return (
    <Table>
      <TableHead style={{ padding: '0px 8px' }}>
        <TableRow style={{ borderTop: '1px solid  rgba(1,1,1,0.05)' }}>
          {TABLE_COLUMNS.slice(0, props.showLabel ? -1 : 11).map((column) => {
            return (
              <TableCell
                style={{
                  color: '#B2BEC7',
                  fontSize: 10,
                  padding: '7px',
                  width: column.width,
                  textWrap: noWrapColumns.includes(column.label)
                    ? 'nowrap'
                    : 'balance',
                  lineHeight: '11px',
                }}
                align="center"
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'hidden' }}>
        {props.boqItem?.titles?.map((item, index) => {
          return (
            <Row
              {...props}
              index={index}
              item={item}
              classes={classes}
              handleEdit={handleEdit}
              rowColor={colors[index % 3]}
            />
          );
        })}
      </TableBody>
    </Table>
  );
});

export default SOWTable;
