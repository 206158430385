import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Zoom,
} from '@material-ui/core';
import SortSelection from 'src/components/SortSelection';
import SearchInput from 'src/components/SearchInput';
import { useLocation, useNavigate } from 'react-router';
import { pickAUser, usersReducer } from 'src/redux/users';
import { useDispatch, useSelector } from 'react-redux';
import { et } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    fontFamily: 'Roboto',
    padding: '.5rem .8rem',
    borderRadius: '10px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontFamily: 'Roboto',
    padding: '.6rem 1.5rem',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    padding: '.5rem 2rem',

    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
  },
}));

const RenderUsersList = ({
  usersList,
  setSelectedUser,
  modals,
  setModals,
  // filters,
  // setFilters,
}) => {
  const [filteredArray, setFilteredArray] = useState(null);
  const [filters, setFilters] = useState({
    sortBy: 0,
    search: '',
    filterBy: 0,
  });
  const [roles, setRoles] = useState([]);

  //! INSTANCES
  const classes = useStyles();
  const location = useLocation();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  //! SELECTORS
  const { loading } = useSelector(usersReducer);

  const handleFilters = (e) => {
    setFilters({ ...filters, sortBy: e.target.value });
    if (e.target.value == 0) {
      setFilteredArray(null);
    } else {
      setFilteredArray(
        usersList.filter((user) => user.userTypeId === e.target.value)
      );
    }
  };

  const handleFilterBy = (e) => {
    setFilters({ ...filters, filterBy: e.target.value });
    if (e.target.value == 0) {
      setFilteredArray(null);
    } else {
      setFilteredArray(
        usersList.filter((user) => user.leadTypeId === e.target.value)
      );
    }
  };

  const search = (e) => {
    const { value } = e.target;
    setFilters({ ...filters, search: value });
    const data = usersList.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setFilteredArray(data);
  };
  const [developerUsers] = useState([
    { value: 'SiteAdmin', name: 'Site Admin' },
    { value: 'ProjectManager', name: 'Project Manager' },
    { value: 'Supervisor', name: 'Supervisor' },
  ]);

  const [contractorUsers] = useState([
    { value: 'JobCreator', name: 'Job Creator' },
    // { value: 'ProjectManager', name: 'Project Manager' },
    // { value: 'Supervisor', name: 'Supervisor' },
  ]);
  const [adminUsers] = useState([
    { value: 'SiteAdmin', name: 'Site Admin' },
    { value: 'ProjectManager', name: 'Project Manager' },
    { value: 'Supervisor', name: 'Supervisor' },
    { value: 'OnboardingOfficer', name: 'Onboarding Officer' },
    { value: 'PayrollOfficer', name: 'Payroll Officer' },

    // { value: 'Developer', name: 'Developer' },
    // { value: 'Contractor', name: 'Contractor' },
    // { value: 'SuperAdmin', name: 'Super Admin' },
  ]);

  //! LIFE-CYCLE
  useEffect(() => {
    if (location.pathname?.includes('/developer'))
      return setRoles(developerUsers);
    if (location.pathname?.includes('/contractor'))
      return setRoles(contractorUsers);
    if (location.pathname?.includes('/app')) return setRoles(adminUsers);
  }, []);

  //!METHOD
  const handleManageUser = (user) => {
    dispatch(pickAUser(user));
    navigate('detail');
  };
  return (
    <Grid container style={{ marginTop: '2em' }} justify="center">
      {loading ? (
        <div
          style={{
            textAlign: 'center',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <CircularProgress style={{ color: '#81B734' }} />
        </div>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={2}
            style={{
              background: '#f0f3f5',
              border: '1px solid #ddd',
              // padding: '0 1rem',
            }}
            // xs={12}
          >
            <Grid
              item
              md={3}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                {/* <InputLabel style={{ color: '#45494c', fontSize: '15px' }}> */}
                <small className="font14">Filter By:</small>
                {/* </InputLabel> */}
              </Grid>
              <Grid item xs={8}>
                <SortSelection
                  initialValue="Filter"
                  // name={filters.sortBy}
                  handleFilters={handleFilterBy}
                  value={filters.filterBy}
                  data={[
                    {
                      value: 'LabourContractor',
                      label: 'Labour Contractor',
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                {/* <InputLabel style={{ color: '#45494c', fontSize: '15px' }}> */}
                <small className="font14">Sort By:</small>
                {/* </InputLabel> */}
              </Grid>
              <Grid item xs={8}>
                <SortSelection
                  // name={filters.sortBy}
                  handleFilters={handleFilters}
                  value={filters.sortBy}
                  data={roles?.map((role) => ({
                    value: role.value,
                    label: role.name,
                  }))}
                />
              </Grid>
            </Grid>

            <Grid
              md={5}
              xs={12}
              item
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={2}>
                {/* <InputLabel style={{ color: '#45494c', fontSize: '15px' }}> */}
                <small className="font14">Search:</small>
                {/* </InputLabel> */}
              </Grid>
              <Grid item md={10} xs={8} style={{ position: 'relative' }}>
                {' '}
                <SearchInput search={filters.search} handleSearch={search} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.overflow}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.headerCellText1}>
                    USER ID
                  </TableCell>
                  <TableCell align="left" className={classes.headerCellText1}>
                    USERNAME
                  </TableCell>
                  <TableCell align="left" className={classes.headerCellText1}>
                    EMAIL
                  </TableCell>
                  <TableCell align="left" className={classes.headerCellText1}>
                    ROLE
                  </TableCell>
                  <TableCell align="left" className={classes.headerCellText1}>
                    ACTIONS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredArray?.length ? (
                  filteredArray?.map((val) => (
                    <TableRow hover key={val.userId}>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        <Grid
                          container
                          alignItems="left"
                          justify="flex-start"
                          item
                          xs={12}
                        >
                          {/* <Grid item xs={6}>
														<img
															src='/static/images/bettamintImages/userpic.png'
															alt='orderProfile'
															style={{
																width: '30px',
																height: '30px',
																marginRight: 4,
															}}
														/>
													</Grid> */}
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val.userId}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        {val.username}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        {val.emailAddress}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        {val.userTypeId}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                        width="25%"
                      >
                        <Grid container justify="space-between">
                          <Button
                            size="small"
                            className={classes.buttonGreen}
                            style={{ fontSize: 12, marginBottom: '.5rem' }}
                            onClick={() => handleManageUser(val)}
                          >
                            Manage User
                          </Button>
                          <Button
                            className={classes.buttonPurpleOpacity}
                            style={{ fontSize: 12, marginBottom: '.5rem' }}
                            size="small"

                            //   onClick={() => {
                            //     setEdit(true);
                            //     setShowCard(false);
                            //     // setCreateNewProject(false);
                            //   }}
                          >
                            History
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : filters.search !== '' || filteredArray?.length === 0 ? (
                  <TableRow>No Search Found!</TableRow>
                ) : (
                  usersList?.map((val) => (
                    <TableRow hover key={val.userId}>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        <Grid
                          container
                          alignItems="left"
                          justify="flex-start"
                          item
                          xs={12}
                        >
                          {/* <Grid item xs={6}>
														<img
															src='/static/images/bettamintImages/userpic.png'
															alt='orderProfile'
															style={{
																width: '30px',
																height: '30px',
																marginRight: 4,
															}}
														/>
													</Grid> */}
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val.userId}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        {val.username}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        {val.emailAddress}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                      >
                        {val.userTypeId}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headerCellText}
                        width="25%"
                      >
                        <Grid container justify="space-between">
                          <Button
                            size="small"
                            className={classes.buttonGreen}
                            style={{ fontSize: 12, marginBottom: '.5rem' }}
                            onClick={() => handleManageUser(val)}
                          >
                            Manage User
                          </Button>
                          <Button
                            className={classes.buttonPurpleOpacity}
                            style={{ fontSize: 12, marginBottom: '.5rem' }}
                            size="small"

                            //   onClick={() => {
                            //     setEdit(true);
                            //     setShowCard(false);
                            //     // setCreateNewProject(false);
                            //   }}
                          >
                            History
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default RenderUsersList;
