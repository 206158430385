import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

import { getProjects, projectReducer } from 'src/redux/projects';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import moment from 'moment';
import { getContractors, usersReducer } from 'src/redux/users';
import { createFieldNoteEntry, fieldNoteReducer } from 'src/redux/fieldNote';
import toast, { Toaster } from 'react-hot-toast';
import { getScopeList, productivityReducer } from 'src/redux/productivity';
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },

  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  input1: {
    display: 'none',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  inputFieldsContainer: {
    paddingRight: '1rem',
    // marginTop: 20,
  },

  bankAccountNumberField: {
    paddingRight: '1rem',
  },
  paddingRight: {
    paddingRight: '1rem',
  },
  commonStyleInput: {
    paddingBottom: '1.5rem',
  },
  imageCard: {
    width: '300px',
    height: '250px',
    backgroundColor: 'white',
    border: '1px solid #ddd',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  paddingLeft: {
    paddingLeft: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    inputFieldsContainer: {
      paddingRight: '0px',
    },
    bankAccountNumberField: {
      paddingRight: '0px',
      paddingBottom: '10px',
    },
    paddingRight: {
      paddingRight: '0',
    },
    imageCard: {
      width: '100%',
      padding: '10px',
    },
    paddingLeft: {
      paddingLeft: '0',
    },
  },
}));
const EditFieldNote = ({ fromModal, closeModal }) => {
  const [preview, setPreview] = useState(null);

  //! INSTANCES
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { contractorList } = useSelector(usersReducer);
  const { projectsList } = useSelector(projectReducer);
  const { loading, selectedFieldNote } = useSelector(fieldNoteReducer);
  const { scopeList } = useSelector(productivityReducer);

  //! LIFE CYCLE
  useEffect(() => {
    dispatch(getScopeList());
    dispatch(getContractors(selectedFieldNote?.project?.projectId));
  }, []);
  useEffect(() => {
    dispatch(getProjects());
  }, []);

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    scopeOfWorkerId: yup.string().required('scopeOfWork is required'),
    description: yup.string().required('description is required'),
    date: yup.string().required('date is required'),
    time: yup.string().required('time is required'),
    projectId: yup.string().required('Project is required'),
    contractorId: yup.string().required('Contractor is required'),

    location: yup.string().required('location is required'),
  });
  var formik = useFormik({
    initialValues: {
      scopeOfWorkerId: selectedFieldNote?.scopeOfWorkerId ?? '',
      description: selectedFieldNote?.description ?? '',
      date: moment(selectedFieldNote?.dateTime).format('YYYY-MM-DD'),
      time: moment(selectedFieldNote?.dateTime).format('HH:MM'),
      contractorId: selectedFieldNote?.contractor?.userId ?? '',
      location: selectedFieldNote?.location ?? '',
      image: '',
      projectId: selectedFieldNote?.project?.projectId ?? '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const checkArrayValues = Object.values(values);
      if (checkArrayValues.includes(' ')) {
        return;
      }
      const { date, time, image, ...rest } = values;

      const formData = new FormData();
      for (const key in rest) {
        if (rest.hasOwnProperty(key)) {
          formData.append(key, rest[key]);
        }
      }
      formData.append(
        'DateTime',
        moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
      );
      formData.append('FieldNoteId', selectedFieldNote.fieldNoteId);
      if (preview) {
        formData.append('Image', image);
      }

      let resp = await dispatch(createFieldNoteEntry(formData));
      if (resp?.status === 200) {
        toast.success('Field Note Updated Successfully!', {
          position: 'top-right',
        });
        if (fromModal) {
          return closeModal();
        }
        navigate('/app/field-notes');
      } else {
        toast.error('Field Note Update Unsuccessful!', {
          position: 'top-right',
        });
      }
    },
  });

  const handleImage = (e) => {
    // let selectedImage = e.target.files[0];

    if (e.target.files.length) {
      setPreview(URL?.createObjectURL(e.target.files[0]));
      formik.setFieldValue('image', e.target.files[0], true);
      // setRotation(0); // Reset rotation angle
    }
  };
  return (
    <Grid
      container
      style={{
        padding: '0 10px',
      }}
    >
      <Grid container justify="space-between" style={{ marginBottom: '2rem' }}>
        <Grid item style={{ fontWeight: 700, fontSize: '16px' }}>
          <Typography variant="h6">Update Field Note</Typography>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="flex-start"
          style={{
            marginTop: '1rem',
          }}
        >
          <Grid
            item
            md={3}
            xs={12}
            // className={classes.paddingLeft}
            container
            direction="column"
          >
            {/* Profile */}
            <Grid item>
              <input
                accept="image/*"
                className={classes.input1}
                id="icon-button-file"
                type="file"
                onChange={handleImage}
              />

              <label
                htmlFor="icon-button-file"
                className={classes.ImagePlaceHolder}
              >
                {selectedFieldNote?.imageUrl && !preview ? (
                  <img
                    src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${selectedFieldNote.imageUrl}`}
                    alt="project url"
                    style={{
                      width: '300px',
                      height: '250px',
                      borderRadius: '10px',
                      marginBottom: '10px',
                    }}
                  />
                ) : preview ? (
                  <img
                    src={`${preview}`}
                    alt="project url"
                    style={{
                      width: '300px',
                      height: '250px',
                      borderRadius: '10px',
                      marginBottom: '10px',
                    }}
                  />
                ) : (
                  <Grid
                    container
                    className={classes.imageCard}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Grid item>
                        <ImageIcon
                          style={{
                            color: 'RGB(183, 194, 202)',
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          color: 'RGB(151, 196, 87)',
                          fontSize: '12px',
                        }}
                      >
                        +{' '}
                        <span style={{ textDecoration: 'underline' }}>
                          UPLOAD PICTURE
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </label>
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                }}
              >
                {' '}
                {formik.touched.image && formik.errors.image}
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={8}
            xs={12}
            style={{
              padding: '10px 0px 30px 10px',
            }}
            alignItems="space-between"
            className={`${classes.inputFieldsContainer} ${classes.marginLeft}`}
          >
            <Grid item xs={12} className={classes.commonStyleInput} container>
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                <TextField
                  select
                  label="SCOPE OF WORK"
                  size="small"
                  style={{ fontFamily: 'Roboto' }}
                  // placeholder='----'
                  variant="filled"
                  fullWidth
                  // style={{ width: "300px" }}
                  className={classes.commonStyleInput}
                  id="scopeOfWorkerId"
                  name="scopeOfWorkerId"
                  value={formik.values.scopeOfWorkerId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scopeOfWorkerId &&
                    Boolean(formik.errors.scopeOfWorkerId)
                  }
                  helperText={
                    formik.touched.scopeOfWorkerId &&
                    formik.errors.scopeOfWorkerId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value="">Select Scope</MenuItem>

                  {scopeList?.length > 0 &&
                    scopeList?.map((sow, index) => (
                      <MenuItem
                        key={sow.scopeOfWorkId}
                        value={sow.scopeOfWorkId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {sow.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid
                // container
                item
                md={6}
                xs={12}
                className={classes.commonStyleInput}
                // justify='center'
              >
                <TextField
                  inputProps={{ style: { fontFamily: 'Roboto' } }}
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  variant="filled"
                  label="DESCRIPTION"
                  size="small"
                  fullWidth
                  // value={value}
                  // onChange={handleInput}
                  style={{
                    width: '100%',
                    // padding: '10px 14px',
                    // background: 'none',
                    borderRadius: '4px',
                    // border: 'none',
                    backgroundColor: '#F9F9F9',
                    // borderColor: '',
                    fontFamily: 'Roboto',
                    // outline: 'none',
                    // border:
                    // 	formik.touched.address &&
                    // 	Boolean( formik.errors.address ) &&
                    // 	'1px solid red',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.commonStyleInput}
              // justify="space-between"
              // alignItems="center"
            >
              <TextField
                select
                label="SELECT PROJECT"
                size="small"
                style={{ fontFamily: 'Roboto' }}
                // placeholder='----'
                variant="filled"
                fullWidth
                // style={{ width: "300px" }}
                className={classes.commonStyleInput}
                id="projectId"
                name="projectId"
                value={formik.values.projectId}
                onChange={(data) => {
                  formik.setFieldValue('projectId', data.target.value);
                  if (data.target.value !== 'Select Project') {
                    dispatch(getContractors(data.target.value));
                  }
                }}
                error={
                  formik.touched.projectId && Boolean(formik.errors.projectId)
                }
                helperText={formik.touched.projectId && formik.errors.projectId}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}`,
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value="Select Project">Select Project</MenuItem>

                {projectsList?.map((project) => (
                  <MenuItem
                    key={project.projectId}
                    value={project?.projectId}
                    style={{ fontFamily: 'Roboto' }}
                    // onClick={() => handleProjectChange(project)}
                  >
                    {project.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              container
              item
              xs={12}
              className={classes.commonStyleInput}
              // justify="space-between"
              // alignItems="center"
            >
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                <TextField
                  size="small"
                  type="date"
                  label="DATE"
                  fullWidth
                  variant="filled"
                  id="date"
                  name="date"
                  className={classes.commonStyleInput}
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.bankName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                  // inputProps={{
                  //   max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                  //   min: moment().subtract(58, "years").format("YYYY-MM-DD"),
                  // }}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.commonStyleInput}>
                <TextField
                  size="small"
                  type="time"
                  label="TIME"
                  fullWidth
                  variant="filled"
                  id="time"
                  name="time"
                  value={formik.values.time}
                  onChange={formik.handleChange}
                  error={formik.touched.time && Boolean(formik.errors.time)}
                  helperText={formik.touched.time && formik.errors.time}
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.bankName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                  // inputProps={{
                  //   max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                  //   min: moment().subtract(58, "years").format("YYYY-MM-DD"),
                  // }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.commonStyleInput}
              justify="center"
            >
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                <TextField
                  select
                  label="ASSIGN TO"
                  size="small"
                  style={{ fontFamily: 'Roboto' }}
                  // placeholder='----'
                  variant="filled"
                  fullWidth
                  // style={{ width: "300px" }}
                  className={classes.commonStyleInput}
                  id="contractorId"
                  name="contractorId"
                  value={formik.values.contractorId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contractorId &&
                    Boolean(formik.errors.contractorId)
                  }
                  helperText={
                    formik.touched.contractorId && formik.errors.contractorId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value="">Assign</MenuItem>

                  {formik.values.projectId === 'Select Project'
                    ? []
                    : contractorList?.length > 0 &&
                      contractorList
                        .filter(
                          (item) => item.leadTypeId === 'LabourContractor'
                        )
                        .map((item) => (
                          <MenuItem
                            key={item.userId}
                            value={item.userId}
                            style={{ fontFamily: 'Roboto' }}
                          >
                            {item.fullName}
                          </MenuItem>
                        ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12} className={classes.commonStyleInput}>
                <TextField
                  size="small"
                  label="LOCATION"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.ifscCode ?? 'N/A'}
                  id="location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              justify="flex-end"
              style={{ marginTop: '3rem' }}
            >
              <Grid item style={{ marginRight: '1rem' }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  style={{
                    background: '#81B734',
                    color: '#fff',
                    // borderRadius: '10px',
                    padding: '10px 1.5rem',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                  }}
                  disabled={loading}
                  // onClick={() => formik.submitForm()}
                >
                  {loading ? 'loading...' : ' Update Note'}
                </Button>
              </Grid>
              <Grid item>
                {fromModal ? (
                  <Button
                    // type="submit"
                    size="small"
                    variant="contained"
                    style={{
                      background: 'RGB(156, 117, 240)',
                      color: '#fff',
                      // borderRadius: '10px',
                      padding: '10px 2.5rem',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                    }}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Link
                    to={fromModal ? './' : '..'}
                    style={{ color: 'black' }}
                    // onClick={() => dispatch(setWorkerForDetail(null))}
                  >
                    <Button
                      // type="submit"
                      size="small"
                      variant="contained"
                      style={{
                        background: 'RGB(156, 117, 240)',
                        color: '#fff',
                        // borderRadius: '10px',
                        padding: '10px 2.5rem',
                        fontSize: '12px',
                        textTransform: 'capitalize',
                      }}
                    >
                      Cancel
                    </Button>
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Toaster
          toastOptions={{
            // Define default options
            // className: "",
            duration: 10000,
            // style: {
            //   background: "#363636",
            //   color: "#fff",
            // },

            // Default options for specific types
            // success: {
            //   duration: 3000,
            //   theme: {
            //     primary: "green",
            //     secondary: "black",
            //   },
            // },
          }}
        />
      </form>
    </Grid>
  );
};

export default EditFieldNote;
