import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  rolesList: [],
  res: null,
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    creatingRoles(state, action) {
      state.loading = true;
      state.error = null;
      state.res = null;
    },
    creatingRolesSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.res = action.payload;
    },
    creatingRolesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.res = null;
    },
    gettingRoles(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingRolesSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.rolesList = action.payload;
    },
    gettingRolesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.rolesList = [];
    },
  },
});
const {
  creatingRoles,
  creatingRolesSuccess,
  creatingRolesFailure,
  gettingRoles,
  gettingRolesSuccess,
  gettingRolesFailure,
} = slice.actions;
export const rolesReducer = (state) => state.roles;

export const createRole = (role) => async (dispatch) => {
  dispatch(creatingRoles());
  try {
    const response = await axios.post(`${config.baseUrl}/dashboard/Role`, role);
    if (response?.status === 200) {
      dispatch(creatingRolesSuccess(response));
    } else {
      dispatch(
        creatingRolesFailure('Something went wrong while creating roles!'),
      );
    }

    return response;
  } catch (e) {
    // console.log('e.m', e?.message);

    dispatch(
      creatingRolesFailure('Something went wrong while creating roles!'),
    );
  }
};
export const getRoles = () => async (dispatch) => {
  dispatch(gettingRoles());
  try {
    const response = await axios.get(`${config.baseUrl}/dashboard/Role`);
    if (response?.status === 200) {
      dispatch(gettingRolesSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingRolesFailure('Something went wrong while getting roles!'));
  }
};

export default slice.reducer;
