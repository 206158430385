import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import SOWTable from './SOWTable';
import { useSelector } from 'react-redux';
import { productivityReducer } from 'src/redux/productivity';
import BOQAccordian1 from './BOQAccordian1';

const SOWAccordian1 = (props) => {
  const { sowItem, index, filter } = props;

  const { scopeList } = useSelector(productivityReducer);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedNested, setIsExpandedNested] = useState(false);
  const [lastIndex, setLastIndex] = useState(false);

  const getValueInLakhsString = (value) => {
    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const handleChange = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleChangeNested = (index) => {
    setIsExpandedNested((prev) => !prev);
    setLastIndex(lastIndex === 0 || '0' ? false : index);
  };

  const selectedScope = scopeList.find(
    (scope) => scope.scopeOfWorkId === sowItem.scopeOfWorkId
  );

  const getTotalAmount = useCallback(
    (data) => {
      const totalAmountForAllBoqs = data?.reduce((totalBoqAmount, subItem) => {
        const totalAmountForSubItem = subItem.titles?.reduce(
          (totalTitleAmount, title) => {
            const totalAmountForTitle = title.descriptions?.reduce(
              (total, current) => total + current.amount,
              0
            );
            return totalTitleAmount + totalAmountForTitle;
          },
          0
        );
        return totalBoqAmount + totalAmountForSubItem;
      }, 0);
      return totalAmountForAllBoqs;
    },
    [sowItem]
  );

  const getTotalPercentage = useCallback(
    (data) => {
      return data?.reduce((totalBoqPercentage, subItem) => {
        const totalPercentageForSubItem = subItem.titles?.reduce(
          (totalTitlePercentage, title) => {
            const totalPercentageForTitle = title.descriptions?.reduce(
              (total, current) => {
                if (current.amount > 0) {
                  return total + (current.actualAmount / current.amount) * 100;
                }
                return total;
              },
              0
            );
            return totalTitlePercentage + totalPercentageForTitle;
          },
          0
        );
        return totalBoqPercentage + totalPercentageForSubItem;
      }, 0);
    },
    [sowItem]
  );
  // const totalAmount = boqItem?.titles
  //   ?.map((ii) =>
  //     ii.descriptions?.reduce((total, current) => total + current.amount, 0)
  //   )
  //   ?.reduce((total, current) => total + current, 0);

  // const totalPercentage = boqItem?.titles.reduce(
  //   (total, current) =>
  //     total + (current.totalAmount / current.totalAcutalAmount) * 100 ===
  //     Infinity
  //       ? 0
  //       : (current.totalAmount / current.totalAcutalAmount) * 100,
  //   0
  // );

  return (
    <Accordion
      style={{
        overflowY: 'auto',
        width: '100%',
        borderBottom: '1px solid #A1C3E5',
        borderTop: '1px solid #A1C3E5',
        margin: 0,
        minWidth: '100%',
      }}
      onChange={handleChange}
    >
      <AccordionSummary>
        <Grid
          item
          container
          xs={12}
          justify="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            container
            style={{ flexWrap: 'nowrap', minWidth: 27 }}
            alignItems="center"
          >
            <Grid
              item
              container
              style={{
                backgroundColor: 'rgba(1,1,1,0.2)',
                borderRadius: 20,
                minWidth: 30,
                maxWidth: 30,
                height: 30,
                color: '#000000',
                margin: '0px 10px',
              }}
              justify="center"
              alignItems="center"
            >
              {sowItem.scopeOfWorkId}
            </Grid>
            <Grid style={{ minWidth: 70, flexWrap: 'nowrap' }} item container>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  flexGrow: 1,
                  textWrap: 'nowrap',
                  display: 'flex',
                }}
              >
                <img
                  src={selectedScope?.imageUrl}
                  alt={selectedScope?.name}
                  style={{ width: 20, height: 20, margin: '0px 10px' }}
                />
                {sowItem?.scopeOfWork}
              </Typography>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
          </Grid>
          <Grid item style={{ maxWidth: 240 }} container justify="flex-end">
            <Grid item container xs={6}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                {getValueInLakhsString(getTotalAmount(sowItem.dtoboqList))}
              </Typography>
              <Typography
                style={{
                  color: '#ADBAC3',
                  fontSize: 10,
                }}
              >
                Actual Amount
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        style={{
          width: '100%',
          padding: '8px 0px 16px',
          margin: 0,
        }}
      >
        <Grid item container>
          {sowItem?.dtoboqList.map((boqItem, boqIndex) => {
            return (
              <Accordion
                style={{
                  overflowY: 'auto',
                  width: '100%',
                  borderBottom: '1px solid #A1C3E5',
                  borderTop: '1px solid #A1C3E5',
                  margin: 0,
                  minWidth: '100%',
                }}
                onChange={() => handleChangeNested(boqIndex)}
              >
                <AccordionSummary>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={2}
                      container
                      style={{ flexWrap: 'nowrap', minWidth: 27 }}
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        style={{
                          backgroundColor: '#ffffff',
                          borderRadius: 20,
                          minWidth: 30,
                          maxWidth: 30,
                          height: 30,
                          color: 'white',
                          margin: '0px 10px',
                        }}
                        justify="center"
                        alignItems="center"
                      >
                        {/* {boqIndex + 1} */}
                      </Grid>
                      <Grid
                        style={{ minWidth: 70, flexWrap: 'nowrap' }}
                        container
                        item
                      >
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: 13,
                            textWrap: 'nowrap',
                            flexGrow: 1,
                          }}
                        >
                          {`WORKORDER # ${
                            boqItem?.workOrder === null
                              ? 'N/A'
                              : boqItem?.workOrder
                          }`}
                        </Typography>
                        {boqIndex === lastIndex && isExpandedNested ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{ maxWidth: 240 }}
                      container
                      justify="flex-end"
                    >
                      <Grid item container xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            width: '100%',
                          }}
                        >
                          {boqItem?.titles
                            ?.map((ii) =>
                              ii.descriptions?.reduce(
                                (total, current) => total + current.amount,
                                0
                              )
                            )
                            ?.reduce((total, current) => total + current, 0)}
                          {/* {getValueInLakhsString(totalAmount)} */}
                        </Typography>
                        <Typography
                          style={{
                            color: '#ADBAC3',
                            fontSize: 10,
                            width: '100%',
                          }}
                        >
                          ACTUAL AMOUNT
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <BOQAccordian1
                    boqIndex={boqIndex}
                    boqItem={boqItem}
                    {...props}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SOWAccordian1;
