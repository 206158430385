import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Container,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import moment from 'moment';
import SortSelection from 'src/components/SortSelection';
import { useDispatch, useSelector } from 'react-redux';
import { getSkills, jobReducer } from 'src/redux/jobs';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SearchInput from 'src/components/SearchInput';
import Page from 'src/components/Page';
import { Autocomplete } from '@material-ui/lab';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import defaultProjectImage from '../../assests/Projects Icon.png';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Avatar from '@material-ui/core/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import {
  assignContractorFieldNote,
  deleteFielNote,
  downloadFIeldNoteReport,
  fieldNoteReducer,
  getFieldNoteList,
  markFieldNoteAction,
  selectFieldNote,
  verifyFieldNote,
} from 'src/redux/fieldNote';
import toast, { Toaster } from 'react-hot-toast';
import { getContractors } from 'src/redux/users';
import { usersReducer } from 'src/redux/users';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import TimerIcon from '@material-ui/icons/Timer';
import RestoreIcon from '@material-ui/icons/Restore';
import { getScopeList, productivityReducer } from 'src/redux/productivity';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { downloadExcel } from 'react-export-table-to-excel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getLoggedInUserData } from 'src/App';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ImageIcon from '@material-ui/icons/Image';
import useAccessPerView from 'src/common/Access';

registerLocale('es', es);
//! SLIDES-IMAGE
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    textTransform: 'capitalize',

    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  cardPaddingRight: {
    paddingRight: '2rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '90%',
  },

  caroselHeight: {
    height: '38em',
  },
  table: {
    minWidth: 650,
  },
  DARAndDLRColums: {
    color: '#c2c1cdff',
    fontSize: '12px',
  },
  projectInput: {
    // fontWeight: 300,
    // fontSize: "12px",
    '& .MuiInputBase-input': {
      fontWeight: '400 !important',
      fontSize: '12px',
    },
  },

  whiteColor: {
    color: '#fff',
  },
  purpleColor: {
    color: '#A179F2',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  imageCard: {
    width: '300px',
    height: '250px',
    backgroundColor: 'white',
    border: '1px solid #ddd',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  input1: {
    display: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
  },
}));
const FieldNotes = () => {
  let Accesses = {};
  Accesses['CHANGE_MANAGEMENT'] = useAccessPerView('CHANGE_MANAGEMENT');
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      noValue: true,
    },
  ]);
  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });
  const [classifiedProject, setClassifiedProject] = useState('');
  const [currentProject, setCurrentProject] = useState('');
  const [filteredArray, setFilteredArray] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [anchorEl, setAnchorEl] = React.useState([]);
  const [anchorEl1, setAnchorEl1] = React.useState([]);

  const [fieldNOteImageOpne, setFieldNOteImageOpne] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [LabourContractor, setLabourContractor] = useState('');
  const [openFIleUploadModal, setOpenFileUploadModal] = useState(false);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const [markStatus, setMarkStatus] = useState(null);

  const handleClick = (event, index) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEl(newAnchorEls);
  };

  const handleClickOptions = (event, index) => {
    const newAnchorEls = [...anchorEl1];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEl1(newAnchorEls);
  };

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  //! SELECTORS
  const { skillsList } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);
  const { fieldNoteList, loading } = useSelector(fieldNoteReducer);
  const { contractorList } = useSelector(usersReducer);
  const { scopeList } = useSelector(productivityReducer);

  //! LIFECYCLE
  useEffect(() => {
    if (!fieldNoteList) {
      dispatch(getSkills());
      dispatch(getProjectsClassificationList());
      dispatch(getContractors());
      dispatch(getScopeList());
      dispatch(getFieldNoteList());
    }
    if (fieldNoteList?.length) {
      const initialAnchors = fieldNoteList.map(() => null);
      setAnchorEl(initialAnchors);
    }
  }, [fieldNoteList?.length]);

  const classifyProject = (project) => {
    if (project) {
      setCurrentProject(project);
      dispatch(getFieldNoteList(project?.projectId));
    } else {
      dispatch(getFieldNoteList());

      setCurrentProject('');
    }
  };
  const handleClose = (index) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = null;
    setAnchorEl(newAnchorEls);
  };

  const handleCloseOptions = (index) => {
    const newAnchorEls = [...anchorEl1];
    newAnchorEls[index] = null;
    setAnchorEl1(newAnchorEls);
  };

  const markFieldNote = async (index, fieldNoteId, mark) => {
    handleClose(index);
    setOpenFileUploadModal(true);

    setMarkStatus({
      fieldNoteId,
      mark,
    });
    return;
  };

  const assignContractorToFieldNote = async (fieldNoteId, contractorId) => {
    let resp = await dispatch(
      assignContractorFieldNote(fieldNoteId, contractorId)
    );
    if (resp?.status === 200) {
      dispatch(getFieldNoteList());
      toast.success('Assigned Contractor Successfully!', {
        position: 'top-right',
      });
    } else {
      toast.error('Assign Contractor Failed!', {
        position: 'top-right',
      });
    }
  };

  //! FILTERS
  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      const data =
        parseInt(filters.sortBy) !== 0
          ? // || "dateRange[1]"
            filteredArray
          : fieldNoteList;

      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }

    if (!value) {
      return setFilteredArray([]);
    }
  };
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
    const dynamicFieldNoteList =
      filteredArray?.length > 0 ? filteredArray : fieldNoteList;

    if (end) {
      let newData = dynamicFieldNoteList.filter((filedNote) => {
        const dateTime = new Date(filedNote.dateTime).setHours(0, 0, 0, 0);

        if (dateTime >= start.getTime() && dateTime <= end.getTime()) {
          return filedNote;
        }
      });

      if (newData?.length === 0) {
        return setFilteredArray(null);
      } else {
        return setFilteredArray(newData);
      }
    }
    if (!start && !end) {
      if (filters.sortBy !== 0) {
        // return handleFilters({ target: { value: filters.sortBy } });
        return setFilteredArray([]);
      }
      return setFilteredArray([]);
    }
  };

  const handleFilters = (e) => {
    const { value, name } = e.target;

    if (name === 'project') {
      setFilters({ ...filters, project: value });
      if (
        fieldNoteList?.filter((job) => job?.scopeOfWork === value).length ===
          0 &&
        value != 0
      ) {
        return setFilteredArray(null);
      }
      return setFilteredArray(
        fieldNoteList.filter((job) => job?.scopeOfWork === value)
      );
    }

    if (value !== 0) {
      setFilters({ ...filters, sortBy: value });
      if (
        fieldNoteList?.filter((job) => job?.scopeOfWork === value).length ===
          0 &&
        value != 0
      )
        return setFilteredArray(null);

      setFilteredArray(
        fieldNoteList.filter((job) => job?.scopeOfWork === value)
      );
    }
  };

  const header = [
    'ID',
    'SCOPE OF WORK',
    'DESCRIPTION',
    'DATE',
    'LOCATION',
    'CONTRACTOR',
  ];
  const body = fieldNoteList?.map((fieldNote) => [
    fieldNote?.fieldNoteId ?? 'N/A',
    fieldNote?.scopeOfWork ?? 'N/A',
    fieldNote?.description ?? 'N/A',
    fieldNote.dateTime === '' || fieldNote.dateTime === undefined
      ? 'N/A'
      : moment(fieldNote.dateTime).format('LLL'),
    fieldNote?.location ?? 'N/A',
    fieldNote?.contractor?.fullName ?? 'N/A',
  ]);

  async function handleDownloadExcel() {
    let resp = await dispatch(
      downloadFIeldNoteReport(
        currentProject?.projectId ?? projectClassificationList[0]?.projectId
      )
    );
    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute(
        'download',
        `FieldNote${moment().format('MMM - YYYY')}.xlsx`
      );

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    }
  }

  const deleteFieldNoteRow = async (id, index) => {
    let resp = await dispatch(deleteFielNote(id));
    if (resp?.status === 200) {
      handleCloseOptions(index);
      toast.success('Field Note deleted successfully!');
      dispatch(getFieldNoteList());
    } else {
      toast.error('Unable to delete');
    }
  };

  const opnenEditFieldNotePage = (row) => {
    navigate('edit');
    dispatch(selectFieldNote(row));
  };
  const handleLabourContractor = (newValue) => {
    setLabourContractor(newValue);

    if (!newValue) {
      return dispatch(getFieldNoteList(currentProject?.projectId));
    }
    dispatch(getFieldNoteList(0, newValue?.userId));
  };

  const handleImage = (e) => {
    // let selectedImage = e.target.files[0];

    if (e.target.files.length) {
      setPreview(URL?.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      // setRotation(0); // Reset rotation angle
    }
  };
  const markTheFieldNoteFromServer = async () => {
    let formData = new FormData();
    formData.append('VerificationImage', image);
    formData.append('Action', markStatus.mark);
    let resp = await dispatch(
      markFieldNoteAction(markStatus.fieldNoteId, formData)
    );
    if (resp?.status === 200) {
      dispatch(getFieldNoteList());
      toast.success('Field Note Mark Successfully!', {
        position: 'top-right',
      });
    } else {
      toast.error('Field Note Marked unsuccessful!', {
        position: 'top-right',
      });
    }
    setOpenFileUploadModal(false);
  };

  const makeVerifiedFieldNote = async (id) => {
    let resp = await dispatch(verifyFieldNote(id));
    let loader = toast.loading('Loading...');
    if (resp.status === 200) {
      toast.dismiss(loader);
      dispatch(getFieldNoteList());
      toast.success('Field Note Verified Successfully!');
    }
  };
  return (
    <Page className={classes.root} id="zoom-level" title="Field Notes">
      <Dialog
        open={openFIleUploadModal}
        onClose={() => setOpenFileUploadModal(false)}
        keepMounted
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => setOpenFileUploadModal(false)}
        >
          Upload FieldNote Image
        </DialogTitle>
        <DialogContent
          style={{
            padding: '15px',
          }}
        >
          <Grid
            item
            xs={12}
            // className={classes.paddingLeft}
            container
            direction="column"
          >
            {/* Profile */}
            <Grid item>
              <input
                accept="image/*"
                className={classes.input1}
                id="icon-button-file"
                type="file"
                onChange={handleImage}
              />

              <label
                htmlFor="icon-button-file"
                className={classes.ImagePlaceHolder}
              >
                {preview ? (
                  <img
                    src={`${preview}`}
                    alt="project url"
                    style={{
                      width: '300px',
                      height: '250px',
                      borderRadius: '10px',
                      marginBottom: '10px',
                    }}
                  />
                ) : (
                  <Grid
                    container
                    className={classes.imageCard}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Grid item>
                        <ImageIcon
                          style={{
                            color: 'RGB(183, 194, 202)',
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          color: 'RGB(151, 196, 87)',
                          fontSize: '12px',
                        }}
                      >
                        +{' '}
                        <span style={{ textDecoration: 'underline' }}>
                          UPLOAD PICTURE
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </label>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              className={classes.buttonPurple}
              onClick={markTheFieldNoteFromServer}
            >
              Mark
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={fieldNOteImageOpne}
        onClose={() => setFieldNOteImageOpne(false)}
        keepMounted
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => setFieldNOteImageOpne(false)}
        ></DialogTitle>
        <DialogContent
          style={{
            padding: '15px',
          }}
        >
          <img
            src={imageURL}
            alt="thumbnail"
            style={{ borderRadius: '5px', width: '400px', height: '400px' }}
          />
        </DialogContent>
      </Dialog>
      <Container maxWidth={false}>
        <Grid
          container
          style={{
            background: 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Grid item xs={12}>
            <Grid container item xs={12}>
              <Grid xs={3}>
                <Typography variant="h6">Field Notes</Typography>
              </Grid>
              <Grid
                item
                md={12}
                container
                justify="space-between"
                alignItems="center"
                className={`${classes.marginRight} ${classes.marginTop}`}
                style={{ margin: '10px 0' }}
              >
                <Grid item xs={8} container>
                  <Grid item xs={4}>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProject[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                      }
                      value={currentProject}
                      onChange={classifyProject}
                    />
                  </Grid>
                  <Grid xs={4} style={{ paddingLeft: '10px' }}>
                    <FilterByLabourContractorComponent
                      value={LabourContractor}
                      onChange={handleLabourContractor}
                      setInitialUser={(user) => setLabourContractor(user)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  md={3}
                  alignItems="center"
                  justify="flex-end"
                  className={classes.marginTop}
                >
                  <Grid item>
                    {Accesses['CHANGE_MANAGEMENT'].isEditMode && (
                      <Link to="/app/field-notes/create">
                        <Button
                          // size="small"
                          variant="outlined"
                          className={classes.buttonGreen}
                          endIcon={<NoteAddIcon />}
                          style={{
                            borderRadius: '10px',
                          }}
                        >
                          Add New Note
                        </Button>
                      </Link>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    style={{
                      background: '#81B734',
                      width: 30,
                      height: 30,
                      borderRadius: '5px',
                      // padding: '5px'
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={handleDownloadExcel}
                  >
                    <SystemUpdateAltIcon
                      fontSize="small"
                      style={{ color: 'white', textAlign: 'center' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              // spacing={3}
              // style={{ marginTop: '1rem' }}
              alignItems="center"
              className="input-container-nav"
              justify="space-between"
              // style={ { border: '2px solid green' } }
            >
              {/* container */}

              <Grid
                item
                md={3}
                xs={12}
                container
                alignItems="center"
                justify="space-between"
                // className='padding5'
                // style={{ padding: '2px' }}
                className={classes.marginTop}
              >
                <Grid item xs={1}>
                  <small>SOW: </small>
                </Grid>
                <Grid item md={10} xs={9}>
                  <SortSelection
                    initialValue="Scope Of Work"
                    value={filters.sortBy}
                    handleFilters={handleFilters}
                    data={scopeList?.map((sow) => ({
                      value: sow.name,
                      label: sow.name,
                    }))}
                  />
                </Grid>
              </Grid>

              {/* //! 2.DatePickerInput */}
              <Grid
                item
                md={4}
                xs={12}
                container
                alignItems="center"
                justify="space-between"
                style={{ zIndex: 10 }}
              >
                {/* <div style={{ width: '100%', position: 'relative' }}> */}
                <Grid
                  item
                  xs={3}
                  md={3}
                  className={classes.searchFilterContainer}
                >
                  <small>Search Filters:</small>
                </Grid>
                <Grid
                  item
                  style={{
                    position: 'relative',
                    // padding: '1rem',
                  }}
                  xs={9}
                >
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                  <DatePicker
                    dateFormat="MMMM d, yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      handleDateRange(update);
                    }}
                    isClearable={true}
                    className="font12 date-picker "
                  />
                  {!startDate && (
                    <EventNoteIcon
                      style={{
                        position: 'absolute',
                        right: '5px',
                        top: '7px',
                      }}
                    />
                  )}
                  {/* </MuiPickersUtilsProvider> */}
                </Grid>
              </Grid>

              {/* //! 3.SearchInput */}
              <Grid
                item
                md={3}
                xs={12}
                container
                justify="space-between"
                alignItems="center"
                className={classes.marginTop}
              >
                <Grid item md={1} xs={3}>
                  <small style={{ paddingRight: '15px' }}>Search:</small>
                </Grid>
                <Grid item xs={9} md={10} style={{ position: 'relative' }}>
                  <SearchInput
                    search={filters.search}
                    handleSearch={handleSearch}
                    // className='font12'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '5px' }}>
            <TableContainer component={Paper}>
              {loading ? (
                <div
                  style={{
                    textAlign: 'center',
                    height: '76vh',
                    width: '100%',
                  }}
                >
                  <CircularProgress style={{ color: '#81B734' }} />
                </div>
              ) : (
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="DAR Table"
                  style={{ position: 'relative' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.DARAndDLRColums}
                        align="center"
                      >
                        ID
                      </TableCell>
                      <TableCell
                        className={classes.DARAndDLRColums}
                        align="center"
                      >
                        IMAGE
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                      >
                        SCOPE OF WORK
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                      >
                        DESCRIPTION
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                        width="10%"
                      >
                        DATE
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                      >
                        LOCATION
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                      >
                        ASSIGNEE CONTRACTOR
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                      >
                        STATUS
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                      >
                        RESOLUTION
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.DARAndDLRColums}
                        width="330px"
                      >
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!fieldNoteList?.length ? (
                    <TableBody>
                      <TableRow>No Data Found!</TableRow>
                    </TableBody>
                  ) : filteredArray?.length ? (
                    <TableBody>
                      {filteredArray?.map((item, index) => (
                        <TableRow key={index + 1}>
                          <TableCell align="left" style={{ fontSize: '12px' }}>
                            {item.fieldNoteId ?? 'N/A'}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{ fontSize: '12px' }}
                          >
                            <Avatar
                              style={{ cursor: 'pointer' }}
                              alt={item?.scopeOfWork?.name}
                              src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.imageUrl}`}
                              className={classes.small}
                              onClick={() => {
                                setFieldNOteImageOpne(true);
                                setImageURL(
                                  `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.imageUrl}`
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            width="150px"
                            style={{ fontSize: '12px' }}
                          >
                            {item?.scopeOfWork?.name ?? 'N/A'}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontSize: '12px',
                              // whiteSpace: "nowrap", // Prevent text from wrapping
                              // overflow: "hidden", // Hide overflow
                              // textOverflow: "ellipsis", // Add ellipsis for overflowed text
                            }}
                          >
                            {item?.description?.split(' ')?.length > 15
                              ? item?.description
                                  ?.split(' ')
                                  ?.slice(0, 15)
                                  .join(' ') + '...'
                              : item?.description ?? 'N/A'}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: '12px' }}>
                            {moment(item?.dateTime).format('LLL')}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: '12px' }}>
                            {item?.location ?? 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            <Autocomplete
                              disabled={
                                getLoggedInUserData()?.user?.leadTypeId ===
                                'LabourContractor'
                              }
                              size="small"
                              value={item.contractor}
                              onChange={(event, newValue) => {
                                if (newValue === null) {
                                  return;
                                }
                                assignContractorToFieldNote(
                                  item?.fieldNoteId,
                                  newValue?.userId
                                );
                              }}
                              getOptionLabel={(option) => option.fullName}
                              options={
                                contractorList?.length
                                  ? contractorList?.filter(
                                      (item) =>
                                        item.user?.leadTypeId ===
                                        'LabourContractor'
                                    )
                                  : []
                              }
                              style={{
                                width: 230,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  label="Contractor"
                                  variant="outlined"
                                  className={classes.projectInput}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: '12px' }}
                          >
                            {item.verifiedAction ? (
                              <DoneAllIcon style={{ color: 'blue' }} />
                            ) : (
                              'Not Verified'
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: '12px',
                              width: '330px',
                              textAlign: 'center',
                            }}
                          >
                            {item.verifiedImageUrl ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Avatar
                                  style={{ cursor: 'pointer' }}
                                  alt={item?.scopeOfWork?.name}
                                  src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.verifiedImageUrl}`}
                                  className={classes.small}
                                  onClick={() => {
                                    setFieldNOteImageOpne(true);
                                    setImageURL(
                                      `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.verifiedImageUrl}`
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              'N/A'
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Grid container justify="space-between">
                              <div>
                                <Button
                                  disabled={
                                    item?.action === 'MarkAsResolved'
                                    // ||item?.action === 'ReworkRequired' ||
                                    // item?.action === 'DecisionPending'
                                  }
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  // onClick={handleClick}
                                  onClick={(event) => handleClick(event, index)}
                                  className={classes.buttonPurpleOpacity}
                                  endIcon={<KeyboardArrowDownRoundedIcon />}
                                  startIcon={
                                    item.action === 'MarkAsResolved' ? (
                                      <CheckCircleIcon
                                        style={{ color: '#fff' }}
                                      />
                                    ) : item.action === 'ReworkRequired' ? (
                                      <RestoreIcon
                                        className={classes.purpleColor}
                                      />
                                    ) : item.action === 'DecisionPending' ? (
                                      <TimerIcon style={{ color: '#fff' }} />
                                    ) : null
                                  }
                                  fullWidth
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    width: '115px',
                                    backgroundColor:
                                      item.action === 'MarkAsResolved'
                                        ? '#81B734'
                                        : item.action === 'ReworkRequired'
                                        ? 'rgba(161, 120, 242, 0.20)'
                                        : item.action === 'DecisionPending'
                                        ? '#687378'
                                        : '#fff',
                                    color:
                                      item.action === 'MarkAsResolved'
                                        ? '#fff'
                                        : item.action === 'ReworkRequired'
                                        ? '#A179F2'
                                        : item.action === 'DecisionPending'
                                        ? '#fff'
                                        : '#333',
                                    border:
                                      item.action === 'MarkAsResolved'
                                        ? 'none'
                                        : item.action === 'ReworkRequired'
                                        ? 'none'
                                        : item.action === 'DecisionPending'
                                        ? 'none'
                                        : '1px solid #333',
                                  }}
                                >
                                  {item.action === 'MarkAsResolved'
                                    ? 'Resolved'
                                    : item.action === 'ReworkRequired'
                                    ? 'Rework'
                                    : item.action === 'DecisionPending'
                                    ? 'Pending'
                                    : 'Action'}
                                </Button>
                                <Menu
                                  disabled={true}
                                  id="simple-menu"
                                  anchorEl={anchorEl[index]}
                                  keepMounted
                                  open={Boolean(anchorEl[index])}
                                  onClose={() => handleClose(index)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      markFieldNote(
                                        index,
                                        item.fieldNoteId,
                                        'MarkAsResolved'
                                      );
                                    }}
                                    style={{
                                      backgroundColor: '#a0d558',
                                    }}
                                    // style={{ width: "210px" }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      // justify="space-between"
                                    >
                                      <CheckCircleIcon
                                        className={classes.whiteColor}
                                      />
                                      <span
                                        style={{
                                          paddingLeft: '10px',
                                        }}
                                        className={classes.whiteColor}
                                      >
                                        Resolved
                                      </span>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      markFieldNote(
                                        index,

                                        item.fieldNoteId,
                                        'ReworkRequired'
                                      )
                                    }
                                    // style={{ width: "210px" }}
                                    style={{
                                      backgroundColor:
                                        'rgba(161, 120, 242, 0.20)',
                                    }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      // justify="space-between"
                                    >
                                      <RestoreIcon
                                        className={classes.purpleColor}
                                      />

                                      <span
                                        style={{ paddingLeft: '10px' }}
                                        className={classes.purpleColor}
                                      >
                                        Rework
                                      </span>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      markFieldNote(
                                        index,

                                        item.fieldNoteId,
                                        'DecisionPending'
                                      )
                                    }
                                    style={{
                                      backgroundColor: '#687378',
                                    }}
                                    // style={{ width: "210px" }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      // justify="space-between"
                                    >
                                      <TimerIcon
                                        className={classes.whiteColor}
                                      />

                                      <span
                                        style={{ paddingLeft: '10px' }}
                                        className={classes.whiteColor}
                                      >
                                        Pending
                                      </span>
                                    </Grid>
                                  </MenuItem>
                                </Menu>
                              </div>

                              {item.action === 'MarkAsResolved' && (
                                <Button
                                  className={classes.buttonGreen}
                                  onClick={() =>
                                    makeVerifiedFieldNote(item.fieldNoteId)
                                  }
                                  fullWidth
                                  style={{
                                    marginTop: '5px',
                                    width: '115px',
                                    backgroundColor:
                                      item.verifiedAction && '#ddd',
                                  }}
                                  size="small"
                                  // Only Level 1 user can verify
                                  disabled={
                                    !Accesses['CHANGE_MANAGEMENT'].hasAccess
                                      .LEVEL1 || item.verifiedAction
                                  }
                                >
                                  Verify
                                </Button>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : filters.search !== '' || filteredArray === null ? (
                    <TableRow>No Search Found!</TableRow>
                  ) : (
                    <TableBody>
                      {fieldNoteList?.map((item, index) => (
                        <TableRow key={item?.fieldNoteId}>
                          <TableCell align="left" style={{ fontSize: '12px' }}>
                            {item?.fieldNoteId ?? 'N/A'}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{ fontSize: '12px' }}
                          >
                            <Avatar
                              style={{ cursor: 'pointer' }}
                              alt={item?.scopeOfWork?.name}
                              src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.imageUrl}`}
                              className={classes.small}
                              onClick={() => {
                                setFieldNOteImageOpne(true);
                                setImageURL(
                                  `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.imageUrl}`
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            width="150px"
                            style={{ fontSize: '12px' }}
                          >
                            {item?.scopeOfWork?.name ?? 'N/A'}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontSize: '12px',
                              // whiteSpace: "nowrap", // Prevent text from wrapping
                              // overflow: "hidden", // Hide overflow
                              // textOverflow: "ellipsis", // Add ellipsis for overflowed text
                            }}
                          >
                            {item.description?.split(' ')?.length > 15
                              ? item.description
                                  ?.split(' ')
                                  ?.slice(0, 15)
                                  .join(' ') + '...'
                              : item.description ?? 'N/A'}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: '12px' }}>
                            {moment(item.dateTime).format('LLL')}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: '12px' }}>
                            {item.location ?? 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            <Autocomplete
                              disabled={
                                getLoggedInUserData()?.user?.leadTypeId ===
                                'LabourContractor'
                              }
                              size="small"
                              value={item.contractor}
                              onChange={(event, newValue) => {
                                if (newValue === null) {
                                  return;
                                }
                                assignContractorToFieldNote(
                                  item.fieldNoteId,
                                  newValue?.userId
                                );
                              }}
                              getOptionLabel={(option) => option.fullName}
                              options={
                                contractorList?.length
                                  ? contractorList?.filter(
                                      (item) =>
                                        item.user?.leadTypeId ===
                                        'LabourContractor'
                                    )
                                  : []
                              }
                              style={{
                                width: 230,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  label="Contractor"
                                  variant="outlined"
                                  className={classes.projectInput}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            align="center"
                            style={{ fontSize: '12px' }}
                          >
                            {item.verifiedAction ? (
                              <DoneAllIcon style={{ color: 'blue' }} />
                            ) : (
                              'Not Verified'
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: '12px',
                              width: '330px',
                              textAlign: 'center',
                            }}
                          >
                            {item.verifiedImageUrl ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Avatar
                                  style={{ cursor: 'pointer' }}
                                  alt={item?.scopeOfWork?.name}
                                  src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.verifiedImageUrl}`}
                                  className={classes.small}
                                  onClick={() => {
                                    setFieldNOteImageOpne(true);
                                    setImageURL(
                                      `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.verifiedImageUrl}`
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              'N/A'
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Grid container justify="space-between">
                              <div>
                                <Button
                                  disabled={
                                    item?.action === 'MarkAsResolved'
                                    // ||item?.action === 'ReworkRequired' ||
                                    // item?.action === 'DecisionPending'
                                  }
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  // onClick={handleClick}
                                  onClick={(event) => handleClick(event, index)}
                                  className={classes.buttonPurpleOpacity}
                                  endIcon={<KeyboardArrowDownRoundedIcon />}
                                  startIcon={
                                    item.action === 'MarkAsResolved' ? (
                                      <CheckCircleIcon
                                        style={{ color: '#fff' }}
                                      />
                                    ) : item.action === 'ReworkRequired' ? (
                                      <RestoreIcon
                                        className={classes.purpleColor}
                                      />
                                    ) : item.action === 'DecisionPending' ? (
                                      <TimerIcon style={{ color: '#fff' }} />
                                    ) : null
                                  }
                                  fullWidth
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    width: '115px',
                                    backgroundColor:
                                      item.action === 'MarkAsResolved'
                                        ? '#81B734'
                                        : item.action === 'ReworkRequired'
                                        ? 'rgba(161, 120, 242, 0.20)'
                                        : item.action === 'DecisionPending'
                                        ? '#687378'
                                        : '#fff',
                                    color:
                                      item.action === 'MarkAsResolved'
                                        ? '#fff'
                                        : item.action === 'ReworkRequired'
                                        ? '#A179F2'
                                        : item.action === 'DecisionPending'
                                        ? '#fff'
                                        : '#333',
                                    border:
                                      item.action === 'MarkAsResolved'
                                        ? 'none'
                                        : item.action === 'ReworkRequired'
                                        ? 'none'
                                        : item.action === 'DecisionPending'
                                        ? 'none'
                                        : '1px solid #333',
                                  }}
                                >
                                  {item.action === 'MarkAsResolved'
                                    ? 'Resolved'
                                    : item.action === 'ReworkRequired'
                                    ? 'Rework'
                                    : item.action === 'DecisionPending'
                                    ? 'Pending'
                                    : 'Action'}
                                </Button>
                                <Menu
                                  disabled={true}
                                  id="simple-menu"
                                  anchorEl={anchorEl[index]}
                                  keepMounted
                                  open={Boolean(anchorEl[index])}
                                  onClose={() => handleClose(index)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      markFieldNote(
                                        index,
                                        item.fieldNoteId,
                                        'MarkAsResolved'
                                      );
                                    }}
                                    style={{
                                      backgroundColor: '#a0d558',
                                    }}
                                    // style={{ width: "210px" }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      // justify="space-between"
                                    >
                                      <CheckCircleIcon
                                        className={classes.whiteColor}
                                      />
                                      <span
                                        style={{
                                          paddingLeft: '10px',
                                        }}
                                        className={classes.whiteColor}
                                      >
                                        Resolved
                                      </span>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      markFieldNote(
                                        index,

                                        item.fieldNoteId,
                                        'ReworkRequired'
                                      )
                                    }
                                    // style={{ width: "210px" }}
                                    style={{
                                      backgroundColor:
                                        'rgba(161, 120, 242, 0.20)',
                                    }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      // justify="space-between"
                                    >
                                      <RestoreIcon
                                        className={classes.purpleColor}
                                      />

                                      <span
                                        style={{ paddingLeft: '10px' }}
                                        className={classes.purpleColor}
                                      >
                                        Rework
                                      </span>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      markFieldNote(
                                        index,

                                        item.fieldNoteId,
                                        'DecisionPending'
                                      )
                                    }
                                    style={{
                                      backgroundColor: '#687378',
                                    }}
                                    // style={{ width: "210px" }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      // justify="space-between"
                                    >
                                      <TimerIcon
                                        className={classes.whiteColor}
                                      />

                                      <span
                                        style={{ paddingLeft: '10px' }}
                                        className={classes.whiteColor}
                                      >
                                        Pending
                                      </span>
                                    </Grid>
                                  </MenuItem>
                                </Menu>
                              </div>

                              {item.action === 'MarkAsResolved' && (
                                <Button
                                  className={classes.buttonGreen}
                                  onClick={() =>
                                    makeVerifiedFieldNote(item.fieldNoteId)
                                  }
                                  fullWidth
                                  style={{
                                    marginTop: '5px',
                                    width: '115px',
                                    backgroundColor:
                                      item.verifiedAction && '#ddd',
                                  }}
                                  size="small"
                                  disabled={
                                    !Accesses['CHANGE_MANAGEMENT'].hasAccess
                                      .LEVEL1 || item.verifiedAction
                                  }
                                >
                                  Verify
                                </Button>
                              )}
                              {/* <div>
                                <Button
                                  aria-controls="simple-menu1"
                                  aria-haspopup="true"
                                  // onClick={handleClick}
                                  onClick={(event) =>
                                    handleClickOptions(event, index)
                                  }
                                  // className={classes.buttonPurpleOpacity}
                                  // endIcon={<KeyboardArrowDownRoundedIcon />}
                                  // fullWidth
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    width: "30px",
                                  }}
                                >
                                  <MoreVertIcon style={{ color: "#555" }} />
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl1[index]}
                                  keepMounted
                                  open={Boolean(anchorEl1[index])}
                                  onClose={() => handleCloseOptions(index)}
                                >
                                  {getLoggedInUserData()?.user?.leadTypeId !==
                                    "LabourContractor" && (
                                    <MenuItem
                                      onClick={() =>
                                        deleteFieldNoteRow(
                                          item.fieldNoteId,
                                          index
                                        )
                                      }
                                      style={{
                                        color: "red",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "120px",
                                      }}
                                    >
                                      <small>Delete</small>
                                      <DeleteForeverIcon
                                        style={{ color: "red" }}
                                      />
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    style={{
                                      color: "rgba (89, 104, 125, 1)",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={() => opnenEditFieldNotePage(item)}
                                  >
                                    <small>Edit</small>
                                    <EditIcon
                                      style={{
                                        color: "rgba (89, 104, 125, 1)",
                                      }}
                                    />
                                  </MenuItem>
                                </Menu>
                              </div> */}

                              {/* <Button
                                size="small"
                                onClick={() =>
                                  deleteFieldNoteRow(item.fieldNoteId)
                                }
                              >
                                <DeleteForeverIcon style={{ color: "red" }} />
                              </Button>
                              <Button
                                size="small"
                                onClick={() =>
                                  deleteFieldNoteRow(item.fieldNoteId)
                                }
                              >
                                <EditIcon
                                  style={{ color: "rgba (89, 104, 125, 1)" }}
                                />
                              </Button> */}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
        <Toaster />
      </Container>
    </Page>
  );
};

export default FieldNotes;
