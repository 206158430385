import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import GradientIcon from '@material-ui/icons/Gradient';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChatIcon from '@material-ui/icons/Chat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import NavItem from './NavItem';
import Logo from 'src/components/Logo';

const items = [
	{
		href: '/supervisor/dashboard',
		icon: DashboardIcon,
		title: 'Dashboard',
	},

	{
		href: '/supervisor/project',
		icon: AssignmentIcon,
		title: 'Projects',
	},

	{
		href: '/supervisor/communication',
		icon: ChatIcon,
		title: 'Communication',
	},
	{
		href: '/supervisor/attendance',
		icon: GradientIcon,
		title: 'Attendance',
	},
	{
		href: '/supervisor/workerprofile',
		icon: AssignmentIndIcon,
		title: 'Workers',
	},
	{
		href: '/supervisor/payments',
		icon: PaymentIcon,
		title: 'Payments',
	},

	{
		href: '/supervisor/reports',
		icon: TrendingUpIcon,
		title: 'Reports',
	},
];

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)',
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64,
	},
	logo: {
		width: 'inherit',
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box
			height='100%'
			display='flex'
			flexDirection='column'
			style={{ background: '#F3F6F8' }}>
			<Box p={2}>
				<Hidden lgUp>
					<Logo style={{ width: 180 }} />
				</Hidden>
				<List>
					{items.map((item) => (
						<NavItem
							href={item.href}
							key={item.title}
							title={item.title}
							icon={item.icon}
						/>
					))}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor='left'
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant='temporary'>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor='left'
					classes={{ paper: classes.desktopDrawer }}
					open
					variant='persistent'>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
