import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';

const LandingCartTile = (props) => {
  const { classes, title, subTitle, icon, isLoading, variations } = props;

  const varationColor = variations[0] === '-' ? '#FF0000' : '#81B734';

  const tileTitle = (
    <Typography
      style={{
        fontFamily: 'Roboto',
        fontSize: 12,
        color: '#B2BEC7',
      }}
    >
      {title}
    </Typography>
  );

  const spinner = (
    <CircularProgress
      style={{
        color: '#81B734',
        width: '25px',
        height: '25px',
      }}
    />
  );

  const percentVariation = (
    <Typography
      variant="p"
      style={{
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: '400',
        color: varationColor,
      }}
    >
      {variations}
    </Typography>
  );

  const tileSubTitle = (
    <Typography
      style={{
        fontFamily: 'Roboto',
        fontSize: 26,
        fontWeight: '400',
        textWrap: 'noWrap',
      }}
      variant="p"
    >
      {subTitle} {percentVariation}
    </Typography>
  );

  const dailyValue = (
    <Typography
      style={{
        fontFamily: 'Roboto',
        fontSize: 10,
        fontWeight: '400',
        color: '#B2BEC7',
      }}
    >
      Daily value*
    </Typography>
  );

  const tileIcon = (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        background: '#A179F2',
        height: 60,
        width: 60,
        borderRadius: 10,
      }}
    >
      {icon}
    </Grid>
  );

  const renderTileContent = () => {
    return (
      <Grid item xs={8}>
        {tileTitle}
        {isLoading ? spinner : tileSubTitle}
        {dailyValue}
      </Grid>
    );
  };

  const renderTileIcon = () => <Grid item>{tileIcon}</Grid>;

  const renderContent = () => (
    <CardContent>
      <Grid container justify="space-between" alignItems="center" item xs={12}>
        {renderTileContent()}
        {renderTileIcon()}
      </Grid>
    </CardContent>
  );

  return (
    <Grid
      item
      container
      md={3}
      xs={12}
      style={{
        padding: '5px 10px',
      }}
      alignItems="center"
    >
      <Card
        style={{
          width: '100%',
          cursor: 'pointer',
          borderRadius: 10,
        }}
      >
        {renderContent()}
      </Card>
    </Grid>
  );
};

export default LandingCartTile;
