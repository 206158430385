import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
	loading: false,
	error: null,
	contractorsList: null,
};

const slice = createSlice({
	name: 'contractors',
	initialState,
	reducers: {
		gettingContractors(state, action) {
			state.loading = true;
			state.error = null;
		},
		gettingContractorsSuccess(state, action) {
			state.contractorsList = action.payload;
			state.loading = false;
			state.error = null;
		},
		gettingContractorsFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},

		creatitngContractor(state, action) {
			state.loading = true;
			state.error = null;
		},
		creatitngContractorSuccess(state, action) {
			state.loading = false;
			state.error = null;
		},
		creatitngContractorFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});
const {
	gettingContractors,
	gettingContractorsSuccess,
	gettingContractorsFailure,
	creatitngContractor,
	creatitngContractorSuccess,
	creatitngContractorFailure,
} = slice.actions;
export const contractorReducer = state => state.contractors;

export const getContractors = () => async dispatch => {
	dispatch(gettingContractors());
	try {
		const response = await axios.get(
			`${config.baseUrl}/dashboard/Contractor/getall`
		);
		if (response.status === 200) {
			dispatch(gettingContractorsSuccess(response.data));
		}
		return response;
	} catch (e) {
		dispatch(
			gettingContractorsFailure('Something went wrong while getting users!')
		);
	}
};

export const updateContractor = worker => async dispatch => {
	dispatch(creatitngContractor());
	try {
		const response = await axios.post(
			`${config.baseUrl}/dashboard/Contractor/addupdatecontractor`,
			worker
		);

		if (response.status === 200) {
			dispatch(creatitngContractorSuccess(response.data));
		}
		return response;
	} catch (e) {
		dispatch(
			creatitngContractorFailure('Something went wrong while getting users!')
		);
		return e;
	}
};

export default slice.reducer;
